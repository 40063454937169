import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Icon } from '@iq/react-components';

import Pill from '../../Pill';
import services from '../../../services';
import { displayNotification, checkIsOnline } from '../../../bundles/notifications';
import getNotification from '../../../bundles/notification-defaults';
import { getSharedCacheKey, useAsync } from '../../../utils';

const UserEventStatus = ({ event, withCount = false }) => {
  const dispatch = useDispatch();
  const eventId = event.id;
  const { response: comments = [] } = useAsync(
    async (cache) => {
      const { values = [] } = await cache(
        () =>
          services.collaboration.getComments({ parentId: eventId }).then(
            (res) => res,
            (e) => {
              console.error('Unable to fetch comments: ', e);
              dispatch(checkIsOnline());
              dispatch(displayNotification(getNotification('getComments', 'error')()));
              return {};
            }
          ),
        getSharedCacheKey('collaboration/getComments', eventId)
      );
      return values;
    },
    [eventId]
  );
  const hasComments = comments.length > 0;
  const hasAttachments =
    event.files.length ||
    comments.some((comment) => comment.relations.some((rel) => rel.entityType === 'file'));

  const getNumberAttachments = useCallback(() => {
    let count = event.files.length;
    comments.forEach((comment) => {
      count += comment.relations.length;
    });
    return count;
  }, [event, comments]);

  return (
    <div className="event-timeline-user-event-status">
      {hasComments && (
        <div className="event-timeline-user-event-status__comments">
          <Icon
            icon="he-chat"
            size="s"
          />
          {withCount && <Pill text={comments.length} />}
        </div>
      )}
      {hasAttachments && (
        <div className="event-timeline-user-event-status__attachments">
          <Icon
            icon="he-attachment"
            size="s"
          />
          {withCount && <Pill text={getNumberAttachments()} />}
        </div>
      )}
    </div>
  );
};

export default UserEventStatus;
