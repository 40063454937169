import Visualization, {
  schema,
  uiSchema,
  config,
  component,
  visualizations,
} from './Visualization';
import './Visualization.scss';

export default Visualization;
export { schema, uiSchema, config, component, visualizations };
