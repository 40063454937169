import DisplayPropertiesPicker from '../widgets/DisplayPropertiesPicker';

const displaySchema = {
  type: 'object',
  properties: {
    componentIsActive: {
      type: ['boolean', 'null'],
      title: 'When a component is selected',
      default: null,
      anyOf: [
        {
          const: null,
          title: 'No setting',
        },
        {
          const: true,
          title: 'SHow',
        },
        {
          const: false,
          title: 'Hide',
        },
      ],
    },
  },
};

const displayUiSchema = {
  componentIsActive: {
    'ui:widget': DisplayPropertiesPicker,
  },
};

export { displaySchema, displayUiSchema };
