import React, { useEffect, useContext } from 'react';

import AppContext from '../../AppContext';

import Section from '../Section';
import Column from '../Column';
import Panel from '../Panel';

const HeroSection = ({ site, pageIndex, viewer = {}, hiddenPanels, onHidePanel }) => {
  const { display = true, panels = [], showFullHeight = false, ...viewerProps } = viewer;
  const { isViewerFullScreen } = useContext(AppContext);
  const { setIsFullScreen } = useContext(AppContext);

  useEffect(() => {
    if (!display) setIsFullScreen(false);
  }, [display]);

  return (
    <Section
      className={`hero-section-component ${display ? 'visible' : 'hidden'} ${
        showFullHeight ? 'full-height' : ''
      }`}
      align
    >
      <Column>
        <Panel
          pageIndex={pageIndex}
          sectionIndex={'viewer'}
          type="viewer"
          site={site}
          options={{ display, ...viewerProps }}
        />
      </Column>
      {panels.length > 0 && display ? (
        <Column>
          {panels.map((panel) => (
            <Panel
              hidePanel={
                hiddenPanels.includes(panel.id) ||
                (isViewerFullScreen && panel.options && !panel.options.allowFloat)
              }
              pageIndex={pageIndex}
              sectionIndex={'viewer'}
              key={panel.id}
              site={site}
              type={panel.type}
              options={panel.options}
              onHidePanel={onHidePanel}
              {...panel}
            />
          ))}
        </Column>
      ) : null}
    </Section>
  );
};

export default HeroSection;
