/* eslint-disable default-param-last */
import {
  call,
  race,
  take,
  takeLatest,
  takeEvery,
  delay,
  select,
  put,
  debounce,
} from 'redux-saga/effects';

import {
  getEventTypes,
  uploadEventsFile,
  getUserEvents as getUserEventsApi,
  getEvents as getEventsApi,
  deleteUserEvent as deleteUserEventApi,
  excludeSeriesEvent as excludeSeriesEventApi,
} from '../services';
import { displayNotification, removeNotification, checkOnline, getTempId } from './notifications';
import getNotification from './notification-defaults';
import { uploadFileStarted, uploadFileCompleted, clearUploaded, getUploading } from './files';
import { CLEAR_SITE_DATA, REFRESH_VALUES } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const FETCH_USER_EVENT_TYPES = 'dt/events/FETCH_USER_EVENT_TYPES';
const RECEIVE_USER_EVENT_TYPES = 'dt/events/RECEIVE_USER_EVENT_TYPES';
const REQUEST_NOTIFY_EVENTS = 'dt/events/REQUEST_NOTIFY_EVENTS';
const DELETE_USER_EVENT = 'dt/events/DELETE_USER_EVENT';
const EXCLUDE_USER_EVENT_OCCURRENCE = 'dt/events/EXCLUDE_USER_EVENT_OCCURRENCE';
const RECEIVE_USER_EVENTS = 'dt/events/RECEIVE_USER_EVENTS';
const RECEIVE_SERIES_EVENTS = 'dt/events/RECEIVE_SERIES_EVENTS';
const FETCH_SERIES_EVENTS = 'dt/events/FETCH_SERIES_EVENTS';
const FETCH_EXPORT_EVENTS = 'dt/events/FETCH_EXPORT_EVENTS';
const STOP_FETCH_EXPORT_EVENTS = 'dt/events/STOP_FETCH_EXPORT_EVENTS';
const EXPORTING_DONE = 'dt/events/EXPORTING_DONE';
const RECEIVE_NOTIFY_EVENTS = 'dt/events/RECEIVE_NOTIFY_EVENTS';
const UPLOAD_IOT_EVENTS_FILE = 'dt/events/UPLOAD_IOT_EVENTS_FILE';
const IOT_EVENTS_UPDATED = 'dt/events/IOT_EVENTS_UPDATED';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestUserEventTypes = () => ({
  type: FETCH_USER_EVENT_TYPES,
});

export const receiveUserEventTypes = (types) => ({
  type: RECEIVE_USER_EVENT_TYPES,
  userEventTypes: types,
});

export const requestNotifyEvents = (siteId) => ({
  type: REQUEST_NOTIFY_EVENTS,
  siteId,
});

export const receiveUserEvents = (events) => ({
  type: RECEIVE_USER_EVENTS,
  events,
});

export const receiveSeriesEvents = (events) => ({
  type: RECEIVE_SERIES_EVENTS,
  events,
});

export const requestSeriesEvents = (query, callback) => ({
  type: FETCH_SERIES_EVENTS,
  query,
  callback,
});

export const receiveNotifyEvents = (eventCounts) => {
  return {
    type: RECEIVE_NOTIFY_EVENTS,
    eventCounts,
  };
};

export const deleteUserEvent = (eventId, callback) => ({
  type: DELETE_USER_EVENT,
  eventId,
  callback,
});

export const excludeUserEventOccurrence = (event, callback) => ({
  type: EXCLUDE_USER_EVENT_OCCURRENCE,
  event,
  callback,
});

export const addIotEventsFile = (file) => ({
  type: UPLOAD_IOT_EVENTS_FILE,
  file,
});

const iotEventsAdded = (updatedAt) => ({
  type: IOT_EVENTS_UPDATED,
  updatedAt,
});

export const fetchExportEvents = ({ eventType, query, perRequestCallback, exportCallback }) => ({
  type: FETCH_EXPORT_EVENTS,
  eventType,
  query,
  perRequestCallback,
  exportCallback,
});

export const stopFetchExportEvents = () => ({ type: STOP_FETCH_EXPORT_EVENTS });

const exportingDone = () => ({
  type: EXPORTING_DONE,
});

// Initial state
/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  iotUpdatedAt: undefined,
  userEventTypes: [],
  isExporting: false,
  events: [],
  seriesEvents: [],
  eventNotifications: {
    overdueCount: 0,
    todoCount: 0,
    notifiedEvents: [],
  },
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_USER_EVENT_TYPES: {
      return { ...state, userEventTypes: action.userEventTypes };
    }
    case RECEIVE_USER_EVENTS: {
      return { ...state, events: action.events };
    }
    case RECEIVE_SERIES_EVENTS: {
      return { ...state, seriesEvents: action.events };
    }
    case RECEIVE_NOTIFY_EVENTS: {
      return {
        ...state,
        eventNotifications: {
          ...state.eventNotifications,
          overdueCount: action.eventCounts.overdueCount,
          todoCount: action.eventCounts.todoCount,
          notifiedEvents: action.eventCounts.notifiedEvents,
        },
      };
    }
    case IOT_EVENTS_UPDATED: {
      return { ...state, iotUpdatedAt: action.updatedAt };
    }
    case FETCH_EXPORT_EVENTS: {
      return { ...state, isExporting: true };
    }
    case EXPORTING_DONE: {
      return { ...state, isExporting: false };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        iotUpdatedAt: undefined,
        userEventTypes: [],
        isExporting: false,
        events: [],
        seriesEvents: [],
        eventNotifications: {
          overdueCount: 0,
          todoCount: 0,
          notifiedEvents: [],
        },
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getUserEventTypes = (state) => state.events.userEventTypes;
export const getUserEvents = (state) => state.events.events;
export const getSeriesEvents = (state) => state.events.seriesEvents;
export const getIotUpdated = (state) => state.events.iotUpdatedAt;
export const getIsExporting = (state) => state.events.isExporting;
export const getEventNotifications = (state) => state.events.eventNotifications;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doGetUserEventTypes() {
  // TO DO: site specific event types?
  try {
    const { values: userEventTypes } = yield call(getEventTypes);
    yield put(
      receiveUserEventTypes(userEventTypes.sort(({ id: a }, { id: b }) => a.localeCompare(b)))
    );
  } catch (e) {
    console.error('Unable to fetch user event types: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getEventTypes', 'error')()));
  }
}

function* doUploadEventsFile(action) {
  const { file } = action;
  const formData = new FormData();
  Object.keys(file).forEach((key) => {
    if (Array.isArray(file[key])) {
      file[key].forEach((item) => {
        formData.append(`${key}[]`, item);
      });
    } else {
      formData.append(key, file[key]);
    }
  });

  yield put(uploadFileStarted(file));
  const tempId = getTempId();
  yield put(displayNotification(getNotification('injectEvents', 'pre')(file.file.name)));

  try {
    const upload = yield call(uploadEventsFile, formData, file.source);
    yield put(removeNotification(tempId));

    const errs = upload.errors;
    if (errs.length > 0) {
      console.error(errs);
      yield put(uploadFileCompleted(file, 'ERROR'));
      yield put(
        displayNotification(
          getNotification('injectEvents', 'partialSuccess')(upload.eventsAdded, errs)
        )
      );
    } else {
      yield put(uploadFileCompleted(file, 'SUCCESS'));
      yield put(
        displayNotification(getNotification('injectEvents', 'success')(upload.eventsAdded))
      );
    }
    yield put(iotEventsAdded(new Date().getTime()));
  } catch (e) {
    console.error('Unable to inject IoT events: ', e);
    yield call(checkOnline);
    yield put(uploadFileCompleted(file, 'ERROR'));
    yield put(displayNotification(getNotification('injectEvents', 'error')(file.file.name)));
  }
  yield delay(3000);
  const numberUploads = yield select(getUploading);
  if (numberUploads && numberUploads.length === 0) {
    yield put(clearUploaded());
  }
}

function* doGetNotifyEvents(action) {
  const { siteId } = action;
  try {
    const eventNotifications = yield select(getEventNotifications);
    const { data: events } = yield call(getUserEventsApi, {
      site: siteId,
      state: ['overdue', 'todo'],
    });

    const newEvents = events.filter(
      (event) => !(eventNotifications.notifiedEvents || []).includes(event.id)
    );

    yield put(
      receiveNotifyEvents({
        overdueCount: newEvents.filter((e) => e.state === 'overdue').length,
        todoCount: newEvents.filter((e) => e.state === 'todo').length,
        notifiedEvents: events.map((event) => event.id),
      })
    );
  } catch (e) {
    yield console.error('Unable to fetch user events: ', e);
    yield call(checkOnline);
  }
}

function* doGetSeriesEvents(action) {
  const { query, callback } = action;
  try {
    const { data: events = [] } = yield call(getUserEventsApi, query);
    yield put(receiveSeriesEvents(events));
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (e) {
    yield console.error('Unable to fetch series events: ', e);
    yield call(checkOnline);
  }
}

const requestEvents = async (query, perRequestCallback, iteration = 0) => {
  const { values, total, sourceOptions } = await getEventsApi(query);
  perRequestCallback(iteration + 1);
  // if we have 10k events we can assume we need to make another req as we've hit the limit
  if (values.length === 10000) {
    const { values: nextValues, total: nextTotal } = await requestEvents(
      { ...query, sourceOptions },
      perRequestCallback,
      iteration + 1
    );
    return { values: [...values, ...nextValues], total: total + nextTotal };
  }
  return { values, total };
};

function* doFetchExportEvents(action) {
  // fetch up to 300k events for export
  const { eventType, query, exportCallback, perRequestCallback } = action;
  try {
    if (eventType === 'iot') {
      // fetch, recursively until all events
      const { values: allEvents } = yield call(requestEvents, query, perRequestCallback);
      yield call(exportCallback, allEvents);
    }
    if (eventType === 'user') {
      const { data: allEvents, eventsStats: stats } = yield call(getUserEventsApi, {
        ...query,
        withRecurring: true,
      });
      yield call(exportCallback, query.statistics ? stats : allEvents);
    }
    yield put(exportingDone());
  } catch (e) {
    yield console.error('Unable to export user events: ', e);
    yield call(exportCallback);
    yield put(displayNotification(getNotification('exportEvents', 'error')()));
    yield call(checkOnline);
  }
}

function* doDeleteUserEvent(action) {
  const { eventId, callback } = action;
  try {
    yield call(deleteUserEventApi, eventId);
    yield put(displayNotification(getNotification('deleteEvent', 'success')(eventId)));
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (e) {
    console.error('Unable to delete event: ', e);
    yield put(displayNotification(getNotification('deleteEvent', 'error')(eventId)));
    yield call(checkOnline);
  }
}

function* doExcludeUserEventOccurrence(action) {
  const { event, callback } = action;
  try {
    yield call(excludeSeriesEventApi, event);
    yield put(displayNotification(getNotification('deleteEvent', 'success')(event.id)));
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (e) {
    console.error('Unable to delete event: ', e);
    yield put(displayNotification(getNotification('deleteEvent', 'error')(event.id)));
    yield call(checkOnline);
  }
}

function* doStartFetchExportEvents(action) {
  yield race([call(doFetchExportEvents, action), take(STOP_FETCH_EXPORT_EVENTS)]);
}

export const sagas = [
  takeLatest(FETCH_USER_EVENT_TYPES, doGetUserEventTypes),
  takeEvery(UPLOAD_IOT_EVENTS_FILE, doUploadEventsFile),
  takeLatest(REQUEST_NOTIFY_EVENTS, doGetNotifyEvents),
  takeLatest(FETCH_SERIES_EVENTS, doGetSeriesEvents),
  takeLatest(DELETE_USER_EVENT, doDeleteUserEvent),
  takeLatest(EXCLUDE_USER_EVENT_OCCURRENCE, doExcludeUserEventOccurrence),
  takeLatest(FETCH_EXPORT_EVENTS, doStartFetchExportEvents),
  debounce(500, REFRESH_VALUES, doGetNotifyEvents),
];
