const getOptions = function (vizCount) {
  const options = Array(vizCount)
    .fill()
    .map((_, i) => i + 1);
  return [
    { const: 'none', title: 'None' },
    ...options.map((num) => ({
      const: num,
      title: num,
    })),
  ];
};
const getVisualizationSettingsSchema = (vizCount = 5) => ({
  type: 'object',
  properties: {
    priority: {
      type: ['number', 'string'],
      title: 'Priority',
      anyOf: getOptions(vizCount),
    },
  },
});

export default getVisualizationSettingsSchema;
