.step-navigator {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
.step-navigator > div:last-child {
  display: flex;
  justify-content: flex-end;
}
.step-navigator > div:last-child > button:not(:last-child) {
  margin-right: 0.5rem;
}
.step-navigator__button-next {
  margin-left: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9XaXphcmQvY29tcG9uZW50cy9TdGVwTmF2aWdhdG9yIiwic291cmNlcyI6WyJTdGVwTmF2aWdhdG9yLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7O0FBR0o7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5zdGVwLW5hdmlnYXRvciB7XG4gIG1hcmdpbi10b3A6IDJyZW07XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7XG5cbiAgPiBkaXY6bGFzdC1jaGlsZCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kO1xuXG4gICAgPiBidXR0b246bm90KDpsYXN0LWNoaWxkKSB7XG4gICAgICBtYXJnaW4tcmlnaHQ6IDAuNXJlbTtcbiAgICB9XG4gIH1cbiAgJl9fYnV0dG9uLW5leHQge1xuICAgIG1hcmdpbi1sZWZ0OiAxcmVtO1xuICB9XG59XG4iXX0= */