import React from 'react';

import { Icon } from '@iq/react-components';

const Pill = ({ icon, text, onClick, ...props }) => (
  <div
    className={`pill-component ${typeof onClick === 'function' ? 'clickable' : ''}`}
    onClick={onClick}
    {...props}
  >
    {icon ? (
      <Icon
        icon={icon}
        size="s"
      />
    ) : null}
    <div className="text">{text}</div>
  </div>
);

export default Pill;
