import { Button, Icon } from '@iq/react-components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { dismissSystemMessage } from '../../../bundles/systemMessages';

const SystemMessageItem = ({ systemMessage }) => {
  const dispatch = useDispatch();

  return (
    <div className="system-message-item">
      <div className="close-button">
        <Button
          activity="secondary"
          slim
          onClick={() => dispatch(dismissSystemMessage(systemMessage.id))}
        >
          <Icon
            size="xs"
            icon="he-close"
          />
        </Button>
      </div>
      <strong> {systemMessage.title} </strong>
      <div dangerouslySetInnerHTML={{ __html: systemMessage.body }}></div>
    </div>
  );
};

export default SystemMessageItem;
