import React, { Children } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iq/react-components';
import HealthRiskItem from './HealthListItem';
import { STATUSES } from '../../../constants';
import { getActiveComponent, getComponentsStatus } from '../../../bundles/components';
import { getPageType } from '../../../bundles/application';
import { getSources } from '../../../bundles/sources';

const HealthPanel = ({
  showComponentName = true,
  showStatus = true,
  showSuggestions = true,
  format,
  showRiskIfNoActiveComponent = true,
}) => {
  const componentsStatus = useSelector(getComponentsStatus);
  const { id, name, itemDesignation, icon } = useSelector(getActiveComponent) || {};
  const pageType = useSelector(getPageType);
  const sources = useSelector(getSources);
  const elasticSearchSource = sources.find((s) => s.type === 'elasticsearch');
  const riskThreshold = elasticSearchSource?.options?.riskThreshold || {};

  const showAllRisks = showRiskIfNoActiveComponent && !id;

  if (!showRiskIfNoActiveComponent && !id) {
    return <div className="health-panel-component empty">No component selected</div>;
  }
  const componentStatus = componentsStatus.find((c) => c.id === id) || {};

  const getStatusColor = (s) => (STATUSES[s] || STATUSES.default).color;
  const getStatusIcon = (s) => (STATUSES[s] || STATUSES.default).icon;
  const getRGB = (s) => (STATUSES[s] || STATUSES.default).color.join();

  const getContent = () => {
    const contentObj = {
      // Handle for None and StateSet page types in future if needed
      risk: {
        showRiskStatus: showStatus,
        riskStatus: showAllRisks
          ? componentsStatus
              .filter((c) => c?.eventRiskStatus?.length > 0)
              .flatMap((r) => r.eventRiskStatus)
          : componentsStatus.find((c) => c.id === id && c?.eventRiskStatus?.length > 0)
              ?.eventRiskStatus,
      },
      health: {
        showHealthStatus: showStatus,
        healthStatus: componentStatus?.status,
        statusText: componentStatus?.statusText,
        healthSuggestions: componentStatus?.suggestions,
      },
    };
    return contentObj[pageType] || {};
  };

  const {
    showHealthStatus,
    healthStatus,
    healthSuggestions,
    statusText,
    showRiskStatus,
    riskStatus,
  } = getContent();

  return (
    <div className={`health-panel-component ${format}`}>
      {showComponentName && !showAllRisks && (
        <div className="component-header">
          <div>
            <div className="component-name">{name}</div>
            <div className="component-id">{itemDesignation}</div>
          </div>
          <Icon icon={icon} />
        </div>
      )}
      {!showHealthStatus && !showRiskStatus && (
        <div className="component-status--empty">No status available</div>
      )}
      {showHealthStatus &&
        (healthStatus ? (
          <div
            className="component-status--wrapper"
            style={{
              backgroundColor: `rgba(${getRGB(healthStatus)}, ${
                getStatusColor(healthStatus) ? 1 : 0
              })`,
            }}
          >
            <div className="component-status--icon">
              <Icon icon={getStatusIcon(healthStatus)} />
            </div>
            <div className="component-status--text">{statusText}</div>
          </div>
        ) : (
          <div className="component-status--empty">No Health available</div>
        ))}
      {showSuggestions && healthSuggestions && (
        <div className="component-suggestion--wrapper">
          <div className="component-suggestion--title">Suggestions</div>
          {healthSuggestions.length !== 0 ? (
            <ul className="component-suggestion--list">
              {healthSuggestions.map((suggestion, i) => (
                <li key={i}>{suggestion}</li>
              ))}
            </ul>
          ) : (
            <div className="component-suggestion--empty">No suggestions</div>
          )}
        </div>
      )}
      {showRiskStatus && (
        <div className="component-suggestion--wrapper">
          {riskStatus && riskStatus.length !== 0 ? (
            <div className="risk-lists">
              {Children.toArray(
                riskStatus
                  .sort((x, y) => y.eventDateTime - x.eventDateTime)
                  .map((risk) => {
                    return (
                      <HealthRiskItem
                        risk={risk}
                        riskThreshold={riskThreshold}
                      />
                    );
                  })
              )}
            </div>
          ) : (
            <div className="component-status--empty">No Risk available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default HealthPanel;
