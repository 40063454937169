.image-preview-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 20vw;
  height: 100%;
  text-align: center;
  max-height: calc(100vh - 8rem);
  padding: 0 0 1rem 1rem;
}
.image-preview-content img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
}
.image-preview-content .image-pan-wrapper {
  width: 100%;
  max-height: calc(100vh - 20rem);
}

.image-preview-button-row {
  display: flex;
  display: none;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  margin: 15px 0;
}
.image-preview-button-row button:first-child {
  margin-right: 10px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvRmlsZVByZXZpZXdNb2RhbC90eXBlcy9JbWFnZVByZXZpZXciLCJzb3VyY2VzIjpbIkltYWdlUHJldmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7OztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuaW1hZ2UtcHJldmlldy1jb250ZW50IHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIG92ZXJmbG93OiBoaWRkZW47XG4gIG1pbi13aWR0aDogMjB2dztcbiAgaGVpZ2h0OiAxMDAlO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIG1heC1oZWlnaHQ6IGNhbGMoMTAwdmggLSA4cmVtKTtcbiAgcGFkZGluZzogMCAwIDFyZW0gMXJlbTtcblxuICBpbWcge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgICBtYXgtaGVpZ2h0OiAxMDAlO1xuICAgIG9iamVjdC1maXQ6IGNvbnRhaW47XG4gIH1cblxuICAuaW1hZ2UtcGFuLXdyYXBwZXIge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIG1heC1oZWlnaHQ6IGNhbGMoMTAwdmggLSAyMHJlbSk7XG4gIH1cbn1cblxuLmltYWdlLXByZXZpZXctYnV0dG9uLXJvdyB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGRpc3BsYXk6IG5vbmU7XG4gIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBmbGV4OiAxO1xuICBtYXJnaW46IDE1cHggMDtcblxuICBidXR0b246Zmlyc3QtY2hpbGQge1xuICAgIG1hcmdpbi1yaWdodDogMTBweDtcbiAgfVxufVxuIl19 */