import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Button, colors } from '@iq/react-components';

import StyledItem from '../../../StyledItem';
import { datetimeString, utcToSite } from '../../../../datetimeUtils';
import { getTimezone } from '../../../../bundles/application';

const STATUS_COLORS = {
  CREATED: colors.StatusBlue,
  PENDING: colors.StatusYellow,
  DOWNLOADING: colors.StatusBlue,
  PROCESSING: colors.StatusBlue,
  UPLOADING: colors.StatusBlue,
  COMPLETED: colors.StatusGreen,
  FAILED: colors.StatusRed,
};

const JobListItem = ({ job, file }) => {
  const timezone = useSelector(getTimezone);
  const [isOpen, setOpen] = useState(false);

  const { status, processedOn, stacktrace = [], failedReason } = job;

  return (
    <StyledItem
      itemClass="job-list-item"
      active={isOpen}
      itemPre={
        <div
          className="job-status-bar"
          style={{
            background: STATUS_COLORS[job.status],
            border: `2px solid ${STATUS_COLORS[job.status]}`,
          }}
        />
      }
      headerContent={
        <>
          <div className="job-status">
            <strong>{status} </strong>
          </div>
          <div>
            {processedOn ? (
              <strong>{datetimeString(utcToSite(processedOn, timezone), timezone)}</strong>
            ) : (
              '-'
            )}
          </div>
        </>
      }
      headerActions={
        <>
          {status === 'FAILED' && (
            <Button
              slim
              activity="secondary"
              onClick={() => setOpen(!isOpen)}
            >
              Show Error
            </Button>
          )}

          {file && (
            <a
              href={`${window.location.protocol}//${file.resourceUrl}`}
              download
            >
              <Icon icon="he-download" />
            </a>
          )}
        </>
      }
    >
      <div className="job-error">
        {status === 'FAILED' && isOpen && (
          <div className="job-error__content">
            <div className="job-error__content--failed-reason">{failedReason}</div>
            <div className="job-error__content--stacktraces">
              {stacktrace.map((message, i) => (
                <pre key={i}> {message} </pre>
              ))}
            </div>
          </div>
        )}
      </div>
    </StyledItem>
  );
};

export default JobListItem;
