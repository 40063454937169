import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@iq/react-components';

import VisualizationEditor from '../../VisualizationEditor';
import Visualization from '../../Visualization';
import Loader from '../../Loader';
import Pagination from '../../Pagination';
import VisualizationsHeader from './components/VisualizationsHeader';
import ExportTimeSeriesModal from '../../SiteAdminView/VariableView/components/ExportTimeSeriesModal';

import { getHasPermission } from '../../../bundles/auth';
import { getVisualizations, resetVisualizations } from '../../../bundles/visualizations';
import {
  getStaticComponents,
  getComponentsUpdatedAt,
  getScopedComponents,
  getFilteredActiveComponentId,
} from '../../../bundles/components';
import {
  useDateSubscription,
  stopSitePolling,
  startSitePolling,
  getTimezone,
} from '../../../bundles/application';
import { SignalViewerContainer } from '../../SignalViewerContainer';
import { requestTfrTemplates } from '../../../bundles/tfr-templates';

const VisualizationsPanel = ({
  visualizations: includedVisualizations = [],
  visualizationTypes = [],
  visualizationLimit = 10,
  width = 'medium',
  height = 'medium',
  format = 'wide',
  syncVisualizationTooltip = false,
  componentScope,
  autoPopulate = false,
  hideToolbar = true,
  isFullScreen = false,
  site,
  panelId,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { org, id: siteId } = site || {};
  const timezone = useSelector(getTimezone);
  const visualizations = useSelector(getVisualizations)?.sort((a, b) => {
    const {
      configuration: { priority: aPriority },
    } = a;
    const {
      configuration: { priority: bPriority },
    } = b;
    return /[A-Za-z]/.test(aPriority) - /[A-Za-z]/.test(bPriority) || aPriority - bPriority;
  });
  const visualizationNames = visualizations.map((v) => v.name.toLowerCase());
  const activeComponentId = useSelector(getFilteredActiveComponentId);
  const components = useSelector(getStaticComponents);
  const componentsUpdatedAt = useSelector(getComponentsUpdatedAt);
  const canEditVisualizations = useSelector((state) =>
    getHasPermission(state, 'visualizations/Write', { org, site: siteId })
  );

  const granularityMap = {
    '1 min': '60s',
    '15 min': '900s',
    '30 min': '1800s',
    '1 hr': '3600s',
    '1 day': '86400s',
    '1 wk': '604800s',
    '1 mo': '2592000s',
    '3 mo': '7862400s',
    '1 yr': '31536000s',
  };

  const pollingDateRange = useDateSubscription(panelId);
  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const [signalViewer, setSignalViewer] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedVisualizations, setSelectedVisualizations] = useState([]);
  const [showExportForm, setShowExportForm] = useState(false);
  const [exportGranularity, setExportGranularity] = useState(granularityMap['1 day']);

  useEffect(() => {
    setExportGranularity(pollingDateRange.granularity);
  }, [pollingDateRange]);

  const scopedComponentIds = useMemo(
    () =>
      getScopedComponents({
        components,
        componentsUpdatedAt,
        activeComponentId,
        componentScope: JSON.stringify(componentScope),
      }).map((c) => c.id),
    [componentsUpdatedAt, activeComponentId, componentScope]
  );

  useEffect(() => {
    dispatch(requestTfrTemplates({ site: site.id, templateType: 'signal' }));
  }, [site.id]);

  useEffect(() => {
    if (signalViewer) dispatch(stopSitePolling());
  }, [signalViewer]);

  const handleCloseExportModal = useCallback(() => setShowExportForm(false), [setShowExportForm]);

  const scopedVisualizations = useMemo(() => {
    if (
      autoPopulate &&
      !activeComponentId &&
      componentScope.selectedScope === 'all' &&
      visualizations
    ) {
      if (visualizationTypes.length) {
        return visualizations.filter((viz) => visualizationTypes.includes(viz.type));
      }
      return visualizations;
    }

    if (autoPopulate && scopedComponentIds && visualizations) {
      return visualizations.filter((viz) => {
        // if we've specifically included the visualization
        if (includedVisualizations.includes(viz.id)) {
          return true;
        }
        // otherwise ignore if we've specified types to show and it's not of type
        if (visualizationTypes.length && !visualizationTypes.includes(viz.type)) {
          return false;
        }
        // else check if it falls in component scope
        const compIds = (viz.relations || [])
          .filter((rel) => rel?.entity_type?.includes('component'))
          .map((rel) => rel.entity_id);
        let inScope = false;
        compIds.forEach((id) => {
          if (scopedComponentIds.includes(id)) {
            inScope = true;
          }
        });
        return inScope;
      });
    }
    return includedVisualizations.map((id) => visualizations.find((v) => v.id === id));
  }, [
    autoPopulate,
    includedVisualizations,
    activeComponentId,
    componentScope,
    scopedComponentIds,
    visualizations,
    visualizationTypes,
  ]);

  useEffect(() => {
    setPage(1);
  }, [filterValue]);

  const [activeScopedVisualizations, pages] = useMemo(() => {
    const offset = (page - 1) * visualizationLimit;
    const filters = filterValue?.trim().split(' ');
    const filteredVisualizations = filters
      ? scopedVisualizations.filter((v) =>
          filters.every((filter) => v?.name?.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1)
        )
      : scopedVisualizations;

    return [
      filteredVisualizations.slice(offset, offset + visualizationLimit),
      Math.ceil(filteredVisualizations.length / visualizationLimit),
    ];
  }, [filterValue, page, visualizationLimit, scopedVisualizations]);

  const noVisualization = (text) => <div className="empty-visualization">{text}</div>;

  if (components.length === 0) {
    return <Loader />;
  }

  if (scopedVisualizations.length === 0) {
    return noVisualization('No visualizations available');
  }

  let formattedWidth = {
    narrow: 'medium-small',
    wide: 'medium',
    widest: 'large',
  }[format];

  if (format !== 'narrow' || !['large', 'medium'].includes(width)) {
    formattedWidth = width;
  }

  const handleOnClose = () => {
    setSelected(null);
    dispatch(resetVisualizations());
  };

  const onFormReset = () => {
    setSelectedVisualizations([]);
    setAllSelected(false);
    setSignalViewer(false);
    setShowExportForm(false);
    dispatch(startSitePolling());
  };

  const toggleAllSelected = () => {
    // Consider only graph, duval and star visualizations
    const validAllSelectableTypes = ['graph', 'duval', 'star'];
    setSelectedVisualizations(
      allSelected
        ? []
        : activeScopedVisualizations.filter((v) => validAllSelectableTypes.includes(v.type))
    );
    setAllSelected((prev) => {
      return !prev;
    });
  };

  const toggleSelect = (visualization) => {
    setSelectedVisualizations((viz) => {
      if (!viz.find((v) => v.id === visualization.id)) {
        return [...viz, visualization];
      }
      setAllSelected(false);
      return viz.filter((item) => item.id !== visualization.id);
    });
  };
  // Since the duval and star point don't display all signals,
  // we need to extract the signals that are actually assigned to plots
  const selectedVizVariables = () => {
    return selectedVisualizations.flatMap((viz) => {
      switch (viz.type) {
        case 'duval': {
          return viz.configuration.plots
            .flatMap((plot) => [plot.leftId, plot.rightId, plot.bottomId])
            .map((signalId) => viz.variables.find((v) => v.id === signalId?.split('.')[0]));
        }
        case 'star': {
          const { L1, L2, L3 } = viz.configuration.plot;
          return [L1, L2, L3]
            .map((signal) => {
              return viz.variables.find((v) => v.id === signal?.split('.')[0]);
            })
            .filter((variable) => variable);
        }
        default:
          return viz.variables;
      }
    });
  };

  return (
    <>
      <VisualizationsHeader
        filterValue={filterValue}
        format={format}
        hideToolbar={hideToolbar}
        isFullScreen={isFullScreen}
        setFilterValue={setFilterValue}
        panelId={panelId}
        anySelected={selectedVisualizations.length > 0}
        allSelected={allSelected}
        toggleAllSelected={toggleAllSelected}
        openSignalViewer={() => setSignalViewer(true)}
        exportTimeSeries={() => setShowExportForm(true)}
      />
      {activeScopedVisualizations.length ? (
        <>
          {signalViewer && (
            <SignalViewerContainer
              selectedVisualizations={selectedVisualizations}
              pollingDateRange={pollingDateRange}
              site={site}
              onFormReset={onFormReset}
              theme={theme}
              tz={timezone}
            ></SignalViewerContainer>
          )}
          {showExportForm && selectedVisualizations?.length > 0 && (
            <ExportTimeSeriesModal
              selectedVizVariables={selectedVizVariables()}
              onCloseModal={handleCloseExportModal}
              showAggregation={false}
              exportGranularity={exportGranularity}
              onExportCompleted={onFormReset}
              parentPanelId={panelId}
            />
          )}
          <div
            className={`visualizations-panel-component width-${formattedWidth} height-${height}`}
          >
            {activeScopedVisualizations.map((visualization) => {
              if (!visualization) return null;
              return (
                <React.Fragment key={`${visualization.id}`}>
                  <div
                    className="visualization-wrapper"
                    onDoubleClick={() => {
                      if (canEditVisualizations) {
                        setSelected(visualization.id);
                      }
                    }}
                    style={{ marginBottom: `${visualization.variables.length * 6.5}px` }}
                  >
                    <Visualization
                      syncVisualizationTooltip={syncVisualizationTooltip}
                      panelId={panelId}
                      visualization={visualization}
                      pollingDateRange={pollingDateRange}
                      selected={!!selectedVisualizations.find((v) => v.id === visualization.id)}
                      onSelect={toggleSelect}
                      exportGranularity={exportGranularity}
                      openSignalViewer={() => {
                        const viz = [];
                        viz.push(visualization);
                        setSelectedVisualizations(viz);
                        setSignalViewer(true);
                      }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
            {pages > 1 && (
              <Pagination
                pages={pages}
                page={page}
                setPage={setPage}
              />
            )}
            {selected !== null && (
              <VisualizationEditor
                visualizationId={selected}
                visualizationNames={visualizationNames}
                onClose={() => handleOnClose()}
                parentPanelId={panelId}
              />
            )}
          </div>
        </>
      ) : (
        noVisualization('No visualizations found')
      )}
    </>
  );
};

export default VisualizationsPanel;
