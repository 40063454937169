import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import { getLastRefresh } from '../../../bundles/application';

const PageLastUpdated = () => {
  const [lastUpdatedText, setLastUpdatedText] = useState('');

  const lastUpdatedAt = useSelector(getLastRefresh);

  useEffect(() => {
    const interval = setInterval(() => {
      // Only refresh every 5 seconds
      if (differenceInSeconds(lastUpdatedAt, Date.now()) % 5 !== 0) return;

      let text = '';

      if (lastUpdatedAt) {
        text = `Updated ${formatDistanceToNowStrict(lastUpdatedAt, {
          includeSeconds: true,
          addSuffix: 'ago',
        })}`;
      }

      setLastUpdatedText(text);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [lastUpdatedAt]);

  return <div className="page-last-updated-at-component">{lastUpdatedText}</div>;
};

export default withRouter(PageLastUpdated);
