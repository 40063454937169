const sectionSchema = {
  type: 'object',
  required: ['title'],
  properties: {
    title: {
      type: 'string',
      title: 'Section title',
      default: '',
    },
    displayTitle: {
      type: 'boolean',
      title: 'Display title',
      default: false,
    },
  },
};

export default sectionSchema;
