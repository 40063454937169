/**
 * Return the value as a float with `decimals` decimal places. The value is
 * returned as is if `decimals` is set to `auto`.
 *
 */
const toFixed = (v, decimals) =>
  decimals === 'auto' || Number.isNaN(parseInt(v, 10)) ? v : parseFloat(v).toFixed(decimals);

const multiplicatives = new Map([
  [
    'tera',
    {
      exponent: 12,
      symbol: 'T',
    },
  ],
  [
    'giga',
    {
      exponent: 9,
      symbol: 'G',
    },
  ],
  [
    'mega',
    {
      exponent: 6,
      symbol: 'M',
    },
  ],
  [
    'kilo',
    {
      exponent: 3,
      symbol: 'k',
    },
  ],
  [
    null,
    {
      exponent: 0,
      symbol: '',
    },
  ],
  [
    'mili',
    {
      exponent: -3,
      symbol: 'm',
    },
  ],
  [
    'micro',
    {
      exponent: -6,
      symbol: 'µ',
    },
  ],
]);

/**
 * Convert a value from one multiplicative unit to another given that they
 * are of the same category (see units.category below).
 *
 * @example
 *
 *   convertValue('kHz', 'Hz', 1.3);
 *   // 1300
 *
 */
const convertValue = (value, fromMultiplicative = null, toMultiplicative = null) => {
  const { exponent: fromExponent } = multiplicatives.get(fromMultiplicative) || {
    exponent: 0,
  };
  const { exponent: toExponent } = multiplicatives.get(toMultiplicative) || {
    exponent: 0,
  };

  const sign = Math.sign(value);
  let normalizedValue = Math.abs(value);
  if (fromExponent > 0) normalizedValue = Math.abs(value) * 10 ** fromExponent;
  if (fromExponent < 0) normalizedValue = Math.abs(value) / 10 ** fromExponent;

  return toExponent > 0
    ? sign * (normalizedValue / 10 ** toExponent)
    : sign * (normalizedValue * 10 ** toExponent);
};

/**
 * Return an object containing a `unit` including a metric prefix and a
 * `value` converted using the correct multiple or fraction of the value for
 * that unit.
 *
 * @example
 *
 *   normalizeAndPrefixUnit('mega', 1300, 'Hz');
 *   // { unit: 'GHz', value: 1.3 }
 *
 */
const normalizeAndPrefixUnit = (inputMultiplicative, value, stem) => {
  const { exponent: inputExponent } = multiplicatives.get(inputMultiplicative) || {
    exponent: 0,
  };

  const sign = Math.sign(value);
  const normalizedValue = Math.abs(value) * 10 ** inputExponent;

  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const [, { exponent, symbol }] of multiplicatives) {
    if (normalizedValue >= 10 ** exponent) {
      return {
        value: sign * (normalizedValue / 10 ** exponent),
        unit: `${symbol}${stem}`,
      };
    }
  }

  return { value, unit: stem };
};

const units = [
  {
    id: 'variable-default',
    category: 'Default',
    name: 'Signal default',
    unit: () => '',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'celsius',
    category: 'Temperature',
    name: 'Celsius',
    unit: () => 'C',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'fahrenheit',
    category: 'Temperature',
    name: 'Fahrenheit',
    unit: () => 'F',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'number',
    category: 'Misc',
    name: 'Number',
    unit: () => '',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'percentage',
    category: 'Misc',
    name: 'Percentage',
    unit: () => '%',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'ppm',
    category: 'Misc',
    name: 'ppm',
    unit: () => 'ppm',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'year',
    category: 'Date',
    name: 'Year',
    unit: (value, decimals) => (toFixed(value, decimals) === '1' ? 'year' : 'years'),
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'month',
    category: 'Date',
    name: 'Month',
    unit: (value, decimals) => (toFixed(value, decimals) === '1' ? 'month' : 'months'),
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'day',
    category: 'Date',
    name: 'Day',
    unit: (value, decimals) => (toFixed(value, decimals) === '1' ? 'day' : 'days'),
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  // On/Off
  {
    id: 'on-off',
    category: 'Misc',
    name: '"On" or "Off"',
    unit: () => '',
    formatter: (value) => (value > 0 ? 'On' : 'Off'),
  },
  // Voltage
  {
    id: 'V',
    category: 'Voltage',
    name: 'V',
    multiplicative: null,
    unit: (value) => normalizeAndPrefixUnit(null, value, 'V').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit(null, value, 'A').value, decimals),
  },
  {
    id: 'kV',
    category: 'Voltage',
    name: 'kV',
    multiplicative: 'kilo',
    unit: (value) => normalizeAndPrefixUnit('kilo', value, 'V').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('kilo', value, 'A').value, decimals),
  },
  {
    id: 'MV',
    category: 'Voltage',
    name: 'MV',
    multiplicative: 'mega',
    unit: (value) => normalizeAndPrefixUnit('mega', value, 'V').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('mega', value, 'A').value, decimals),
  },
  // Ampere
  {
    id: 'A',
    category: 'Ampere',
    name: 'A',
    multiplicative: null,
    unit: (value) => normalizeAndPrefixUnit(null, value, 'A').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit(null, value, 'A').value, decimals),
  },
  {
    id: 'kA',
    category: 'Ampere',
    name: 'kA',
    multiplicative: 'kilo',
    unit: (value) => normalizeAndPrefixUnit('kilo', value, 'A').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('kilo', value, 'A').value, decimals),
  },
  {
    id: 'MA',
    category: 'Ampere',
    name: 'MA',
    multiplicative: 'mega',
    unit: (value) => normalizeAndPrefixUnit('mega', value, 'A').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('mega', value, 'A').value, decimals),
  },
  // Watt
  {
    id: 'W',
    category: 'Watt',
    name: 'W',
    multiplicative: null,
    unit: (value) => normalizeAndPrefixUnit(null, value, 'W').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit(null, value, 'W').value, decimals),
  },
  {
    id: 'kW',
    category: 'Watt',
    name: 'kW',
    multiplicative: 'kilo',
    unit: (value) => normalizeAndPrefixUnit('kilo', value, 'W').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('kilo', value, 'W').value, decimals),
  },
  {
    id: 'MW',
    category: 'Watt',
    name: 'MW',
    multiplicative: 'mega',
    unit: (value) => normalizeAndPrefixUnit('mega', value, 'W').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('mega', value, 'W').value, decimals),
  },
  // Watt-hours
  {
    id: 'Wh',
    category: 'Watt-hours',
    name: 'Wh',
    multiplicative: null,
    unit: (value) => normalizeAndPrefixUnit(null, value, 'Wh').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit(null, value, 'Wh').value, decimals),
  },
  {
    id: 'kWh',
    category: 'Watt-hours',
    name: 'kWh',
    multiplicative: 'kilo',
    unit: (value) => normalizeAndPrefixUnit('kilo', value, 'Wh').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('kilo', value, 'Wh').value, decimals),
  },
  {
    id: 'MWh',
    category: 'Watt-hours',
    name: 'MWh',
    multiplicative: 'mega',
    unit: (value) => normalizeAndPrefixUnit('mega', value, 'Wh').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('mega', value, 'Wh').value, decimals),
  },
  // Var
  {
    id: 'Var',
    category: 'Var',
    name: 'Var',
    multiplicative: null,
    unit: (value) => normalizeAndPrefixUnit(null, value, 'Var').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit(null, value, 'Var').value, decimals),
  },
  {
    id: 'kVar',
    category: 'Var',
    name: 'kVar',
    multiplicative: 'kilo',
    unit: (value) => normalizeAndPrefixUnit('kilo', value, 'Var').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('kilo', value, 'Var').value, decimals),
  },
  {
    id: 'MVar',
    category: 'Var',
    name: 'MVar',
    multiplicative: 'mega',
    unit: (value) => normalizeAndPrefixUnit('mega', value, 'Var').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('mega', value, 'Var').value, decimals),
  },
  // Hz
  {
    id: 'hz',
    category: 'Frequency',
    name: 'Hz',
    multiplicative: null,
    unit: (value) => normalizeAndPrefixUnit(null, value, 'Hz').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit(null, value, 'Hz').value, decimals),
  },
  {
    id: 'kHz',
    category: 'Frequency',
    name: 'kHz',
    multiplicative: 'kilo',
    unit: (value) => normalizeAndPrefixUnit('kilo', value, 'Hz').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('kilo', value, 'Hz').value, decimals),
  },
  {
    id: 'MHz',
    category: 'Frequency',
    name: 'MHz',
    multiplicative: 'mega',
    unit: (value) => normalizeAndPrefixUnit('mega', value, 'Hz').unit,
    formatter: (value, decimals) =>
      toFixed(normalizeAndPrefixUnit('mega', value, 'Hz').value, decimals),
  },
  // Pressure
  {
    id: 'psi',
    category: 'Pressure',
    name: 'psi',
    unit: () => 'psi',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  {
    id: 'bar',
    category: 'Pressure',
    name: 'bar',
    unit: () => 'bar',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
  // Time
  {
    id: 'hours',
    category: 'Time',
    name: 'Hours',
    unit: () => 'h',
    formatter: (value, decimals) => toFixed(value, decimals),
  },
];

const MULTIPLICATIVE_CATEGORIES = ['Voltage', 'Ampere', 'Watt', 'Watt-hours', 'Var', 'Frequency'];

const format = (unitId, value, decimalSetting = 'auto') => {
  const config = units.find(({ id }) => id === unitId) || {};
  const decimals = decimalSetting === 'auto' ? 'auto' : parseInt(decimalSetting, 10);

  const { unit = () => unitId || '', formatter = (v) => toFixed(v, decimals) } = config;

  return {
    formatter,
    decimals,
    value: value === null ? '-' : formatter(value, decimals),
    unit: unit(value, decimals),
  };
};

export { convertValue, units, format, MULTIPLICATIVE_CATEGORIES };
