/* eslint-disable default-param-last */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { all, call, debounce, put, select, takeLatest, takeEvery } from 'redux-saga/effects';

import { v4 as uuidv4 } from 'uuid';

import {
  bulkUpdateVisualization as bulkUpdateVisualizationApi,
  updateVisualization as updateVisualizationApi,
  deleteVisualization as deleteVisualizationApi,
  createVisualization as createVisualizationApi,
  getVisualizations as getVisualizationsApi,
  getSeries,
  getLastValues,
} from '../services';
import {
  getPollingDateRange,
  REFRESH_VALUES,
  CLEAR_SITE_DATA,
  setPollingActive,
  setPollingActiveDone,
  getRangeFromLabel,
  getRangeFromPartial,
} from './application';
import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { getSourceConnectivityTree } from './sources';

export const LAST_VALUE_VISUALIZATIONS = ['metric', 'gauge', 'bar-gauge', 'status', 'state'];

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const CREATE_VISUALIZATIONS_SUBSCRIPTION = 'dt/visualizations/CREATE_VISUALIZATIONS_SUBSCRIPTION';
const DESTROY_VISUALIZATIONS_SUBSCRIPTION = 'dt/visualizations/DESTROY_VISUALIZATIONS_SUBSCRIPTION';
const GET_SINGLE_VISUALIZATION_DATA = 'dt/visualizations/GET_SINGLE_VISUALIZATION_DATA';
const BULK_UPSERT_VISUALIZATION = 'dt/visualizations/BULK_UPSERT_VISUALIZATION';
const UPSERT_VISUALIZATION = 'dt/visualizations/UPSERT_VISUALIZATION';
const RESET_VISUALIZATION = 'dt/visualizations/RESET_VISUALIZATION';
const REQUEST_DELETE_VISUALIZATION = 'dt/visualizations/REQUEST_DELETE_VISUALIZATION';
const DELETE_VISUALIZATION_SUCCESS = 'dt/visualizations/DELETE_VISUALIZATION_SUCCESS';
const REQUEST_VISUALIZATIONS = 'dt/visualizations/REQUEST_VISUALIZATIONS';
const RECEIVE_VISUALIZATION = 'dt/visualizations/RECEIVE_VISUALIZATION';
const RECEIVE_VISUALIZATIONS = 'dt/visualizations/RECEIVE_VISUALIZATIONS';
const SAVE_VISUALIZATION = 'dt/visualizations/SAVE_VISUALIZATION';
const VISUALIZATION_SAVED = 'dt/visualizations/VISUALIZATION_SAVED';
const RECEIVED_VALUES = 'dt/visualizations/RECEIVED_VALUES';
const INIT_VISUALIZATIONS = 'dt/visualizations/INIT_VISUALIZATIONS';
const RESET_VISUALIZATIONS = 'dt/visualizations/RESET_VISUALIZATIONS';
const SET_LOADING = 'dt/visualizations/SET_LOADING';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const getSingleVisualization = (visualization, panelId) => ({
  type: GET_SINGLE_VISUALIZATION_DATA,
  visualization,
  panelId,
});

export const bulkUpsertVisualization = (visualization, siteId) => ({
  type: BULK_UPSERT_VISUALIZATION,
  visualization,
  siteId,
});

export const upsertVisualization = (siteId, visualization) => ({
  type: UPSERT_VISUALIZATION,
  siteId,
  visualization,
});

export const resetVisualization = (siteId) => ({
  type: RESET_VISUALIZATION,
  siteId,
});

export const saveVisualization = (visualization, parentPanelId) => ({
  type: SAVE_VISUALIZATION,
  visualization,
  parentPanelId,
});

export const requestVisualizations = (siteId) => ({
  type: REQUEST_VISUALIZATIONS,
  siteId,
});

export const requestDeleteVisualization = (visualizationId) => ({
  type: REQUEST_DELETE_VISUALIZATION,
  visualizationId,
});

export const deleteVisualizationSuccess = (visualizationId) => ({
  type: DELETE_VISUALIZATION_SUCCESS,
  visualizationId,
});

export const receiveVisualization = (visualization) => ({
  type: RECEIVE_VISUALIZATION,
  visualization,
});

export const receiveVisualizations = (visualizations, siteId) => ({
  type: RECEIVE_VISUALIZATIONS,
  visualizations,
  siteId,
});

export const layoutSaved = (siteId, layout, dateRange) => ({
  type: VISUALIZATION_SAVED,
  siteId,
  layout,
  dateRange,
});

export const subscribe = (visualizationId, panelId) => ({
  type: CREATE_VISUALIZATIONS_SUBSCRIPTION,
  visualizationId,
  panelId,
});

export const unsubscribe = (visualizationId, panelId) => ({
  type: DESTROY_VISUALIZATIONS_SUBSCRIPTION,
  visualizationId,
  panelId,
});

export const receivedValues = (visualizationId, values, panelId) => {
  const { latestValues, seriesValues } = values;
  return {
    type: RECEIVED_VALUES,
    visualizationId,
    latestValues,
    seriesValues,
    panelId,
  };
};

export const setLoading = (visualizationId, panelId, loading = true) => ({
  type: SET_LOADING,
  visualizationId,
  panelId,
  loading,
});

export const initVisualizations = () => ({
  type: INIT_VISUALIZATIONS,
});

export const resetVisualizations = () => ({
  type: RESET_VISUALIZATIONS,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  visualizations: {},
  originalVisualizations: {},
  subscriptions: {},
  values: {},
  siteLoaded: undefined,
  hasBeenUpdated: false,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_VISUALIZATIONS: {
      return {
        ...state,
        visualizations: { ...state.originalVisualizations },
        originalVisualizations: { ...state.originalVisualizations },
      };
    }
    case UPSERT_VISUALIZATION: {
      const { visualization } = action;

      return {
        ...state,
        visualizations: {
          ...state.visualizations,
          [visualization.id]: {
            ...(state.visualizations[visualization.id] || {}),
            ...visualization,
          },
        },
      };
    }
    case RECEIVE_VISUALIZATION: {
      const { visualization } = action;

      return {
        ...state,
        visualizations: {
          ...state.visualizations,
          [visualization.id]: { ...visualization },
        },
        originalVisualizations: {
          ...state.originalVisualizations,
          [visualization.id]: { ...visualization },
        },
      };
    }
    case RECEIVE_VISUALIZATIONS: {
      const { visualizations, siteId } = action;
      const indexed = visualizations.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

      return {
        ...state,
        visualizations: { ...indexed },
        originalVisualizations: { ...indexed },
        siteLoaded: siteId || state.siteLoaded,
      };
    }
    case DELETE_VISUALIZATION_SUCCESS: {
      const { visualizationId } = action;
      const vizCopy = { ...state.visualizations };
      const origVizCopy = { ...state.originalVisualizations };
      delete vizCopy[visualizationId];
      delete origVizCopy[visualizationId];

      return {
        ...state,
        visualizations: vizCopy,
        originalVisualizations: origVizCopy,
      };
    }
    case RECEIVED_VALUES: {
      const {
        visualizationId,
        latestValues: newLatestValues,
        seriesValues: newSeriesValues,
        panelId,
      } = action;

      const { values } = state;
      const updated = (values[visualizationId] || []).map((item) => {
        if (item.panelId === panelId) {
          const { latestValues, seriesValues } = item;
          return {
            panelId,
            latestValues: newLatestValues || latestValues,
            seriesValues: newSeriesValues || seriesValues,
            loading: false,
            initialLoaded: true,
          };
        }
        return item;
      });

      return {
        ...state,
        values: {
          ...values,
          [visualizationId]: updated,
        },
      };
    }
    case SET_LOADING: {
      const { visualizationId, loading, panelId } = action;
      const { values } = state;
      const vizValues = values[visualizationId];
      if (vizValues) {
        const updated = vizValues.map((item) => {
          if (item.panelId === panelId) {
            return { ...item, loading };
          }
          return item;
        });

        return {
          ...state,
          values: {
            ...values,
            [visualizationId]: updated,
          },
        };
      }
      return state;
    }
    case CREATE_VISUALIZATIONS_SUBSCRIPTION: {
      const { visualizationId, panelId } = action;
      const { subscriptions, values } = state;
      const newVizSub = [...(subscriptions[visualizationId] || [])];
      const newValues = [...(values[visualizationId] || [])];
      const newSub = { panelId, subId: uuidv4() };
      const value = {
        values: null,
        initialLoaded: false,
        loading: false,
        panelId,
      };
      const valueExists = newValues.find((v) => v.panelId === panelId);
      const subExists = newVizSub.find((v) => v.panelId === panelId);

      if (!valueExists) {
        newValues.push(value);
      }
      if (!subExists) {
        newVizSub.push(newSub);
      }

      return {
        ...state,
        subscriptions: {
          ...subscriptions,
          [visualizationId]: newVizSub,
        },
        values: {
          ...values,
          [visualizationId]: newValues,
        },
      };
    }
    case DESTROY_VISUALIZATIONS_SUBSCRIPTION: {
      const { visualizationId, panelId } = action;
      const { subscriptions } = state;
      const { [visualizationId]: viz, ...rest } = subscriptions;

      const vizPanelIdIndex = viz?.findIndex((p) => p.panelId === panelId);

      if (vizPanelIdIndex > -1) {
        viz.splice(vizPanelIdIndex, 1);
        if (viz.length > 0) {
          rest[visualizationId] = viz;
        }
      }

      return {
        ...state,
        subscriptions: {
          ...rest,
        },
      };
    }
    case INIT_VISUALIZATIONS:
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        visualizations: {},
        originalVisualizations: {},
        subscriptions: {},
        values: {},
        hasBeenUpdated: false,
        siteLoaded: undefined,
      };
    }
    default:
      return state;
  }
};

/** ********************************************
 *                                             *
 *                    Hooks                    *
 *                                             *
 ******************************************** */

export const useSubscription = (visualizationId, panelId) => {
  const dispatch = useDispatch();
  const visualizationPanelValues = useSelector(({ visualizations }) =>
    visualizations.values[visualizationId]?.find((v) => v.panelId === panelId)
  );

  useEffect(() => {
    dispatch(subscribe(visualizationId, panelId));

    return () => {
      dispatch(unsubscribe(visualizationId, panelId));
    };
  }, []);

  const { latestValues, seriesValues, loading, initialLoaded } = visualizationPanelValues || {};
  return {
    latestValues,
    seriesValues,
    loading,
    initialLoaded,
  };
};

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getVisualizationsLoaded = (state) => state.visualizations.siteLoaded;

export const getMappedVisualizations = (state) => state.visualizations.visualizations;

export const getVisualizations = createSelector(getMappedVisualizations, (visualizations) =>
  Object.values(visualizations)
);

export const getVisualizationSubscriptions = (state) => state.visualizations.subscriptions;

export const getVisualizationValues = (state) => state.visualizations.values;

export const getVisualization = createSelector(
  getMappedVisualizations,
  (_, id) => id,
  (visualizations, id) => visualizations[id]
);

export const getVisualizationPanelValue = createSelector(
  getVisualizationValues,
  (_, visualizationId, panelId) => [visualizationId, panelId],
  (vizValues, [visualizationId, panelId]) =>
    vizValues[visualizationId]?.find((panelValues) => panelValues.panelId === panelId)
);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

export function* doSaveVisualization(action) {
  const { visualization, parentPanelId } = action;
  if (!visualization) return;

  try {
    let res;

    const cleanedViz = {
      ...visualization,
      variables: visualization.variables.map(({ aggregate = 'none', ...rest }) => ({
        ...rest,
        aggregate,
      })),
    };
    if (!visualization.id || visualization.id.includes('temporary')) {
      const tempId = visualization.id;
      delete visualization.id;
      res = yield call(createVisualizationApi, cleanedViz);
      yield put(deleteVisualizationSuccess(tempId));
    } else {
      res = yield call(updateVisualizationApi, visualization.id, cleanedViz);
    }

    yield put(receiveVisualization(res));
    // yeild series data - to avoid reload on select/unselect checkbox in viz panel
    if (parentPanelId) yield put(getSingleVisualization(res, parentPanelId));
    yield put(displayNotification(getNotification('saveVisualization', 'success')()));
  } catch (e) {
    console.error('Unable to save visualization: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('saveVisualization', 'error')()));
  }
}

export function* doBulkSaveVisualization(action) {
  const { visualization, siteId } = action;
  if (!visualization) return;

  try {
    yield call(bulkUpdateVisualizationApi, visualization);
    yield put(requestVisualizations(siteId));
    yield put(displayNotification(getNotification('saveVisualization', 'success')()));
  } catch (e) {
    yield call(checkOnline);
    yield put(displayNotification(getNotification('saveVisualization', 'error')()));
  }
}

export function* doDeleteVisualization(action) {
  const { visualizationId } = action;
  try {
    yield call(deleteVisualizationApi, visualizationId);
    yield put(deleteVisualizationSuccess(visualizationId));
    yield put(
      displayNotification(getNotification('deleteVisualization', 'success')(visualizationId))
    );
  } catch (e) {
    console.error('Unable to delete visualization: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(getNotification('deleteVisualization', 'error')(visualizationId))
    );
  }
}

export function* doRequestVisualizations(action) {
  const { siteId } = action;
  try {
    const { values } = yield call(getVisualizationsApi, siteId);
    yield put(receiveVisualizations(values, siteId));
  } catch (e) {
    console.error('Unable to fetch visualizations: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getVisualizations', 'error')()));
    yield put(receiveVisualizations([], siteId));
  }
}

export function* doFetchVisualizationData(visualization, panelId) {
  const { variables: rawVariables, type } = visualization || {};

  yield put(setLoading(visualization.id, panelId));

  const dateRange = yield select((state) => getPollingDateRange(state, panelId));
  const relativeAdjustedRange = dateRange?.label
    ? getRangeFromLabel(dateRange.label)
    : getRangeFromPartial(dateRange);

  try {
    const variables = (rawVariables || []).map((config) => {
      const { aggregate: aggValue, ...rest } = config;

      if (LAST_VALUE_VISUALIZATIONS.includes(type)) {
        rest.fill = null;
      }

      if (aggValue === 'none') {
        rest.aggregate = 'avg';
      } else {
        rest.aggregate = aggValue;
      }
      return rest;
    });

    const connection = yield select(getSourceConnectivityTree);

    const payload = { variables, connection };

    if (LAST_VALUE_VISUALIZATIONS.includes(type)) {
      const data = yield call(getLastValues, payload);

      yield put(receivedValues(visualization.id, { latestValues: data.values }, panelId));
    } else {
      const data = yield call(getSeries, {
        ...payload,
        from: relativeAdjustedRange.startDate,
        to: relativeAdjustedRange.endDate,
        interval: relativeAdjustedRange.granularity,
      });

      yield put(receivedValues(visualization.id, { seriesValues: data.values }, panelId));
    }
  } catch (e) {
    console.error('Unable to fetch visualization: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(
        getNotification('getVisualization', 'error')(
          visualization.id,
          typeof e.message === 'object' ? e.message?.message : e.message
        )
      )
    );
    yield put(setLoading(visualization.id, panelId, false));
  }
}

export function* doGetSingleVisualization(action) {
  const { visualizationId, visualization, panelId } = action;
  const viz = visualization || (yield select((state) => getVisualization(state, visualizationId)));
  yield call(doFetchVisualizationData, viz, panelId);
}

export function* doUpdateAllImmediately() {
  yield put(setPollingActive('visualizations'));
  const subscriptions = yield select(getVisualizationSubscriptions);
  const subscriptionIds = Object.keys(subscriptions);
  const values = yield select(getVisualizationValues);
  const visualizations = yield all(
    subscriptionIds.map((id) => select((state) => getVisualization(state, id)))
  );

  yield all(
    visualizations.flatMap((visualization) =>
      values[visualization.id].map((item) =>
        call(doFetchVisualizationData, visualization, item.panelId)
      )
    )
  );

  yield put(setPollingActiveDone('visualizations'));
}

export const sagas = [
  takeEvery(SAVE_VISUALIZATION, doSaveVisualization),
  takeLatest(BULK_UPSERT_VISUALIZATION, doBulkSaveVisualization),
  takeLatest(REQUEST_VISUALIZATIONS, doRequestVisualizations),
  takeLatest(REQUEST_DELETE_VISUALIZATION, doDeleteVisualization),

  takeEvery(CREATE_VISUALIZATIONS_SUBSCRIPTION, doGetSingleVisualization),
  takeEvery(GET_SINGLE_VISUALIZATION_DATA, doGetSingleVisualization),
  debounce(500, REFRESH_VALUES, doUpdateAllImmediately),
];
