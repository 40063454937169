/* eslint-disable default-param-last */
import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import { can } from '@iq/react-components';
import { createSelector } from 'reselect';

import {
  getGrants as getGrantsApi,
  getMemberTypes as getMemberTypesApi,
  getMembers as getMembersApi,
  createMember as createMemberApi,
  updateMember as updateMemberApi,
  deleteMember as deleteMemberApi,
  deleteMemberConnection as deleteMemberConnectionApi,
  getInvitations as getInvitationsApi,
  createInvitation as createInvitationApi,
  deleteInvitation as deleteInvitationApi,
  updateInvitation as updateInvitationApi,
  authorizeUser as authorizeUserApi,
  getServiceAccounts as getServiceAccountsApi,
  deleteEntity as deleteEntityApi,
  transferServiceAccountPermissions,
} from '../services';
import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';
import { getUsers, deleteUser } from './ad';
import { getSites } from './sites';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const REQUEST_GRANTS = 'dt/auth/REQUEST_GRANTS';
export const RECEIVE_GRANTS = 'dt/auth/RECEIVE_GRANTS';
export const REQUEST_MEMBER_TYPES = 'dt/auth/REQUEST_MEMBER_TYPES';
export const RECEIVE_MEMBER_TYPES = 'dt/auth/RECEIVE_MEMBER_TYPES';
export const REQUEST_MEMBERS = 'dt/auth/REQUEST_MEMBERS';
export const RECEIVE_MEMBERS = 'dt/auth/RECEIVE_MEMBERS';
export const RECEIVE_TENANT_USERS = 'dt/auth/RECEIVE_TENANT_USERS';
export const CREATE_MEMBER_AND_CONNECTION = 'dt/auth/CREATE_MEMBER_AND_CONNECTION';
export const UPDATE_MEMBER_AND_CONNECTION = 'dt/auth/UPDATE_MEMBER_AND_CONNECTION';
export const UPDATE_MEMBER = 'dt/auth/UPDATE_MEMBER';
export const UPDATE_MEMBERS = 'dt/auth/UPDATE_MEMBERS';
export const DELETE_MEMBER = 'dt/auth/DELETE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'dt/auth/CREATE_MEMBER_SUCCESS';
export const DELETE_MEMBER_CONNECTION = 'dt/auth/DELETE_MEMBER_CONNECTION';
export const DELETE_ALL_MEMBER_CONNECTIONS = 'dt/auth/DELETE_ALL_MEMBER_CONNECTIONS';
export const REQUEST_INVITATIONS = 'dt/auth/REQUEST_INVITATIONS';
export const RECEIVE_INVITATIONS = 'dt/auth/RECEIVE_INVITATIONS';
export const CREATE_INVITATIONS = 'dt/auth/CREATE_INVITATIONS';
export const UPDATE_INVITATION = 'dt/auth/UPDATE_INVITATION';
export const UPDATE_INVITATIONS = 'dt/auth/UPDATE_INVITATIONS';
export const DELETE_INVITATION = 'dt/auth/DELETE_INVITATION';
export const DELETE_INVITATION_SUCCESS = 'dt/auth/DELETE_INVITATION_SUCCESS';
export const AUTHORIZE_USER = 'dt/auth/AUTHORIZE_USER';
export const CHECKED_INVITATIONS = 'dt/auth/CHECKED_INVITATIONS';
export const REQUEST_SERVICE_ACCOUNTS = 'dt/auth/REQUEST_SERVICE_ACCOUNTS';
export const RECEIVE_SERVICE_ACCOUNTS = 'dt/auth/RECEIVE_SERVICE_ACCOUNTS';
export const TRANSFER_SERVICE_ACCOUNTS = 'dt/auth/TRANSFER_SERVICE_ACCOUNTS';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestGrants = (oid, callback) => ({
  type: REQUEST_GRANTS,
  oid,
  callback,
});

export const receiveGrants = (grants) => ({
  type: RECEIVE_GRANTS,
  grants,
});

export const requestMemberTypes = () => ({
  type: REQUEST_MEMBER_TYPES,
});

export const receiveMemberTypes = (memberTypes) => ({
  type: RECEIVE_MEMBER_TYPES,
  memberTypes,
});

export const requestMembers = (objectId, indexUsers = false) => ({
  type: REQUEST_MEMBERS,
  objectId,
  indexUsers,
});

export const receiveMembers = (members) => ({
  type: RECEIVE_MEMBERS,
  members,
});

export const receiveTenantUsers = (tenantUsers) => ({
  type: RECEIVE_TENANT_USERS,
  tenantUsers,
});

export const createMemberAndConnection = (data, indexUsers = false) => ({
  type: CREATE_MEMBER_AND_CONNECTION,
  data,
  indexUsers,
});

export const updateMemberAndConnection = (data, indexUsers = false) => ({
  type: UPDATE_MEMBER_AND_CONNECTION,
  data,
  indexUsers,
  isUpdate: true,
});

export const updateMember = (data) => ({
  type: UPDATE_MEMBER,
  data,
});

export const updateMembers = (data, user) => ({
  type: UPDATE_MEMBERS,
  data,
  user,
});

export const deleteMember = (memberId) => ({
  type: DELETE_MEMBER,
  memberId,
});

export const createMemberSuccess = (members) => ({
  type: CREATE_MEMBER_SUCCESS,
  members,
});

export const deleteMemberConnection = (id, userId) => ({
  type: DELETE_MEMBER_CONNECTION,
  id,
  userId,
});

export const deleteAllMemberConnections = (userId) => ({
  type: DELETE_ALL_MEMBER_CONNECTIONS,
  userId,
});

export const requestInvitations = (org) => ({
  type: REQUEST_INVITATIONS,
  org,
});

export const receiveInvitations = (invitations) => ({
  type: RECEIVE_INVITATIONS,
  invitations,
});

export const createInvitations = (data) => ({
  type: CREATE_INVITATIONS,
  data,
});

export const updateInvitation = (data, invitationId) => ({
  type: UPDATE_INVITATION,
  data,
  invitationId,
});

export const updateInvitations = (invitations) => ({
  type: UPDATE_INVITATIONS,
  invitations,
});

export const deleteInvitation = (invitationId) => ({
  type: DELETE_INVITATION,
  invitationId,
});

export const deleteInvitationSuccess = (invitationId) => ({
  type: DELETE_INVITATION_SUCCESS,
  invitationId,
});

export const authorizeUser = (data) => ({
  type: AUTHORIZE_USER,
  data,
});

export const setCheckedInvitations = (checkedAt) => ({
  type: CHECKED_INVITATIONS,
  checkedInvitations: checkedAt,
});

export const requestServiceAccounts = () => ({
  type: REQUEST_SERVICE_ACCOUNTS,
});

export const receiveServiceAccounts = (serviceAccounts) => ({
  type: RECEIVE_SERVICE_ACCOUNTS,
  serviceAccounts,
});

export const transferServiceAccounts = (fromId, toId) => ({
  type: TRANSFER_SERVICE_ACCOUNTS,
  fromId,
  toId,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  grants: undefined,
  loadingMembers: true,
  loadingTenantUsers: true,
  loadingInvitations: true,
  loadingServiceAccounts: true,
  transferringServiceAccounts: false,
  checkedInvitations: null,
  members: [],
  memberTypes: [],
  tenantUsers: {},
  invitations: [],
  serviceAccounts: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_GRANTS: {
      return { ...state, grants: action.grants };
    }
    case RECEIVE_MEMBER_TYPES: {
      return { ...state, memberTypes: action.memberTypes };
    }
    case REQUEST_MEMBERS: {
      return {
        ...state,
        loadingMembers: true,
        loadingTenantUsers: !!action.indexUsers,
      };
    }
    case CREATE_MEMBER_SUCCESS:
    case RECEIVE_MEMBERS:
      return {
        ...state,
        members: action.members,
        loadingMembers: false,
      };
    case RECEIVE_TENANT_USERS: {
      return {
        ...state,
        tenantUsers: action.tenantUsers,
        loadingTenantUsers: false,
      };
    }
    case RECEIVE_INVITATIONS: {
      return {
        ...state,
        invitations: action.invitations,
        loadingInvitations: false,
      };
    }
    case DELETE_INVITATION_SUCCESS: {
      const inviteIndex = state.invitations.findIndex((i) => i.id === action.invitationId);
      return {
        ...state,
        invitations: [
          ...state.invitations.slice(0, inviteIndex),
          ...state.invitations.slice(inviteIndex + 1),
        ],
      };
    }

    case CHECKED_INVITATIONS: {
      return {
        ...state,
        checkedInvitations: action.checkedInvitations,
      };
    }

    case REQUEST_SERVICE_ACCOUNTS: {
      return {
        ...state,
        loadingServiceAccounts: true,
      };
    }

    case TRANSFER_SERVICE_ACCOUNTS: {
      return {
        ...state,
        transferringServiceAccounts: true,
      };
    }

    case RECEIVE_SERVICE_ACCOUNTS: {
      return {
        ...state,
        serviceAccounts: action.serviceAccounts,
        loadingServiceAccounts: false,
        transferringServiceAccounts: false,
      };
    }

    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        loadingMembers: true,
        loadingTenantUsers: true,
        loadingInvitations: true,
        loadingServiceAccounts: true,
        transferringServiceAccounts: false,
        checkedInvitations: null,
        members: [],
        memberTypes: [],
        tenantUsers: {},
        invitations: [],
        serviceAccounts: [],
      };
    }
    default:
      return state;
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getGrants = (state) => state.auth.grants;

export const getMembers = createSelector(
  (state) => state.auth.members,
  (_, objectId) => objectId,
  (members, objectId) => {
    const membersAsArray = Object.values(members);
    if (!objectId) {
      return membersAsArray;
    }
    return membersAsArray.filter((role) => role.objectId === objectId);
  }
);

export const getMemberTypes = (state) => state.auth.memberTypes;

export const isLoadingMembers = (state) => state.auth.loadingMembers;

export const isLoadingTenantUsers = (state) => state.auth.loadingTenantUsers;

export const getTenantUsers = createSelector(
  (state) => state.auth.tenantUsers,
  (tenantUsers) =>
    Object.entries(tenantUsers)
      .reduce((acc, [id, userData]) => [...acc, { id, ...userData }], [])
      .sort((u1, u2) => u1.name.localeCompare(u2.name))
);

export const getInvitations = (state) => state.auth.invitations;

export const isLoadingInvitations = (state) => state.auth.loadingInvitations;

export const hasCheckedInvitations = (state) => state.auth.checkedInvitations;

export const getHasPermission = createSelector(
  getGrants,
  (_, permission, scope) => [permission, scope],
  (grants, [permission, scope]) => {
    if (!(grants && permission)) return false;
    const check = [grants, permission];
    if (scope) check.push(scope);
    return can(...check);
  }
);

export const getIsTenantOrSuperAdmin = createSelector(
  getGrants,
  (_, org) => org,
  (grants, org) => {
    if (!(grants && org)) return false;
    return [can(grants, 'sites/Create', { org }), can(grants, 'sites/Create', {})].some(
      (grant) => grant
    );
  }
);

export const getIsAdmin = createSelector(
  getGrants,
  (_, site) => site,
  (grants, site) => {
    if (!(grants && site?.id && site?.org)) return false;
    return can(grants, 'users/Read', { org: site.org });
  }
);

export const getIsContributorOrAdmin = createSelector(
  getGrants,
  (_, site) => site,
  (grants, site) => {
    if (!(grants && site?.id && site?.org)) return false;
    return [
      can(grants, 'members/Write', { org: site.org, site: site.id }),
      can(grants, 'sites/Write', { org: site.org, site: site.id }),
      can(grants, 'sources/Write', { org: site.org, site: site.id }),
      can(grants, 'visualizations/Write', { org: site.org, site: site.id }),
      can(grants, 'models/Write', { org: site.org, site: site.id }),
      can(grants, 'algorithms/Write', { org: site.org, site: site.id }),
    ].some((grant) => grant);
  }
);

export const getServiceAccounts = (state) => state.auth.serviceAccounts;

export const isLoadingServiceAccounts = (state) => state.auth.loadingServiceAccounts;

export const isTransferringServiceAccounts = (state) => state.auth.transferringServiceAccounts;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doRequestGrants({ oid, callback }) {
  try {
    const { permissions } = yield call(getGrantsApi, oid);
    yield put(receiveGrants(permissions));
  } catch (e) {
    yield console.error('Unable to fetch grants', e);
    yield call(checkOnline);
  }
  if (callback) yield callback();
}

function* doRequestMemberTypes() {
  try {
    const memberTypes = yield call(getMemberTypesApi);
    yield put(receiveMemberTypes(memberTypes));
  } catch (e) {
    yield console.error('Unable to fetch member types: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getMemberTypes', 'error')()));
  }
}

function* doRequestMembers(action) {
  try {
    const { objectId, indexUsers } = action;

    let members = [];

    if (objectId) {
      const { values } = yield call(getMembersApi, { objectId });
      members = values;
    } else {
      const { values } = yield call(getMembersApi);
      members = values;
    }

    if (indexUsers) {
      const sites = yield select(getSites);

      // build and return only users that are connected to site members, have an email,
      // are enabled, and include associated roles per site
      const indexedUsers = members.reduce((acc, member) => {
        member.connected.forEach((user) => {
          if (member.objectId?.indexOf('site/') === 0 && user.indexOf('users/') === 0) {
            const userId = user.split('/')[1];
            const siteId = member.objectId.split('/')[1];
            const validSite = sites.find((s) => s.id === siteId);
            if (acc[userId] && validSite) {
              acc[userId] = {
                roles: {
                  ...acc[userId].roles,
                  [member.type]: [...(acc[userId].roles[member.type] || []), siteId],
                },
              };
            } else if (validSite) {
              acc[userId] = {
                roles: {
                  [member.type]: [siteId],
                },
              };
            }
          }
        });
        return acc;
      }, {});

      const users = yield select(getUsers);
      const filteredUsers = {};
      Object.keys(indexedUsers).forEach((userId) => {
        const user = users.find((u) => u.id === userId);
        if (user && user.enabled && user.email) {
          filteredUsers[userId] = {
            ...indexedUsers[userId],
            username: user.username,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            name: user.name,
            emailVerified: user.emailVerified,
            createdAt: user.createdAt,
          };
        }
      });
      yield put(receiveTenantUsers(filteredUsers));
    }
    yield put(receiveMembers(members));
  } catch (e) {
    yield console.error('Unable to fetch members: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getMembers', 'error')()));
  }
}

function* doUpdateOrCreateMemberConnection(action) {
  const { data, indexUsers, isUpdate } = action;
  const notifyType = isUpdate ? 'updateMember' : 'createMember';
  try {
    yield call(createMemberApi, data);
    yield put(requestMembers(undefined, indexUsers));
    if (isUpdate) {
      yield put(displayNotification(getNotification(notifyType, 'success')(data.oid)));
    }
  } catch (e) {
    yield console.error('Unable to add member: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification(notifyType, 'error')()));
  }
}

function* doUpdateMember(action) {
  const {
    data: { id, ...memberUpdate },
  } = action;

  try {
    yield call(updateMemberApi, id, memberUpdate);
    yield put(displayNotification(getNotification('updateMember', 'success')()));
    yield put(requestMembers(undefined, true));
  } catch (e) {
    yield console.error('Unable to update member ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateMember', 'error')()));
  }
}

function* doDeleteMember(action) {
  const { memberId } = action;

  try {
    yield call(deleteMemberApi, memberId);
    yield put(requestMembers(undefined, true));
    yield put(displayNotification(getNotification('deleteMember', 'success')(memberId)));
  } catch (e) {
    yield console.error('Unable to update member ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteMember', 'error')()));
  }
}

function* doDeleteMemberConnection(action) {
  try {
    const { id, userId } = action;

    yield call(deleteMemberConnectionApi, id, userId);
    yield put(requestMembers(undefined, true));
  } catch (e) {
    yield console.error('Unable to delete member: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteMemberConnection', 'error')()));
  }
}

function* doUpdate(update) {
  yield call(createMemberApi, update);
}

function* doRemove(data) {
  yield call(deleteMemberConnectionApi, data.id, data.userId);
}

function* doUpdateUserMembers(action) {
  const {
    data: { roles: newRoles },
    user: { id: userId, roles: currentRoles },
  } = action;
  try {
    const members = yield select(getMembers);
    const sites = yield select(getSites);
    const updates = [];
    Object.entries(newRoles).forEach(([role, siteIds]) =>
      siteIds.forEach((siteId) => {
        const selectedSite = sites.find((s) => s.id === siteId);
        if (selectedSite) {
          const member = members.find(
            (m) => m.type === role && m.objectId === `org/${selectedSite.org}`
          );
          if (member && !(currentRoles[role] || []).includes(siteId)) {
            const postData = {
              scope: { org: selectedSite.org, site: siteId },
              permissions: member.permissions,
              type: member.type,
              objectId: `site/${siteId}`,
              oid: userId,
              templateId: member.id,
            };
            updates.push(postData);
          }
        }
      })
    );
    yield all(updates.map((update) => call(doUpdate, update)));

    const deletions = [];
    Object.entries(currentRoles).forEach(([role, siteIds]) =>
      siteIds.forEach((siteId) => {
        const member = members.find((m) => m.type === role && m.objectId === `site/${siteId}`);
        if (member && !newRoles[role].includes(siteId)) {
          deletions.push({ id: member.id, userId });
        }
      })
    );
    yield all(deletions.map((data) => call(doRemove, data)));

    yield put(requestMembers(undefined, true));
    yield put(displayNotification(getNotification('updateMember', 'success')(userId)));
  } catch (e) {
    yield console.error('Unable to update user: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateMember', 'error')()));
  }
}

function* doDeleteAllMemberConnections(action) {
  try {
    const { userId } = action;
    const members = yield select(getMembers);
    const userMembers = members.filter((m) => m.connected.includes(`users/${userId}`));

    // remove all user's member connections
    yield all(userMembers.map((member) => call(doRemove, { id: member.id, userId })));

    // delete user from KC and auth-service, displays successful delete notification
    try {
      yield put(deleteUser(userId));
      yield call(deleteEntityApi, `users/${userId}`);
    } catch (e) {
      // catch 403 here for non-tenant admins
      if (e.status !== 403) {
        throw e;
      }
    }

    yield put(requestMembers(undefined, true));
  } catch (e) {
    yield console.error('Unable to delete member: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteMember', 'error')()));
  }
}

function* doRequestInvitations(action) {
  try {
    const { org } = action;
    const { values: invitations } = yield call(getInvitationsApi, { org });
    yield put(receiveInvitations(invitations));
  } catch (e) {
    yield console.error('Unable to fetch members: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getMembers', 'error')()));
  }
}

function* doCreateInvitations(action) {
  const { data } = action;

  try {
    const { total } = yield call(createInvitationApi, data);
    yield put(displayNotification(getNotification('createInvitations', 'success')(total)));
    yield put(requestInvitations(data.org));
  } catch (e) {
    yield console.error('Unable to create invitation ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createInvitations', 'error')()));
  }
}

function* doUpdateInvitation(action) {
  const { data, invitationId } = action;
  try {
    yield call(updateInvitationApi, invitationId, data);
    yield put(displayNotification(getNotification('updateInvitation', 'success')(invitationId)));
    yield call(doRequestInvitations, data.org);
  } catch (e) {
    yield console.error('Unable to update invitation ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateInvitation', 'error')()));
  }
}
function* doUpdateInvitations(action) {
  const { invitations } = action;
  const [
    {
      data: { org },
    },
  ] = invitations;
  try {
    yield all(invitations.map((invitation) => call(doUpdateInvitation, invitation)));
    yield put(displayNotification(getNotification('updateInvitations', 'success')()));
    yield call(doRequestInvitations, org);
  } catch (e) {
    yield console.error('Unable to update invitations ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateInvitations', 'error')()));
  }
}

function* doDeleteInvitation(action) {
  try {
    const { invitationId } = action;

    yield call(deleteInvitationApi, invitationId);
    yield put(deleteInvitationSuccess(invitationId));
  } catch (e) {
    yield console.error('Unable to delete member: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteInvitation', 'error')()));
  }
}

function* doAuthorizeUser(action) {
  try {
    const { data } = action;

    yield call(authorizeUserApi, data);
    yield put(setCheckedInvitations(Date.now()));
  } catch (e) {
    yield console.error('Unable to authorize user: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('authorizeUser', 'error')()));
  }
}

function* doRequestServiceAccounts() {
  try {
    const { values: serviceAccounts } = yield call(getServiceAccountsApi);
    yield put(receiveServiceAccounts(serviceAccounts));
  } catch (e) {
    yield console.error('Unable to fetch service accounts: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getServiceAccounts', 'error')()));
  }
}

function* doTransferServiceAccount(action) {
  try {
    const { toId, fromId } = action;
    yield call(transferServiceAccountPermissions, { fromId, toId });
    yield call(doRequestServiceAccounts);
    yield put(displayNotification(getNotification('transferServiceAccounts', 'success')()));
  } catch (e) {
    yield console.error('Unable to transfer account: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('transferServiceAccounts', 'error')()));
  }
}

export const sagas = [
  takeLatest(REQUEST_GRANTS, doRequestGrants),
  takeLatest(REQUEST_MEMBER_TYPES, doRequestMemberTypes),
  takeLatest(REQUEST_MEMBERS, doRequestMembers),
  takeLatest(CREATE_MEMBER_AND_CONNECTION, doUpdateOrCreateMemberConnection),
  takeLatest(UPDATE_MEMBER_AND_CONNECTION, doUpdateOrCreateMemberConnection),
  takeLatest(UPDATE_MEMBER, doUpdateMember),
  takeLatest(UPDATE_MEMBERS, doUpdateUserMembers),
  takeLatest(DELETE_MEMBER, doDeleteMember),
  takeLatest(DELETE_MEMBER_CONNECTION, doDeleteMemberConnection),
  takeLatest(DELETE_ALL_MEMBER_CONNECTIONS, doDeleteAllMemberConnections),
  takeLatest(REQUEST_INVITATIONS, doRequestInvitations),
  takeLatest(CREATE_INVITATIONS, doCreateInvitations),
  takeLatest(UPDATE_INVITATION, doUpdateInvitation),
  takeLatest(UPDATE_INVITATIONS, doUpdateInvitations),
  takeLatest(DELETE_INVITATION, doDeleteInvitation),
  takeLatest(AUTHORIZE_USER, doAuthorizeUser),
  takeLatest(REQUEST_SERVICE_ACCOUNTS, doRequestServiceAccounts),
  takeLatest(TRANSFER_SERVICE_ACCOUNTS, doTransferServiceAccount),
];
