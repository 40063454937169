import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import ReactMapGL, { Popup, Marker, FlyToInterpolator, NavigationControl } from 'react-map-gl';
import { Link } from 'react-router-dom';
import { WebMercatorViewport } from '@math.gl/web-mercator';
import 'mapbox-gl/dist/mapbox-gl.css';

import { NO_SITES_MESSAGE, MAPBOX_ACCESS_TOKEN } from '../../constants';
import SiteThumbnail from '../SiteThumbnail';

const SELECTED_ZOOM = 16.7;
const MAP_CONTROLS = ['dragPan', 'dragRotate', 'scrollZoom', 'touchZoom', 'touchRotate'];

const getMarkerColorClass = (properties, { markers = [] }) => {
  const { colors: markerColors = [] } = markers;

  let theColor = 'red';
  markerColors.forEach((markerColor) => {
    const { property: propertyAttribute, value, color } = markerColor;
    const property = properties.find(({ attribute }) => attribute === propertyAttribute);

    if (typeof property !== 'undefined' && property.value === value) {
      theColor = color;
    }
  });
  return theColor;
};

const transition = (transitionDuration = 1000) => ({
  transitionInterpolator: new FlyToInterpolator(),
  transitionDuration,
});

const zoomViewport = (viewport, sites, mapWidth, mapHeight) => {
  if (sites.length === 0) {
    return viewport;
  }

  if (sites.length === 1) {
    const {
      location: { lng, lat },
    } = sites[0];

    return {
      ...viewport,
      ...transition(),
      latitude: lat,
      longitude: lng,
      pitch: 0,
      bearing: 0,
    };
  }

  if (sites.length > 1) {
    const bbox = sites.reduce(
      ([[minLng, minLat], [maxLng, maxLat]], { location: { lat, lng } }) => [
        [Math.min(minLng, lng), Math.min(minLat, lat)],
        [Math.max(maxLng, lng), Math.max(maxLat, lat)],
      ],
      [
        [sites[0].location.lng, sites[0].location.lat],
        [sites[0].location.lng, sites[0].location.lat],
      ]
    );

    const wmviewport = new WebMercatorViewport(viewport);
    const { longitude, latitude, zoom } = wmviewport.fitBounds(bbox, {
      padding: 100,
    });
    return {
      ...viewport,
      ...transition(),
      height: mapHeight,
      width: mapWidth,
      longitude,
      latitude,
      zoom,
      pitch: 0,
      bearing: 0,
    };
  }

  return {
    ...viewport,
  };
};

const SitePopup = ({
  site: {
    name,
    properties,
    location: { lat, lng },
    thumbnail,
  },
}) => (
  <Popup
    longitude={lng}
    latitude={lat}
    closeButton={false}
  >
    <div className="map-popup">
      <div className="thumbnail">
        <SiteThumbnail
          src={thumbnail}
          name={name}
        />
      </div>
      <div>
        <div className="site-name">{name}</div>
        {properties
          .filter(({ category }) => category === 'general')
          .map(({ attribute, name: propName, value }) => (
            <div
              key={attribute}
              className="property"
            >
              <div className="name">{propName}</div>
              <div className="value">{value}</div>
            </div>
          ))}
      </div>
    </div>
  </Popup>
);

const Map = memo(({ sites, isReady, nightmode }) => {
  const [popupSite, setPopupSite] = useState(null);
  const [viewport, setViewport] = useState({});
  const [controls, setControls] = useState([]);

  const mapContainerRef = useRef({});
  const { clientHeight: mapHeight, clientWidth: mapWidth } = mapContainerRef.current;
  const mapRef = useRef();

  const resize = useCallback(() => {
    if (mapContainerRef.current) {
      const { width, height } = mapContainerRef.current.getBoundingClientRect();
      setViewport((vp) => ({ ...vp, width, height }));
    }
  }, [mapContainerRef]);

  let resizeTimer;

  const handleResize = useCallback(() => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => resize(), 200);
  }, [resize]);

  useEffect(() => {
    resize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (sites && mapWidth && mapHeight) {
      setViewport((vp) => zoomViewport(vp, sites, mapWidth, mapHeight));
      setControls(MAP_CONTROLS);
    }
  }, [sites, mapWidth, mapHeight]);

  const onLoad = useCallback(() => {
    const map = mapRef.current.getMap();

    map.addLayer(
      {
        id: 'extruded-buildings',
        source: 'composite',
        'source-layer': 'building',
        filter: ['==', 'extrude', 'true'],
        type: 'fill-extrusion',
        minZoom: SELECTED_ZOOM,
        paint: {
          'fill-extrusion-color': '#ccc',
          'fill-extrusion-height': ['get', 'height'],
        },
      },
      'waterway-label'
    );

    if (sites && mapWidth && mapHeight) {
      setViewport((vp) => zoomViewport(vp, sites, mapWidth, mapHeight));
    }
  }, [sites, mapWidth, mapHeight]);

  const handleMouseMove = useCallback(
    (event) => {
      if (
        event.target.classList.contains('site-marker') &&
        (popupSite === null || popupSite.id !== event.target.id)
      ) {
        setPopupSite(sites.find((s) => s.id === event.target.id));
      } else if (event.target.classList.contains('overlays')) {
        setPopupSite(null);
      }
    },
    [popupSite, sites]
  );

  const markers = sites.map((item) => {
    const {
      location: { lat, lng },
      id,
      properties,
      layout,
    } = item;
    const size = 24;
    return (
      <Marker
        key={id}
        longitude={lng}
        latitude={lat}
        offsetLeft={-(size / 2)}
        offsetTop={-(size / 2)}
        captureClick
      >
        <Link to={`/sites/${id}/`}>
          <div
            id={id}
            className={`site-marker ${getMarkerColorClass(properties, layout)}`}
            style={{
              width: size,
              height: size,
            }}
          >
            <svg
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                fill="currentColor"
                fillOpacity="0.2"
                cx="50"
                cy="50"
                r="50"
              />
              <circle
                fill="currentColor"
                cx="50"
                cy="50"
                r="30"
              />
            </svg>
          </div>
        </Link>
      </Marker>
    );
  });

  return (
    <div
      className="map-component"
      ref={mapContainerRef}
    >
      {isReady && sites.length === 0 && <div className="no-sites-overlay">{NO_SITES_MESSAGE}</div>}
      <ReactMapGL
        {...controls}
        {...viewport}
        ref={mapRef}
        mapStyle={`mapbox://styles/mapbox/${nightmode ? 'dark-v10' : 'light-v8'}`}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        mapOptions={{
          attributionControl: false,
        }}
        attributionControl={false}
        onLoad={onLoad}
        onViewportChange={(vp) => setViewport(vp)}
        onMouseMove={handleMouseMove}
      >
        {markers}
        {popupSite !== null ? <SitePopup site={popupSite} /> : null}
        <div className="map-controls-wrapper">
          <NavigationControl />
        </div>
      </ReactMapGL>
    </div>
  );
});

export default Map;
