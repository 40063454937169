import React, { memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import JSONEditor from '../../JSONEditor';
import { getActiveSiteId, getSite, getSites, updateSite } from '../../../bundles/sites';
import { getLayouts } from '../../../bundles/layouts';
import { getSiteSchema } from '../../SiteCreateView/components/SiteCreateModal/schemas/siteSchema';

const SiteView = memo(
  ({
    siteId, // when editing site directly from /sites
    onSave = () => {
      /* empty */
    },
    onSaveCallback,
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const activeSiteId = useSelector(getActiveSiteId);
    const theId = siteId || activeSiteId;
    const site = useSelector((state) => getSite(state, theId));
    const sites = useSelector(getSites);
    const layouts = useSelector(getLayouts);
    const templateOptions = useMemo(
      () =>
        Object.keys(layouts)
          .filter((sId) => sites.findIndex((s) => s.id === sId && s.isTemplate) >= 0)
          .map((sId) => ({
            key: sId,
            siteName: (sites.find(({ id }) => id === sId) || {}).name,
          })),
      [sites, layouts]
    );

    const schema = getSiteSchema(templateOptions);
    const { name, customer, isTemplate, template, location = {}, support = {} } = site || {};

    const updateFields = {
      name,
      customer,
      isTemplate,
      location: {
        city: location.city,
        country: location.country,
        lat: location.lat,
        lng: location.lng,
      },
      support: {
        name: support.name,
        email: support.email,
        phone: support.phone,
      },
      template,
    };

    const transformErrors = (errors) =>
      errors.map((error) => {
        /* eslint-disable no-return-assign, no-param-reassign */
        switch (error.name) {
          case 'maximum':
          case 'minimum': {
            if (error.property === '.lat') error.message = 'Enter valid Latitude';
            else if (error.property === '.lng') error.message = 'Enter valid Longitude';
            break;
          }
          case 'required': {
            let property = error.property.includes('.') ? error.property.slice(1) : error.property;
            if (property.indexOf('location') === 0) {
              [, property] = property.split('.');
            }
            if (property === 'lat') error.message = 'Enter Latitude';
            else if (property === 'lng') error.message = 'Enter Longitude';
            else error.message = `Enter ${property.slice(0, 1).toUpperCase() + property.slice(1)}`;
            break;
          }

          default:
        }
        /* eslint-enable no-return-assign, no-param-reassign */
        return error;
      });

    const onSubmit = useCallback(
      (siteData) => {
        onSave();
        dispatch(updateSite(theId, siteData, onSaveCallback));
      },
      [theId, onSave, onSaveCallback]
    );
    const onClose = () => {
      history.push(`/sites/${activeSiteId}/admin/`);
    };

    const customValidate = useCallback(
      (formData, errors) => {
        if (
          formData.support.email &&
          !/^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(formData.support.email)
        ) {
          errors.support.email.addError('Enter Valid Email');
        }
        if (formData.support.phone && !/^\+[1-9]{1}[0-9]{3,14}$/.test(formData.support.phone)) {
          errors.support.phone.addError('Phone number must start with a leading "+"');
        }
        return errors;
      },
      [schema]
    );
    return (
      <div className="edit-site-content">
        <JSONEditor
          title="Site"
          formData={updateFields}
          schema={schema}
          initialEditMode={true}
          showEditButton={false}
          saveButtonText={'Save'}
          cancelCallback={onClose}
          onFormSubmit={({ formData }) => onSubmit(formData)}
          customValidate={customValidate}
          customTransformErrors={(errors) => transformErrors(errors)}
          editorOnly
        />
      </div>
    );
  }
);

export default SiteView;
