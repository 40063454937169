import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserEventTypes } from '../../../bundles/events';
import { getEventTimeLineAdminSchema } from '../../panels/EventTimelinePanel/jsonSchema';
import JSONEditor from '../../JSONEditor';

const EventTimelineSettings = ({ settings, onSave, onFullSettings, onClose }) => {
  const eventTypes = useSelector(getUserEventTypes);
  const schema = useMemo(
    () => getEventTimeLineAdminSchema(eventTypes),
    [JSON.stringify(eventTypes)]
  );
  return (
    <div className="column-content">
      <JSONEditor
        title=""
        schema={schema}
        formData={settings}
        onFormSubmit={onSave}
        onSecondaryAction={onFullSettings}
        cancelCallback={onClose}
        saveButtonText={'Save'}
        initialEditMode
        showEditButton={false}
      />
    </div>
  );
};
export default EventTimelineSettings;
