import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Can } from '@iq/react-components';
import { useHistory, useParams } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import { getStaticComponents, requestComponents } from '../../bundles/components';
import {
  requestAlgorithms,
  updateAlgorithm,
  deleteAlgorithm,
  getAlgorithm,
} from '../../bundles/algorithms';
import { requestTasks, createTask, getAlgorithmTasks } from '../../bundles/tasks';
import { requestTypes, getTypes } from '../../bundles/types';
import { requestTags, getTags } from '../../bundles/tags';
import { getUploads } from '../../bundles/uploads';
import { getActiveSite } from '../../bundles/sites';

import CreateTaskModal from './components/CreateTaskModal';
import Delete from '../Delete';
import Heading from '../Heading';
import StyledItem from '../StyledItem';
import JSONEditor from '../JSONEditor';
import { getTaskSchema, taskUiSchema } from '../TaskDetailView/schemas/taskSchema';
import {
  algorithmSchema,
  algorithmUiSchema,
} from '../SiteAdminView/AlgorithmsView/schemas/algorithmSchema';

const ManageAlgorithmView = () => {
  const history = useHistory();
  const params = useParams();
  const { algorithmId } = params;
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);
  const algorithm = useSelector((state) => getAlgorithm(state, algorithmId));
  const tasks = useSelector((state) => getAlgorithmTasks(state, algorithmId));
  const uploads = useSelector(getUploads);

  const [displayCreateTask, setDisplayCreateTask] = useState(false);

  useEffect(() => {
    if (site?.id) {
      dispatch(requestTypes(site.id));
      dispatch(requestComponents(site.id));
      dispatch(requestTags(site.id));
      dispatch(requestAlgorithms({ site: site.id }));
      dispatch(requestTasks({ site: site.id }));
    }
  }, [site.id]);

  useEffect(() => {
    let pollingDelay;
    if (site && uploads.length) {
      pollingDelay = setTimeout(() => dispatch(requestAlgorithms({ site: site.id })), 3000);
    }
    return () => clearTimeout(pollingDelay);
  }, [uploads, site]);

  const onDelete = useCallback(
    (id) => {
      if (site) {
        dispatch(deleteAlgorithm(id));
        history.push(`/sites/${site.id}/admin/algorithms`);
      }
    },
    [site]
  );

  const onCreate = useCallback(
    (data) => {
      if (site) {
        const { config, ...rest } = data;
        const task = {
          ...rest,
          config: JSON.parse(config),
          org: site.org,
          site: site.id,
          algorithm_id: algorithmId,
        };
        dispatch(createTask(task));
        dispatch(requestTasks({ site: site.id }));
      }
    },
    [site]
  );

  const onUpdate = useCallback(
    ({ formData }) => {
      const update = { ...algorithm, ...formData };
      if (!update.description) {
        delete update.description;
      }
      dispatch(updateAlgorithm(algorithm.id, update));
    },
    [algorithm]
  );

  const onClose = () => {
    setDisplayCreateTask(false);
  };

  const components = useSelector(getStaticComponents);
  const tags = useSelector(getTags);
  const types = useSelector(getTypes);

  const taskSchema = getTaskSchema({
    components,
    tags,
    types,
  });

  const updateFields = {
    image: algorithm.image,
    description: algorithm.description,
    name: algorithm.name,
  };

  if (!algorithm) return null;

  return (
    <>
      <Heading
        contentLeft={<div className="title">{`Algorithm: ${algorithm.name}`}</div>}
        contentRight={
          <>
            <Button
              onClick={() => history.push(`/sites/${site.id}/admin/algorithms/`)}
              activity="secondary"
            >
              Close
            </Button>
            <Button onClick={() => setDisplayCreateTask(true)}>Add Task</Button>
          </>
        }
      />

      <JSONEditor
        title="Properties"
        formData={updateFields}
        uiSchema={algorithmUiSchema}
        schema={algorithmSchema}
        onFormSubmit={onUpdate}
        saveButtonText={'Update algorithm'}
      />
      <Can
        permission="algorithms/Delete"
        scope={{ org: site.org, site: site.id }}
      >
        <div className="algorithm-column-content">
          <Delete
            onDelete={() => onDelete(algorithm.id)}
            title="Delete algorithm"
          />
        </div>
      </Can>

      <Heading contentLeft={<div className="title">Tasks</div>} />

      {tasks.length > 0 ? (
        tasks.map((task) => (
          <StyledItem
            key={task.id}
            headerContent={`${task.name} - ${format(parseISO(task.createdAt), 'yyyy-MM-dd')}`}
            onClick={() =>
              history.push(`/sites/${site.id}/admin/algorithms/${algorithm.id}/tasks/${task.id}`)
            }
          />
        ))
      ) : (
        <div>No tasks yet, create one to get started</div>
      )}

      {displayCreateTask && (
        <CreateTaskModal
          uiSchema={taskUiSchema}
          schema={taskSchema}
          onSubmit={onCreate}
          onCloseModal={onClose}
        />
      )}
    </>
  );
};

export default ManageAlgorithmView;
