import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemMessages, requestSystemMessages } from '../../bundles/systemMessages';
import SystemMessageItem from './components';

const SystemMessagesList = () => {
  const dispatch = useDispatch();
  const POLLING_INTERVAL = 1000 * 30;

  useEffect(() => {
    dispatch(requestSystemMessages());
    const polling = setInterval(() => {
      dispatch(requestSystemMessages());
    }, POLLING_INTERVAL);
    return () => clearInterval(polling);
  }, []);

  const systemMessages = useSelector(getSystemMessages);

  return (
    <div className="system-messages-component">
      {systemMessages.map((systemMessage) => (
        <SystemMessageItem
          key={systemMessage.id}
          systemMessage={systemMessage}
        />
      ))}
    </div>
  );
};

export default SystemMessagesList;
