import React, { useEffect, useState } from 'react';
import './PdfPreview.scss';

const PdfPreview = (props) => {
  const [Component, setComponent] = useState(null);
  useEffect(() => {
    import('./PdfPreview').then((mod) => setComponent(mod.default));
  }, []);
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};

export default PdfPreview;
