import { PAGE_TYPES, DEFAULT_PAGETYPE } from '../../../../constants';

const getPageTypeSchema = () => ({
  type: 'object',
  properties: {
    pageType: {
      type: 'string',
      title: 'Page type',
      default: DEFAULT_PAGETYPE,
      oneOf: Object.entries(PAGE_TYPES).map(([pageType, displayPageType]) => ({
        const: pageType,
        title: displayPageType,
      })),
    },
  },
  dependencies: {
    pageType: {
      oneOf: [
        {
          properties: {
            pageType: { const: 'risk' },
            caution: {
              type: 'object',
              title: '',
              description: '* Risks triggered by open MACH alarms generated over the last year',
            },
          },
        },
        {
          properties: {
            pageType: { not: { const: 'risk' } },
          },
        },
      ],
    },
  },
});

const getPageSchema = (components = []) => {
  const schema = {
    type: 'object',
    required: ['title', 'path', 'icon'],
    properties: {
      title: {
        type: 'string',
        title: 'Page title',
        default: '',
      },
      path: {
        type: 'string',
        title: 'Page path',
        default: null,
      },
      ...getPageTypeSchema().properties,
      icon: {
        type: 'string',
        title: 'Page icon',
        default: null,
      },
    },
    dependencies: getPageTypeSchema().dependencies,
  };

  if (components.length) {
    schema.properties.activeComponentFilter = {
      type: 'object',
      title: '',
      properties: {
        active: {
          type: 'boolean',
          title: 'Active component filtering',
          description:
            'Active component filtering can be used to ignore the active component, for example when switching between pages where the active component from one page should not be used in the other.',
          default: false,
        },
      },
      dependencies: {
        active: {
          oneOf: [
            {
              properties: {
                active: { enum: [false] },
              },
            },
            {
              properties: {
                active: { enum: [true] },
                filterType: {
                  type: 'string',
                  title: 'Filter type',
                  default: 'include',
                  oneOf: [
                    {
                      const: 'include',
                      title:
                        'Ignore active component if it is included in the selected type(s) or component(s) below',
                    },
                    {
                      const: 'exclude',
                      title:
                        'Ignore active component if it is *not* included in selected type(s) or component(s) below',
                    },
                  ],
                },
                types: {
                  isMulti: true,
                  uniqueItems: true,
                  type: 'array',
                  title: 'Filter by component type(s)',
                  default: [],
                  items: {
                    type: 'string',
                    anyOf: [
                      { const: 'site', title: 'Site' },
                      { const: 'branch', title: 'Branch' },
                      { const: 'group', title: 'Group' },
                      { const: 'component', title: 'Component' },
                      { const: 'virtual', title: 'Area' },
                    ],
                  },
                },
                components: {
                  type: 'array',
                  title: 'Filter by specific component(s)',
                  default: [],
                  uniqueItems: true,
                  isMulti: true,
                  items: {
                    type: 'string',
                    anyOf: components.map((comp) => ({
                      const: comp.id,
                      title: comp.name,
                    })),
                  },
                },
              },
            },
          ],
        },
      },
    };
  }

  return schema;
};

const pageUiSchema = {
  icon: {
    'ui:field': 'iconPicker',
  },
};

export { getPageSchema, pageUiSchema, getPageTypeSchema };
