import React, { memo } from 'react';
import SimpleModal from '../../../SimpleModal/SimpleModal';
import JSONEditor from '../../../JSONEditor';
import getVisualizationSettingsSchema from '../schemas/visualizationSchema';

const VisualizationSettingsModal = memo((props) => {
  const { onCloseModal, visLength, onSubmit } = props;
  const schema = getVisualizationSettingsSchema(visLength);

  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Settings"
    >
      <div>
        <JSONEditor
          schema={schema}
          onFormSubmit={({ formData: form }) => onSubmit(form)}
          saveButtonText={'Save'}
          initialEditMode={true}
          showEditButton={false}
          cancelCallback={onCloseModal}
        />
      </div>
    </SimpleModal>
  );
});

export default VisualizationSettingsModal;
