import React from 'react';
import { Icon, Button } from '@iq/react-components';

const TopNotification = ({
  icon,
  message,
  action,
  actionText,
  activity = 'primary',
  iconColor,
}) => (
  <div className="top-notification">
    <div>
      <div style={{ color: iconColor }}>
        <Icon icon={icon || 'error'} />
      </div>

      <div>{message}</div>
    </div>
    {action && (
      <Button
        activity={activity}
        onClick={action}
      >
        {actionText || 'OK'}
      </Button>
    )}
  </div>
);

export default TopNotification;
