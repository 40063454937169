import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveSite, setActiveSite } from '../../bundles/sites';

const SupportView = ({
  match: {
    params: { id: siteId },
  },
}) => {
  const dispatch = useDispatch();
  const site = useSelector(getActiveSite);
  const { support } = site;

  useEffect(() => {
    if (siteId) {
      dispatch(setActiveSite(siteId));
    }
  }, [siteId]);

  return (
    <>
      {support ? (
        <div className="support-content">
          <div className="support-badge">
            <p>
              <strong>Serving customers. Supporting innovation.</strong>
            </p>
            <p>
              Hitachi Energy is committed to supplying support solutions that meet our customers
              needs and specifications. To rectify a problem as soon as possible, we provide several
              support options.
            </p>
            <p>
              Short response time to a knowledgeable Hitachi Energy engineer provides immediate
              access to solutions, saving valuable time. Minor incidents can be resolved quickly,
              and in the event of a major issue, immediate support is available.
            </p>
          </div>
          <div className="support-badge">
            <strong>Contact information</strong>
            <div>Name: {support.name || 'n/a'}</div>
            <div>
              Phone: {support.phone ? <a href={`tel:${support.phone}`}>{support.phone}</a> : 'n/a'}
            </div>
            <div>
              Email:{' '}
              {support.email ? <a href={`mailto:${support.email}`}>{support.email}</a> : 'n/a'}
            </div>
          </div>
          <img src="/images/abb-support.jpg" />
        </div>
      ) : (
        'No support information.'
      )}
    </>
  );
};

export default SupportView;
