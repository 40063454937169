.corevisualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
.corevisualization-component .viz-loading-spinner {
  margin-right: 1.5rem;
}

.height-medium-small .metric-visualization-component .wrapper .value-wrapper .value,
.height-small .metric-visualization-component .wrapper .value-wrapper .value {
  font-size: 1.4rem;
}
.height-medium-small .metric-visualization-component .wrapper .label,
.height-small .metric-visualization-component .wrapper .label {
  top: 0.5rem;
}
.height-medium-small .metric-visualization-component .value-wrapper,
.height-small .metric-visualization-component .value-wrapper {
  line-height: 1.6rem;
}
.height-medium-small .gauge-visualization-component .gauge-wrapper .value-wrapper .value-wrapper--value .value,
.height-small .gauge-visualization-component .gauge-wrapper .value-wrapper .value-wrapper--value .value {
  font-size: 1.4rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvVmlzdWFsaXphdGlvbiIsInNvdXJjZXMiOlsiVmlzdWFsaXphdGlvbi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7OztBQU9BO0FBQUE7RUFDRTs7QUFJQTtBQUFBO0VBQ0U7O0FBSUo7QUFBQTtFQUNFOztBQUlGO0FBQUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5jb3JldmlzdWFsaXphdGlvbi1jb21wb25lbnQge1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiAxMDAlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWl0ZW1zOiBjZW50ZXI7XG5cbiAgLnZpei1sb2FkaW5nLXNwaW5uZXIge1xuICAgIG1hcmdpbi1yaWdodDogMS41cmVtO1xuICB9XG59XG5cbi5oZWlnaHQtbWVkaXVtLXNtYWxsLFxuLmhlaWdodC1zbWFsbCB7XG4gIC5tZXRyaWMtdmlzdWFsaXphdGlvbi1jb21wb25lbnQge1xuICAgIC53cmFwcGVyIC52YWx1ZS13cmFwcGVyIC52YWx1ZSB7XG4gICAgICBmb250LXNpemU6IDEuNHJlbTtcbiAgICB9XG5cbiAgICAud3JhcHBlciB7XG4gICAgICAubGFiZWwge1xuICAgICAgICB0b3A6IDAuNXJlbTtcbiAgICAgIH1cbiAgICB9XG5cbiAgICAudmFsdWUtd3JhcHBlciB7XG4gICAgICBsaW5lLWhlaWdodDogMS42cmVtO1xuICAgIH1cbiAgfVxuICAuZ2F1Z2UtdmlzdWFsaXphdGlvbi1jb21wb25lbnQge1xuICAgIC5nYXVnZS13cmFwcGVyIC52YWx1ZS13cmFwcGVyIC52YWx1ZS13cmFwcGVyLS12YWx1ZSAudmFsdWUge1xuICAgICAgZm9udC1zaXplOiAxLjRyZW07XG4gICAgfVxuICB9XG59XG4iXX0= */