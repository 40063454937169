import React from 'react';
import IntegrationView from '../../../../SiteAdminView/IntegrationView';

const TemplateTab = () => {
  return (
    <IntegrationView
      templateView
      siteView={false}
    />
  );
};

export default TemplateTab;
