import React from 'react';
import { Button } from '@iq/react-components';

import SimpleModal from '../SimpleModal';

const BUTTON_TYPES = ['primary', 'secondary', 'info', 'success', 'warning', 'danger'];

const ConfirmationDialog = ({
  modal = true,
  onCancel,
  onConfirm,
  title = 'Confirm Action',
  subtitle,
  body = 'Are you sure?',
  confirmText = 'OK',
  confirmType = BUTTON_TYPES[0],
  optionType = BUTTON_TYPES[2],
  cancelText = 'Cancel',
  cancelType = BUTTON_TYPES[1],
  slimButtons = false,
  disabled = false,
  className = '',
  onOption,
  showCancel,
  optionText,
}) => {
  const confirmButtonType = BUTTON_TYPES.includes(confirmType) ? confirmType : BUTTON_TYPES[0];
  const optionButtonType = BUTTON_TYPES.includes(optionType) ? optionType : BUTTON_TYPES[2];
  const cancelButtonType = BUTTON_TYPES.includes(cancelType) ? cancelType : BUTTON_TYPES[1];

  const content = (
    <>
      {subtitle ? (
        <div className="subtitle">
          <h3>{subtitle}</h3>
        </div>
      ) : null}

      <div className="body">{body}</div>

      <div className="actions">
        {showCancel ? (
          <Button
            activity={cancelButtonType}
            onClick={onCancel}
            slim={slimButtons}
          >
            {cancelText}
          </Button>
        ) : null}
        <Button
          activity={confirmButtonType}
          onClick={onConfirm}
          slim={slimButtons}
          disabled={disabled}
        >
          {confirmText}
        </Button>
        {onOption ? (
          <Button
            activity={optionButtonType}
            onClick={onOption}
            slim={slimButtons}
            disabled={disabled}
          >
            {optionText}
          </Button>
        ) : null}
      </div>
    </>
  );

  if (modal) {
    return (
      <SimpleModal
        title={title}
        className={`confirmation-dialog ${className || ''}`}
        onClose={onCancel}
      >
        {content}
      </SimpleModal>
    );
  }

  return <div className="confirmation-dialog">{content}</div>;
};

export default ConfirmationDialog;
