import thresholdSchema from './thresholdSchema';

export const schema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      title: 'Title',
    },
    layout: {
      type: 'string',
      title: 'Layout',
      default: 'vertical',
      // inverted layout naming due to issues when switching in component
      anyOf: [
        {
          const: 'vertical',
          title: 'horizontal',
        },
        {
          const: 'horizontal',
          title: 'vertical',
        },
      ],
    },
    decimals: {
      default: 'auto',
      title: 'Decimals',
      type: 'string',
      enum: ['auto', '0', '1', '2', '3', '4', '5'],
    },
    ...thresholdSchema,
  },
};

export const uiSchema = {
  thresholds: {
    areaItems: {
      'ui:options': {
        orderable: false,
      },
    },
    linearItems: {
      'ui:options': {
        orderable: false,
      },
    },
  },
};
