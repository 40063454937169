/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input, Spinner } from '@iq/react-components';

import {
  requestSources,
  getSources,
  getSourcesLoaded,
  createVariables,
  updateVariable,
  deleteVariable,
} from '../../../bundles/sources';
import { getActiveSite } from '../../../bundles/sites';
import getNotification from '../../../bundles/notification-defaults';
import { displayNotification, checkIsOnline } from '../../../bundles/notifications';
import {
  requestComponents,
  getComponentsLoaded,
  getStaticComponents,
} from '../../../bundles/components';
import { useDebounce, useClientSize, cleanFormData, withTypedValues } from '../../../utils';
import { INTERNAL_DATA_SOURCE } from '../../../constants';
import { getVariables } from '../../../services';
import ComponentSelect from '../../ComponentSelect';
import ListItem from '../../ListItem';
import EditItemModal from '../../EditItemModal';
import Heading from '../../Heading';
import Pagination from '../../Pagination';
import { getAttributeSchema, attributeUiSchema } from '../ComponentView/schemas/attributeSchema';

const AttributeView = () => {
  const dispatch = useDispatch();
  const { id: siteId } = useSelector(getActiveSite);
  const sources = useSelector(getSources);
  const sourcesLoaded = useSelector(getSourcesLoaded);
  const componentsLoaded = useSelector(getComponentsLoaded);
  const components = useSelector(getStaticComponents);

  const [attribute, setAttribute] = useState(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [compFilter, setCompFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [attributes, setAttributes] = useState(null);
  const [totalAttributes, setTotalAttributes] = useState(0);
  const [needsRefresh, setNeedsRefresh] = useState(0);
  const [extraErrors, setExtraErrors] = useState({});

  const [{ height }, clientRef] = useClientSize();

  // list body height - header (46) - pagination (54) / row height (51); min limit 5
  const queryLimit = useMemo(() => Math.max(Math.floor((height - 46 - 54) / 51), 5), [height]);

  useEffect(() => {
    if (siteId && !sourcesLoaded) {
      dispatch(requestSources({ siteId }));
    }
    if (siteId && !componentsLoaded) {
      dispatch(requestComponents(siteId));
    }
  }, [siteId, sourcesLoaded, componentsLoaded]);

  const internalSiteSource = sources.find((source) => source.type === INTERNAL_DATA_SOURCE);

  const baseQuery = useMemo(
    () => ({
      limit: queryLimit,
      page,
      order: 'ASC',
      isAttribute: true,
    }),
    [queryLimit, page]
  );

  const onRequestAttributes = useDebounce(() => {
    const query = { ...baseQuery };
    if (searchFilter) {
      query.search = searchFilter;
    }
    if (compFilter) {
      query.assetId = compFilter;
    }

    const getAttributes = async () => {
      try {
        const { values, total } = await getVariables(internalSiteSource.id, query);
        setAttributes(values.map(withTypedValues));
        setTotalAttributes(total);
      } catch (e) {
        console.error('Unable to fetch attributes: ', e);
        dispatch(checkIsOnline());
        dispatch(displayNotification(getNotification('getVariables', 'error')('attribute')));
      }
    };
    getAttributes();
  }, 500);

  useEffect(() => {
    if (internalSiteSource) {
      onRequestAttributes();
    }
  }, [internalSiteSource, baseQuery, searchFilter, compFilter, needsRefresh]);

  const isNewAttribute = !attribute?.id;
  const schema = useMemo(
    () => getAttributeSchema(components, isNewAttribute),
    [components, isNewAttribute]
  );

  const onChangeAttribute = useCallback(
    ({ formData }) => {
      if (formData.name !== attribute.name) {
        setExtraErrors({});
      }

      if (formData.dataType !== attribute.dataType) {
        setAttribute({
          ...formData,
          lastValue: formData.dataType === 'boolean' ? 'true' : undefined,
        });
      } else {
        setAttribute(formData);
      }
    },
    [attribute]
  );

  const onSubmitAttribute = useCallback(
    ({ formData }) => {
      const payload = cleanFormData(formData);
      let value = payload.lastValue;
      if (payload.dataType === 'boolean') {
        value = payload.lastValue === 'true';
      }
      payload.values = [{ value, timestamp: new Date().toISOString() }];

      const successCallback = () => {
        setPage(1);
        setExtraErrors({});
        setAttribute(null);
        setNeedsRefresh((prev) => prev + 1);
      };

      const failureCallback = (errors) => {
        setExtraErrors({ name: { __errors: errors } });
      };

      if (isNewAttribute) {
        dispatch(
          createVariables(
            internalSiteSource.id,
            { source: internalSiteSource.id, ...payload },
            successCallback,
            failureCallback
          )
        );
      } else {
        dispatch(
          updateVariable(payload.source_id, payload.id, payload, successCallback, failureCallback)
        );
      }
    },
    [isNewAttribute, internalSiteSource]
  );

  const onCloseAttributeModal = useCallback(() => {
    setAttribute(null);
    setExtraErrors({});
  }, []);

  const onDeleteAttribute = useCallback((attr) => {
    dispatch(
      deleteVariable(attr.source_id, attr.id, true, () => {
        setPage(1);
        setNeedsRefresh((prev) => prev + 1);
      })
    );
  }, []);

  const headers = [
    { label: 'Attribute name' },
    { label: 'Data type' },
    { label: 'Category' },
    { label: 'Latest Value' },
    { label: 'Component' },
  ];

  const headerColumns = headers.map((h, i) => <div key={`${h.label}-${i}`}>{h.label}</div>);

  const getListItemColumns = useCallback(
    (attr) => {
      const component = components.find((comp) => comp.id === attr?.asset?.component.id);
      return component
        ? [
            <div
              key={`${component.id}-col-1`}
              className="ellipsed-text"
            >
              {attr.name}
            </div>,
            <div
              key={`${component.id}-col-2`}
              className="ellipsed-text"
            >
              {attr.dataType[0].toUpperCase() + attr.dataType.slice(1)}
            </div>,
            <div
              key={`${component.id}-col-3`}
              className="ellipsed-text"
            >
              {attr.category}
            </div>,
            <div
              key={`${component.id}-col-4`}
              className="ellipsed-text"
            >
              {attr.lastValue?.toString()}
            </div>,
            <div
              key={`${component.id}-col-5`}
              className="blocked-item-container"
            >
              <div className="blocked-item">
                {`${component.name} (${component.itemDesignation})`}
              </div>
            </div>,
          ]
        : [];
    },
    [components]
  );

  const columnWidths = {
    type: 'percent',
    widths: [22, 15, 22, 22, 19],
  };

  const attributeList = useMemo(() => {
    if (!attributes || !getListItemColumns) {
      return (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      );
    }
    return (
      <div
        ref={clientRef}
        className="manage-attributes-body"
      >
        <p className="attributes">{`Manage attributes (${totalAttributes})`} </p>

        <div className="list-attributes-container manage-attributes-body">
          <ListItem
            isHeader
            columns={headerColumns}
            columnWidths={columnWidths}
          />
          <div className="custom-thin-scrollbar">
            {attributes.map((attr) => (
              <ListItem
                key={attr.id}
                entity={`Attribute (${attr.name})`}
                item={attr}
                columns={getListItemColumns(attr)}
                columnWidths={columnWidths}
                customEdit
                onEdit={() => setAttribute(attr)}
                onDelete={() => onDeleteAttribute(attr)}
                confirmationDialogTitle={'Delete attribute'}
                confimationDialogBody={<p>Are you sure you want to remove this attribute?</p>}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }, [attributes, getListItemColumns]);

  const onSearchFilter = useCallback((e) => {
    setPage(1);
    setSearchFilter((e.target.value || '').trim());
  }, []);

  const onComponentFilter = useCallback((value) => {
    setPage(1);
    setCompFilter(value);
  }, []);

  if (!attributes) {
    return (
      <div className="loading-container">
        <Spinner
          size="s"
          className="spinner"
        />
      </div>
    );
  }

  return (
    <>
      <Heading
        contentLeft={
          <div className="attributes-header">
            <div className="title">Attributes</div>
            <Input
              type="text"
              onChange={onSearchFilter}
              value={searchFilter}
              placeholder="Search attribute name"
            />

            <div className="asset-select">
              <ComponentSelect
                isMulti={false}
                value={compFilter}
                availableComponents={components.filter((comp) => comp.asset.variables.length)}
                onChange={onComponentFilter}
                nullOptionText="All components"
                useAssetIds
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '100%',
                    height: '36px',
                    minHeight: '36px',
                  }),
                }}
              />
            </div>
          </div>
        }
        contentRight={
          <Button
            disabled={!internalSiteSource}
            onClick={() => setAttribute({})}
            className={`button-create`}
            icon={<Icon icon="he-add" />}
          >
            Add Attribute
          </Button>
        }
      />
      {attributeList}
      <Pagination
        pages={Math.ceil(totalAttributes / queryLimit)}
        page={page}
        setPage={setPage}
      />
      {attribute && componentsLoaded && (
        <EditItemModal
          entity="Attribute"
          action={attribute.id ? 'Edit' : 'Add'}
          formData={attribute}
          schema={schema}
          uiSchema={attributeUiSchema}
          onSubmit={onSubmitAttribute}
          onChange={onChangeAttribute}
          onCloseModal={onCloseAttributeModal}
          withTitle={false}
          extraErrors={extraErrors}
          flexEndButtons
        />
      )}
    </>
  );
};

export default AttributeView;
