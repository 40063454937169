#global-message-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}
#global-message-container > div:empty {
  display: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvTm90aWZpY2F0aW9ucy9HbG9iYWxNZXNzYWdlUG9ydGFsIiwic291cmNlcyI6WyJHbG9iYWxNZXNzYWdlUG9ydGFsLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdFO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIjZ2xvYmFsLW1lc3NhZ2UtY29udGFpbmVyIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB0b3A6IDA7XG4gIGxlZnQ6IDA7XG4gIHdpZHRoOiAxMDAlO1xuICB6LWluZGV4OiAxMDAwMDsgLy8gc2hvdWxkIGJlIGhpZ2hlc3QgaW5kZXhcblxuICA+IGRpdiB7XG4gICAgJjplbXB0eSB7XG4gICAgICBkaXNwbGF5Om5vbmVcbiAgICB9XG4gIH1cbn1cbiJdfQ== */