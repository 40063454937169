import React from 'react';

const SubStepIndicator = ({ steps = [], activeStep, setStep }) => {
  const activeClass = (step) => (step === activeStep ? 'substep-indicator__step--active' : '');

  return (
    <div className="substep-indicator">
      {steps.map(({ name, label }, i) => (
        <React.Fragment key={`substep-${i}`}>
          <div
            className={`substep-indicator__step ${activeClass(name)}`}
            onClick={() => setStep(i)}
          >
            {label}
          </div>
          {i < steps.length - 1 ? <div className="substep-indicator__spacer" /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SubStepIndicator;
