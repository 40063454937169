import React, { useState } from 'react';

import { Button } from '@iq/react-components';

const Delete = ({ title = 'Delete', onDelete }) => {
  const [needsConfirmation, setConfirmation] = useState(false);

  const handleDelete = () => {
    setConfirmation(false);
    onDelete();
  };

  return (
    <div className="delete-component">
      {needsConfirmation ? (
        <>
          <div className="confirmation">Are you sure?</div>
          <div className="buttons">
            <Button
              activity="secondary"
              onClick={() => setConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              activity="danger"
            >
              Yes, delete
            </Button>
          </div>
        </>
      ) : (
        <div className="buttons">
          <Button
            activity="secondary"
            onClick={() => setConfirmation(true)}
          >
            {title}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Delete;
