import React, { memo } from 'react';

const DefaultErrorComponent = memo(() => (
  <div className="bound-error">
    <div>
      <p>Something went wrong</p>
      <p className="muted">See the Dev Tools console for more info.</p>
    </div>
  </div>
));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, retries: 0 };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  handleReload = () => {
    this.setState((state) => ({
      hasError: false,
      retries: state.retries + 1,
    }));
  };

  render() {
    if (this.state.hasError) {
      const { ErrorComponent } = this.props;
      const { retries } = this.state;
      if (ErrorComponent) {
        return (
          <ErrorComponent
            onReload={this.handleReload}
            retries={retries}
            {...this.props}
          />
        );
      }
      return <DefaultErrorComponent />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
