import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button } from '@iq/react-components';

import { getHasPermission } from '../../../bundles/auth';
import { getSources, getSourceTypes, requestSources } from '../../../bundles/sources';
import { addSubscriptions as addVariableSubscriptions } from '../../../bundles/variables';
import { requestModels, getAllModels } from '../../../bundles/models';
import {
  getComponentsLoaded,
  getComponents,
  getStaticComponents,
  getComponentTree,
  getFilteredActiveComponentId,
  getCreatedComponent,
  deleteComponent,
  deleteNonSiteComponents,
  updateComponent,
  createComponent,
  setPageComponents,
  getPageComponents,
} from '../../../bundles/components';
import { getActiveModelId, getViewerReady } from '../../../bundles/application';

import prepData from '../../SiteAdminView/ComponentView/utils';
import ComponentModal from '../../SiteAdminView/ComponentView/components/ComponentModal';
import Empty from '../../Empty';
import ConfirmationDialog from '../../ConfirmationDialog';
import ComponentTree from './components/ComponentTree';

const ComponentsPanel = ({
  site = {},
  panelId,
  pageIndex,
  displayStatus,
  showVirtuals,
  flatList,
  withToolbar,
  autoLoad,
  startingDepth = 1,
  endingDepth = 0,
  expandedDepth = 0,
  limitComponents = [],
  excludeComponents = [],
  componentScope: { activeComponentTypeFilter },
  showModels = true,
  showDeleteTreeButton = false,
}) => {
  const dispatch = useDispatch();

  const activeComponentId = useSelector(getFilteredActiveComponentId);
  const modelsHashmap = useSelector(getAllModels);
  const componentsLoaded = useSelector(getComponentsLoaded);
  const components = useSelector(getComponents);
  const staticComponents = useSelector(getStaticComponents);
  const componentTree = useSelector(getComponentTree);
  const activeModelId = useSelector(getActiveModelId);
  const pageComponents = useSelector(getPageComponents);
  const sources = useSelector(getSources);
  const sourceTypes = useSelector(getSourceTypes);

  const createdComponent = useSelector(getCreatedComponent);
  const viewerReady = useSelector(getViewerReady);

  const [staticComps, setStaticComps] = useState([]);
  const [updating, setUpdating] = useState({ show: false, componentId: null });
  const [creating, setCreating] = useState({ show: false });
  const [deleting, setDeleting] = useState({
    show: false,
    componentId: null,
    confirmed: false,
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const canEdit = useSelector((state) =>
    getHasPermission(state, 'components/Write', { org: site.org, site: site.id })
  );
  const hasDelete = useSelector((state) =>
    getHasPermission(state, 'components/Delete', { org: site.org, site: site.id })
  );
  const canDelete = hasDelete && !site.properties.some((p) => p.name === 'integration');

  useEffect(() => {
    if (pageIndex === 'wizard') {
      dispatch(requestModels(site.id));
      dispatch(requestSources({ siteId: site.id }));
    }
  }, []);

  useEffect(() => {
    if (components && components.length) {
      const virtualsAndDescendants = [];
      if (!showVirtuals) {
        components
          .filter((c) => c.type === 'virtual')
          .forEach((c) => virtualsAndDescendants.push(...[c.id, ...c.descendantIds]));
      }
      dispatch(
        setPageComponents(
          components.filter((c) => {
            let include = true;
            if (activeComponentTypeFilter && activeComponentTypeFilter !== 'any') {
              include = c.type === activeComponentTypeFilter;
            }
            if (include && limitComponents.length > 0) {
              include = limitComponents.includes(c.id);
            }
            if (include && excludeComponents.length > 0) {
              include = !excludeComponents.includes(c.id);
            }
            if (include && !showVirtuals) {
              include = !virtualsAndDescendants.includes(c.id);
            }
            return include;
          })
        )
      );
      const variablesWithComponents = components
        .reduce((acc, curr) => {
          if (curr.variables.length > 0) {
            return [...acc, ...curr.variables.map((v) => v.id)];
          }
          return acc;
        }, [])
        .filter((val, idx, arr) => arr.indexOf(val) === idx);
      dispatch(addVariableSubscriptions(panelId, variablesWithComponents));
    }
  }, [
    components,
    JSON.stringify(limitComponents),
    JSON.stringify(excludeComponents),
    showVirtuals,
    activeComponentTypeFilter,
    pageIndex,
  ]);

  useEffect(() => {
    setStaticComps(staticComponents.filter((c) => pageComponents.map((w) => w.id).includes(c.id)));
  }, [staticComponents, pageComponents]);

  useEffect(() => {
    if (updating.componentId) {
      setUpdating(() => ({
        ...updating,
        show: true,
      }));
    }
  }, [updating.componentId]);

  useEffect(() => {
    if (deleting.componentId) {
      const component = staticComps.find((c) => c.id === deleting.componentId);
      if (component) {
        setDeleting(() => ({
          ...deleting,
          show: true,
          component,
        }));
      }
    }
  }, [deleting.componentId, staticComps]);

  useEffect(() => {
    if (deleting.confirmed && deleting.component) {
      dispatch(deleteComponent(deleting.componentId, site.id));
      setDeleting({
        show: false,
        componentId: null,
        component: null,
        confirmed: false,
      });
    }
  }, [deleting.confirmed]);

  const getSourceConfig = useCallback(
    (sourceId) => {
      if (sources && sourceTypes) {
        const selectedSource = sources.find((source) => source.id === sourceId);
        const selectedSourceType = sourceTypes.find(
          (sourceType) => sourceType.name === selectedSource.type
        );
        if (selectedSourceType && selectedSourceType.schemas.config.properties.objectId) {
          return { objectId: selectedSource.options?.iotDevice?.objectId };
        }
        return null;
      }
      return null;
    },
    [sources, sourceTypes]
  );

  const prepCompFormData = (data) => {
    const { attributeData, ...componentData } = data;
    if (componentData.source) {
      const sourceConfig = getSourceConfig(componentData.source);
      if (sourceConfig) {
        componentData.sourceConfig = sourceConfig;
      }
    }

    return {
      component: prepData(componentData),
      attributes: attributeData,
    };
  };

  const handleUpdateComponent = useCallback(
    (formData) => {
      const preparedData = prepCompFormData(formData);
      dispatch(updateComponent(updating.componentId, site.id, preparedData));
      setUpdating({
        show: false,
        componentId: null,
        component: null,
      });
    },
    [updating]
  );

  const handleCloseUpdating = useCallback(() => {
    setUpdating({
      show: false,
      componentId: null,
      component: null,
    });
  }, []);

  const handleCreateComponent = useCallback(
    (formData) => {
      const preparedData = prepCompFormData(formData);
      dispatch(createComponent(site.id, site.org, preparedData));
      setCreating({ show: false });
    },
    [sources, sourceTypes]
  );

  const handleCloseCreating = useCallback(() => setCreating({ show: false }), []);

  const needsSite = componentsLoaded && !componentTree.children;
  const empty = componentsLoaded && !needsSite && !pageComponents.length;

  if (empty) {
    return <Empty text="No available components" />;
  }

  return (
    <section className={`components-panel ${needsSite || empty ? 'centered-content' : ''}`}>
      {needsSite && canEdit ? (
        <Button
          activity="primary"
          type="text"
          onClick={() =>
            dispatch(
              createComponent(site.id, site.org, {
                component: {
                  name: site.name,
                  type: 'site',
                  parent: null,
                  itemDesignation: '-',
                },
              })
            )
          }
        >
          Add Component
        </Button>
      ) : (
        <ComponentTree
          activeModelId={activeModelId}
          autoLoad={autoLoad}
          canDelete={canDelete}
          canEdit={canEdit}
          componentTree={componentTree}
          displayStatus={displayStatus}
          endingDepth={endingDepth}
          expandedDepth={expandedDepth}
          flat={flatList}
          modelsHashmap={modelsHashmap}
          viewerReady={viewerReady}
          onCreate={setCreating}
          onDelete={setDeleting}
          onUpdate={setUpdating}
          pageIndex={pageIndex}
          selectedId={activeComponentId}
          shown={pageComponents}
          startingDepth={startingDepth}
          toolbar={withToolbar}
          createdComponent={createdComponent}
          showModels={showModels}
          showDeleteTreeButton={showDeleteTreeButton}
          setShowDeleteDialog={setShowDeleteDialog}
        />
      )}
      {canEdit && (updating.show || creating.show) && (
        <ComponentModal
          modelsHashmap={modelsHashmap}
          onSubmit={updating.show ? handleUpdateComponent : handleCreateComponent}
          onCloseModal={updating.show ? handleCloseUpdating : handleCloseCreating}
          saveButtonText={updating.show ? 'Update' : 'Create'}
          updateId={updating.componentId}
          parentId={creating.parent}
        />
      )}
      {canDelete && deleting.show && (
        <ConfirmationDialog
          onCancel={() => setDeleting({ show: false, confirmed: false })}
          onConfirm={() => setDeleting({ ...deleting, show: false, confirmed: true })}
          title="Remove Component"
          subtitle={`${deleting.component.name}, ${deleting.component.itemDesignation}`}
          body={
            <>
              <p>
                {'This will also remove all child components, variables, event links and references ' +
                  'associated with the component.'}
              </p>
              <p style={{ paddingTop: '1.5rem' }}>
                This is a destructive action and cannot be un-done.
              </p>
            </>
          }
          confirmText="Remove"
          confirmType="danger"
        />
      )}
      {showDeleteDialog && (
        <ConfirmationDialog
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={() => {
            dispatch(deleteNonSiteComponents(site.org, site.id));
            setShowDeleteDialog(false);
          }}
          title="Remove component tree"
          body={
            <>
              <p>
                {'This will remove all nonsite components, variables, event links and references ' +
                  'associated with the component.'}
              </p>
              <p style={{ paddingTop: '1.5rem' }}>
                This is a destructive action and cannot be un-done.
              </p>
            </>
          }
          confirmText="Remove"
          confirmType="danger"
        />
      )}
    </section>
  );
};

export default withRouter(ComponentsPanel);
