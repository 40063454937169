import React, { useState, useMemo, useCallback } from 'react';
import { Spinner, Icon } from '@iq/react-components';
import { useSelector } from 'react-redux';

import { getHasPermission } from '../../../../../bundles/auth';
import ListItem from '../../../../ListItem';
import ConfirmationDialog from '../../../../ConfirmationDialog';

const UserList = (props) => {
  const {
    org,
    tenantUsers = [],
    searchText = '',
    roleFilter,
    isLoading,
    schema,
    uiSchema,
    onDelete,
    getPermissions,
  } = props;
  const [expanded, setExpanded] = useState([]);

  const canDeleteUsers = useSelector((state) => getHasPermission(state, 'members/Delete', { org }));

  const roleFilteredUsers = useMemo(() => {
    if (roleFilter !== 'all') {
      return tenantUsers.filter((u) => (u.roles[roleFilter] || []).length);
    }
    return tenantUsers;
  }, [tenantUsers, roleFilter]);

  const filteredUsers = useMemo(() => {
    if (searchText) {
      const filters = searchText.trim().split(' ');
      return roleFilteredUsers.filter(
        (u) =>
          filters.every((f) => (u.name?.toLowerCase() || '').indexOf(f.toLowerCase()) !== -1) ||
          filters.every((f) => (u.email?.toLowerCase() || '').indexOf(f.toLowerCase()) !== -1)
      );
    }
    return roleFilteredUsers;
  }, [roleFilteredUsers, searchText]);

  const headers = ['Users', 'Login email', 'Site access'];

  const headerCols = headers.map((h, i) => {
    const key = `col-${i}`;
    return (
      <div
        key={key}
        className="list-item--header__col"
      >
        {h}
      </div>
    );
  });

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const deleteUserPopup = useCallback(
    (i) => {
      setUserToDelete(i);
      setShowConfirmDelete(true);
    },
    [setUserToDelete, setShowConfirmDelete]
  );

  const onConfirmDelete = useCallback(() => {
    setShowConfirmDelete(false);
    onDelete(filteredUsers[userToDelete].id);
    setUserToDelete(null);
  }, [setShowConfirmDelete, setUserToDelete, onDelete, filteredUsers, userToDelete]);

  const listItems = useMemo(
    () =>
      filteredUsers.map((user, i) => (
        <ListItem
          key={user.name + i}
          itemIndex={i}
          entity={user.name}
          item={user}
          schema={schema}
          uiSchema={uiSchema}
          onExpand={() => setExpanded(user.name + i)}
          isExpanded={expanded.includes(user.name + i)}
          accordionContent={getPermissions(user)}
          hideActionMenu
          actionButtons={
            canDeleteUsers && (
              <Icon
                icon="cancel"
                onClick={() => deleteUserPopup(i)}
                className="delete-icon"
              />
            )
          }
          columns={[
            <div
              key={`row-${i}-name`}
              className="ellipsed-text"
            >
              {user.name}
            </div>,
            <div
              key={`row-${i}-email`}
              className="ellipsed-text"
            >
              {user.email}
            </div>,
            <div
              key={`row-${i}-count`}
              className="count-indicator"
            >
              {Object.values(user.roles).reduce((acc, userSites) => acc + userSites.length, 0)}
            </div>,
          ]}
        />
      )),
    [filteredUsers, expanded]
  );

  return (
    <>
      {showConfirmDelete ? (
        <ConfirmationDialog
          onCancel={() => setShowConfirmDelete(false)}
          onConfirm={onConfirmDelete}
          title={'Delete user'}
          body={
            <p style={{ paddingTop: '1.5rem' }}>
              Are you sure you want to remove <b>{filteredUsers[userToDelete].name}</b> from your
              organization?
            </p>
          }
          confirmText="Remove"
          confirmType="danger"
        />
      ) : null}

      {isLoading ? (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      ) : (
        <div className="list-container">
          <ListItem
            isHeader
            columns={headerCols}
          />
          <div className="custom-thin-scrollbar">{listItems}</div>
        </div>
      )}
    </>
  );
};

export default UserList;
