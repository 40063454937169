import { units } from '../../../../units';

export const schema = {
  type: 'object',
  properties: {
    label: {
      type: 'string',
      title: 'Label',
    },
    defaultEmptyValue: {
      type: 'string',
      title: 'Default text (when no value)',
    },
    unit: {
      type: 'string',
      title: 'Unit',
      default: units[0].id,
      anyOf: units.map((unit) => ({
        const: unit.id,
        title: `${unit.category}: ${unit.name}`,
      })),
    },
    unitLabel: {
      type: 'string',
      title: 'Custom unit label',
    },
    decimals: {
      default: 'auto',
      title: 'Decimals',
      type: 'string',
      enum: ['auto', '0', '1', '2', '3', '4', '5'],
    },
    icon: {
      type: 'string',
      title: 'Icon',
      default: '',
    },
  },
};

export const uiSchema = {
  icon: {
    'ui:field': 'iconPicker',
  },
};
