.version-options-wrapper {
  display: block;
  min-width: auto;
  position: relative;
  bottom: auto;
  margin: auto;
}
.version-options-wrapper .submit {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.version-options-wrapper .field {
  margin-bottom: 0.5rem;
}
.version-options-wrapper .help {
  line-height: 1.2rem;
  padding-top: 0.25rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9Nb2RlbFZpZXcvY29tcG9uZW50cy9WZXJzaW9uT3B0aW9ucyIsInNvdXJjZXMiOlsiVmVyc2lvbk9wdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIudmVyc2lvbi1vcHRpb25zLXdyYXBwZXIge1xuICBkaXNwbGF5OiBibG9jaztcbiAgbWluLXdpZHRoOiBhdXRvO1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIGJvdHRvbTogYXV0bztcbiAgbWFyZ2luOiBhdXRvO1xuXG4gIC5zdWJtaXQge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIG1hcmdpbi10b3A6IDJyZW07XG4gIH1cblxuICAuZmllbGQge1xuICAgIG1hcmdpbi1ib3R0b206IDAuNXJlbTtcbiAgfVxuXG4gIC5oZWxwIHtcbiAgICBsaW5lLWhlaWdodDogMS4ycmVtO1xuICAgIHBhZGRpbmctdG9wOiAwLjI1cmVtO1xuICB9XG59XG4iXX0= */