.delete-component {
  text-align: center;
  margin: 1rem 0;
}
.delete-component .title,
.delete-component .confirmation {
  margin-bottom: 1rem;
}
.delete-component .buttons {
  display: flex;
  justify-content: center;
}
.delete-component .buttons .avt-react-components--button {
  margin: 0 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvRGVsZXRlIiwic291cmNlcyI6WyJEZWxldGUuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7O0FBRUE7QUFBQTtFQUVFOztBQUdGO0VBQ0U7RUFDQTs7QUFFQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmRlbGV0ZS1jb21wb25lbnQge1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIG1hcmdpbjogMXJlbSAwO1xuXG4gIC50aXRsZSxcbiAgLmNvbmZpcm1hdGlvbiB7XG4gICAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbiAgfVxuXG4gIC5idXR0b25zIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuXG4gICAgLmF2dC1yZWFjdC1jb21wb25lbnRzLS1idXR0b24ge1xuICAgICAgbWFyZ2luOiAwIDAuNXJlbTtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */