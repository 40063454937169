.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
}
.heading > div:first-child {
  display: flex;
  align-items: center;
}
.heading > div:first-child > *:not(:first-child) {
  margin-left: 1rem;
}
.heading > div:first-child .type-label-component {
  margin-right: 1rem;
  min-width: 8.2rem;
}
.heading.title {
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.heading.size-large div:first-child div:last-child {
  font-size: 1.4rem;
}
.heading > div:last-child {
  display: flex;
  align-items: center;
}
.heading > div:last-child > * {
  margin-left: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvSGVhZGluZyIsInNvdXJjZXMiOlsiSGVhZGluZy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBOztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7O0FBRUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5oZWFkaW5nIHtcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBtYXJnaW46IDEuNXJlbSAwO1xuXG4gID4gZGl2OmZpcnN0LWNoaWxkIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgICA+ICo6bm90KDpmaXJzdC1jaGlsZCkge1xuICAgICAgbWFyZ2luLWxlZnQ6IDFyZW07XG4gICAgfVxuXG4gICAgLnR5cGUtbGFiZWwtY29tcG9uZW50IHtcbiAgICAgIG1hcmdpbi1yaWdodDogMXJlbTtcbiAgICAgIG1pbi13aWR0aDogOC4ycmVtO1xuICAgIH1cbiAgfVxuXG4gICYudGl0bGUge1xuICAgIGZvbnQtc2l6ZTogMS4xcmVtO1xuICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICB9XG5cbiAgJi5zaXplLWxhcmdlIGRpdjpmaXJzdC1jaGlsZCBkaXY6bGFzdC1jaGlsZCB7XG4gICAgZm9udC1zaXplOiAxLjRyZW07XG4gIH1cblxuICA+IGRpdjpsYXN0LWNoaWxkIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgICA+ICoge1xuICAgICAgbWFyZ2luLWxlZnQ6IDFyZW07XG4gICAgfVxuICB9XG59XG4iXX0= */