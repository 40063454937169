/* eslint-disable default-param-last */
import { DELETE_FILE_SUCCESS } from './files';
import { CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const PIN_FILE = 'dt/user-settings/PIN_FILE';
export const PERSIST_PIN_FILE = 'dt/user-settings/PERSIST_PIN_FILE';
export const REMOVE_PINNED_FILE = 'dt/user-settings/REMOVE_PINNED_FILE';
export const BOOKMARK_CREATED = 'dt/user-settings/BOOKMARK_CREATED';
export const BOOKMARK_DELETED = 'dt/user-settings/BOOKMARK_DELETED';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const pinFile = (fileId) => ({
  type: PIN_FILE,
  fileId,
});

export const removePinnedFile = (fileId) => ({
  type: REMOVE_PINNED_FILE,
  fileId,
});

export const bookmarkCreated = (fileId, bookmark) => ({
  type: BOOKMARK_CREATED,
  fileId,
  bookmark,
});

export const bookmarkDeleted = (fileId, id) => ({
  type: BOOKMARK_DELETED,
  fileId,
  id,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  pinnedFiles: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case PIN_FILE: {
      const { fileId } = action;
      if (state.pinnedFiles.includes(fileId)) return state;

      return {
        ...state,
        pinnedFiles: [...state.pinnedFiles, fileId],
      };
    }
    case REMOVE_PINNED_FILE:
    case DELETE_FILE_SUCCESS: {
      const { fileId } = action;

      return {
        ...state,
        pinnedFiles: state.pinnedFiles.filter((id) => id !== fileId),
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return { ...state, pinnedFiles: [] };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getPinnedFiles = (state) =>
  state.userSettings.pinnedFiles.reduce((acc, id) => {
    if (state.files.fileObjects[id]) acc.push(state.files.fileObjects[id]);
    return acc;
  }, []);
