/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.site-edit-modal-component .spinner-active {
  color: transparent !important;
}
.site-edit-modal-component .spinner-active .avt-react-components--spinner {
  position: absolute;
  width: calc(100% - 19px);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9TaXRlVmlldyIsInNvdXJjZXMiOlsiU2l0ZVZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtBQUNBO0FBQUE7RUFFRTtFQUNBOzs7QUFHRjtBQUNBO0VBQ0U7OztBQUlBO0VBQ0U7O0FBRUE7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLyogQ2hyb21lLCBTYWZhcmksIEVkZ2UsIE9wZXJhICovXG5pbnB1dDo6LXdlYmtpdC1vdXRlci1zcGluLWJ1dHRvbixcbmlucHV0Ojotd2Via2l0LWlubmVyLXNwaW4tYnV0dG9uIHtcbiAgLXdlYmtpdC1hcHBlYXJhbmNlOiBub25lO1xuICBtYXJnaW46IDA7XG59XG5cbi8qIEZpcmVmb3ggKi9cbmlucHV0W3R5cGU9J251bWJlciddIHtcbiAgLW1vei1hcHBlYXJhbmNlOiB0ZXh0ZmllbGQ7XG59XG5cbi5zaXRlLWVkaXQtbW9kYWwtY29tcG9uZW50IHtcbiAgLnNwaW5uZXItYWN0aXZlIHtcbiAgICBjb2xvcjogdHJhbnNwYXJlbnQgIWltcG9ydGFudDtcblxuICAgIC5hdnQtcmVhY3QtY29tcG9uZW50cy0tc3Bpbm5lciB7XG4gICAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgICB3aWR0aDogY2FsYygxMDAlIC0gMTlweCk7XG4gICAgfVxuICB9XG59XG4iXX0= */