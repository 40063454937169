import React from 'react';

import MemberView from '../../../MemberView';
import StepNavigator from '../StepNavigator';

const MemberStep = ({ onNext, onBack, wizardType }) => (
  <>
    <div className="wizard-step-top">
      <div className="wizard-step-top__header">
        <h1>Add site members</h1>
      </div>
    </div>
    <div className="wizard-step-main__body--framed custom-thin-scrollbar">
      <MemberView wizardType={wizardType} />
    </div>
    <StepNavigator
      onBack={onBack}
      onNext={onNext}
    />
  </>
);

export default MemberStep;
