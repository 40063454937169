.dropdown-menu--trigger {
  border-radius: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  flex-grow: 1;
}
.dropdown-menu--trigger.slim {
  padding: 0.1rem;
  font-size: 0.8rem;
}
.dropdown-menu--trigger .dropdown-menu--trigger-content-left {
  display: flex;
  align-items: center;
}
.dropdown-menu--trigger .dropdown-menu--trigger-content-left .dropdown-menu--trigger-label {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.dropdown-menu--trigger .dropdown-menu--trigger-content-left .pill-component {
  height: 0.8rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  margin-left: 0.5rem;
}
.dropdown-menu--trigger .dropdown-menu--trigger-content-left .pill-component .text {
  margin: 0;
  padding: 0 0.2rem;
}
.dropdown-menu--trigger .avt-react-components--icon {
  margin-left: 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvRHJvcGRvd25NZW51L0Ryb3Bkb3duVHJpZ2dlciIsInNvdXJjZXMiOlsiRHJvcGRvd25UcmlnZ2VyLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBS047RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5kcm9wZG93bi1tZW51LS10cmlnZ2VyIHtcbiAgYm9yZGVyLXJhZGl1czogMC4yNXJlbTtcbiAgcGFkZGluZzogMC41cmVtO1xuICBjdXJzb3I6IHBvaW50ZXI7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZm9udC1zaXplOiAxcmVtO1xuICBmbGV4LWdyb3c6IDE7XG5cbiAgJi5zbGltIHtcbiAgICBwYWRkaW5nOiAwLjFyZW07XG4gICAgZm9udC1zaXplOiAwLjhyZW07XG4gIH1cblxuICAuZHJvcGRvd24tbWVudS0tdHJpZ2dlci1jb250ZW50LWxlZnQge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcblxuICAgIC5kcm9wZG93bi1tZW51LS10cmlnZ2VyLWxhYmVsIHtcbiAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgICAgZmxleC13cmFwOiB3cmFwO1xuICAgIH1cblxuICAgIC5waWxsLWNvbXBvbmVudCB7XG4gICAgICBoZWlnaHQ6IDAuOHJlbTtcbiAgICAgIGJvcmRlci1yYWRpdXM6IDFyZW07XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgICAgcGFkZGluZzogMC4ycmVtO1xuICAgICAgbWFyZ2luLWxlZnQ6IDAuNXJlbTtcblxuICAgICAgLnRleHQge1xuICAgICAgICBtYXJnaW46IDA7XG4gICAgICAgIHBhZGRpbmc6IDAgMC4ycmVtO1xuICAgICAgfVxuICAgIH1cbiAgfVxuXG4gIC5hdnQtcmVhY3QtY29tcG9uZW50cy0taWNvbiB7XG4gICAgbWFyZ2luLWxlZnQ6IDAuNXJlbTtcbiAgfVxufVxuIl19 */