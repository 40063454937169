/* eslint-disable default-param-last */
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  getTfrTemplates as getTfrTemplatesApi,
  createTfrTemplate as createTfrTemplateApi,
  updateTfrTemplate as updateTfrTemplateApi,
  deleteTfrTemplate as deleteTfrTemplateApi,
} from '../services';
import getNotification from './notification-defaults';
import { displayNotification, checkOnline } from './notifications';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const REQUEST_TFR_TEMPLATE = 'dt/tfrTemplates/REQUEST_TFR_TEMPLATE';
const ADD_TFR_TEMPLATE = 'dt/tfrTemplates/ADD_TFR_TEMPLATE';
const UPDATE_TFR_TEMPLATE = 'dt/tfrTemplates/UPDATE_TFR_TEMPLATE';
const DELETE_TFR_TEMPLATE = 'dt/tfrTemplates/DELETE_TFR_TEMPLATE';
const RECEIVE_TFR_TEMPLATES = 'dt/tfrTemplates/RECEIVE_TFR_TEMPLATES';
const REMOVE_TFR_TEMPLATE = 'dt/tfrTemplates/REMOVE_TFR_TEMPLATE';

// Action creators
/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestTfrTemplates = (query) => ({
  type: REQUEST_TFR_TEMPLATE,
  query,
});

export const createTfrTemplate = (payload, callback) => ({
  type: ADD_TFR_TEMPLATE,
  payload,
  callback,
});

export const updateTfrTemplate = (templateId, payload, callback) => ({
  type: UPDATE_TFR_TEMPLATE,
  templateId,
  payload,
  callback,
});

export const deleteTfrTemplate = (templateId, callback) => ({
  type: DELETE_TFR_TEMPLATE,
  templateId,
  callback,
});

export const receiveTfrTemplates = (tfrTemplates) => ({
  type: RECEIVE_TFR_TEMPLATES,
  tfrTemplates,
});

export const removeTfrTemplate = (templateId) => ({
  type: REMOVE_TFR_TEMPLATE,
  templateId,
});

// Initial state
/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  tfrTemplates: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_TFR_TEMPLATES: {
      return {
        ...state,
        tfrTemplates: action.tfrTemplates,
      };
    }
    case REMOVE_TFR_TEMPLATE: {
      const templateIdx = state.tfrTemplates.findIndex(
        (template) => template.id === action.templateId
      );
      if (templateIdx >= 0) {
        return {
          ...state,
          tfrTemplates: [
            ...state.tfrTemplates.slice(0, templateIdx),
            ...state.tfrTemplates.slice(templateIdx + 1),
          ],
        };
      }
      return state;
    }
    default:
      return state;
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const fetchTfrTemplates = (state) => {
  return state.tfrTemplates.tfrTemplates;
};

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doRequestTfrTemplates(action) {
  const { query } = action;
  try {
    const response = yield getTfrTemplatesApi(query);
    yield put(receiveTfrTemplates(response.values));
  } catch (e) {
    console.error('Unable to get TFR templates: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('requestTfrTemplates', 'error')()));
  }
}

function* doCreateTfrTemplate(action) {
  const { payload, callback } = action;
  try {
    yield createTfrTemplateApi(payload);
    yield put(requestTfrTemplates({ site: payload.site, templateType: payload.templateType }));
    yield put(displayNotification(getNotification('addTfrTemplates', 'success')()));
  } catch (e) {
    console.error('Unable to add TFR template: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('addTfrTemplates', 'error')()));
  }
  yield callback();
}

function* doUpdateTfrTemplate(action) {
  const { templateId, payload, callback } = action;
  try {
    yield updateTfrTemplateApi(templateId, payload);
    yield put(requestTfrTemplates({ site: payload.site, templateType: payload.templateType }));
    yield put(displayNotification(getNotification('updateTfrTemplates', 'success')()));
  } catch (e) {
    console.error('Unable to update TFR templates: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateTfrTemplates', 'error')()));
  }
  yield callback();
}

function* doDeleteTfrTemplate(action) {
  const { templateId, callback } = action;
  try {
    yield call(deleteTfrTemplateApi, templateId);
    yield put(removeTfrTemplate(templateId));
    yield put(displayNotification(getNotification('deleteTfrTemplate', 'success')()));
  } catch (e) {
    console.error('Unable to delete TFR Template: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteTfrTemplate', 'error')()));
  }
  yield callback();
}

export const sagas = [
  takeLatest(REQUEST_TFR_TEMPLATE, doRequestTfrTemplates),
  takeEvery(ADD_TFR_TEMPLATE, doCreateTfrTemplate),
  takeLatest(UPDATE_TFR_TEMPLATE, doUpdateTfrTemplate),
  takeLatest(DELETE_TFR_TEMPLATE, doDeleteTfrTemplate),
];
