import React from 'react';
import { Icon } from '@iq/react-components';
import classnames from 'classnames';

import Pill from '../../Pill';

const DropdownTrigger = ({
  children,
  className,
  slim,
  label = '',
  icon = null,
  pillCount = 0,
  ...props
}) => {
  const classes = classnames('dropdown-menu--trigger', className, { slim });

  return (
    <div
      className={classes}
      {...props}
    >
      {children}
      {label && (
        <div className="dropdown-menu--trigger-content-left">
          {label && <div className="dropdown-menu--trigger-label">{label}</div>}
          {pillCount > 0 && <Pill text={pillCount} />}
        </div>
      )}
      {typeof icon === 'string' ? (
        <Icon
          icon={icon}
          size="s"
        />
      ) : (
        icon
      )}
    </div>
  );
};

export default DropdownTrigger;
