.no-grants-view-component {
  font-family: arial, sans-serif;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url("/opt/src/images/login.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #333;
  padding-top: 15vh;
  box-sizing: border-box;
  height: 100vh;
}
.no-grants-view-component .no-grants-view-panel {
  margin: 0 auto;
  padding: 1rem 2rem 2rem;
  background: #fff;
  max-width: 25rem;
}
.no-grants-view-component .no-grants-view-panel button {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}
.no-grants-view-component .no-grants-view-panel p {
  margin: 0.5rem 0;
}
.no-grants-view-component .no-grants-view-panel img {
  width: 75%;
  padding: 0.5rem 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvTm9HcmFudHNWaWV3Iiwic291cmNlcyI6WyJOb0dyYW50c1ZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5uby1ncmFudHMtdmlldy1jb21wb25lbnQge1xuICBmb250LWZhbWlseTogYXJpYWwsIHNhbnMtc2VyaWY7XG4gIGxpbmUtaGVpZ2h0OiBub3JtYWw7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogZmxleC1zdGFydDtcbiAgYmFja2dyb3VuZDogdXJsKCcvb3B0L3NyYy9pbWFnZXMvbG9naW4uanBnJyk7XG4gIGJhY2tncm91bmQtc2l6ZTogY292ZXI7XG4gIGJhY2tncm91bmQtcmVwZWF0OiBuby1yZXBlYXQ7XG4gIGNvbG9yOiAjMzMzO1xuICBwYWRkaW5nLXRvcDogMTV2aDtcbiAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgaGVpZ2h0OiAxMDB2aDtcblxuICAubm8tZ3JhbnRzLXZpZXctcGFuZWwge1xuICAgIG1hcmdpbjogMCBhdXRvO1xuICAgIHBhZGRpbmc6IDFyZW0gMnJlbSAycmVtO1xuICAgIGJhY2tncm91bmQ6ICNmZmY7XG4gICAgbWF4LXdpZHRoOiAyNXJlbTtcblxuICAgIGJ1dHRvbiB7XG4gICAgICBkaXNwbGF5OiBibG9jaztcbiAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgICAgbWFyZ2luLXRvcDogMnJlbTtcbiAgICB9XG5cbiAgICBwIHtcbiAgICAgIG1hcmdpbjogMC41cmVtIDA7XG4gICAgfVxuXG4gICAgaW1nIHtcbiAgICAgIHdpZHRoOiA3NSU7XG4gICAgICBwYWRkaW5nOiAwLjVyZW0gMDtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */