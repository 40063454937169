import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { contrastChart, Spinner } from '@iq/react-components';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';

import Loader from '../../../Loader';
import { getSiteVariablesIndex } from '../../../../bundles/sources';
import { useSubscription } from '../../../../bundles/visualizations';
import { format } from '../../../../units';
import { useClientSize } from '../../../../utils';

const PieVisualization = ({ visualization, panelId }) => {
  const {
    seriesValues: values,
    initialLoaded,
    loading,
  } = useSubscription(visualization.id, panelId);
  const variablesIndex = useSelector(getSiteVariablesIndex);
  const [{ height }, clientRef] = useClientSize();
  const { variables = {}, configuration = {} } = visualization;

  const getColor = (index, config) => {
    if (config.color) {
      return config.color;
    }

    return contrastChart[index % contrastChart.length];
  };

  const CustomLegend = ({ payload }) => {
    return (
      <ul className="pie-legend">
        {payload.map((dataSet, i) => {
          const key = `${dataSet.color} + ${i}`;
          return (
            <li
              key={key}
              className="pie-legend-item"
            >
              <svg
                width="10"
                height="10"
              >
                <rect
                  width="10"
                  height="10"
                  fill={dataSet.color}
                />
              </svg>
              {dataSet.value}
            </li>
          );
        })}
      </ul>
    );
  };

  const data = useMemo(() => {
    return values?.map(({ values: val, variableName, name }, index) => {
      const [id, agg] = name.split('.');
      const varConfig = variables.find((v) => v.id === id && v.aggregate === agg);
      const varUnit =
        varConfig?.unit === 'variable-default'
          ? (variablesIndex || {})[id]?.unit || 'number'
          : varConfig?.unit || 'variable-default';
      const filteredVals = val.filter((va) => va[1]);

      return {
        value: filteredVals.length ? parseFloat(filteredVals[filteredVals.length - 1][1]) : null,
        fill: getColor(index, {}),
        name: visualization.variables[index].label || variableName,
        decimals: varConfig?.decimals || 0,
        unit: varUnit,
      };
    });
  }, [values]);

  const vizHeight = height - 28; // less header
  const loaderHeight = vizHeight - 28; // less loader padding

  return (
    <div
      className={`pie-visualization-component`}
      ref={clientRef}
    >
      <div className="pie-header">
        <div className="pie-title">
          <span className="title">{configuration.title || visualization.name || '\u00A0'}</span>
        </div>
        {initialLoaded && loading && (
          <Spinner
            size="s"
            className="spinner"
          />
        )}
      </div>
      {!initialLoaded && loading ? (
        <Loader
          text="Loading ..."
          height={loaderHeight}
        />
      ) : (
        <div className="pie-wrapper">
          <ResponsiveContainer
            width="100%"
            height={vizHeight}
          >
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                innerRadius={configuration.pieType === 'Solid' ? '0%' : '80%'}
                paddingAngle={configuration.sliceGap}
                outerRadius="100%"
                isAnimationActive={false}
              >
                {data?.map((slice, i) => {
                  return (
                    <Cell
                      key={i}
                      fill={slice.fill}
                      fillOpacity={1}
                      stroke={slice.fill}
                    />
                  );
                })}
              </Pie>

              <Tooltip
                isAnimationActive={false}
                labelStyle={{ marginBottom: '0.4rem' }}
                formatter={(value, name, formatterData) => {
                  const { value: formattedValue, unit } = format(
                    formatterData.payload.unit,
                    formatterData.payload.value,
                    formatterData.payload.decimals
                  );
                  const percentage = value / data.reduce((acc, cur) => acc + cur.value, 0);
                  return [
                    `${formattedValue} ${unit} (${(percentage * 100).toFixed(2)}%)`,
                    formatterData.payload.name,
                  ];
                }}
                allowEscapeViewBox={{ x: false, y: true }}
                wrapperStyle={{ zIndex: '1000' }}
              />
              {configuration.legend?.show && (
                <Legend
                  content={<CustomLegend />}
                  height={36}
                />
              )}
            </PieChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default PieVisualization;
