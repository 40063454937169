const textPanelSchema = {
  title: {
    type: 'string',
    default: 'Text',
  },
  options: {
    type: 'object',
    properties: {
      text: {
        type: 'string',
      },
    },
  },
};

export default textPanelSchema;
