import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Button, Icon } from '@iq/react-components';

import { useDebounce, useClientSize } from '../../../utils';

import FilePreviewModal from '../../FilePreviewModal';
import FileUploadModal from './components/FileUploadModal';
import FileEditModal from './components/FileEditModal';
import FiletypeIcon from '../../FiletypeIcon';
import ConfirmationDialog from '../../ConfirmationDialog';
import FilesHeader from './components/FilesHeader';
import Loader from '../../Loader';
import Pagination from '../../Pagination';
import RenderFiles from './components/RenderFiles';

import {
  getComponentsUpdatedAt,
  getScopedComponents,
  getStaticComponents,
  getFilteredActiveComponentId,
} from '../../../bundles/components';
import { requestTypes, getTypes } from '../../../bundles/types';
import { requestTags, getTags } from '../../../bundles/tags';
import { getHasPermission } from '../../../bundles/auth';
import {
  useFilesSession,
  getFiles,
  requestFiles,
  deleteFile,
  zipFiles,
} from '../../../bundles/files';

const empty = [];
const emptyObj = {};

const FilesPanel = React.memo(
  ({
    componentScope = { selectedScope: 'all' },
    format = 'widest',
    layouts = 'Grid & list',
    hideToolbar = false,
    fileLimit = 20,
    filters: {
      includeFiles: { active: incFilesActive, files: includeFiles = empty } = emptyObj,
      includeTypes: { active: incTypesActive, types: includeTypes = empty } = emptyObj,
      excludeTypes: { active: excTypesActive, types: excludeTypes = empty } = emptyObj,
      includeTags: {
        active: incTagsActive,
        operator: incTagsOperator = 'or',
        tags: includeTags = empty,
      } = emptyObj,
      excludeTags: {
        active: excTagsActive,
        operator: excTagsOperator = 'or',
        tags: excludeTags = empty,
      } = emptyObj,
    } = emptyObj,
    hideSearch = false,
    hideFilters = false,
    hideUploadButton = false,
    isFullScreen = false,
    disableSelect = false,
    showFileExtension = true,
    selector = false,
    onAttach = () => null,
    onClose = () => null,
    site,
    filesToList = [],
    onFileDelete = () => null,
    isEventPage = false,
    editEvent = false,
  }) => {
    const dispatch = useDispatch();
    const { org, id: siteId } = site;
    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [{ width }, clientRef] = useClientSize();

    const onKeyDown = (e) => {
      const { altKey: a, ctrlKey: c, metaKey: m, shiftKey: s } = e;
      if (s && !(a || c || m)) {
        setIsShiftPressed(true);
      } else {
        setIsShiftPressed(false);
      }
    };

    const onKeyUp = (e) => {
      if (e.keyCode === 16) {
        setIsShiftPressed(false);
      }
    };

    const canWriteFiles = useSelector((state) =>
      getHasPermission(state, 'files/Write', { org, site: siteId })
    );
    const canDeleteFiles = useSelector((state) =>
      getHasPermission(state, 'files/Delete', { org, site: siteId })
    );
    const canEdit = !selector && canWriteFiles;
    const canDelete = !selector && canDeleteFiles;
    const canDownload = !selector;

    useEffect(() => {
      dispatch(requestTypes(siteId));
      dispatch(requestTags(siteId));
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
      };
    }, []);

    const allTypes = useSelector(getTypes);
    const allTags = useSelector(getTags);

    const availableTags = useMemo(() => {
      if (incTagsActive && includeTags.length && incTagsOperator === 'or') {
        return allTags.filter(({ id }) => includeTags.includes(id));
      }
      if (excTagsActive && excludeTags.length && excTagsOperator === 'or') {
        return allTags.filter(({ id }) => !excludeTags.includes(id));
      }
      return allTags;
    }, [allTags, incTagsActive, includeTags, excTagsActive, excludeTags]);

    const allExcludes = useMemo(() => {
      if (excTagsActive && excludeTags.length) {
        return allTags.filter(({ id }) => excludeTags.includes(id));
      }
      return null;
    }, [allTags, excTagsActive, excludeTags]);

    const allIncludes = useMemo(() => {
      if (incTagsActive && includeTags.length) {
        return allTags.filter(({ id }) => includeTags.includes(id));
      }
      return null;
    }, [allTags, incTagsActive, includeTags]);

    const limitTags = useMemo(
      () => ({
        includesTogether: incTagsActive && incTagsOperator === 'and' ? includeTags : null,
        excludesTogether: excTagsActive && excTagsOperator === 'and' ? excludeTags : null,
        fixedTags: incTagsActive && !!includeTags.length && incTagsOperator === 'or',
        allExcludes,
        allIncludes,
      }),
      [
        incTagsActive,
        incTagsOperator,
        includeTags,
        allIncludes,
        excTagsActive,
        excTagsOperator,
        excludeTags,
        allExcludes,
      ]
    );

    const availableTypes = useMemo(() => {
      const types = incTypesActive && includeTypes.length ? includeTypes : allTypes;

      if (excTypesActive && excludeTypes.length) {
        return types.filter((type) => !excludeTypes.includes(type));
      }
      return types;
    }, [allTypes, incTypesActive, includeTypes, excTypesActive, excludeTypes]);

    const [filesToEdit, setFilesToEdit] = useState(null);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [displayUploadModal, setDisplayUploadModal] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [fileViewType, setFileViewType] = useState(
      ['Only list', 'List & grid'].includes(layouts) ? 'table' : 'grid'
    );

    const [page, setPage] = useState(1);

    const [previewFileId, setPreviewFileId] = useState(null);
    const [activeBookmark, setActiveBookmark] = useState(null);

    const [typeFilter, setTypeFilter] = useState([]);
    const [tagFilter, setTagFilter] = useState([]);
    const [sortBy, setSortBy] = useState('createdAt');
    const [order, setOrder] = useState('desc');

    const [selectedFiles, setSelectedFiles] = useState([]);
    const selectedFilesIds = useMemo(() => selectedFiles.map((f) => f.id), [selectedFiles]);

    const sessionId = useFilesSession();
    const { files, pages, loading } = useSelector((state) => getFiles(state, sessionId));

    const activeComponentId = useSelector(getFilteredActiveComponentId);
    const components = useSelector(getStaticComponents);
    const componentsUpdatedAt = useSelector(getComponentsUpdatedAt);
    const { selectedScope, activeComponentTypeFilter } = componentScope;

    const availableComponents = useMemo(() => {
      if (activeComponentTypeFilter && activeComponentTypeFilter !== 'any') {
        return components.filter((c) => c.type === activeComponentTypeFilter);
      }
      return components;
    }, [components, activeComponentTypeFilter]);

    const scopedComponents = useMemo(
      () =>
        getScopedComponents({
          components,
          componentsUpdatedAt,
          activeComponentId,
          componentScope: JSON.stringify(componentScope),
        }),
      [componentsUpdatedAt, activeComponentId, componentScope]
    );

    const presetTagFilters = useMemo(() => {
      const filters = [];
      if (incTagsActive && includeTags.length) {
        filters.push({
          filterType: 'include',
          operator: incTagsOperator,
          tags: includeTags,
        });
      }
      if (excTagsActive && excludeTags.length) {
        filters.push({
          filterType: 'exclude',
          operator: excTagsOperator,
          tags: excludeTags,
        });
      }
      return filters;
    }, [incTagsActive, incTagsOperator, includeTags, excTagsActive, excTagsOperator, excludeTags]);

    const query = useMemo(() => {
      const includeIndex = presetTagFilters.findIndex((f) => f.filterType === 'include');
      // if we have tagFilters (user-defined) we know includes are using 'and' operator
      // (tag filter disabled/not shown if includes use 'or')
      // so we replace preset tags with our filters
      let tagFilters = [...presetTagFilters];
      if (includeIndex >= 0 && tagFilter.length) {
        tagFilters[includeIndex] = {
          ...tagFilters[includeIndex],
          tags: tagFilter,
        };
      } else if (includeIndex < 0 && tagFilter.length) {
        tagFilters = [{ filterType: 'include', operator: 'and', tags: tagFilter }, ...tagFilters];
      }
      if (includeIndex >= 0 && !hideToolbar && !hideFilters && !tagFilter.length) {
        delete tagFilters[includeIndex];
      }

      const activeIncTypes = incTypesActive ? includeTypes : empty;
      const includeTypesFilter = typeFilter.length ? typeFilter : activeIncTypes;

      const reqQuery = {
        org,
        site: siteId,
        tags: tagFilters,
        types: includeTypesFilter,
        excludeTypes: excTypesActive ? excludeTypes : empty,
        files: incFilesActive ? includeFiles : empty,
        search: filterValue.length ? filterValue : null,
        sortBy,
        order,
        page,
        limit: fileLimit,
      };

      if (
        selectedScope !== 'all' ||
        (activeComponentId && activeComponentTypeFilter && activeComponentTypeFilter !== 'any')
      ) {
        if (scopedComponents.length > 0) {
          reqQuery.components = scopedComponents.map((c) => c.id);
        } else {
          // if no components we poison the query so we get no results
          reqQuery.site = '___NO_SITE___';
        }
      }
      return reqQuery;
    }, [
      selectedScope,
      activeComponentTypeFilter,
      JSON.stringify(scopedComponents),
      tagFilter,
      typeFilter,
      incTypesActive,
      includeTypes,
      incFilesActive,
      includeFiles,
      filterValue,
      excTypesActive,
      excludeTypes,
      presetTagFilters,
      sortBy,
      order,
      page,
      fileLimit,
    ]);

    const onPageChanged = useDebounce(() => {
      dispatch(requestFiles(sessionId, query));
      setSelectedFiles([]);
    }, 300);

    useEffect(() => {
      if (isMounted) {
        onPageChanged();
      } else {
        setIsMounted(true);
      }
    }, [page]);

    const onFilterValueChanged = useDebounce(() => {
      setPage(1);
      dispatch(requestFiles(sessionId, { ...query, page: 1 }));
    }, 300);

    useEffect(() => {
      onFilterValueChanged();
    }, [
      JSON.stringify(scopedComponents),
      tagFilter,
      typeFilter,
      includeFiles,
      includeTypes,
      filterValue,
      excludeTypes,
      presetTagFilters,
      sortBy,
      order,
      fileLimit,
    ]);

    const availableTagIds = useMemo(() => availableTags.map((tag) => tag.id), [availableTags]);

    const filteredBookmarkFiles = useMemo(() => {
      if (files && (incTagsActive || excTagsActive || tagFilter.length)) {
        return files.map((file) => {
          if (file.bookmarks && file.bookmarks.length) {
            const { bookmarks } = file;
            return { ...file, bookmarks };
          }
          return file;
        });
      }
      if (files) return files;
      return null;
    }, [
      files,
      availableTagIds,
      incTagsActive,
      incTagsOperator,
      excTagsActive,
      excTagsOperator,
      tagFilter,
    ]);

    useEffect(() => {
      if (filesToDelete.length) {
        filesToDelete.forEach((fileId) => {
          if (!files.map((f) => f.id).includes(fileId)) {
            setFilesToDelete(() => filesToDelete.filter((f) => f !== fileId));
          }
        });
      }
    }, [files, filesToDelete]);

    const onSelectFile = (file) => {
      if (selectedFiles.find((f) => f.id === file.id)) {
        setSelectedFiles(selectedFiles.filter((f) => f.id !== file.id));
      } else {
        setSelectedFiles([...selectedFiles, file]);
      }
    };

    const toggleSelectAllFiles = (filesToSelect) => {
      if (selectedFiles.length === filesToSelect.length) {
        setSelectedFiles([]);
      } else {
        setSelectedFiles(filesToSelect);
      }
    };

    const onDelete = async (id) => {
      setFilesToDelete([id]);
    };

    const onDownload = (resourceUrl) => {
      setTimeout(() => {
        window.location = `${window.location.protocol}//${resourceUrl}`;
      });
    };

    const onDeleteSelected = () => {
      const fileIds = selectedFiles.filter((f) => !f.bookmarkData).map(({ id }) => id);
      setFilesToDelete(fileIds);
      setSelectedFiles(selectedFiles.filter((f) => !fileIds.includes(f.id)));
    };

    const getConfirmDeletionBody = useCallback(() => {
      const plural = filesToDelete.length > 1;
      const fileObjs = filesToDelete.map((id) => files.find((f) => f.id === id));

      return (
        <>
          <p>{`Are you sure you want to delete the following file${plural ? 's' : ''}?`}</p>
          <div className="filename-container">
            {fileObjs.map((f) => {
              if (!f) {
                return false;
              }
              let contexts = {};
              if (f.context && Array.isArray(f.context)) {
                contexts = f.context.reduce((acc, ctx) => {
                  if (ctx.type) {
                    acc[ctx.type] = (acc[ctx.type] || 0) + 1;
                  }
                  return acc;
                }, {});
              } else if (f.context && f.context.type) {
                contexts = { [f.context.type]: 1 };
              }
              delete contexts.general;
              return (
                <div
                  key={f.id}
                  className="filename"
                >
                  <FiletypeIcon file={f} />
                  <span>{f.filename}</span>
                  {Object.keys(contexts).length > 0 && (
                    <>
                      {Object.entries(contexts).map(([k, v], i) => (
                        <span key={`context-${i}`}>{`(${v} ${k} attachment${
                          v > 1 ? 's' : ''
                        }) `}</span>
                      ))}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </>
      );
      // 'files' is left out as a dep on purpose to prevent
      // file objects from being undefined at point of confirmation
    }, [filesToDelete]);

    const onConfirmDeletion = () => {
      filesToDelete.forEach((id) => dispatch(deleteFile(id)));
    };

    const onDownloadSelected = () => {
      if (selectedFiles.length > 1) {
        dispatch(zipFiles(selectedFiles));
      } else if (selectedFiles.length === 1) {
        window.location = `${window.location.protocol}//${selectedFiles[0].resourceUrl}`;
      }
    };

    const onEdit = (id) => {
      setFilesToEdit(files.filter(({ id: _id }) => _id === id));
    };

    const onEditSelected = () => {
      setFilesToEdit(selectedFiles.filter((f) => !f.bookmarkData));
    };

    const onPreview = (file, bookmark) => {
      const fileWithAllBookmarks = files.find((f) => f.id === file.id);
      if (!fileWithAllBookmarks) return;

      if (
        activeComponentId &&
        file.components &&
        !file.components.includes(activeComponentId) &&
        !bookmark
      ) {
        // eslint-disable-next-line no-param-reassign
        bookmark = file.bookmarks.find((b) => b.components.includes(activeComponentId));
      }

      if (typeof bookmark !== 'undefined') {
        setActiveBookmark(bookmark.id);
      }

      setPreviewFileId(fileWithAllBookmarks.id);
    };

    const onPreviewToggleItem = (fileId, direction) => {
      const currentPreviewIndex = files.map((c) => c.id).indexOf(fileId);

      let nextItem;
      if (direction === 'next') {
        if (currentPreviewIndex !== files.length - 1) {
          nextItem = files[currentPreviewIndex + 1];
        } else {
          // eslint-disable-next-line prefer-destructuring
          nextItem = files[0];
        }
      } else if (direction === 'prev') {
        if (currentPreviewIndex !== 0) {
          nextItem = files[currentPreviewIndex - 1];
        } else {
          nextItem = files[files.length - 1];
        }
      }

      onPreview(nextItem);
    };

    const onDrop = (acceptedFiles) => {
      setUploadFiles(acceptedFiles);
      setDisplayUploadModal(true);
    };

    const [dragCounter, setDragCounter] = useState(0);

    const handleDragEnter = (e) => {
      e.preventDefault();
      setDragCounter(dragCounter + 1);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      setDragCounter(dragCounter - 1);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      disabled: !canEdit,
      onDrop,
    });
    const onClickFile = (file) => {
      if (isShiftPressed) {
        onSelectFile(file);
      } else {
        onPreview(file);
      }
    };
    return (
      <>
        {isEventPage ? (
          <>
            {filesToList.map((file, idx) => (
              <div
                key={idx}
                className={`event-modal__files ${!editEvent ? 'editable' : ''}`}
                onClick={!editEvent ? () => onClickFile(file) : null}
              >
                <FiletypeIcon
                  file={{
                    fileType: file.site ? file.fileType : file.type,
                    filename: file.name || file.filename,
                    mime: file.mime || file.type,
                  }}
                />
                <span>{file.name || file.filename}</span>
                {editEvent && (
                  <Button
                    slim
                    activity="secondary"
                    icon={
                      <Icon
                        icon="he-delete"
                        onClick={() => onFileDelete(file)}
                      />
                    }
                  />
                )}
              </div>
            ))}
          </>
        ) : (
          <div
            ref={clientRef}
            className={`files-panel-component ${loading ? 'updating-files' : 'loaded'}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <FilesHeader
              availableTags={availableTags}
              availableTypes={availableTypes}
              canDelete={canDelete}
              canDownload={canDownload}
              canEdit={canEdit}
              disableSelect={disableSelect}
              fileViewType={fileViewType}
              files={files}
              filterValue={filterValue}
              format={format}
              hideFilters={hideFilters}
              hideSearch={hideSearch}
              hideToolbar={hideToolbar}
              hideUploadButton={hideUploadButton}
              includeTags={(incTagsActive && includeTags) || []}
              includeTypes={(incTypesActive && includeTypes) || []}
              isFullScreen={isFullScreen}
              layouts={layouts}
              limitTags={limitTags}
              onDeleteSelected={onDeleteSelected}
              onDownloadSelected={onDownloadSelected}
              onEditSelected={onEditSelected}
              selectedFiles={selectedFiles}
              setDisplayUploadModal={setDisplayUploadModal}
              setFileViewType={setFileViewType}
              setFiletypeFilter={setTypeFilter}
              setFilterValue={setFilterValue}
              setTagFilter={setTagFilter}
              toggleSelectAllFiles={toggleSelectAllFiles}
              width={width}
            />
            <div className="files-panel">
              <div
                {...getRootProps()}
                className="drop-wrapper"
              >
                {dragCounter > 0 && isDragActive && canEdit && (
                  <>
                    <input {...getInputProps()} />
                    <div className="drop-zone">
                      <p>Drop the files here...</p>
                    </div>
                  </>
                )}
                <RenderFiles
                  activeComponentId={activeComponentId}
                  canDelete={canDelete}
                  canDownload={canDownload}
                  canEdit={canEdit}
                  disableSelect={disableSelect}
                  fileViewType={fileViewType}
                  files={
                    filteredBookmarkFiles === null
                      ? null
                      : filteredBookmarkFiles.map((file) => ({
                          ...file,
                          isSelected: selectedFilesIds.includes(file.id),
                        }))
                  }
                  filterValue={filterValue}
                  format={format}
                  isFullScreen={isFullScreen}
                  isShiftPressed={isShiftPressed}
                  onDelete={onDelete}
                  onDownload={onDownload}
                  onEdit={onEdit}
                  onPreview={onPreview}
                  onSelectFile={onSelectFile}
                  selectedFiles={selectedFiles}
                  showFileExtension={showFileExtension}
                  tagFilter={tagFilter}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  setOrder={setOrder}
                  order={order}
                />
              </div>

              <Pagination
                pages={pages}
                page={page}
                setPage={setPage}
              />

              {selector && (
                <div className="files-panel-component__selector-actions">
                  <Button
                    onClick={() => onAttach(selectedFiles)}
                    disabled={!selectedFiles.length}
                  >
                    {`Attach file${selectedFiles.length > 1 ? 's' : ''}`}
                  </Button>
                  <Button
                    activity="secondary"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </div>
              )}

              {loading && (
                <div className="overlay-loader">
                  <Loader />
                </div>
              )}

              {displayUploadModal && (
                <FileUploadModal
                  files={uploadFiles}
                  activeComponentId={activeComponentId}
                  availableComponents={availableComponents}
                  availableTags={availableTags}
                  availableTypes={availableTypes}
                  limitTags={limitTags}
                  typeFilter={typeFilter}
                  includeTags={includeTags}
                  onCloseModal={() => setDisplayUploadModal(false)}
                />
              )}

              {filesToDelete.length > 0 && (
                <ConfirmationDialog
                  onCancel={() => setFilesToDelete([])}
                  onConfirm={onConfirmDeletion}
                  confirmType="danger"
                  title="Remove Files"
                  body={getConfirmDeletionBody()}
                  confirmText="Remove"
                />
              )}

              {filesToEdit && (
                <FileEditModal
                  files={filesToEdit}
                  availableComponents={availableComponents}
                  availableTags={availableTags}
                  availableTypes={availableTypes}
                  limitTags={limitTags}
                  onCloseModal={() => {
                    setSelectedFiles([]);
                    setFilesToEdit(false);
                  }}
                />
              )}
            </div>
          </div>
        )}
        {previewFileId && (
          <FilePreviewModal
            fileId={previewFileId}
            files={files}
            activeBookmark={activeBookmark}
            setActiveBookmark={setActiveBookmark}
            onCloseModal={() => {
              setPreviewFileId(null);
              setActiveBookmark(null);
            }}
            onStepNext={(fileId) => onPreviewToggleItem(fileId, 'next')}
            onStepPrevious={(fileId) => onPreviewToggleItem(fileId, 'prev')}
            onPreview={onPreview}
            canEdit={canEdit}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps)
);

export default FilesPanel;
