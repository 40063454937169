/* eslint-disable default-param-last */
import { call, put, takeLatest } from 'redux-saga/effects';

import { getTypes as getTypesApi } from '../services';
import { checkOnline, displayNotification } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const REQUEST_TYPES = 'dt/types/REQUEST_TYPES';
export const RECEIVED_TYPES = 'dt/types/RECEIVED_TYPES';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestTypes = (siteId) => ({
  type: REQUEST_TYPES,
  query: { site: siteId },
});

export const receiveTypes = (types) => ({
  type: RECEIVED_TYPES,
  types,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  types: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVED_TYPES: {
      return { ...state, types: action.types };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return { ...state, types: [] };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getTypes = (state) => state.types.types;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doRequestTypes({ query }) {
  try {
    const { values: types } = yield call(getTypesApi, query);
    yield put(receiveTypes(types));
  } catch (err) {
    yield console.error(err);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getTypes', 'error')()));
  }
}

export const sagas = [takeLatest(REQUEST_TYPES, doRequestTypes)];
