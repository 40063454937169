import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Button, Spinner } from '@iq/react-components';

import JSONEditor from '../../../../JSONEditor';
import TypeLabel from '../../../../TypeLabel';
import StyledItem from '../../../../StyledItem';
import ModelEditModal from '../ModelEditModal';

import { getActiveSite, updateSite, getModelThumbnail } from '../../../../../bundles/sites';
import {
  COMPLETED,
  FAILED,
  REMOVED,
  PENDING,
  removeUploadingId,
} from '../../../../../bundles/models';
import { removeUpload } from '../../../../../bundles/uploads';
import { checkIsOnline, displayNotification } from '../../../../../bundles/notifications';
import getNotification from '../../../../../bundles/notification-defaults';

const VersionListItem = ({ version, model, schema, upload }) => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);
  const forgeThumbnail = useSelector((state) => getModelThumbnail(state, model.id, version.id));

  const getStatus = () => {
    if (version.removed) return 'REMOVED';
    return version.status;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [displayModelEditModal, setDisplayModelEditModal] = useState(false);
  const [status, setStatus] = useState(getStatus());

  const formattedVersion = {
    Status: getStatus(),
    Comments: version.comment,
    'Scene Configuration': !!version.sceneConfig,
    'Component Mappings': version.mappings.reduce((acc, mapping) => {
      acc.push(...mapping.itemDesignations);
      return acc;
    }, []),
  };

  const updateSiteModel = () => {
    setIsUpdating(true);
    dispatch(
      updateSite(
        site.id,
        {
          ...site,
          model: model.id,
          version: version.id,
        },
        () => setIsUpdating(false)
      )
    );
  };

  const getLabelColor = () => {
    if (status === COMPLETED) return 'grey';
    if ([FAILED, REMOVED].includes(status)) return 'red';
    return 'yellow';
  };

  const isProcessing = ![COMPLETED, FAILED, REMOVED].includes(status);

  useEffect(() => {
    const newStatus = getStatus();
    if (newStatus !== status) {
      setStatus(newStatus);
    }
  }, [version]);

  useEffect(() => {
    if (status !== PENDING) {
      dispatch(removeUploadingId(model.id));
    }
    if (upload && !isProcessing) {
      dispatch(removeUpload(upload.id));
      // should not have an upload with REMOVED status
      if (status === COMPLETED) {
        dispatch(displayNotification(getNotification('uploadModelVersion', 'success')()));
      } else {
        dispatch(checkIsOnline());
        dispatch(displayNotification(getNotification('uploadModelVersion', 'error')()));
      }
    }
  }, [status]);

  const hasCustomThumbnail = version?.thumbnail?.split(':')[1]?.split('/')[0] === 'image';
  const hasForgeThumbnail = forgeThumbnail?.split(':')[1]?.split('/')[0] === 'image';
  const displayThumbnail =
    (hasCustomThumbnail && version.thumbnail) || (hasForgeThumbnail && forgeThumbnail);

  return (
    <StyledItem
      itemClass="version-list-item"
      active={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      headerLabel={
        <TypeLabel
          color={getLabelColor()}
          text={`Version ${version.versionNumber}`}
        />
      }
      headerContent={
        <div className={`title ${version.removed && ' xout'}`}>{`${version.comment}`}</div>
      }
      headerActions={
        <>
          {(isProcessing || isUpdating) && (
            <Spinner
              size="s"
              className="spinner"
            />
          )}
          <Icon icon={isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'} />
        </>
      }
      bodyActions={
        <>
          {!version.removed && version.status === COMPLETED && (
            <Button
              activity="primary"
              disabled={isUpdating || displayModelEditModal}
              onClick={updateSiteModel}
            >
              Set Site Image
            </Button>
          )}
          <Button
            activity="primary"
            disabled={status !== COMPLETED || isUpdating || displayModelEditModal}
            onClick={() => setDisplayModelEditModal(true)}
          >
            Edit
          </Button>
        </>
      }
    >
      <div className="model-version-properties-container">
        <div className="model-version-properties">
          <JSONEditor
            title={`Version ${version.versionNumber}`}
            formData={formattedVersion}
            schema={schema}
            showEditButton={false}
          />
        </div>
        <div className="model-version-thumbnail">
          {hasCustomThumbnail || hasForgeThumbnail ? (
            <img
              src={displayThumbnail}
              alt={`Thumbnail for ${model.name} - v${version.versionNumber}`}
            />
          ) : (
            <p className="missing-thumbnail-text">Edit model to add thumbnail</p>
          )}
        </div>

        {displayModelEditModal && (
          <ModelEditModal
            geometryMapping
            sceneConfig
            title={`${model.name} - v${version.versionNumber}`}
            model={model}
            version={version}
            onCloseModal={() => setDisplayModelEditModal(false)}
            activeExtension="mapping"
          />
        )}
      </div>
    </StyledItem>
  );
};

VersionListItem.propTypes = {
  version: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  upload: PropTypes.object,
};

export default VersionListItem;
