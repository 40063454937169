import React, { useCallback } from 'react';
import { Button } from '@iq/react-components';

import FileThumbnail from '../FileThumbnail';
import { truncateFilename } from '../../utils';

const FileLink = ({ file, onClick, maxNameLength = 22 }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      if (onClick) {
        onClick();
      }
    },
    [onClick]
  );

  const [truncatedFilename, isTruncated] = truncateFilename(file.filename, maxNameLength);

  return (
    <div className="file-link">
      <Button
        design="text"
        onClick={handleClick}
        icon={
          <FileThumbnail
            file={file}
            width={100}
            height={100}
          />
        }
        tooltip={isTruncated ? file.filename : undefined}
        tooltipPosition="top-end"
      >
        {truncatedFilename}
      </Button>
    </div>
  );
};

export default FileLink;
