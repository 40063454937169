import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { createHashHistory, createBrowserHistory } from 'history';
import { logger, useAuth, AuthProvider } from '@iq/react-components';

import config from '../../config';
import store from '../../store';

import Router from '../Router';
import GlobalMessagePortal from '../Notifications/GlobalMessagePortal';
import TopNotification from '../Notifications/TopNotification';

import { AppContextProvider, initialState } from '../../AppContext';
import { ObservableStateProvider, checkNetworkStatus, getRealm } from '../../utils';

const authConfig = { ...config.auth };

const authInitConfig = {
  pkceMethod: 'S256',
  enableLogging: true,
};

const history =
  window.navigator && window.navigator.standalone ? createHashHistory() : createBrowserHistory();

const { applicationInsights } = config;

if (applicationInsights) {
  const { connectionString, maxAjaxCallsPerView, ...rest } = applicationInsights;
  logger.init(
    {
      ...rest,
      connectionString,
      maxAjaxCallsPerView,
    },
    { browserHistory: history }
  );
}

const App = () => {
  const [online, setOnline] = useState(true);
  const { authenticated, subject, logOut, authError, initialize } = useAuth();
  const checkStatus = async () => {
    if (!navigator.onLine) {
      setOnline(false);
    } else {
      const status = await checkNetworkStatus();
      setOnline(status);
    }
  };

  useEffect(() => {
    let checkStatusInterval;
    if (!online) {
      checkStatusInterval = setInterval(checkStatus, 5000);
    } else {
      initialize();

      if (checkStatusInterval) {
        clearInterval(checkStatusInterval);
      }
    }
    return () => {
      if (checkStatusInterval) {
        clearInterval(checkStatusInterval);
      }
    };
  }, [online]);

  useEffect(() => {
    if (authenticated) {
      logger.setUser(subject);
    } else {
      logger.setUser(null);
    }
  }, [authenticated]);

  useEffect(() => {
    if (authError) {
      checkStatus();
    }
  }, [authError]);

  if (!authenticated && !authError) {
    return null;
  }

  if (!authenticated && authError && online) {
    return (
      <GlobalMessagePortal>
        <TopNotification
          message="Unable to authenticate"
          action={logOut}
          actionText="Retry login"
        />
      </GlobalMessagePortal>
    );
  }

  return (
    <Provider store={store}>
      <ObservableStateProvider>
        <AppContextProvider value={initialState}>
          <GlobalMessagePortal>
            {!online && (
              <TopNotification
                icon="he-nonetwork"
                message="No internet connection"
              />
            )}
          </GlobalMessagePortal>
          <Router history={history} />
        </AppContextProvider>
      </ObservableStateProvider>
    </Provider>
  );
};

const AppWithProviders = (props) => {
  const realm = getRealm();

  return (
    <AuthProvider
      config={{ ...authConfig, realm }}
      initConfig={authInitConfig}
    >
      <App {...props} />
    </AuthProvider>
  );
};

export default AppWithProviders;
