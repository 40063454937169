import React, { memo } from 'react';
import { Button } from '@iq/react-components';

import ErrorBoundary from '../ErrorBoundary';
import BigModal from './BigModal';
import './BigModal.scss';

const ErrorComponent = memo(({ onReload, retries, ...rest }) => {
  const reloader = retries > 0 ? () => window.location.reload() : onReload;
  const reloadCta = retries > 0 ? 'Reload Page' : 'Reload';
  return (
    <BigModal {...rest}>
      <div className="bound-error">
        <div>
          <p>Something went wrong</p>
          <Button
            onClick={reloader}
            activity="secondary"
            type="button"
          >
            {reloadCta}
          </Button>
        </div>
      </div>
    </BigModal>
  );
});

const BoundBigModal = (props) => {
  const { children, ...rest } = props;

  return (
    <ErrorBoundary
      ErrorComponent={ErrorComponent}
      {...rest}
    >
      <BigModal {...props} />
    </ErrorBoundary>
  );
};

export default BoundBigModal;
