import React from 'react';
import { Textarea } from '@iq/react-components';

const TextareaWidget = ({
  value,
  schema: {
    default: defaultValue,
    disabled,
    readonly,
    required: customRequired,
    placeholder,
    editable = true,
    rows = 5,
  },
  required,
  onChange,
}) => {
  const defaultVal = defaultValue;
  const useDefault = !value;
  const val = useDefault ? defaultVal : value;
  const viewRows = Math.ceil(Math.min(rows, (val?.length || 1) / 25));

  return (
    <>
      {editable ? (
        <Textarea
          required={required || customRequired}
          disabled={disabled}
          readOnly={readonly}
          placeholder={placeholder}
          rows={rows}
          defaultValue={val}
          onChange={(event) => {
            let v = event.target.value;
            if (typeof v === 'string') {
              v = v.trim();
            }
            onChange(v);
          }}
        />
      ) : (
        <Textarea
          readOnly={true}
          rows={viewRows}
          value={val}
          className="view-widget"
        />
      )}
    </>
  );
};

export default TextareaWidget;
