import React from 'react';

import StepNavigator from '../StepNavigator';

const SuccessStep = (props) => {
  const {
    members: { admin, contributor, collaborator, viewer } = {},
    models: { cad, twoD, threeD } = {},
    components: { component, branch, group, virtual } = {},
    data: { sources, variables, visualizations } = {},
    onClose,
  } = props;

  return (
    <>
      <div className="wizard-success__message">Congrats</div>
      <div className="wizard-success__overview">
        <div className="wizard-success__overview__section">
          <div>Project members</div>
          {admin ? <div>{admin} Site mangers</div> : null}
          {contributor ? <div>{contributor} Contributors</div> : null}
          {collaborator ? <div>{collaborator} Collaborators</div> : null}
          {viewer ? <div>{viewer} Viewers</div> : null}
        </div>
        <div className="wizard-success__overview__section">
          <div>3D/2D models</div>
          {cad ? <div>{cad} CAD models</div> : null}
          {twoD ? <div>{twoD} 2D models</div> : null}
          {threeD ? <div>{threeD} 3D models</div> : null}
        </div>
        <div className="wizard-success__overview__section">
          <div>Components</div>
          {component ? <div>{component} Components</div> : null}
          {branch ? <div>{branch} Branches</div> : null}
          {group ? <div>{group} Groups</div> : null}
          {virtual ? <div>{virtual} Areas </div> : null}
        </div>
        <div className="wizard-success__overview__section">
          <div>Data visualization</div>
          {sources ? <div>{sources} Data sources</div> : null}
          {variables ? <div>{variables} Signals</div> : null}
          {visualizations ? <div>{visualizations} Visualizations</div> : null}
        </div>
      </div>
      <StepNavigator onClose={onClose} />
    </>
  );
};

export default SuccessStep;
