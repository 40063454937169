import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import { useBackgroundFreeze, useParentOnlyClickHandler } from '../../utils';

const modalRoot = document.getElementById('global-modal-container');

const BigModal = ({
  children,
  onClose,
  className = '',
  overlayClassName = '',
  overlayCanClose = true,
}) => {
  const overlayRef = useRef();

  const keyListener = (e) => {
    if (e.keyCode === 27) {
      onClose(e);
    }
  };

  useParentOnlyClickHandler(onClose, overlayRef.current, overlayCanClose);
  useBackgroundFreeze();

  useEffect(() => {
    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, []);

  const overlayClass = classnames('big-modal-overlay', overlayClassName);

  return ReactDOM.createPortal(
    <div
      ref={overlayRef}
      className={overlayClass}
      style={{ cursor: 'pointer' }}
    >
      <div className={`big-modal-wrapper ${className}`}>{children}</div>
    </div>,
    modalRoot
  );
};

export default BigModal;
