import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spinner } from '@iq/react-components';

import {
  requestSources,
  createSource,
  updateSource,
  deleteSource,
  getSources,
  getSourcesLoaded,
  getSourceTypes,
} from '../../../bundles/sources';
import { getActiveSite } from '../../../bundles/sites';
import { getHasPermission } from '../../../bundles/auth';

import Heading from '../../Heading';
import ConfirmationDialog from '../../ConfirmationDialog';
import SourceListItem from './components/SourceListItem';
import CreateSourceModal from './components/CreateSourceModal';
import { INTERNAL_DATA_SOURCE } from '../../../constants';

// TODO: normalize adapter schemas to use similar naming conventions
const SECRET_KEYS = ['secret', 'clientSecret', 'client_secret', 'password'];
const ALLOWED_SOURCES = ['custom', 'dec', 'dummy', 'elasticsearch'];

const SourceView = ({ wizardType }) => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);
  const sourcesLoaded = useSelector(getSourcesLoaded);
  const sources = useSelector(getSources);
  const sourceTypes = useSelector(getSourceTypes);
  const isSuperAdmin = useSelector((state) => getHasPermission(state, 'permissions/Write'));

  const [showNoSourceDeleteMsg, setShowNoSourceDeleteMsg] = useState(false);
  const [displayCreateSourceForm, setDisplayCreateSourceForm] = useState(false);

  useEffect(() => {
    if (site.id && !sourcesLoaded) {
      dispatch(requestSources({ siteId: site.id, withVariables: false }));
    }
  }, [site, sourcesLoaded]);

  const sortedSources = sources.sort(({ createdAt: a }, { createdAt: b }) => a - b);

  const formatSchema = (sourceType, ignoreProperties = [], isUpdate = false) => {
    const options =
      sourceType && Object.entries(sourceType.schemas.source.properties).length > 0
        ? JSON.parse(JSON.stringify(sourceType.schemas.source))
        : {};
    const riskThreshold = { ...options?.properties?.riskThreshold };

    const schema = {
      type: 'object',
      required: ['name', 'type'],
      properties: {
        type: {
          type: 'string',
          title: 'Source Type',
          enum: [
            ...(sourceTypes || [])
              .map(({ name }) => name)
              .filter((name) => (isSuperAdmin ? true : ALLOWED_SOURCES.includes(name))),
          ],
        },
        name: {
          type: 'string',
          title: 'Name',
          format: 'sourceName',
          maxLength: 30,
        },
        description: {
          type: 'string',
          title: 'Description',
          default: '',
        },
        options:
          isUpdate && options.required
            ? {
                ...options,
                required: options.required.filter((field) => !SECRET_KEYS.includes(field)),
              }
            : options,
      },
    };

    if (schema.properties?.options?.properties?.riskStatusEnabled) {
      // Delete this schema prop coming from IB. So that we can add dependencies here.
      delete schema.properties.options.properties.riskThreshold;
      schema.properties.options.dependencies = {
        riskStatusEnabled: {
          oneOf: [
            {
              properties: {
                riskStatusEnabled: { enum: [false] },
              },
            },
            {
              properties: {
                riskStatusEnabled: { enum: [true] },
                riskThreshold,
              },
            },
          ],
        },
      };
    }

    if (schema.properties?.options?.properties?.importedFileName) {
      delete schema.properties.options.properties.importedFileName;
    }

    ignoreProperties.forEach((prop) => {
      delete schema.properties[prop];
    });

    return schema;
  };

  const transformErrors = (errors) =>
    errors.map((error) => {
      const customError = { ...error };
      if (customError.name === 'format' && customError.property === '.name') {
        customError.message =
          "Source name may not contain the characters [ ] * ? : / \\ or the character ' (apostrophe) in the first or last position.";
      }
      return customError;
    });

  const customFormats = {
    // eslint-disable-next-line no-useless-escape
    sourceName: /^[^\'\[\]\*\?\:\/\\][^\[\]\*\?\:\/\\]+[^\'\[\]\*\?\:\/\\]$/,
  };

  const customValidate = useCallback(
    (formData, errors) => {
      sources.forEach((source) => {
        if (
          source.id !== formData.id &&
          source.name.toLowerCase() === formData.name?.toLowerCase()
        ) {
          errors.name.addError('Source name should be unique.');
        }
        if (source.id === formData.id && formData.options?.riskThreshold) {
          const { shutdownWarning, shutdownEmergency, downtimeWarning, downtimeEmergency } =
            formData.options.riskThreshold;
          const thresholdErrors = errors.options.riskThreshold;
          if (shutdownWarning <= 0 || shutdownWarning > 100) {
            thresholdErrors.shutdownWarning.addError('The range should be between 0 and 100.');
          }
          if (shutdownEmergency <= 0 || shutdownEmergency > 100) {
            thresholdErrors.shutdownEmergency.addError('The range should be between 0 and 100.');
          }
          if (downtimeWarning <= 0) {
            thresholdErrors.downtimeWarning.addError('The number must exceed 0.');
          }
          if (downtimeEmergency <= 0) {
            thresholdErrors.downtimeEmergency.addError('The number must exceed 0.');
          }
        }
      });

      return errors;
    },
    [sources]
  );

  const createSchemas = sourceTypes.reduce(
    (prev, sourceType) => {
      if (sourceType.name !== INTERNAL_DATA_SOURCE) {
        return {
          ...prev,
          [sourceType.name]: formatSchema(sourceType),
        };
      }
      return prev;
    },
    { default: formatSchema() }
  );

  const updateSchemas = sourceTypes.reduce(
    (prev, sourceType) => ({
      ...prev,
      [sourceType.name]: formatSchema(sourceType, ['type'], true),
    }),
    { default: formatSchema() }
  );

  const onDelete = useCallback(
    (sourceId) => {
      const source = sources.find((s) => s.id === sourceId);
      if (source?.type === INTERNAL_DATA_SOURCE) {
        setShowNoSourceDeleteMsg(true);
      } else {
        dispatch(deleteSource(site.id, sourceId));
      }
    },
    [sources, site.id]
  );

  const onUpdate = useCallback(
    (sourceId, data) => dispatch(updateSource(site.id, sourceId, data)),
    [site.id]
  );

  const onCreate = useCallback(
    (formData) => {
      dispatch(createSource({ org: site.org, site: site.id, ...formData }));
      setDisplayCreateSourceForm(false);
    },
    [site.org, site.id, setDisplayCreateSourceForm]
  );

  const onClose = useCallback(
    () => setDisplayCreateSourceForm(false),
    [setDisplayCreateSourceForm]
  );

  const wizClass = wizardType ? `--${wizardType}` : '';

  return (
    <>
      {sourcesLoaded ? (
        <>
          <Heading
            contentLeft={<div className="title">Data Sources</div>}
            contentRight={
              <Button
                className={`button-create${wizardType ? wizClass : ''}`}
                onClick={() => setDisplayCreateSourceForm(true)}
              >
                Add Source
              </Button>
            }
          />

          {sortedSources.length > 0 ? (
            sortedSources.map((source, sourceIndex) => (
              <SourceListItem
                site={site}
                title={source.name}
                columnCount={sortedSources.length}
                sourceIndex={sourceIndex}
                key={source.id}
                source={source}
                schema={updateSchemas[source.type] || updateSchemas.default}
                onDelete={onDelete}
                onSubmit={onUpdate}
                transformErrors={transformErrors}
                customFormats={customFormats}
                customValidate={customValidate}
              />
            ))
          ) : (
            <div>No sources yet, create one to get started</div>
          )}
        </>
      ) : (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      )}

      {displayCreateSourceForm && sourceTypes.length > 0 && (
        <CreateSourceModal
          schemas={createSchemas}
          onSubmit={onCreate}
          onCloseModal={onClose}
          transformErrors={transformErrors}
          customFormats={customFormats}
          customValidate={customValidate}
        />
      )}

      {showNoSourceDeleteMsg && (
        <ConfirmationDialog
          onCancel={() => setShowNoSourceDeleteMsg(false)}
          onConfirm={(e) => {
            e.preventDefault();
            setShowNoSourceDeleteMsg(false);
          }}
          confirmType="info"
          title="Remove source"
          body={
            <div className="remove-internal-source">
              <div>Internal sources can not be removed.</div>
            </div>
          }
          confirmText="OK"
        />
      )}
    </>
  );
};

export default SourceView;
