import React from 'react';

import { Button, Icon } from '@iq/react-components';

const Pagination = ({ pages, page, setPage }) => {
  const canPreviousPage = page > 1;
  const canNextPage = page < pages;

  if (pages === null || pages < 2) {
    return null;
  }

  return (
    <div className="panel-pagination">
      <Button
        icon={
          <Icon
            size="xs"
            icon="he-fastbackward"
          />
        }
        activity="secondary"
        onClick={() => setPage(1)}
        disabled={!canPreviousPage}
        slim
        tooltip="First page"
      />
      <Button
        icon={
          <Icon
            size="xs"
            icon="he-backwards"
          />
        }
        activity="secondary"
        onClick={() => setPage(page - 1)}
        disabled={!canPreviousPage}
        slim
        tooltip="Previous page"
      />
      <span>
        Page{' '}
        <strong>
          {' '}
          {page} of {pages}{' '}
        </strong>{' '}
      </span>
      <Button
        icon={
          <Icon
            size="xs"
            icon="he-forwards"
          />
        }
        activity="secondary"
        onClick={() => setPage(page + 1)}
        disabled={!canNextPage}
        slim
        tooltip="Next page"
      />
      <Button
        icon={
          <Icon
            size="xs"
            icon="he-fastforward"
          />
        }
        activity="secondary"
        onClick={() => setPage(pages)}
        disabled={!canNextPage}
        slim
        tooltip="Last page"
      />
    </div>
  );
};

export default Pagination;
