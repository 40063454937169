import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

const VideoPreview = ({
  file,
  videoRef,
  setBookmarkState = () => null,
  activeBookmark = null,
  setActiveBookmark = () => null,
  setFileInfo = () => null,
  displayBookmarkPanel = () => null,
  noPadding = false,
}) => {
  const [wasPlaying, setWasPlaying] = useState(false);
  const [playing, setPlaying] = useState(false);

  const renderVideoPreview = () => {
    const spacebarKeyListener = (e) => {
      // Play/Pause on spacebar
      if (e.keyCode === 32) {
        if (videoRef.current.paused) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', spacebarKeyListener);

      return () => {
        document.removeEventListener('keydown', spacebarKeyListener);
      };
    }, []);

    const handleLoading = () => {
      setPlaying(false);
    };

    const handlePlaying = () => {
      setPlaying(true);
    };

    useEffect(() => {
      if (videoRef.current && activeBookmark !== null) {
        // eslint-disable-next-line no-param-reassign
        videoRef.current.currentTime = activeBookmark.bookmarkData.time;

        // Re-set the active bookmark in order to be able to trigger the same
        // bookmark again. This could also be done as the video progresses.
        setActiveBookmark(null);
      }
    }, [videoRef.current, activeBookmark]);

    useEffect(() => {
      if (videoRef.current) {
        if (displayBookmarkPanel) {
          videoRef.current.pause();
        } else if (wasPlaying) {
          videoRef.current.play();
        }
      }
    }, [displayBookmarkPanel]);

    useEffect(() => {
      if (videoRef.current) {
        /* eslint-disable no-param-reassign */
        videoRef.current.onwaiting = handleLoading;
        videoRef.current.onplaying = handlePlaying;
        /* eslint-enable no-param-reassign */

        setBookmarkState({
          bookmarkData: {
            time: 0,
          },
        });
      }
    }, [videoRef.current]);

    const handleTimeUpdate = (e) => {
      setBookmarkState({
        bookmarkData: {
          time: e.target.currentTime,
        },
      });
    };

    const handleLoadedMetadata = () => {
      setFileInfo({
        type: 'video',
        duration: videoRef.current.duration,
      });
    };

    const classes = classnames({
      'media-playing': playing,
      'media-loading': !playing,
      'no-padding': noPadding,
    });

    return (
      <div className={`video-preview-content ${classes}`}>
        <video
          key={file.id}
          controls
          autoPlay
          ref={videoRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onPlay={() => setWasPlaying(true)}
          onPause={() => {
            if (!displayBookmarkPanel) {
              setWasPlaying(false);
            }
          }}
          onEnded={() => setWasPlaying(false)}
          controlsList="nodownload"
          disablePictureInPicture
        >
          <source
            src={`${window.location.protocol}//${file.resourceUrl}`}
            type={file.mime && file.mime.includes('quicktime') ? 'video/mp4' : file.mime}
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    );
  };

  return renderVideoPreview();
};

export default VideoPreview;
