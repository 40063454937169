const columnSchema = {
  type: 'object',
  required: ['title'],
  properties: {
    title: {
      type: 'string',
      title: 'Column title',
      default: '',
    },
    displayTitle: {
      type: 'boolean',
      title: 'Display title',
      default: false,
    },
  },
};

export default columnSchema;
