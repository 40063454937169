import thresholdSchema from './thresholdSchema';
import colors from '../../../../colors';

export const schema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      title: 'Title',
    },
    starMode: {
      type: 'string',
      title: 'Mode',
      default: 'current',
      oneOf: [
        { const: 'current', title: 'Current value' },
        { const: 'over time', title: 'Values over time' },
      ],
    },
    pointRadius: {
      type: 'number',
      title: 'Size of plot points',
      default: 5,
      minimum: 0,
      maximum: 10,
    },
    legend: {
      type: 'object',
      title: 'Legend',
      properties: {
        show: { type: 'boolean', default: false },
      },
    },
    scale: {
      type: 'object',
      title: 'Domain scale',
      properties: {
        scaleMode: {
          type: 'string',
          title: 'Domain mode',
          enum: ['Automatic', 'Manual'],
          default: 'Automatic',
        },
      },
      if: {
        properties: {
          scaleMode: {
            enum: ['Manual'],
          },
        },
      },
      then: {
        properties: {
          value: {
            type: 'number',
            title: 'Domain max',
            default: 300,
          },
        },
      },
    },
    ...thresholdSchema,
    thresholdsPosition: {
      type: 'object',
      title: 'Thresholds position',
      properties: {
        selection: {
          title: ' ',
          type: 'string',
          default: 'first',
          oneOf: [
            { const: 'first', title: 'At first plot point' },
            { const: 'center', title: 'Center' },
            { const: 'custom', title: 'Custom position' },
          ],
        },
      },
      if: {
        properties: {
          selection: { const: 'custom' },
        },
      },
      then: {
        properties: {
          manualPos: {
            type: 'object',
            title: 'Manual thresholds position',
            properties: {
              L1: {
                type: 'number',
                title: 'L1',
                default: 0,
              },
              L2: {
                type: 'number',
                title: 'L2',
                default: 0,
              },
              L3: {
                type: 'number',
                title: 'L3',
                default: 0,
              },
            },
          },
        },
      },
    },

    radialGrid: {
      type: 'object',
      title: 'Radial Grid',
      properties: {
        axes: {
          type: 'number',
          title: 'Grid axes amount',
          default: 20,
          minimum: 0,
          maximum: 30,
        },
        size: {
          type: 'number',
          title: 'Tick spacing',
        },
      },
    },

    rollingAverage: {
      type: 'object',
      title: 'Rolling average',
      properties: {
        show: {
          type: 'boolean',
          title: 'show',
          default: false,
        },
        decay: {
          title: ' ',
          type: 'null',
          default: 0.5,
        },
      },
      if: {
        properties: {
          show: {
            enum: [true],
          },
        },
      },
      then: {
        properties: {
          type: {
            type: 'string',
            title: 'Rolling average type',
            enum: ['Points', 'Lines', 'Both'],
            default: 'Points',
          },
          decay: {
            type: 'number',
            title: 'Rolling average decay',
            default: 0.5,
            multipleOf: 0.05,
            maximum: 1,
            minimum: 0,
          },
          color: {
            type: 'string',
            title: 'Rolling average color',
            default: colors[1].value,
            oneOf: colors.map((color) => ({
              const: color.value,
              title: color.label,
            })),
          },
        },
      },
    },
  },
};

export const uiSchema = {
  thresholds: {
    areaItems: {
      'ui:options': {
        orderable: false,
      },
    },
    linearItems: {
      'ui:options': {
        orderable: false,
      },
    },
  },
  rollingAverage: {
    'ui:order': ['show', 'type', 'decay', 'color'],
    decay: {
      'ui:widget': 'range',
    },
  },
  radialGrid: {
    axes: {
      'ui:widget': 'range',
    },
  },
  pointRadius: {
    'ui:widget': 'range',
  },
  gridAmount: {
    'ui:widget': 'range',
  },
  rollingAverageDecay: {
    'ui:widget': 'range',
  },
};
