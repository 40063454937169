import React from 'react';

import Heading from '../../../../../../Heading';
import { ComponentsPanel } from '../../../../../../panels/ComponentsPanel';

const ManageStep = ({ site, importType }) => (
  <>
    <div className="wizard-step-main__top">
      <Heading contentLeft={<div className="title">Manage components</div>} />
    </div>
    <div className="wizard-step-main__body--framed custom-thin-scrollbar manage-components-step">
      <ComponentsPanel
        site={site}
        pageIndex="wizard"
        showVirtuals
        withToolbar
        showDeleteTreeButton={importType !== 'manual'}
        componentScope={{}}
        showModels={false}
      />
    </div>
  </>
);

export default ManageStep;
