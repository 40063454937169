/* eslint-disable default-param-last */
import axios from 'axios';
import { take, takeEvery, call, put, select } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';

import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';
import { updateModel, createModelVersion } from '../services';
import { calculateFileHash } from './utils';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const ADD_FILES = 'avt/upload/ADD_FILES';
export const REMOVE_UPLOAD = 'avt/upload/REMOVE_UPLOAD';
export const INIT_UPLOAD = 'avt/upload/INIT_UPLOAD';
export const UPLOAD_STARTED = 'avt/upload/UPLOAD_STARTED';
export const UPLOAD_PROGRESS = 'avt/upload/UPLOAD_PROGRESS';
export const UPLOAD_COMPLETED = 'avt/upload/UPLOAD_COMPLETED';
export const UPLOAD_FAILED = 'avt/auth/UPLOAD_FAILED';

export const ADDED = 'added';
export const INITIALIZED = 'initialized';
export const UPLOADING = 'uploading';
export const UPLOADED = 'uploaded';
export const FAILED = 'failed';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const addFile = (file, siteId, modelId) => ({
  type: ADD_FILES,
  files: [file],
  siteId,
  modelId,
});

export const addFiles = (files, siteId, modelId) => ({
  type: ADD_FILES,
  files,
  siteId,
  modelId,
});

export const removeUpload = (id) => ({
  type: REMOVE_UPLOAD,
  id,
});

export const initUpload = (upload) => ({
  type: INIT_UPLOAD,
  id: upload.id,
  org: upload.org,
  siteId: upload.siteId,
  arkitSupported: upload.arkitSupported,
  comment: upload.comment,
  rootFile: upload.rootFile,
  modelName: upload.modelName,
  copyMappings: upload.copyMappings,
  mappingsFromVersion: upload.mappingsFromVersion,
});

export const uploadStarted = (id, versionId) => ({
  type: UPLOAD_STARTED,
  id,
  versionId,
});

export const uploadProgress = (id, progress) => ({
  type: UPLOAD_PROGRESS,
  id,
  progress,
});

export const uploadCompleted = (id) => ({
  type: UPLOAD_COMPLETED,
  id,
});

export const uploadFailed = (id, errors) => ({
  type: UPLOAD_FAILED,
  id,
  errors,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  objects: {},
  ids: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FILES: {
      const { files, siteId, modelId } = action;

      const [newObjects, newIds] = files.reduce(
        ([objects, ids], file) => {
          const id = Math.random().toString(36);
          // eslint-disable-next-line no-param-reassign
          objects[id] = {
            // eslint-disable-line no-param-reassign
            id,
            file,
            isZip: file.name.split('.').pop().toLowerCase() === 'zip',
            siteId,
            modelId,
            modelName: file.name,
            status: ADDED,
          };
          ids.push(id);
          return [objects, ids];
        },
        [{}, []]
      );

      const { objects, ids } = state;

      return {
        ...state,
        objects: { ...objects, ...newObjects },
        ids: [...ids, ...newIds],
      };
    }
    case REMOVE_UPLOAD: {
      const { id } = action;
      const { ids } = state;
      const objects = { ...state.objects };
      delete objects[id];

      return {
        ...state,
        objects,
        ids: ids.filter((uploadId) => uploadId !== id),
      };
    }
    case INIT_UPLOAD: {
      const { objects } = state;
      const upload = {
        ...objects[action.id],
        siteId: action.siteId,
        comment: action.comment,
        arkitSupported: action.arkitSupported,
        rootFile: action.rootFile,
        copyMappings: action.copyMappings,
        mappingsFromVersion: action.mappingsFromVersion,
        progress: 0,
        status: INITIALIZED,
      };

      return {
        ...state,
        objects: { ...objects, [action.id]: upload },
      };
    }
    case UPLOAD_STARTED: {
      const { objects } = state;
      const { id, versionId } = action;

      const upload = {
        ...objects[id],
        versionId,
        status: UPLOADING,
      };

      return {
        ...state,
        objects: { ...objects, [id]: upload },
      };
    }
    case UPLOAD_PROGRESS: {
      const { objects } = state;
      const { id, progress } = action;

      const upload = {
        ...objects[id],
        progress,
      };

      return {
        ...state,
        objects: { ...objects, [id]: upload },
      };
    }
    case UPLOAD_COMPLETED: {
      const { objects } = state;
      const { id } = action;

      const upload = {
        ...objects[id],
        status: UPLOADED,
      };

      return {
        ...state,
        objects: { ...objects, [id]: upload },
      };
    }
    case UPLOAD_FAILED: {
      const { objects } = state;
      const { id } = action;

      const upload = {
        ...objects[id],
        status: FAILED,
      };

      return {
        ...state,
        objects: { ...objects, [id]: upload },
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        objects: {},
        ids: [],
      };
    }
    default:
      return state;
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getUpload = (state, id) => state.uploads.objects[id];

export const getUploads = (state, status) => {
  if (state.uploads.objects === null) {
    return null;
  }
  if (typeof status === 'undefined') {
    return state.uploads.ids.map((id) => state.uploads.objects[id]);
  }
  return state.uploads.ids
    .map((id) => state.uploads.objects[id])
    .filter((upload) => upload.status === status);
};

export const getAdded = (state) => getUploads(state, ADDED);

export const getInitialized = (state) => getUploads(state, INITIALIZED);

export const getUploading = (state) => getUploads(state, UPLOADING);

export const getUploaded = (state) => getUploads(state, UPLOADED);

export const getFailed = (state) => getUploads(state, FAILED);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doUpload(action) {
  const { id } = action;

  try {
    const { file, siteId, modelId, comment, rootFile, copyMappings, mappingsFromVersion } =
      yield select(getUpload, id);

    const hash = yield calculateFileHash(file);
    const modelData = { site: siteId };

    // update model's site in case it was created in team (with project instead of site)
    // and copy whatever value was previously set for arkitSupported
    const { arkitSupported } = yield call(updateModel, modelId, modelData);

    const outputs = [{ type: 'svf' }];

    if (arkitSupported) {
      outputs.push({ type: 'arkit' });
    }

    const versionData = {
      outputs,
      originalName: file.name,
      hash,
      comment,
      rootFile,
      copyMappings,
      mappingsFromVersion,
    };

    const {
      version: { id: versionId, status },
      url,
    } = yield call(createModelVersion, modelId, versionData);

    yield put(uploadStarted(id, versionId));

    if (status.toLowerCase() === 'failed') {
      yield put(uploadFailed(id));
      return;
    }

    const channel = eventChannel((emit) => {
      const fileSizeMb = file.size / (1024 * 1000);

      if (fileSizeMb > 75) {
        let resumableUrl;
        if (url.indexOf('?')) {
          resumableUrl = `${url.substr(0, url.indexOf('?'))}/resumable${url.substr(
            url.indexOf('?')
          )}`;
        } else {
          resumableUrl = `${url}/resumable`;
        }
        const chunkSize = 5 * 1024 * 1024; // Recommended size is 5 Mb
        const nbChunks = Math.ceil(file.size / chunkSize);
        const chunksMap = Array.from(
          {
            length: nbChunks,
          },
          (_e, i) => i
        );
        let progress = 0;

        chunksMap.forEach((chunkIdx) => {
          const start = chunkIdx * chunkSize;
          const end = Math.min(file.size, (chunkIdx + 1) * chunkSize) - 1;

          const range = `bytes ${start}-${end}/${file.size}`;
          const blob = file.slice(start, end + 1);
          axios({
            method: 'PUT',
            url: resumableUrl,
            headers: {
              'Content-Range': range,
              'Session-Id': hash,
            },
            data: blob,
          })
            .then(() => {
              if (chunkIdx === chunksMap.length - 1) {
                emit(uploadCompleted(id));
              } else {
                const currentChunk = chunkIdx * chunkSize;
                progress = progress > currentChunk ? progress : currentChunk;
                emit(uploadProgress(id, progress));
              }
            })
            .catch((e) => {
              console.error('Model upload error', e);
              emit(uploadFailed(id));
              emit(END);
            });
        });
      } else {
        // Non chunked upload
        const config = {
          onUploadProgress: ({ loaded }) => {
            emit(uploadProgress(id, loaded));
          },
        };

        axios
          .put(url, file, config)
          .then(() => {
            emit(uploadCompleted(id));
            emit(END);
          })
          .catch((e) => {
            console.error('Model upload error', e);
            emit(uploadFailed(id));
            emit(END);
          });
      }

      return () => {
        // clean up
      };
    });

    while (true) {
      const dispatchedAction = yield take(channel);
      yield put(dispatchedAction);
    }
  } catch (e) {
    console.error('Upload failed: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('uploadModelVersion', 'error')()));
    yield put(uploadFailed(id, e));
  }
}

export const sagas = [takeEvery(INIT_UPLOAD, doUpload)];
