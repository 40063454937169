import DefaultSiteCreator from './default';

const creators = {
  default: DefaultSiteCreator,
};

export default function getSiteTypeProperties(props) {
  const { type } = props;

  return creators[type] ? creators[type](props) : creators.default(props);
}
