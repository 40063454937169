const createRouteTrail = (activeTree, setActiveTree) => [
  {
    route: '/sites/:id/admin/layout/pages/:page/sections/:section/columns/:column/panels/:panel',
    title: (context, match) =>
      context.page.sections[context.activeTree.section].columns[context.activeTree.column].panels[
        context.activeTree.panel
      ]?.title || match.params.panel,
  },
  {
    route: '/sites/:id/admin/layout/pages/:page/sections/:section/columns/:column',
    title: (context, match) =>
      context.page.sections[context.activeTree.section].columns[context.activeTree.column]?.title ||
      match.params.column,
    onClick: () => {
      setActiveTree({
        ...activeTree,
        panel: undefined,
      });
    },
  },
  {
    route: '/sites/:id/admin/layout/pages/:page/sections/:section',
    title: (context, match) =>
      context.page.sections[context.activeTree.section]?.title || match.params.section,
    onClick: () => {
      setActiveTree({
        ...activeTree,
        panel: undefined,
        column: undefined,
      });
    },
  },
  {
    route: '/sites/:id/admin/layout/pages/:page',
    title: (context, match) => context.page.title || match.params.page,
    onClick: () => {
      setActiveTree({
        ...activeTree,
        panel: undefined,
        column: undefined,
        section: undefined,
      });
    },
  },
  {
    route: '/sites/:id/admin/layout',
    title: 'Layout',
    onClick: () => {
      setActiveTree({
        ...activeTree,
        panel: undefined,
        section: undefined,
        column: undefined,
        page: undefined,
      });
    },
  },
  {
    route: '/sites/:id/admin',
    title: 'Administration',
    to: '/administration',
  },
];

export default createRouteTrail;
