import React from 'react';
import { Button } from '@iq/react-components';

export default function UpdateWidget({ updated, onClick, onSaveLayout }) {
  return updated ? (
    <div className="panel-updated-notice">
      <div className="notice">The current layout has been changed.</div>
      <div className="actions">
        <Button
          onClick={onClick}
          activity="secondary"
        >
          Reset
        </Button>
        <Button
          onClick={onSaveLayout}
          activity="primary"
        >
          Save changes
        </Button>
      </div>
    </div>
  ) : null;
}
