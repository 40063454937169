#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-progress-modal {
  margin-top: 5rem;
}
#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-progress-modal .selected-signals-label {
  padding-top: 1rem;
  font-weight: bold;
}
#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-progress-modal .progress-text {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}
#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-progress-modal .progress-bar-initial {
  width: 100%;
  margin: 0.5rem 0rem 2rem 0rem;
  background-color: #ccced0;
}
#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-progress-modal .export-progress-content {
  margin-top: 1rem;
  overflow-x: hidden;
  max-height: 40rem;
}
#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-progress-modal .export-progress-content > div {
  padding: 0.5rem 0;
}
#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-progress-modal .cancel {
  margin-top: 2rem;
}
#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-variables-modal {
  margin-top: 5rem;
}
#global-modal-container .simple-modal-container .simple-modal-overlay .simple-modal-wrapper.export-variables-modal .tooltip-container {
  cursor: help;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9WYXJpYWJsZVZpZXcvY29tcG9uZW50cy9FeHBvcnRUaW1lU2VyaWVzTW9kYWwiLCJzb3VyY2VzIjpbIkV4cG9ydFRpbWVTZXJpZXNNb2RhbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUlRO0VBQ0U7O0FBRUE7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBSUo7RUFDRTs7QUFJSjtFQUNFOztBQUVBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIjZ2xvYmFsLW1vZGFsLWNvbnRhaW5lciB7XG4gIC5zaW1wbGUtbW9kYWwtY29udGFpbmVyIHtcbiAgICAuc2ltcGxlLW1vZGFsLW92ZXJsYXkge1xuICAgICAgLnNpbXBsZS1tb2RhbC13cmFwcGVyIHtcbiAgICAgICAgJi5leHBvcnQtcHJvZ3Jlc3MtbW9kYWwge1xuICAgICAgICAgIG1hcmdpbi10b3A6IDVyZW07XG5cbiAgICAgICAgICAuc2VsZWN0ZWQtc2lnbmFscy1sYWJlbCB7XG4gICAgICAgICAgICBwYWRkaW5nLXRvcDogMXJlbTtcbiAgICAgICAgICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICAgICAgICAgIH1cblxuICAgICAgICAgIC5wcm9ncmVzcy10ZXh0IHtcbiAgICAgICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgICAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICAgICAgICAgIHBhZGRpbmctdG9wOiAycmVtO1xuICAgICAgICAgIH1cbiAgICAgICAgICAucHJvZ3Jlc3MtYmFyLWluaXRpYWwge1xuICAgICAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICAgICAgICBtYXJnaW46IDAuNXJlbSAwcmVtIDJyZW0gMHJlbTtcbiAgICAgICAgICAgIGJhY2tncm91bmQtY29sb3I6ICNjY2NlZDA7XG4gICAgICAgICAgfVxuICAgICAgICAgIC5leHBvcnQtcHJvZ3Jlc3MtY29udGVudCB7XG4gICAgICAgICAgICBtYXJnaW4tdG9wOiAxcmVtO1xuICAgICAgICAgICAgb3ZlcmZsb3cteDogaGlkZGVuO1xuICAgICAgICAgICAgbWF4LWhlaWdodDogNDByZW07XG5cbiAgICAgICAgICAgID4gZGl2IHtcbiAgICAgICAgICAgICAgcGFkZGluZzogMC41cmVtIDA7XG4gICAgICAgICAgICB9XG4gICAgICAgICAgfVxuXG4gICAgICAgICAgLmNhbmNlbCB7XG4gICAgICAgICAgICBtYXJnaW4tdG9wOiAycmVtO1xuICAgICAgICAgIH1cbiAgICAgICAgfVxuXG4gICAgICAgICYuZXhwb3J0LXZhcmlhYmxlcy1tb2RhbCB7XG4gICAgICAgICAgbWFyZ2luLXRvcDogNXJlbTtcblxuICAgICAgICAgIC50b29sdGlwLWNvbnRhaW5lciB7XG4gICAgICAgICAgICBjdXJzb3I6IGhlbHA7XG4gICAgICAgICAgfVxuICAgICAgICB9XG4gICAgICB9XG4gICAgfVxuICB9XG59XG4iXX0= */