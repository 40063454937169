.generate-qr-code .redirect-button a {
  color: white;
}
.generate-qr-code .select-row {
  margin-bottom: 1rem;
}
.generate-qr-code .select-row > div > div > div > div {
  line-height: 1.3rem;
}
.generate-qr-code h3 {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.generate-qr-code .actions {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9Db21wb25lbnRWaWV3L2NvbXBvbmVudHMvUXJNb2RhbCIsInNvdXJjZXMiOlsiUXJNb2RhbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0U7O0FBR0Y7RUFDRTs7QUFFQTtFQUNFOztBQUdKO0VBQ0U7RUFDQTs7QUFFRjtFQUNFO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5nZW5lcmF0ZS1xci1jb2RlIHtcbiAgLnJlZGlyZWN0LWJ1dHRvbiBhIHtcbiAgICBjb2xvcjogd2hpdGU7XG4gIH1cblxuICAuc2VsZWN0LXJvdyB7XG4gICAgbWFyZ2luLWJvdHRvbTogMXJlbTtcblxuICAgICYgPiBkaXYgPiBkaXYgPiBkaXYgPiBkaXYge1xuICAgICAgbGluZS1oZWlnaHQ6IDEuM3JlbTtcbiAgICB9XG4gIH1cbiAgaDMge1xuICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG4gICAgbWFyZ2luLWJvdHRvbTogMC41cmVtO1xuICB9XG4gIC5hY3Rpb25zIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICBtYXJnaW46IDFyZW0gMHB4O1xuICB9XG59XG4iXX0= */