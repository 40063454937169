.panel-settings-modal__actions {
  justify-content: space-between;
  display: flex;
  margin-top: 2rem;
}
.panel-settings-modal__primary-buttons {
  display: flex;
  justify-content: flex-end;
}
.panel-settings-modal__primary-buttons > button:first-child {
  margin-right: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvUGFuZWwvUGFuZWxTZXR0aW5ncyIsInNvdXJjZXMiOlsiUGFuZWxTZXR0aW5ncy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0U7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7QUFFQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLnBhbmVsLXNldHRpbmdzLW1vZGFsIHtcbiAgJl9fYWN0aW9ucyB7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgbWFyZ2luLXRvcDogMnJlbTtcbiAgfVxuXG4gICZfX3ByaW1hcnktYnV0dG9ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kO1xuXG4gICAgPiBidXR0b246Zmlyc3QtY2hpbGQge1xuICAgICAgbWFyZ2luLXJpZ2h0OiAxcmVtO1xuICAgIH1cbiAgfVxufSJdfQ== */