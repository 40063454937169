import React, { useState } from 'react';

import { HvTabs, HvTab } from '@hitachivantara/uikit-react-core';
import RolesTab from './RolesTab/RolesTab';
import UsersTab from './UserTab/UserTab';
import InvitationsTab from './InvitationTab/InvitationsTab';

const TenantAdminUserView = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (e, newTabIndex) => setTabIndex(newTabIndex);

  return (
    <div className="tenant-admin-panel">
      <HvTabs
        id="tabs-content-change"
        value={tabIndex}
        onChange={handleChange}
      >
        <HvTab
          id="users"
          label="Users"
        />
        <HvTab
          id="invitations"
          label="Invitations"
        />
        <HvTab
          id="roles"
          label="Roles"
        />
      </HvTabs>
      <div className="tenant-admin-content-tabs-container">
        {tabIndex === 0 && <UsersTab />}
        {tabIndex === 1 && <InvitationsTab />}
        {tabIndex === 2 && <RolesTab />}
      </div>
    </div>
  );
};

export default TenantAdminUserView;
