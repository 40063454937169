import React, { useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, useTheme, colors } from '@iq/react-components';

import PopoutSelect from '../PopoutSelect';
import { getMembers, requestMemberTypes } from '../../bundles/auth';
import Loader from '../Loader';

export default function RoleSelect({
  label,
  icon,
  isMulti = true,
  onChange = () => null,
  value,
  closeMenuOnSelect,
  popout = false,
  placeholder,
  menuXPlacement = 'right',
  menuYPlacement = 'auto',
  menuMaxHeight,
  hideAllRoles = false,
  fullHeight = false,
  realm,
  ...props
}) {
  const SelectComponent = popout ? PopoutSelect : Select;
  const dispatch = useDispatch();
  const theme = useTheme();
  const members = useSelector((state) => getMembers(state, `org/${realm}`));

  useEffect(() => {
    dispatch(requestMemberTypes());
  }, []);

  const overrideStyles = () => ({
    option: (base, state) => {
      const themedColor =
        theme === 'dark' ? colors.DarkApplicationPrimaryText : colors.LightApplicationPrimaryText;
      return {
        ...base,
        fontWeight: '400',
        fontSize: '1rem',
        color: state.isSelected ? colors.Grey10 : themedColor,
      };
    },
    menu: (base) => ({
      ...base,
      ...(fullHeight ? {} : { minHeight: 'unset' }),
      border: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
      marginTop: '0.2rem',
      zIndex: '1000',
    }),
    control: (base) => ({
      ...base,
      ...(fullHeight ? {} : { minHeight: 'unset' }),
      lineHeight: '1.3rem',
      backgroundColor: `${theme === 'dark' ? colors.Grey90 : colors.Grey0}`,
    }),
    menuList: (base) => ({
      ...base,
      ...(menuMaxHeight ? { maxHeight: menuMaxHeight } : {}),
      scrollbarColor: `${
        theme === 'dark'
          ? 'rgba(255, 255, 255, .25) rgba(0, 0, 0, 0)'
          : 'rgba(0, 0, 0, .25) rgba(0, 0, 0, 0)'
      }`,
      '::-webkit-scrollbar': {
        width: '7px',
      },
      '::-webkit-scrollbar-thumb': {
        background: `${theme === 'dark' ? 'rgba(255, 255, 255, .25)' : 'rgba(0, 0, 0, .25)'}`,
        borderRadius: '7px',
        boxShadow: `${
          theme === 'dark' ? '0 0 1px rgba(0, 0, 0, .5)' : '"0 0 1px rgba(255, 255, 255, .5)"'
        }`,
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '6px',
    }),
    valueContainer: (base) => ({
      ...base,
      minWidth: '10rem',
      padding: '0px 8px',
    }),
    singleValue: (base) => ({
      ...base,
      fontWeight: '400',
      fontSize: '1rem',
      color: `${
        theme === 'dark' ? colors.DarkApplicationPrimaryText : colors.LightApplicationPrimaryText
      }`,
    }),
  });

  const options = useMemo(() => {
    if (hideAllRoles)
      return [
        ...members.map((m) => ({
          value: m.type,
          label: m.type,
        })),
      ];
    return [
      { value: 'all', label: 'All roles' },
      ...members.map((m) => ({
        value: m.type,
        label: m.type,
      })),
    ];
  }, [members]);

  const handleOnChange = useCallback(
    (selection) => {
      if (isMulti) {
        onChange(selection ? selection.map((option) => option.value) : []);
      } else {
        onChange(selection ? selection.value : undefined);
      }
    },
    [onChange, isMulti]
  );

  const localValue = useMemo(() => {
    if (isMulti) {
      return options.filter((option) => (value || []).find((id) => option.value === id));
    }
    return options.find((option) => option.value === value);
  }, [value, options]);

  if (!options) {
    return <Loader />;
  }

  return (
    <SelectComponent
      label={popout && label}
      icon={popout && icon}
      isMulti={isMulti}
      onChange={handleOnChange}
      value={localValue}
      options={options}
      closeMenuOnSelect={!isMulti}
      placeholder={placeholder || 'Search...'}
      menuXPlacement={menuXPlacement}
      menuYPlacement={popout ? 'bottom' : menuYPlacement}
      styles={(menuMaxHeight || !fullHeight) && overrideStyles()}
      {...props}
    />
  );
}
