import React from 'react';
import { Spinner } from '@iq/react-components';

const Loader = ({ text = '', overlay = false, height, children }) => (
  <div
    className={`${overlay ? 'overlay' : 'inline'} loader-component`}
    style={{ minHeight: typeof height !== 'undefined' ? height : 'none' }}
  >
    <div className="loader">
      <Spinner />
    </div>
    <div className="loader-text">{children || text}</div>
  </div>
);

export default React.memo(Loader);
