import { useHistory } from 'react-router-dom';
import siteTypeFactory from './SiteTypeFactory';

const SiteType = ({ sitesReady, realm, noSiteView }) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const createSiteType = urlParams.get('createSite') || 'default';

  return siteTypeFactory({
    realm,
    sitesReady,
    urlParams,
    type: createSiteType.toLowerCase(),
    noSiteView,
  });
};

export default SiteType;
