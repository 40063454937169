.hero-section-component {
  min-height: 15rem;
  height: 45vh;
}
.hero-section-component.hidden {
  display: none;
}
.hero-section-component.full-height {
  height: calc(100vh - 80px);
}
.hero-section-component.align-height .section-content .column-component:last-child {
  height: 100%;
}
.hero-section-component.align-height .section-content .column-component:last-child .column-content {
  height: 100%;
}
.hero-section-component.align-height .section-content .column-component:last-child .column-content .panel-component.components-component-type .panel-header {
  margin: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvSGVyb1NlY3Rpb24iLCJzb3VyY2VzIjpbIkhlcm9TZWN0aW9uLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTs7QUFLRTtFQUNFOztBQUVBO0VBQ0U7O0FBRUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5oZXJvLXNlY3Rpb24tY29tcG9uZW50IHtcbiAgbWluLWhlaWdodDogMTVyZW07XG4gIGhlaWdodDogNDV2aDtcblxuICAmLmhpZGRlbiB7XG4gICAgZGlzcGxheTogbm9uZTtcbiAgfVxuXG4gICYuZnVsbC1oZWlnaHQge1xuICAgIGhlaWdodDogY2FsYygxMDB2aCAtIDgwcHgpO1xuICB9XG5cbiAgJi5hbGlnbi1oZWlnaHQge1xuICAgIC5zZWN0aW9uLWNvbnRlbnQge1xuICAgICAgLmNvbHVtbi1jb21wb25lbnQ6bGFzdC1jaGlsZCB7XG4gICAgICAgIGhlaWdodDogMTAwJTtcblxuICAgICAgICAuY29sdW1uLWNvbnRlbnQge1xuICAgICAgICAgIGhlaWdodDogMTAwJTtcblxuICAgICAgICAgIC5wYW5lbC1jb21wb25lbnQuY29tcG9uZW50cy1jb21wb25lbnQtdHlwZSAucGFuZWwtaGVhZGVyIHtcbiAgICAgICAgICAgIG1hcmdpbjogMDtcbiAgICAgICAgICB9XG4gICAgICAgIH1cbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */