import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getVisualizations } from '../../../bundles/visualizations';
import { getVisualizationsPanelAdminSchema } from '../../panels/VisualizationsPanel';
import JSONEditor from '../../JSONEditor';

const VisualizationsSettings = ({ settings, onSave, onFullSettings, onClose }) => {
  const visualizations = useSelector(getVisualizations);

  const schema = useMemo(
    () => getVisualizationsPanelAdminSchema(visualizations),
    [JSON.stringify(visualizations)]
  );

  return (
    <div className="column-content">
      <JSONEditor
        title="Visualizations: Options"
        schema={schema}
        formData={settings}
        onFormSubmit={onSave}
        onSecondaryAction={onFullSettings}
        cancelCallback={onClose}
        saveButtonText={'Save'}
        initialEditMode
        showEditButton={false}
      />
    </div>
  );
};

export default VisualizationsSettings;
