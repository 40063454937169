import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, Textarea, Label, Can } from '@iq/react-components';
import { NotFoundError } from '@iq/services';

import { parseISO, format } from 'date-fns';
import { useDropzone } from 'react-dropzone';

import services from '../../../services';
import { requestUser, getUser } from '../../../bundles/ad';
import { getHasPermission } from '../../../bundles/auth';
import { checkIsOnline, displayNotification } from '../../../bundles/notifications';
import getNotification from '../../../bundles/notification-defaults';
import { getSharedCacheKey, useIndicatedCallback, useSingleAsync, useAsync } from '../../../utils';
import FilePreviewModal from '../../FilePreviewModal';
import { UserBadge } from '../../UserBadge';

const RelationLinks = memo(({ relations, parentFiles = [] }) => {
  const dispatch = useDispatch();
  const { response: files } = useAsync(
    async (cache) =>
      Promise.all(
        relations
          .filter((rel) => rel.entityType === 'file')
          .map((rel) =>
            cache(
              () =>
                services.file.getFile(rel.entityId).then(
                  (response) => response,
                  (e) => {
                    if (!(e instanceof NotFoundError)) {
                      console.error('Unable to fetch comment file: ', e);
                      dispatch(checkIsOnline());
                      dispatch(displayNotification(getNotification('getEventFile', 'error')()));
                    }
                    return { error: e };
                  }
                ),
              getSharedCacheKey('file/getFile', rel.entityId)
            )
          )
      ),
    [relations]
  );
  const foundFiles = useMemo(() => (files || []).filter((f) => !f.error), [files]);
  const [showPreview, setShowPreview] = useState(false);
  if (!foundFiles) {
    return null;
  }
  return (
    <div>
      {foundFiles.map((file) => (
        <a
          key={file.id}
          className="event-file-link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowPreview(file);
          }}
        >
          {file.filename}
        </a>
      ))}
      {showPreview && (
        <FilePreviewModal
          fileId={showPreview.id}
          files={[showPreview, ...parentFiles, ...foundFiles]}
          onCloseModal={() => {
            setShowPreview(false);
          }}
          onPreview={setShowPreview}
        />
      )}
    </div>
  );
});

const UserAvatar = memo(({ userId }) => {
  const {
    response: user,
    loading,
    error,
  } = useSingleAsync(
    () => services.auth.getOneUserLimited(userId),
    'auth/getOneUserLimited',
    userId
  );
  if (error) {
    console.error(`Error retrieving user: ${error}`);
    return (
      <UserBadge
        first="Anonymous"
        last="User"
      />
    );
  }
  if (loading || !user) {
    return null;
  }
  return (
    <UserBadge
      first={user.firstName}
      last={user.lastName}
    />
  );
});

const UserSignature = memo(({ userId }) => {
  const {
    response: user,
    loading,
    error,
  } = useSingleAsync(
    () => services.auth.getOneUserLimited(userId),
    'auth/getOneUserLimited',
    userId
  );
  if (error) {
    console.error(`Error retrieving user: ${error}`);
    return <div>Anonymous User</div>;
  }
  if (loading || !user) {
    return <div>Loading...</div>;
  }
  return <div>{user.name}</div>;
});

const CommentForm = ({
  site,
  parentId,
  parentFiles,
  parentComponents,
  isUnsavedSeriesEvent,
  saveEvent,
}) => {
  const dispatch = useDispatch();

  const [body, setBody] = useState();
  const [files, setFiles] = useState([]);

  const me = useSelector((state) => getUser(state, 'me'));
  const canDeleteComments = useSelector((state) =>
    getHasPermission(state, 'comments/Delete', { org: site.org, site: site.id })
  );

  useEffect(() => {
    dispatch(requestUser('me'));
  }, []);

  const { response, error, refresh } = useSingleAsync(
    () =>
      services.collaboration.getComments({ parentId }).then(
        (res) => res,
        (e) => {
          console.error('Unable to fetch comments: ', e);
          dispatch(checkIsOnline());
          dispatch(displayNotification(getNotification('getComments', 'error')()));
          return { values: [] };
        }
      ),
    'collaboration/getComments',
    parentId
  );

  const comments = useMemo(() => {
    if (!response) {
      return [];
    }
    const { values = [] } = response;
    return [...values].reverse();
  }, [response]);

  async function createFile(file) {
    const formData = new FormData();
    Object.keys(file).forEach((key) => {
      if (Array.isArray(file[key])) {
        file[key].forEach((item) => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, file[key]);
      }
    });
    return services.file.createFile(formData).then(
      (res) => res,
      (e) => {
        console.error('Unable to add comment file: ', e);
        dispatch(checkIsOnline());
        dispatch(displayNotification(getNotification('addEventFile', 'error')()));
      }
    );
  }
  const [handleSave, isSaving] = useIndicatedCallback(async () => {
    if (!body && files.length < 1) {
      return;
    }
    try {
      let readyToSave = true;
      if (isUnsavedSeriesEvent && saveEvent) {
        readyToSave = await saveEvent();
      }
      if (readyToSave) {
        const { id } = await services.collaboration.createComment({
          org: site.org,
          site: site.id,
          parentId,
          body,
        });
        const savedFiles = await Promise.all(
          files
            .map((file) => ({
              file,
              site: site.id,
              org: site.org,
              components: parentComponents,
              context: JSON.stringify([{ type: 'comment', identifier: id }]),
            }))
            .map(createFile)
            .filter((file) => !!file)
        );
        await services.collaboration.updateComment(id, {
          org: site.org,
          site: site.id,
          parentId,
          relations: savedFiles.map((file) => ({
            entityType: 'file',
            entityId: file.id,
          })),
        });
        await refresh();
        setBody('');
        setFiles([]);
      }
    } catch (e) {
      console.error('Unable to save comment: ', e);
      dispatch(checkIsOnline());
      dispatch(displayNotification(getNotification('saveComment', 'error')()));
    }
  }, [body, parentId, site, refresh, files]);

  const [handleDelete, isDeleting] = useIndicatedCallback(
    async (comment) => {
      try {
        await services.collaboration.deleteComment(comment.id);
        await refresh();
      } catch (e) {
        console.error('Unable to delete comment: ', e);
        dispatch(checkIsOnline());
        dispatch(displayNotification(getNotification('deleteComment', 'error')()));
      }
    },
    [refresh]
  );

  const deleteFile = useCallback(
    (file) => {
      setFiles((oldFiles) => oldFiles.filter((item) => item !== file));
    },
    [setFiles]
  );

  const onDrop = useCallback(
    (newFiles) => {
      setFiles((oldFiles) => [...oldFiles, ...newFiles]);
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled: isSaving,
    onDrop,
    noClick: true,
  });

  if (error) {
    console.error(error);
    return null;
  }

  return (
    <div className="comment-form">
      <Can
        permission="comments/Write"
        scope={{ org: site.org, site: site.id }}
      >
        <div className="comment-form__actions">
          <div
            className={`comment-form__input${isDragActive ? ' drop-zone-active' : ''}`}
            {...getRootProps()}
          >
            <Textarea
              disabled={isSaving}
              onChange={(e) => setBody(e.target.value)}
              value={body}
              rows={4}
              placeholder="Add new comment"
            />
            {files.length > 0 && (
              <>
                <Label>Attachments</Label>
                {files.map((file, idx) => (
                  <div
                    key={idx}
                    className="comment-form__attachment"
                  >
                    <Button
                      slim
                      activity="secondary"
                      icon={
                        <Icon
                          icon="he-delete"
                          onClick={() => deleteFile(file)}
                        />
                      }
                    />
                    <span>{file.name}</span>
                  </div>
                ))}
              </>
            )}
          </div>
          <input {...getInputProps()} />
          <div className="comment-form__buttons">
            <Button
              disabled={isSaving}
              className="comment-form-attach"
              icon={<Icon icon="he-attachment" />}
              onClick={open}
              activity="secondary"
              slim
            />
            <Button
              disabled={!body || isSaving}
              className="comment-form__submit"
              type="submit"
              onClick={handleSave}
              slim
            >
              Send
            </Button>
          </div>
        </div>
      </Can>
      <div className="comment-form__comments">
        {comments.map((comment) => (
          <div
            key={comment.id}
            className="comment-form__comment"
          >
            <div className="comment-form__badge">
              <UserAvatar userId={comment.createdBy} />
            </div>
            <div className="comment-form__content">
              <div className="comment-form__byline">
                <div className="comment-form__created-by">
                  <UserSignature userId={comment.createdBy} />
                </div>
                <div className="comment-form__created-at">
                  {format(parseISO(comment.createdAt), 'd MMM')}
                </div>
              </div>
              <div className="comment-form__body">
                {comment.body}
                {(canDeleteComments || comment.createdBy === (me && me.id)) && (
                  <Button
                    disabled={isDeleting}
                    className="comment-form__comment-delete"
                    slim
                    activity="secondary"
                    design="text"
                    onClick={() => handleDelete(comment)}
                    icon={<Icon icon="he-delete" />}
                  />
                )}
              </div>
              {comment.relations.length > 0 && (
                <div className="comment-form__attachments">
                  <RelationLinks
                    relations={comment.relations}
                    parentFiles={parentFiles}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentForm;
