import React, { useState, memo } from 'react';

import JSONEditor from '../../../../JSONEditor';
import SimpleModal from '../../../../SimpleModal';

const CreateSourceModal = memo((props) => {
  const { schemas, onSubmit, onCloseModal, transformErrors, customFormats, customValidate } = props;

  const [formData, setFormData] = useState({ type: 'default' });

  const onChange = ({ formData: updatedFormData }) => {
    setFormData(updatedFormData);
  };

  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Add source"
    >
      <div className="file-upload-modal">
        <div className="file-upload-content">
          <JSONEditor
            title="Source"
            formData={formData}
            schema={schemas[formData.type]}
            onFormChange={onChange}
            onFormSubmit={({ formData: form }) => onSubmit(form)}
            saveButtonText={'Create source'}
            initialEditMode={true}
            showEditButton={false}
            cancelCallback={onCloseModal}
            customTransformErrors={(errors) => transformErrors(errors)}
            customFormats={customFormats}
            customValidate={customValidate}
          />
        </div>
      </div>
    </SimpleModal>
  );
});

export default CreateSourceModal;
