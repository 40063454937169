export const schema = {
  type: 'object',
  properties: {
    label: {
      type: 'string',
      title: 'Label',
    },
    defaultEmptyValue: {
      type: 'string',
      title: 'Default text (when no value)',
    },
  },
};

export const uiSchema = {};
