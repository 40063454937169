const getInvitationSchema = (
  members,
  tenantUsers,
  createdEmails,
  handleCreatable,
  validateCreatable
) => ({
  type: 'object',
  title: 'Users',
  required: ['email'],
  properties: {
    email: {
      label: 'Email*',
      default: [],
      type: 'array',
      uniqueItems: true,
      isMulti: true,
      placeholder: 'Search or add',
      handleCreatable: (value) => handleCreatable(value),
      validateCreatable: (value) => validateCreatable(value),
      items: {
        type: 'string',
        oneOf: [
          ...createdEmails.map((email) => ({
            const: email,
            title: email,
          })),
          ...tenantUsers.map((user) => ({
            const: user.email,
            title: user.email,
          })),
        ],
      },
    },
    role: {
      title: 'Role*',
      type: 'string',
      placeholder: 'Select role',
      default: 'Viewer',
      enum: members.map((member) => member.type),
    },
  },
});

const getUiSchema = (creatable = false) => {
  if (creatable) {
    return {
      email: {
        'ui:field': 'creatableField',
      },
    };
  }
  return {
    email: {
      'ui:widget': 'hidden',
    },
  };
};

export { getInvitationSchema, getUiSchema };
