import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFilesSession, requestFiles, getFiles } from '../../../bundles/files';
import { requestTypes, getTypes } from '../../../bundles/types';
import { requestTags, getTags } from '../../../bundles/tags';
import { getFilesPanelAdminSchema } from '../../panels/FilesPanel';
import JSONEditor from '../../JSONEditor';

const FilesSettings = ({
  settings,
  site: { id: siteId, org },
  onSave,
  onFullSettings,
  onClose,
}) => {
  const dispatch = useDispatch();
  const sessionId = useFilesSession();
  const { files } = useSelector((state) => getFiles(state, sessionId));
  const types = useSelector(getTypes);
  const tags = useSelector(getTags);

  useEffect(() => {
    dispatch(
      requestFiles(sessionId, {
        org,
        site: siteId,
        sortBy: 'filename',
        order: 'asc',
      })
    );
    dispatch(requestTypes(siteId));
    dispatch(requestTags(siteId));
  }, []);

  const schema = useMemo(
    () => getFilesPanelAdminSchema(files || [], types || [], tags || []),
    [files && JSON.stringify(files.map((f) => f.id)), JSON.stringify(types), JSON.stringify(tags)]
  );

  return (
    <div className="column-content">
      <JSONEditor
        title="Files: Options"
        schema={schema}
        formData={settings}
        onFormSubmit={onSave}
        onSecondaryAction={onFullSettings}
        cancelCallback={onClose}
        saveButtonText={'Save'}
        initialEditMode
        showEditButton={false}
      />
    </div>
  );
};

export default FilesSettings;
