import React from 'react';

import Loader from '../../../../Loader';
import FileList from '../FileList';
import FileTable from '../FileTable';
import FileGrid from '../FileGrid';

const RenderFiles = ({
  activeComponentId,
  canDelete,
  canDownload,
  canEdit,
  disableSelect,
  fileViewType,
  files,
  filterValue,
  format,
  isFullScreen,
  isShiftPressed,
  onDelete,
  onDownload,
  onEdit,
  onPreview,
  onSelectFile,
  selectedFiles,
  showFileExtension,
  tagFilter,
  setSortBy,
  sortBy,
  setOrder,
  order,
}) => {
  const getDropdownOptions = (file) => {
    const options = [];

    if (canDownload) {
      options.push({
        label: 'Download file',
        value: 'download',
        onSelect: () => onDownload(file.resourceUrl),
      });
    }

    if (canEdit) {
      options.push({
        label: 'Edit file',
        value: 'edit',
        onSelect: () => onEdit(file.id),
      });
    }

    if (canDelete) {
      options.push({
        label: 'Delete file',
        value: 'delete',
        onSelect: () => onDelete(file.id),
      });
    }

    return options;
  };

  if (files === null) {
    return <Loader text="Loading files ..." />;
  }

  if (files.length === 0) {
    return <div className="no-files">No files to display</div>;
  }

  if ((format !== 'narrow' || isFullScreen) && fileViewType === 'table') {
    return (
      <FileTable
        files={files}
        tagFilter={tagFilter}
        activeComponentId={activeComponentId}
        filterValue={filterValue}
        showFileExtension={showFileExtension}
        getDropdownOptions={getDropdownOptions}
        onPreview={onPreview}
        onSelectFile={onSelectFile}
        isShiftPressed={isShiftPressed}
        disableSelect={disableSelect}
        setSortBy={setSortBy}
        sortBy={sortBy}
        setOrder={setOrder}
        order={order}
      />
    );
  }

  if (fileViewType === 'grid') {
    return (
      <FileGrid
        files={files}
        selectedFiles={selectedFiles}
        getDropdownOptions={getDropdownOptions}
        format={format}
        isFullScreen={isFullScreen}
        onPreview={onPreview}
        onSelectFile={onSelectFile}
        isShiftPressed={isShiftPressed}
        disableSelect={disableSelect}
      />
    );
  }

  return (
    <FileList
      files={files}
      onPreview={onPreview}
      filterValue={filterValue}
      tagFilter={tagFilter}
      activeComponentId={activeComponentId}
      setSortBy={setSortBy}
      setOrder={setOrder}
      order={order}
      onSelectFile={onSelectFile}
      disableSelect={disableSelect}
      getDropdownOptions={getDropdownOptions}
    />
  );
};

export default RenderFiles;
