import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Select } from '@iq/react-components';
import { setCuttingLines, setElemsInRow, setScanForPage } from '../../../../../bundles/qrCodes';
import SimpleModal from '../../../../SimpleModal';
import { getLayout } from '../../../../../bundles/layouts';

const elemsInRow = [
  { label: 'Extra Small (6 in line)', value: 6 },
  { label: 'Small (4 in line)', value: 4, default: true },
  { label: 'Medium (2 in line)', value: 2 },
  { label: 'Large (1 in line)', value: 1 },
];

const cuttingOptions = [
  { label: 'Shown', value: true, default: true },
  { label: 'Hidden', value: false },
];

const findDefault = (options) => options.find((option) => option?.default === true);

const QrModal = ({ siteId, onCloseModal }) => {
  const dispatch = useDispatch();
  const [pageOptions, setPageOptions] = useState([]);
  const layout = useSelector((state) => getLayout(state, siteId));

  useEffect(() => {
    const options = layout.pages.map((page, i) => ({
      label: page.title,
      value: page.pageIndex,
      pagePath: page.path,
      default: i === 0,
    }));
    setPageOptions(options);
    dispatch(setScanForPage(findDefault(options).value, findDefault(options).pagePath));
  }, [layout]);

  useEffect(() => {
    dispatch(setElemsInRow(findDefault(elemsInRow).value));
    dispatch(setCuttingLines(findDefault(cuttingOptions).value));
  }, []);

  return (
    <SimpleModal
      className="generate-qr-code"
      title="Generate QR Codes"
      defautOnClose={onCloseModal}
    >
      <div className="body">
        {pageOptions.length > 0 && (
          <div className="select-row">
            <h3>Scan for page*</h3>
            <Select
              options={pageOptions}
              defaultValue={findDefault(pageOptions)}
              onChange={(e) => dispatch(setScanForPage(e.value, e.pagePath))}
            />
          </div>
        )}
        <div className="select-row">
          <h3>QR code size*</h3>
          <Select
            options={elemsInRow}
            defaultValue={findDefault(elemsInRow)}
            onChange={(e) => dispatch(setElemsInRow(e.value))}
          />
        </div>
        <div className="select-row">
          <h3>Cutting lines*</h3>
          <Select
            options={cuttingOptions}
            defaultValue={findDefault(cuttingOptions)}
            onChange={(e) => dispatch(setCuttingLines(e.value))}
          />
        </div>
      </div>
      <div className="actions">
        <Button
          activity="secondary"
          onClick={onCloseModal}
        >
          Cancel
        </Button>
        {pageOptions.length > 0 && (
          <Button className="redirect-button">
            <Link to={`/sites/${siteId}/admin/components/qr`}>Generate</Link>
          </Button>
        )}
      </div>
    </SimpleModal>
  );
};

export default QrModal;
