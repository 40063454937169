import axios from 'axios';
import JsZip from 'jszip';

const maxFileSize = 100 * 1024 * 1024; // 100MB
const maxNestedArchives = 10;

export default async function loadZipFile(url) {
  const response = await axios.get(url, { responseType: 'blob' });

  const contentType = response.headers['content-type'];
  if (contentType !== 'application/zip') {
    throw new Error('Invalid zip file format');
  }

  const contentLength = response.headers['content-length'];
  if (contentLength > maxFileSize) {
    throw new Error('File size exceeds the limit');
  }

  const archive = await JsZip.loadAsync(response.data);

  let nestedArchiveCount = 0;
  archive.forEach((relativePath, file) => {
    if (file.dir) {
      nestedArchiveCount += 1;
      if (nestedArchiveCount > maxNestedArchives) {
        throw new Error('Too many nested archives');
      }
    }
  });

  return archive;
}
