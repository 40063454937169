const getFilters = (files, types, tags, withExcludes = true) => {
  const filters = {};

  if (types && types.length) {
    filters.includeTypes = {
      title: '',
      type: 'object',
      properties: {
        active: {
          type: 'boolean',
          title: 'Restrict by file type (will disable filter).',
          default: false,
        },
      },
      dependencies: {
        active: {
          oneOf: [
            {
              properties: {
                active: { enum: [false] },
              },
            },
            {
              properties: {
                active: { enum: [true] },
                types: {
                  type: 'array',
                  title: 'File types',
                  default: [],
                  uniqueItems: true,
                  isMulti: true,
                  items: {
                    type: 'string',
                    anyOf: types.map((type) => ({
                      const: type,
                      title: type,
                    })),
                  },
                },
              },
            },
          ],
        },
      },
    };
  }

  if (types && types.length && withExcludes) {
    filters.excludeTypes = {
      title: '',
      type: 'object',
      properties: {
        active: {
          type: 'boolean',
          title: 'Specify file types to exclude (unchecked for none, overridden by includes).',
          default: false,
        },
      },
      dependencies: {
        active: {
          oneOf: [
            {
              properties: {
                active: { enum: [false] },
              },
            },
            {
              properties: {
                active: { enum: [true] },
                types: {
                  type: 'array',
                  title: 'File types',
                  default: [],
                  uniqueItems: true,
                  isMulti: true,
                  items: {
                    type: 'string',
                    anyOf: types.map((type) => ({
                      const: type,
                      title: type,
                    })),
                  },
                },
              },
            },
          ],
        },
      },
    };
  }

  if (tags && tags.length) {
    filters.includeTags = {
      title: '',
      type: 'object',
      properties: {
        active: {
          type: 'boolean',
          title: 'Specify tags to include (unchecked for all).',
          default: false,
        },
      },
      dependencies: {
        active: {
          oneOf: [
            {
              properties: {
                active: { enum: [false] },
              },
            },
            {
              properties: {
                active: { enum: [true] },
                operator: {
                  type: 'string',
                  title: 'Filter type',
                  default: 'or',
                  oneOf: [
                    {
                      const: 'and',
                      title: 'All (files must include all of the selected tags)',
                    },
                    {
                      const: 'or',
                      title:
                        'Any (files must include any of the selected tags, will disable filter)',
                    },
                  ],
                },
                tags: {
                  type: 'array',
                  title: 'Select tags for this filter.',
                  default: [],
                  uniqueItems: true,
                  isMulti: true,
                  items: {
                    type: 'string',
                    anyOf: tags.map((tag) => ({
                      const: tag.id,
                      title: tag.name,
                    })),
                  },
                },
              },
            },
          ],
        },
      },
    };
  }

  if (tags && tags.length && withExcludes) {
    filters.excludeTags = {
      title: '',
      type: 'object',
      properties: {
        active: {
          type: 'boolean',
          title: 'Specify tags to exclude (unchecked for none, overridden by includes).',
          default: false,
        },
      },
      dependencies: {
        active: {
          oneOf: [
            {
              properties: {
                active: { enum: [false] },
              },
            },
            {
              properties: {
                active: { enum: [true] },
                operator: {
                  type: 'string',
                  title: 'Filter type',
                  default: 'or',
                  oneOf: [
                    {
                      const: 'and',
                      title: 'All (files must not include all of the selected tags)',
                    },
                    {
                      const: 'or',
                      title: 'Any (files must not include any of the selected tags)',
                    },
                  ],
                },
                tags: {
                  isMulti: true,
                  type: 'array',
                  title: 'Select tags for this filter.',
                  uniqueItems: true,
                  default: [],
                  items: {
                    type: 'string',
                    anyOf: tags.map((tag) => ({
                      const: tag.id,
                      title: tag.name,
                    })),
                  },
                },
              },
            },
          ],
        },
      },
    };
  }

  return filters;
};

const getFilesPanelSchema = (files, types, tags) => ({
  title: {
    type: 'string',
    default: 'Files',
  },
  options: {
    type: 'object',
    properties: {
      layouts: {
        title: 'Layouts',
        type: 'string',
        default: 'Grid & list',
        enum: ['Grid & list', 'List & grid', 'Only grid', 'Only list'],
      },
      hideToolbar: {
        title: 'Hide toolbar',
        type: 'boolean',
        default: false,
      },
      fileLimit: {
        title: 'Limit files per page',
        type: 'number',
        default: 20,
      },
      hideSearch: {
        title: 'Hide search input',
        type: 'boolean',
        default: false,
      },
      hideFilters: {
        title: 'Hide tag & type filters',
        type: 'boolean',
        default: false,
      },
      hideUploadButton: {
        title: 'Hide upload button ()',
        type: 'boolean',
        default: false,
      },
      disableSelect: {
        title: 'Disable selecting of files',
        type: 'boolean',
        default: false,
      },
      showFileExtension: {
        title: 'Show file extensions',
        type: 'boolean',
        default: true,
      },
      filters: {
        title: 'Filters',
        type: 'object',
        properties: getFilters(files, types, tags),
      },
    },
  },
});

const getFilesPanelAdminSchema = (files = [], types = [], tags = []) => ({
  type: 'object',
  properties: {
    filters: {
      title: 'Filters',
      description: !(types.length && tags.length) ? 'None available' : '',
      type: 'object',
      properties: getFilters(files, types, tags, false),
    },
  },
});

export { getFilesPanelSchema, getFilesPanelAdminSchema };
