/* eslint-disable default-param-last */
import { put, takeLatest } from 'redux-saga/effects';

import {
  createStateSet as createStateSetApi,
  getStateSets as getStateSetsApi,
  updateStateSet as updateStateSetApi,
  deleteStateSet as deleteStateSetApi,
} from '../services';
import { displayNotification } from './notifications';
import { CLEAR_SITE_DATA } from './application';
import getNotification from './notification-defaults';

const SAVE_STATE_SET = 'dt/states/SAVE_STATE_SET';
const STATE_SET_SAVED = 'dt/states/STATE_SET_SAVED';
const REQUEST_STATE_SETS = 'dt/states/REQUEST_STATE_SETS';
const RECEIVED_STATE_SETS = 'dt/states/RECEIVED_STATE_SETS';
const UPDATE_STATE_SETS = 'dt/states/UPDATE_STATE_SETS';
const DELETE_STATE_SET = 'dt/states/DELETE_STATE_SET';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const saveStateSet = (data, site, org) => ({
  type: SAVE_STATE_SET,
  data,
  site,
  org,
});

export const stateSetSaved = () => ({
  type: STATE_SET_SAVED,
});

export const requestStateSets = (siteId) => ({
  type: REQUEST_STATE_SETS,
  siteId,
});

export const receivedStateSets = (stateSets, siteId) => ({
  type: RECEIVED_STATE_SETS,
  stateSets,
  siteId,
});

export const updateStateSet = (stateSet) => ({
  type: UPDATE_STATE_SETS,
  stateSet,
});

export const deleteStateSet = (stateSetId, siteId) => ({
  type: DELETE_STATE_SET,
  stateSetId,
  siteId,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initalState = {
  loadingSaveStateSet: false,
  loadingStates: false,
  stateSets: [],
  siteLoaded: undefined,
};

export const reducer = (state = initalState, action) => {
  switch (action.type) {
    case SAVE_STATE_SET: {
      return { ...state, loadingSaveStateSet: true };
    }
    case STATE_SET_SAVED: {
      return { ...state, loadingSaveStateSet: false };
    }
    case REQUEST_STATE_SETS:
    case DELETE_STATE_SET: {
      return { ...state, loadingStates: true };
    }
    case RECEIVED_STATE_SETS: {
      return {
        ...state,
        loadingStates: false,
        stateSets: action.stateSets,
        siteLoaded: action.siteId || state.siteLoaded,
      };
    }
    case UPDATE_STATE_SETS: {
      return {
        ...state,
        loadingSaveStateSet: true,
        stateSet: action.stateSet,
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        loadingSaveStateSet: false,
        loadingStates: false,
        stateSets: [],
        siteLoaded: undefined,
      };
    }
    default:
      return state;
  }
};

/** ********************************************
 *                                             *
 *                   Helpers                   *
 *                                             *
 ******************************************** */

export const getActiveStates = (statefulVarsWithValues) =>
  statefulVarsWithValues.reduce((acc, variable) => {
    const { value, stateset, quality = 1 } = variable;

    const states = [];
    let isActive = false;

    stateset?.states.forEach((state) => {
      const { trigger } = state;
      switch (trigger.condition) {
        case '=':
          isActive = value === trigger.triggerValue;
          break;
        case '<>':
          isActive = value > trigger.triggerValue1 && value < trigger.triggerValue2;
          break;
        case '>':
          isActive = value > trigger.triggerValue;
          break;
        case '<':
          isActive = value < trigger.triggerValue;
          break;
        case '<=':
          isActive = value <= trigger.triggerValue;
          break;
        case '>=':
          isActive = value >= trigger.triggerValue;
          break;
        default:
          break;
      }

      if (isActive) {
        states.push({ ...state, quality });
      }
    });
    return [...acc, ...states];
  }, []);

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getStateSets = (state) => state.statesets.stateSets;

export const getStateSetsLoaded = (state) => state.statesets.siteLoaded;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doSaveStateSet(action) {
  const { data, site, org } = action;
  try {
    yield createStateSetApi({ ...data, site, org });
    yield put(stateSetSaved());
    yield put(requestStateSets(site));
    yield put(displayNotification(getNotification('saveStateSet', 'success')()));
  } catch (e) {
    console.error(e);
    yield put(displayNotification(getNotification('saveStateSet', 'error')()));
  }
}

function* doUpdateStateSet(action) {
  const {
    stateSet: { id: stateSetId, ...data },
  } = action;
  try {
    yield updateStateSetApi(stateSetId, data);
    yield put(requestStateSets(data.site));
    yield put(displayNotification(getNotification('updateStateSet', 'success')(stateSetId)));
  } catch (e) {
    console.error(e);
    yield put(displayNotification(getNotification('updateStateSet', 'error')(stateSetId)));
  }
}

function* doRequestStateSets(action) {
  const { siteId } = action;
  try {
    const { values: statesSets } = yield getStateSetsApi({ site: siteId });
    yield put(receivedStateSets(statesSets, siteId));
  } catch (e) {
    console.error(e);
    yield put(displayNotification(getNotification('getStateSets', 'error')()));
  }
}

function* doDeleteStateSet(action) {
  const { stateSetId, siteId } = action;
  try {
    yield deleteStateSetApi(stateSetId);
    yield doRequestStateSets({ siteId });
    yield put(displayNotification(getNotification('deleteStateSet', 'success')(stateSetId)));
  } catch (e) {
    console.error(e);
    yield put(displayNotification(getNotification('deleteStateSet', 'error')(stateSetId)));
  }
}

export const sagas = [
  takeLatest(SAVE_STATE_SET, doSaveStateSet),
  takeLatest(REQUEST_STATE_SETS, doRequestStateSets),
  takeLatest(UPDATE_STATE_SETS, doUpdateStateSet),
  takeLatest(DELETE_STATE_SET, doDeleteStateSet),
];
