import React, { useMemo, useState } from 'react';
import { Input } from '@iq/react-components';

const TextWidget = (props) => {
  const {
    value,
    schema: {
      default: defaultValue,
      disabled,
      readonly,
      required: customRequired,
      placeholder,
      type,
      inputType,
      min,
      max,
      editable = true,
    },
    required,
    onChange,
  } = props;
  const BASE_TYPES = { string: 'text', number: 'number', color: 'color' };
  const input = inputType || BASE_TYPES[type] || 'text';
  const [dirty, setDirty] = useState(false);

  const theValue = useMemo(() => {
    const defaultVal = !defaultValue && defaultValue !== 0 ? '' : defaultValue;
    const useDefault = !value && value !== 0 && !dirty;
    const preCheckedValue = useDefault ? defaultVal : value;
    return preCheckedValue || preCheckedValue === 0 ? preCheckedValue : '';
  }, [value, dirty]);

  const handleOnChange = (event) => {
    setDirty(true);
    onChange(event.target.value.trim().length === 0 ? undefined : event.target.value);
  };

  return (
    <>
      {editable ? (
        <Input
          key={`unique_text_key`}
          required={required || customRequired}
          disabled={disabled}
          readOnly={readonly}
          placeholder={placeholder}
          type={input}
          step={type === 'number' ? 'any' : undefined}
          value={theValue}
          onChange={handleOnChange}
          min={min}
          max={max}
        />
      ) : (
        <div className="view-widget">{`${theValue}`}</div>
      )}
    </>
  );
};

export default TextWidget;
