import React, { useState } from 'react';
import { parseISO, format } from 'date-fns';

import { Icon } from '@iq/react-components';
import TypeLabel from '../../../../TypeLabel';
import StyledItem from '../../../../StyledItem';

const LayoutItem = ({ layout, children }) => {
  const [open, setOpen] = useState(true);

  return (
    <StyledItem
      itemClass="layout-item"
      active={open}
      headerLabel={<TypeLabel type="layout" />}
      headerContent={
        layout.updatedAt ? (
          <div className="updated">
            Last updated: {format(parseISO(layout.updatedAt), 'yyyy-MM-dd HH:mm')}
          </div>
        ) : null
      }
      headerActions={<Icon icon="keyboard-arrow-right" />}
      onClick={() => setOpen(!open)}
    >
      {children}
    </StyledItem>
  );
};

export default LayoutItem;
