.page-list-item.styled-item .title {
  font-size: 1.1rem;
  font-weight: 500;
}
.page-list-item.styled-item .path {
  opacity: 0.7;
  margin-left: 1rem;
  font-family: monospace;
  font-size: 0.9rem;
}
.page-list-item.styled-item .move-item-component {
  margin: 0 1rem 0 auto;
}

.dark-theme .page-list-item.styled-item {
  background: rgba(255, 255, 255, 0.05);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9MYXlvdXRWaWV3L2NvbXBvbmVudHMvUGFnZUl0ZW0iLCJzb3VyY2VzIjpbIlBhZ2VJdGVtLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7O0FBS0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5wYWdlLWxpc3QtaXRlbS5zdHlsZWQtaXRlbSB7XG4gIC50aXRsZSB7XG4gICAgZm9udC1zaXplOiAxLjFyZW07XG4gICAgZm9udC13ZWlnaHQ6IDUwMDtcbiAgfVxuXG4gIC5wYXRoIHtcbiAgICBvcGFjaXR5OiAwLjc7XG4gICAgbWFyZ2luLWxlZnQ6IDFyZW07XG4gICAgZm9udC1mYW1pbHk6IG1vbm9zcGFjZTtcbiAgICBmb250LXNpemU6IDAuOXJlbTtcbiAgfVxuXG4gIC5tb3ZlLWl0ZW0tY29tcG9uZW50IHtcbiAgICBtYXJnaW46IDAgMXJlbSAwIGF1dG87XG4gIH1cbn1cblxuLmRhcmstdGhlbWUge1xuICAucGFnZS1saXN0LWl0ZW0uc3R5bGVkLWl0ZW0ge1xuICAgIGJhY2tncm91bmQ6IHJnYmEoI2ZmZiwgMC4wNSk7XG4gIH1cbn1cbiJdfQ== */