import React from 'react';
import { Icon } from '@iq/react-components';
import TypeLabel from '../../../../TypeLabel';
import MoveItem from '../MoveItem';
import StyledItem from '../../../../StyledItem';

const PageItem = ({ title, path, movePage, index, pageCount, selectPage }) => {
  const onSelectPage = () => {
    selectPage(index);
  };

  return (
    <StyledItem
      itemClass="page-list-item"
      headerLabel={<TypeLabel type="page" />}
      headerContent={
        <>
          <div className="title">{title}</div>
          <div className="path">{path}</div>
        </>
      }
      headerActions={
        <>
          <MoveItem
            move={movePage}
            index={index}
            count={pageCount}
          />
          <Icon
            icon="keyboard-arrow-right"
            size="m"
          />
        </>
      }
      onClick={onSelectPage}
    ></StyledItem>
  );
};

export default PageItem;
