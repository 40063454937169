import { Button } from '@iq/react-components';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { downloadSitesExcel } from '../../../../bundles/sites';
import SimpleModal from '../../../SimpleModal';

const SitesImport = ({ onCloseModal, onSubmit }) => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const dispatch = useDispatch();
  const onDrop = ([file]) => {
    setFileToUpload(file);
  };

  const { getInputProps, open } = useDropzone({
    noClick: true,
    onDrop,
  });

  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Excel import"
    >
      <div className="import-sites-modal">
        <p> 1. Add new sites in an excel </p>
        <div className="row">
          <Button onClick={() => dispatch(downloadSitesExcel())}>Download</Button>
          <p className="muted-text"> sites_config_template.xlsx </p>
        </div>
        <p> 2. Import new sites from the excel </p>
        <div className="row">
          <Button onClick={open}> Open </Button>
          <input {...getInputProps()} />
          {fileToUpload ? <p> {fileToUpload.name}</p> : <p>choose file ...</p>}
        </div>

        <div
          style={{ marginTop: 45, justifyContent: 'space-between', display: 'flex' }}
          className="row"
        >
          <Button
            activity="secondary"
            onClick={onCloseModal}
          >
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(fileToUpload)}
            disabled={!fileToUpload}
          >
            Apply
          </Button>
        </div>
      </div>
    </SimpleModal>
  );
};

export default SitesImport;
