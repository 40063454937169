import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@iq/react-components';
import { getPageTypeSchema } from '../../SiteAdminView/LayoutView/schemas/pageSchema';
import JSONEditor from '../../JSONEditor';
import SimpleModal from '../../SimpleModal';
import { getUpdatedFields } from '../../../utils';
import { getLayout, saveLayout } from '../../../bundles/layouts';
import { getPageType } from '../../../bundles/application';

const PageSettings = ({ site, pageIndex, onClose }) => {
  const dispatch = useDispatch();
  const [hasChanges, setHasChanges] = useState(false);
  const pageType = useSelector(getPageType);
  const [interpageType, setPageType] = useState(pageType);
  const layout = useSelector((state) => getLayout(state, site.id));

  const onPageTypeChange = useCallback(
    ({ formData: updatedPageType }) => {
      setPageType(updatedPageType.pageType);
      setHasChanges(true);
    },
    [setHasChanges, setPageType]
  );

  const pageTypeSchema = useMemo(() => getPageTypeSchema(), []);
  const formData = { pageType: interpageType };

  const getSettingsPath = () => {
    return ['pages', pageIndex, 'viewer'];
  };

  const onSave = useCallback(
    ({ saveFormData }) => {
      const path = getSettingsPath();
      const currentPageIndex = path[1];
      const update = getUpdatedFields(path, JSON.parse(JSON.stringify(layout)), saveFormData);
      if (saveFormData.pageType) {
        update.pages[currentPageIndex].pageType = saveFormData.pageType;
      }
      dispatch(saveLayout(site.id, { ...layout, ...update }));
      onClose();
    },
    [layout]
  );

  return (
    <SimpleModal
      size="m"
      onClose={onClose}
      title="Settings"
      className="panel-settings-modal"
    >
      <>
        <div className="column-content">
          <JSONEditor
            formData={formData}
            schema={pageTypeSchema}
            editorOnly={true}
            showButtons={false}
            showEditButton={false}
            onFormChange={onPageTypeChange}
          ></JSONEditor>
        </div>

        <div className="panel-settings-modal__actions">
          <Button
            activity="secondary"
            type="text"
            onClick={onClose}
          >
            Cancel
          </Button>
          <div className="panel-settings-modal__primary-buttons">
            <Button
              activity="primary"
              type="button"
              disabled={!hasChanges}
              onClick={() => onSave({ saveFormData: { pageType: interpageType } })}
            >
              Save
            </Button>
          </div>
        </div>
      </>
    </SimpleModal>
  );
};

export default PageSettings;
