/* eslint-disable default-param-last */
import {
  call,
  take,
  takeEvery,
  takeLatest,
  select,
  put,
  debounce,
  all,
  race,
} from 'redux-saga/effects';

import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import {
  getLastValues,
  uploadVariablesExcel as uploadVarsExcelApi,
  downloadVariablesExcelTemplate as downloadVarsExcelApi,
  getSeries,
} from '../services';
import {
  REFRESH_VALUES,
  CLEAR_SITE_DATA,
  setPollingActive,
  setPollingActiveDone,
} from './application';
import { requestExtendedSiteVariables, getSourceConnectivityTree } from './sources';
import { downloadBlob } from '../utils';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const PERSIST_LAST_SUBSCRIPTION = 'dt/variables/PERSIST_LAST_SUBSCRIPTION';
const ADD_LAST_SUBSCRIPTION = 'dt/variables/ADD_LAST_SUBSCRIPTION';
const ADD_LAST_SUBSCRIPTIONS = 'dt/variables/ADD_LAST_SUBSCRIPTIONS';
const REMOVE_LAST_SUBSCRIPTION = 'dt/variables/REMOVE_LAST_SUBSCRIPTION';
const REMOVE_LAST_SUBSCRIPTIONS = 'dt/variables/REMOVE_LAST_SUBSCRIPTIONS';

const FETCH_LAST_VARIABLE_DATA = 'dt/variables/FETCH_LAST_VARIABLE_DATA';
const RECEIVE_LAST_VALUES = 'dt/variables/RECEIVE_LAST_VALUES';

const VARIABLES_IMPORTED_AT = 'dt/variables/VARIABLES_IMPORTED_AT';
const SET_VARIABLES_LAST_POLLING = 'dt/variables/SET_VARIABLES_LAST_POLLING';

const DOWNLOAD_VARIABLES_TEMPLATE = 'dt/variables/DOWNLOAD_VARIABLES_TEMPLATE';
const UPLOAD_VARIABLES_EXCEL_SHEET = 'dt/variables/UPLOAD_VARIABLES_EXCEL_SHEET';
const VARIABLE_IMPORT_ERRORS = 'dt/variables/VARIABLE_IMPORT_ERRORS';
const FETCH_EXTENDED_SERIES_DATA = 'dt/variables/FETCH_EXTENDED_SERIES_DATA';
const STOP_FETCH_EXTENDED_SERIES_DATA = 'dt/variables/STOP_FETCH_EXTENDED_SERIES_DATA';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const addSubscriptions = (panelId, variableIds, subscriptionType = 'last') => {
  const type = subscriptionType === 'last' ? ADD_LAST_SUBSCRIPTIONS : 'undefined';
  return {
    type,
    panelId,
    variableIds,
  };
};

export const removeSubscriptions = (panelId, variableIds, subscriptionType = 'last') => {
  const type = subscriptionType === 'last' ? REMOVE_LAST_SUBSCRIPTIONS : 'undefined';
  return {
    type,
    panelId,
    variableIds,
  };
};

export const receiveLastValues = (values) => ({
  type: RECEIVE_LAST_VALUES,
  values,
});

export const refreshLastVariableData = () => ({
  type: FETCH_LAST_VARIABLE_DATA,
});

export const setVariablesImportedAt = (importedAt = 0) => ({
  type: VARIABLES_IMPORTED_AT,
  variablesImportedAt: importedAt,
});

export const downloadVariablesExcel = (site) => ({
  type: DOWNLOAD_VARIABLES_TEMPLATE,
  site,
});

export const uploadVariablesExcel = (file, siteId, org, template) => ({
  type: UPLOAD_VARIABLES_EXCEL_SHEET,
  file,
  siteId,
  org,
  template,
});

export const setImportErrors = (errors) => ({
  type: VARIABLE_IMPORT_ERRORS,
  errors,
});

export const fetchExtendedSeriesData = (
  { variables, aggregation, granularity, timeRange, startDate, endDate },
  callback
) => ({
  type: FETCH_EXTENDED_SERIES_DATA,
  variables,
  aggregation,
  interval: granularity,
  timeRange,
  startDate,
  endDate,
  callback,
});

export const stopFetchExtendedSeriesData = () => ({ type: STOP_FETCH_EXTENDED_SERIES_DATA });

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  variablesLastPolling: {},
  lastSubscriptions: {},
  lastData: {},
  importErrors: [],
  variablesImportedAt: 0,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case PERSIST_LAST_SUBSCRIPTION: {
      const { variableId, panelId } = action;
      const currentSubscriptions = state.lastSubscriptions[variableId] || {};
      return {
        ...state,
        lastSubscriptions: {
          ...state.lastSubscriptions,
          [action.variableId]: {
            ...currentSubscriptions,
            [panelId]: true,
          },
        },
      };
    }
    case REMOVE_LAST_SUBSCRIPTION: {
      const { variableId, panelId } = action;
      const currentSubscriptions = state.lastSubscriptions[variableId] || {};
      delete currentSubscriptions[panelId];

      return {
        ...state,
        lastSubscriptions: {
          ...state.lastSubscriptions,
          [action.variableId]: currentSubscriptions,
        },
      };
    }
    case SET_VARIABLES_LAST_POLLING: {
      return {
        ...state,
        variablesLastPolling: {
          ...state.variablesPolling,
          ...action.data,
        },
      };
    }
    case RECEIVE_LAST_VALUES: {
      const newLastData = { ...state.lastData };

      action.values.forEach((value) => {
        newLastData[value.variable] = value.value;
      });

      return { ...state, lastData: newLastData };
    }
    case VARIABLES_IMPORTED_AT: {
      return {
        ...state,
        variablesImportedAt: action.variablesImportedAt,
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        variablesLastPolling: {},
        lastSubscriptions: {},
        lastData: {},
        importErrors: [],
        variablesImportedAt: 0,
        variablesWithStates: [],
      };
    }
    case VARIABLE_IMPORT_ERRORS: {
      return { ...state, importErrors: action.errors };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getLastVariableData = (state) => state.variables.lastData;

export const getVariablesImportedAt = (state) => state.variables.variablesImportedAt;

export const getImportErrors = (state) => state.variables.importErrors;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doFetchLastVariableData() {
  const subscriptions = yield select((state) => state.variables.lastSubscriptions);
  const variables = Object.keys(subscriptions).filter(
    (variableId) => Object.keys(subscriptions[variableId]).length
  );

  if (!variables.length) return;

  try {
    const payload = {
      variables: variables.map((variableId) => ({ id: variableId })),
    };
    yield put({
      type: SET_VARIABLES_LAST_POLLING,
      data: variables.reduce((acc, variable) => {
        acc[variable] = true;
        return acc;
      }, {}),
    });

    const { values } = yield call(getLastValues, payload);
    yield put(receiveLastValues(values));
  } catch (e) {
    console.error('Unable to fetch last variable data: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getLastVarValues', 'error')(e.message)));
  }
  yield put({
    type: SET_VARIABLES_LAST_POLLING,
    data: variables.reduce((acc, variable) => {
      acc[variable] = false;
      return acc;
    }, {}),
  });
}

function* doGetExtendedSeriesData(action) {
  // like get series for visualizations, but will use req_type to bypass interval checks in data-service
  // and return up to 10k data points for signal viewer or export
  const { startDate, endDate, variables, interval, aggregation, callback } = action;

  if (!variables?.length) return;

  try {
    const connection = yield select(getSourceConnectivityTree);
    const payload = {
      from: startDate,
      to: endDate,
      interval,
      req_type: 'export_variable', // TODO: rename this to reflect the greater allowance on interval
      variables: variables.map((variable) => ({
        id: variable.id,
        aggregate: aggregation || variable.aggregate,
      })),
      connection,
    };
    const { values } = yield call(getSeries, payload);
    // Consider data points which has good quality
    const filteredValues = values.map((v) => ({
      ...v,
      values: v.values.map(([time, value, quality]) => [
        time,
        quality !== 1 ? null : value,
        quality,
      ]),
    }));
    if (callback) {
      yield call(callback, filteredValues);
    }
  } catch (e) {
    console.error('Unable to export the variable data: ', e);
    yield call(checkOnline);
    if (callback) {
      yield call(callback, { hasError: true });
    }
    yield put(displayNotification(getNotification('getExtendedSeries', 'error')(e.message)));
  }
}

function* doStartGetExtendedSeriesData(action) {
  yield race([call(doGetExtendedSeriesData, action), take(STOP_FETCH_EXTENDED_SERIES_DATA)]);
}

function* doFetchAllVariableData() {
  yield put(setPollingActive('variables'));
  yield all([doFetchLastVariableData()]);
  yield put(setPollingActiveDone('variables'));
}

function* doAddLastSubscriptions(action) {
  const { variableIds, panel } = action;

  yield all(
    variableIds.map((variableId) =>
      put({
        type: ADD_LAST_SUBSCRIPTION,
        panel,
        variableId,
      })
    )
  );
}

function* doRemoveLastSubscriptions(action) {
  const { variableIds, panelId } = action;

  yield all(
    variableIds.map((variableId) =>
      put({
        type: REMOVE_LAST_SUBSCRIPTION,
        panelId,
        variableId,
      })
    )
  );
}

function* doAddLastSubscription(action) {
  const subscriptions = yield select((state) => state.variables.lastSubscriptions);

  if (!action.variableId) {
    return;
  }

  yield put({ ...action, type: PERSIST_LAST_SUBSCRIPTION });

  const shouldTriggerImmediateFetch =
    !Object.keys(subscriptions).includes(action.variableId) ||
    !Object.keys(subscriptions[action.variableId]).length;

  if (shouldTriggerImmediateFetch) {
    // Given it was the first time subscription is added, trigger data fetching.
    yield put({ type: FETCH_LAST_VARIABLE_DATA });
  }
}

function* doUploadVarsExcel(action) {
  const { file, siteId, org, template } = action;
  try {
    yield put(setVariablesImportedAt(0));
    const fd = new FormData();
    fd.append('file', file);
    fd.append('site', siteId);
    fd.append('org', org);
    fd.append('template', template);
    const response = yield call(uploadVarsExcelApi, fd);
    if (response.importErrors.length) {
      yield put(setImportErrors(response.importErrors));
    } else {
      yield put(requestExtendedSiteVariables());
      yield put(setVariablesImportedAt(Date.now()));
      yield put(displayNotification(getNotification('uploadVarsExcel', 'success')()));
    }
  } catch (e) {
    console.error('Unable to upload variables excel: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('uploadVarsExcel', 'error')()));
  }
}

function* doDownloadVarsTemplate(action) {
  const { site } = action;
  try {
    const file = yield call(downloadVarsExcelApi, {
      site: site.id,
      org: site.org,
      template: site.template,
    });
    downloadBlob(
      file.data,
      `${site.name.replace(/\s/g, '_')}-signals_template.xlsx`,
      file.headers['content-type']
    );
  } catch (e) {
    console.error('Unable to download variables excel: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('downloadVarsExcel', 'error')()));
  }
}

export const sagas = [
  takeEvery(ADD_LAST_SUBSCRIPTIONS, doAddLastSubscriptions),
  takeEvery(REMOVE_LAST_SUBSCRIPTIONS, doRemoveLastSubscriptions),
  takeEvery(ADD_LAST_SUBSCRIPTION, doAddLastSubscription),
  takeLatest(DOWNLOAD_VARIABLES_TEMPLATE, doDownloadVarsTemplate),
  takeLatest(UPLOAD_VARIABLES_EXCEL_SHEET, doUploadVarsExcel),
  takeLatest(FETCH_EXTENDED_SERIES_DATA, doStartGetExtendedSeriesData),
  debounce(500, FETCH_LAST_VARIABLE_DATA, doFetchLastVariableData),
  debounce(500, REFRESH_VALUES, doFetchAllVariableData),
];
