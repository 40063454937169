const algorithmSchema = {
  type: 'object',
  required: ['name'],
  properties: {
    image: {
      type: 'string',
      title: '',
      default: 'vpmdev.azurecr.io/matlab:latest',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    description: {
      type: 'string',
      title: 'Description',
      default: '',
    },
  },
};

const algorithmUiSchema = {
  'ui:order': ['image', 'name', 'description'],

  image: {
    'ui:widget': 'hidden',
  },
};

export { algorithmSchema, algorithmUiSchema };
