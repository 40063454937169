const signalViewerContainerSchema = {
  title: {
    type: 'string',
    default: 'Fault Recordings',
  },
  options: {
    type: 'object',
    properties: {
      canLoadLocal: {
        type: 'boolean',
        title: 'Can load local files',
      },
      filesPerPage: {
        type: 'number',
        title: 'Files per page',
        default: 10,
      },
    },
  },
};

const getNewTemplateSchema = () => ({
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      title: 'Template Name',
      type: 'string',
    },
  },
});

export default signalViewerContainerSchema;
export { getNewTemplateSchema };
