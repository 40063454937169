const modelSchema = {
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      title: 'Name',
      default: null,
    },

    modelType: {
      type: 'string',
      enum: ['general', '3d cad', '3d scan', '2d'],
      title: 'Type',
    },
  },
};

const modelUiSchema = {
  'ui:options': {
    orderable: false,
  },
};

export { modelSchema, modelUiSchema };
