import React, { useMemo } from 'react';
import { Spinner } from '@iq/react-components';
import { useSelector } from 'react-redux';

import { getHasPermission } from '../../../../../bundles/auth';
import { DropdownMenu, DropdownTrigger } from '../../../../DropdownMenu';
import ListItem from '../../../../ListItem';

const InvitationList = (props) => {
  const {
    siteId,
    org,
    formData,
    invitations,
    searchText,
    roleFilter,
    isLoading,
    schema,
    uiSchema,
    onEdit = () => {},
    onUpdate,
    onChange,
    onClose = () => {},
    onDelete,
    getRoleOptions,
  } = props;

  const canWriteInvitations = useSelector((state) =>
    getHasPermission(state, 'invitations/Write', { org, site: siteId })
  );
  const canDeleteInvitations = useSelector((state) =>
    getHasPermission(state, 'invitations/Delete', { org, site: siteId })
  );

  const roleFilteredInvites = useMemo(() => {
    if (roleFilter !== 'all') {
      return invitations.filter((u) => u.role === roleFilter);
    }
    return invitations;
  }, [invitations, roleFilter]);

  const filteredInvites = useMemo(() => {
    if (searchText) {
      const filters = searchText.trim().split(' ');
      return roleFilteredInvites.filter((u) =>
        filters.every((f) => (u.email?.toLowerCase() || '').indexOf(f.toLowerCase()) !== -1)
      );
    }
    return roleFilteredInvites;
  }, [roleFilteredInvites, searchText]);

  const headers = ['Email', 'Role'];

  const headerCols = headers.map((h, i) => {
    const key = `col-${i}`;
    return (
      <div
        key={key}
        className="list-item--header__col"
      >
        {h}
      </div>
    );
  });

  const inviteList = useMemo(
    () =>
      filteredInvites.map((invitation, i) => {
        const item = {
          ...invitation,
          ...(invitation.id === formData?.id ? formData : {}),
        };
        return (
          <ListItem
            key={invitation.id}
            itemIndex={i}
            entity={`invitation for ${invitation.email}`}
            withTitle={false}
            item={item}
            schema={schema}
            uiSchema={uiSchema}
            onDelete={() => onDelete(invitation)}
            disableDelete={!canDeleteInvitations}
            confirmationDialogTitle={'Delete invitation'}
            confimationDialogBody={
              <p style={{ paddingTop: '1.5rem' }}>
                Are you sure you want to remove <b>{invitation.email}'s</b> invitation?
              </p>
            }
            onEdit={onEdit}
            disableEdit={!canWriteInvitations}
            onSubmit={(data) => onUpdate(data, invitation.id)}
            onChange={(data) => onChange(data)}
            onClose={onClose}
            columns={[
              <div
                key={`row-${i}-email`}
                className="ellipsed-text"
              >
                {invitation.email}
              </div>,
              <div key={`row-${i}-role`}>
                {canWriteInvitations ? (
                  <DropdownMenu
                    className="dropdown-menu"
                    value={invitation.role}
                    trigger={
                      <DropdownTrigger
                        label={invitation.role}
                        icon="he-down"
                      />
                    }
                    menuOptions={getRoleOptions(item)}
                    styles={{ menu: { width: '10rem' } }}
                  />
                ) : (
                  <div>{invitation.role}</div>
                )}
              </div>,
            ]}
          />
        );
      }),
    [filteredInvites, schema, uiSchema, onEdit, onUpdate, onChange, getRoleOptions]
  );

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      ) : (
        <div className="list-container">
          <ListItem
            isHeader
            columns={headerCols}
          />
          <div className="custom-thin-scrollbar">{inviteList}</div>
        </div>
      )}
    </>
  );
};

export default InvitationList;
