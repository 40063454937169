#global-modal-container .simple-modal-overlay .simple-modal-wrapper.event-file-modal-wrapper.size-m {
  max-width: 70rem;
}

.event-file-modal-wrapper__field-group, .event-file-modal-wrapper__errors > div {
  margin-bottom: 1rem;
}
.event-file-modal-wrapper__multi-field-group {
  display: flex;
  justify-content: space-between;
}
.event-file-modal-wrapper__multi-field-group > * {
  flex: 0 0 48%;
}
.event-file-modal-wrapper__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.event-file-modal-wrapper__actions button:first-child {
  margin-right: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvRXZlbnRGaWxlTW9kYWwiLCJzb3VyY2VzIjpbIkV2ZW50RmlsZU1vZGFsLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7O0FBSUE7RUFFRTs7QUFHRjtFQUNFO0VBQ0E7O0FBRUE7RUFDRTs7QUFJSjtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiI2dsb2JhbC1tb2RhbC1jb250YWluZXIgLnNpbXBsZS1tb2RhbC1vdmVybGF5IC5zaW1wbGUtbW9kYWwtd3JhcHBlci5ldmVudC1maWxlLW1vZGFsLXdyYXBwZXIuc2l6ZS1tIHtcbiAgbWF4LXdpZHRoOiA3MHJlbTtcbn1cblxuLmV2ZW50LWZpbGUtbW9kYWwtd3JhcHBlciB7XG4gICZfX2ZpZWxkLWdyb3VwLFxuICAmX19lcnJvcnMgPiBkaXYge1xuICAgIG1hcmdpbi1ib3R0b206IDFyZW07XG4gIH1cblxuICAmX19tdWx0aS1maWVsZC1ncm91cCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG5cbiAgICA+ICoge1xuICAgICAgZmxleDogMCAwIDQ4JTtcbiAgICB9XG4gIH1cblxuICAmX19hY3Rpb25zIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7XG4gICAgbWFyZ2luLXRvcDogMnJlbTtcblxuICAgIGJ1dHRvbjpmaXJzdC1jaGlsZCB7XG4gICAgICBtYXJnaW4tcmlnaHQ6IDFyZW07XG4gICAgfVxuICB9XG59XG5cbiJdfQ== */