import React from 'react';

const Column = ({ children, title, displayTitle, format }) => (
  <div className="column-component">
    {typeof title !== 'undefined' && displayTitle && <div className="column-title">{title}</div>}
    <div className="column-content">
      {React.Children.map(children, (child) => React.cloneElement(child, { format }))}
    </div>
  </div>
);

export default Column;
