import React, { useState, useCallback } from 'react';
import UserEventModal from './UserEventModal';
import IotEventModal from './IotEventModal';
import EventFileModal from '../EventFileModal';

const EventModal = ({
  eventType,
  event,
  eventId,
  dateDomain,
  userEventTypes,
  site,
  onClose,
  hideInactiveSystemEvents,
  eventListColumns,
}) => {
  const [eventHistory, setEventHistory] = useState([{ eventType, event, eventId }]);
  const goBack = useCallback(
    () => setEventHistory((history) => history.slice(0, -1)),
    [eventHistory]
  );

  const setNext = useCallback(
    (next) => {
      setEventHistory((current) => [...current, next]);
    },
    [eventHistory]
  );

  const handleOnClose = (opts) => {
    setEventHistory([]);
    onClose(opts);
  };

  if (eventHistory.length) {
    return eventHistory.map((eventHistoryItem, i) => {
      if (i !== eventHistory.length - 1) {
        return null;
      }

      if (eventHistoryItem.eventType === 'iot') {
        return (
          <IotEventModal
            key={`event-histoy-item-${i}`}
            event={eventHistoryItem.event}
            hasHistory={eventHistory.length > 1}
            onBack={goBack}
            onClose={handleOnClose}
          />
        );
      }

      if (eventHistoryItem.eventType === 'user' && userEventTypes.length) {
        return (
          <UserEventModal
            key={`event-histoy-item-${i}`}
            site={site}
            event={eventHistoryItem.event}
            dateDomain={dateDomain}
            userEventTypes={userEventTypes}
            hasHistory={eventHistory.length > 1}
            hideInactiveSystemEvents={hideInactiveSystemEvents}
            eventListColumns={eventListColumns}
            initialEdit={!!eventHistoryItem.edit}
            onBack={goBack}
            onNext={setNext}
            onClose={handleOnClose}
          />
        );
      }

      return null;
    });
  }
  return null;
};

export const useEventModal = (dependencies = []) => {
  const [view, setView] = useState(null);
  const openModal = useCallback(
    ({ modalType, onClose, ...rest }) =>
      new Promise((resolve) => {
        const handleOnClose = (opts) => {
          if (onClose) {
            onClose(opts);
          }

          setView(null);
          resolve();
        };

        setView(() => {
          if (modalType === 'file') {
            return (
              <EventFileModal
                {...rest}
                onClose={handleOnClose}
              />
            );
          }
          return (
            <EventModal
              {...rest}
              eventType={modalType}
              onClose={handleOnClose}
            />
          );
        });
      }),
    dependencies
  );
  return [view, openModal];
};

export default useEventModal;
