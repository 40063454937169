import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getUploads, UPLOADED, ADDED } from '../../bundles/uploads';
import UploadProgress from './UploadProgress';

const UploadStatus = () => {
  const uploads = useSelector((state) => getUploads(state));

  const uploading = uploads.filter((upload) => ![ADDED, UPLOADED].includes(upload.status));

  useEffect(() => {
    if (uploading.length) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [uploading.length]);

  if (!uploading.length) {
    return null;
  }

  return (
    <section className="upload-status-component">
      {uploading.map((upload) => {
        const {
          id,
          progress,
          status,
          file: { name, size },
        } = upload;
        return (
          <UploadProgress
            key={id}
            id={id}
            name={name}
            status={status}
            size={size}
            progress={progress}
          />
        );
      })}
    </section>
  );
};

export default UploadStatus;
