/* eslint-disable default-param-last */
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';
import {
  getJobs as getJobsApi,
  getTasks as getTasksApi,
  createTask as createTaskApi,
  deleteTask as deleteTaskApi,
  updateTask as updateTaskApi,
} from '../services';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const REQUEST_TASKS = 'dt/tasks/REQUEST_TASKS';
export const RECEIVED_TASKS = 'dt/tasks/RECEIVED_TASKS';
export const CREATE_TASK = 'dt/tasks/CREATE_TASK';
export const DELETE_TASK = 'dt/tasks/DELETE_TASK';
export const UPDATE_TASK = 'dt/tasks/UPDATE_TASK';

export const REQUEST_JOBS = 'dt/tasks/REQUEST_JOBS';
export const RECEIVED_JOBS = 'dt/tasks/RECEIVED_JOBS';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestTasks = (query = {}) => ({
  type: REQUEST_TASKS,
  query,
});

export const receiveTasks = (tasks) => ({
  type: RECEIVED_TASKS,
  tasks,
});

export const createTask = (data) => ({
  type: CREATE_TASK,
  data,
});

export const deleteTask = (taskId) => ({
  type: DELETE_TASK,
  taskId,
});

export const updateTask = (taskId, data) => ({
  type: UPDATE_TASK,
  taskId,
  data,
});

export const requestJobs = (taskId) => ({
  type: REQUEST_JOBS,
  taskId,
});

export const receiveJobs = (jobs) => ({
  type: RECEIVED_JOBS,
  jobs,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  tasks: [],
  jobs: [],
  dataLoaded: false,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVED_TASKS: {
      return {
        ...state,
        dataLoaded: true,
        tasks: action.tasks || [],
      };
    }
    case RECEIVED_JOBS: {
      return {
        ...state,
        dataLoaded: true,
        jobs: action.jobs || [],
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        tasks: [],
        jobs: [],
        dataLoaded: false,
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getTasks = (state) => state.tasks.tasks;

export const getJobs = (state) => state.tasks.jobs;

export const getTask = createSelector(
  getTasks,
  (_, taskId) => taskId,
  (tasks, taskId) => tasks.find((task) => task.id === taskId)
);

export const getTaskJobs = createSelector(
  getJobs,
  (_, taskId) => taskId,
  (jobs, taskId) => jobs.filter((job) => job.taskId === taskId)
);

export const getAlgorithmTasks = createSelector(
  getTasks,
  (_, algoId) => algoId,
  (tasks, algoId) => tasks.filter((task) => task.algorithm_id === algoId)
);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

export function* doRequestJobs(action) {
  const { taskId } = action;
  try {
    const { values: jobs } = yield call(getJobsApi, taskId);
    yield put(receiveJobs(jobs));
  } catch (e) {
    console.error('Unable to fetch jobs: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getJobs', 'error')()));
    yield put(receiveJobs([]));
  }
}

export function* doRequestTasks(action) {
  const { query } = action;
  try {
    const { values: tasks } = yield call(getTasksApi, query);
    yield put(receiveTasks(tasks));
  } catch (e) {
    console.error('Unable to fetch tasks: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getTasks', 'error')()));
    yield put(receiveTasks([]));
  }
}

function* doCreateTask(action) {
  const { data } = action;
  try {
    yield call(createTaskApi, data);
    yield call(doRequestTasks, { query: data.site });
    yield put(displayNotification(getNotification('createTask', 'success')()));
  } catch (e) {
    console.error('Unable to create task: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createTask', 'error')()));
  }
}

function* doUpdateTask(action) {
  const { taskId, data } = action;
  try {
    yield call(updateTaskApi, taskId, data);
    yield call(doRequestTasks, { query: data.site });
    yield put(displayNotification(getNotification('updateTask', 'success')(taskId)));
  } catch (e) {
    console.error('Unable to update task: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateTask', 'error')(taskId)));
  }
}

function* doDeleteTask(action) {
  const { taskId } = action;
  const task = yield select((state) => state.tasks.tasks.find((a) => a.id === taskId));
  try {
    yield call(deleteTaskApi, taskId);
    yield call(doRequestTasks, { site: task.site });
    yield put(displayNotification(getNotification('deleteTask', 'success')(taskId)));
  } catch (e) {
    console.error('Unable to delete task: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteTask', 'error')(taskId)));
  }
}

export const sagas = [
  takeLatest(REQUEST_JOBS, doRequestJobs),
  takeLatest(REQUEST_TASKS, doRequestTasks),
  takeLatest(CREATE_TASK, doCreateTask),
  takeLatest(DELETE_TASK, doDeleteTask),
  takeLatest(UPDATE_TASK, doUpdateTask),
];
