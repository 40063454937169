import ExcelJS from 'exceljs';

const isItemDesignation = (itemDesignation) => {
  const pattern = /^([+=-])([a-zA-Z0-9]+[+-.=])*[a-zA-Z0-9]+$/;
  return pattern.test(itemDesignation);
};
class ParseExcelError extends Error {
  constructor(errors) {
    super('ParseExcelError');
    this.errors = errors;
  }
}

const normalizeItemDesignation = (itemDesignation) => {
  const isValid = isItemDesignation(itemDesignation);

  if (!itemDesignation || !isValid || !itemDesignation.includes('.')) {
    return itemDesignation;
  }

  const validChars = ['=', '+', '-'];
  const replacedString = itemDesignation.replace(/\./g, (match, offset, string) => {
    for (let i = offset - 1; i >= 0; i -= 1) {
      if (validChars.includes(string[i])) {
        return string[i];
      }
    }
    return match;
  });

  const segmentPattern = /([+=-][^+=-]*)/g;
  const matchedSegments = replacedString.match(segmentPattern);
  return matchedSegments ? matchedSegments.join('') : replacedString;
};

const checkUnique = (array, key, errors, message, worksheetName) => {
  const uniqueVal = {};

  array.some((item, index) => {
    const property = item[key];
    // eslint-disable-next-line no-prototype-builtins
    if (uniqueVal.hasOwnProperty(property)) {
      // Index + 2 since the first row in the excel sheet is the header and index starts at 0.
      errors.push(`${worksheetName}: Row ${index + 2}: ${message}`);
      return true;
    }
    if (property) {
      uniqueVal[property] = true;
    }
    return false;
  });
};

const cleanSheet = (sheet) => {
  const validRows = [];

  sheet.eachRow({ includeEmpty: false }, (row) => {
    // strips out all rows that are empty, we can potentially have a couple of thousands of empty rows.
    const rowData = row.values;
    const isValidRow = !rowData.every((value) => value === null || value === '');

    if (isValidRow) {
      rowData.shift();
      validRows.push(rowData);
    }
  });

  return validRows;
};

const validateSheetHeaders = (headers, sheet, map, errorArray) => {
  const columnNames = Object.keys(map);

  headers.forEach((r, i) => {
    const rowName = r.replace(/\*/g, '').trim();
    const columnLetter = sheet.getColumn(i + 1).letter;

    if (!columnNames.includes(rowName) || (columnNames[i] && map[rowName] !== i)) {
      errorArray.push(`Column ${columnNames[i]} should be on column ${columnLetter}`);
    }
  });
};

const parseComponentSheet = async ({ workbook }) => {
  const componentSheet = workbook.worksheets.find((s) => s.name === 'Components');
  const parsedComponents = [];
  let validItemDesignations;
  const errors = [];

  if (!componentSheet) {
    return [[], [], ['No Components sheet found']];
  }

  const COMPONENT_SHEET_MAP = {
    'Reference Designation': 0,
    'Parent Reference Designation': 1,
    'Component Name': 2,
    'Component Type': 3,
    'Custom Id': 4,
  };

  const [sheetHeaders, ...validRows] = cleanSheet(componentSheet);

  validateSheetHeaders(sheetHeaders, componentSheet, COMPONENT_SHEET_MAP, errors);

  checkUnique(
    validRows,
    COMPONENT_SHEET_MAP['Reference Designation'],
    errors,
    'Reference Designation has to be unique.',
    'Components sheet'
  );

  if (!errors.length) {
    validItemDesignations = new Set(
      validRows.map((row) =>
        row[COMPONENT_SHEET_MAP['Reference Designation']]
          ? normalizeItemDesignation(row[COMPONENT_SHEET_MAP['Reference Designation']])
          : row[COMPONENT_SHEET_MAP['Reference Designation']]
      )
    );

    let rowValid = true;

    validRows.forEach((row, index) => {
      const referenceDesignation = row[COMPONENT_SHEET_MAP['Reference Designation']]
        ? normalizeItemDesignation(row[COMPONENT_SHEET_MAP['Reference Designation']])
        : row[COMPONENT_SHEET_MAP['Reference Designation']];
      const parentReferenceDesignation = row[COMPONENT_SHEET_MAP['Parent Reference Designation']]
        ? normalizeItemDesignation(row[COMPONENT_SHEET_MAP['Parent Reference Designation']])
        : null;
      const name = row[COMPONENT_SHEET_MAP['Component Name']];
      const type = row[COMPONENT_SHEET_MAP['Component Type']];
      const customId = row[COMPONENT_SHEET_MAP['Custom Id']];

      if (!referenceDesignation) {
        rowValid = false;
        errors.push(`Components sheet: Reference designation on row ${index + 2} is required.`);
      }

      if (index >= 1 && !parentReferenceDesignation && type !== 'site') {
        rowValid = false;
        errors.push(`Components sheet: Parent on row ${index + 2} is required. `);
      }

      if (index > 1 && !validItemDesignations.has(parentReferenceDesignation)) {
        rowValid = false;
        errors.push(
          `Components sheet: The reference designation set for this parent on row ${index + 2} is invalid.`
        );
      }

      if (index > 1 && parentReferenceDesignation === referenceDesignation) {
        rowValid = false;
        errors.push(
          `Components sheet: The reference designation and parent reference designation on row ${index + 2} cannot be the same.`
        );
      }

      if (!name) {
        rowValid = false;
        errors.push(`Components sheet: Component name on row ${index + 2} is required.`);
      }

      if (!type) {
        rowValid = false;
        errors.push(`Components sheet: Component type on row ${index + 2} is required.`);
      }

      // Check that parent does not exist after in the list to avoid circualar dependencies when updating components
      if (
        parentReferenceDesignation != null &&
        validRows
          .slice(index + 1)
          .some(
            (vRow) =>
              parentReferenceDesignation === vRow[COMPONENT_SHEET_MAP['Reference Designation']]
          )
      ) {
        rowValid = false;
        errors.push(
          `Components sheet: Component type on row ${index + 2} cannot have parent after in the list.`
        );
      }

      const getCompType = (componentType) => {
        if (componentType === 'equipment') {
          return 'component';
        }
        if (componentType === 'area') {
          return 'virtual';
        }
        return componentType;
      };

      if (rowValid) {
        const parsedComponent = {
          itemDesignation: referenceDesignation,
          parentItemDesignation: parentReferenceDesignation,
          name,
          type: getCompType(type),
          customId,
        };
        parsedComponents.push(parsedComponent);
      }
    });
  }

  return [parsedComponents, validItemDesignations, errors];
};

const parseDocumentSheet = async ({ workbook, drawings, itemDesignations }) => {
  const documentSheet = workbook.worksheets.find((s) => s.name === 'Documents');
  const errors = [];
  const parsedDocuments = [];

  if (!documentSheet) {
    return [[], ['No Document sheet found']];
  }

  const DOCUMENT_SHEET_MAP = {
    Document: 0,
    'Reference Designation': 1,
    Width: 2,
    Height: 3,
  };

  // Removing the headers on the first row in the excel
  const [sheetHeaders, ...validRows] = cleanSheet(documentSheet);
  validateSheetHeaders(sheetHeaders, documentSheet, DOCUMENT_SHEET_MAP, errors);

  checkUnique(
    validRows,
    DOCUMENT_SHEET_MAP.Document,
    errors,
    'Document has to be unique.',
    'Documents sheet'
  );

  if (!errors.length) {
    let rowValid = true;

    validRows.forEach((row, index) => {
      const document = row[DOCUMENT_SHEET_MAP.Document];
      const referenceDesignation = row[DOCUMENT_SHEET_MAP['Reference Designation']]
        ? normalizeItemDesignation(row[DOCUMENT_SHEET_MAP['Reference Designation']])
        : row[DOCUMENT_SHEET_MAP['Reference Designation']];
      const height = row[DOCUMENT_SHEET_MAP.Height];
      const width = row[DOCUMENT_SHEET_MAP.Width];

      const drawingsMatched = drawings.filter((drawing) => drawing.originalName === document);

      if (drawingsMatched.length === 0) {
        rowValid = false;
        errors.push(
          `Documents Sheet: This document on row ${index + 2} does not exist on the site.`
        );
      }

      if (drawingsMatched.length > 1) {
        rowValid = false;
        errors.push(
          `Documents Sheet: There are several documents with the same document name that exist for row ${index + 2}. There can only be one, so make sure to remove the others and try again`
        );
      }

      if (!itemDesignations.has(referenceDesignation)) {
        rowValid = false;
        errors.push(
          `Documents Sheet: Reference designation on row ${index + 2} is was not included in the components sheet.`
        );
      }

      if (!referenceDesignation) {
        rowValid = false;
        errors.push(`Documents Sheet: Reference designation on row ${index + 2} is required.`);
      }

      if (!height) {
        rowValid = false;
        errors.push(`Documents Sheet: Height on row ${index + 2} is required.`);
      }

      if (!width) {
        rowValid = false;
        errors.push(`Documents Sheet: Width on row ${index + 2} is required.`);
      }

      if (rowValid) {
        const parsedDocument = {
          name: document,
          versionId: drawingsMatched[0].versionId,
          modelId: drawingsMatched[0].modelId,
          displayName: drawingsMatched[0].displayName,
          itemDesignation: normalizeItemDesignation(referenceDesignation),
          height: Number(height),
          width: Number(width),
        };
        parsedDocuments.push(parsedDocument);
      }
    });
  }
  return [parsedDocuments, errors];
};

// Waiting for more info about this from Ben & Balaji about the structure.
const parseAttributesSheet = async ({ workbook, parsedComponents }) => {
  const parsedAttributes = {};
  const errors = [];

  const attributesSheet = workbook.worksheets.find((s) => s.name === 'Attributes');

  if (!attributesSheet) {
    return [[], ['No Attributes sheet found']];
  }

  const ATTRIBUTES_SHEET_MAP = {
    'Reference Designation': 0,
    'Attribute Name': 1,
    Value: 2,
    Category: 3,
    'Data type': 4,
    Unit: 5,
  };

  const [sheetHeaders, ...validRows] = cleanSheet(attributesSheet);
  validateSheetHeaders(sheetHeaders, attributesSheet, ATTRIBUTES_SHEET_MAP, errors);

  if (!errors.length) {
    let rowValid = true;

    validRows.forEach((row, index) => {
      const referenceDesignation = row[ATTRIBUTES_SHEET_MAP['Reference Designation']]
        ? normalizeItemDesignation(row[ATTRIBUTES_SHEET_MAP['Reference Designation']])
        : row[ATTRIBUTES_SHEET_MAP['Reference Designation']];
      const attributeName = row[ATTRIBUTES_SHEET_MAP['Attribute Name']];

      const value = row[ATTRIBUTES_SHEET_MAP.Value];
      const category = row[ATTRIBUTES_SHEET_MAP.Category];
      const dataType = row[ATTRIBUTES_SHEET_MAP['Data type']];
      const unit = row[ATTRIBUTES_SHEET_MAP.Unit];

      if (!referenceDesignation) {
        rowValid = false;
        errors.push(`Attributes sheet: Reference designation on row ${index + 2} is required.`);
      }

      if (!attributeName) {
        rowValid = false;
        errors.push(`Attributes sheet: Attribute name on row ${index + 2} is required.`);
      }

      if (value == null) {
        rowValid = false;
        errors.push(`Attributes sheet: Value on row ${index + 2} is required.`);
      }

      if (!category) {
        rowValid = false;
        errors.push(`Attributes sheet: Category on row ${index + 2} is required.`);
      }
      if (
        !dataType &&
        !(dataType === 'string' || dataType === 'boolean' || dataType === 'number')
      ) {
        rowValid = false;
        errors.push(
          `Attributes sheet: Data type on row ${index + 2} is required and needs to be one of string, boolean or number.`
        );
      }

      if (!parsedComponents.map((c) => c.itemDesignation).includes(referenceDesignation)) {
        rowValid = false;
        errors.push(
          `Attributes sheet: (Row ${index + 2}) ${referenceDesignation ? `No component with reference designation ${referenceDesignation}.` : 'Reference designation is empty.'}`
        );
      }

      if (rowValid) {
        const parsedAttribute = {
          name: attributeName,
          dataType,
          values: [{ value, timestamp: new Date(), quality: 1 }],
          itemDesignation: referenceDesignation,
          category,
          ...(unit && unit !== '' && { unit }),
        };

        parsedAttributes[referenceDesignation] = [
          ...(parsedAttributes[referenceDesignation] || []),
          parsedAttribute,
        ];
      }
    });
  }

  return [
    parsedComponents.map((c) => ({
      ...c,
      attributes: parsedAttributes[c.itemDesignation] || [],
    })),
    errors,
  ];
};

const parseShapesSheet = async ({ workbook, documents, itemDesignations }) => {
  const documentsWithShapes = {};
  const errors = [];

  const shapesSheet = workbook.worksheets.find((s) => s.name === 'Shapes');

  if (!shapesSheet) {
    return [[], ['No Attributes sheet found']];
  }

  const SHAPES_SHEET_MAP = {
    Document: 0,
    'Reference Designation': 1,
    'Shape Name': 2,
    X1: 3,
    Y1: 4,
    X2: 5,
    Y2: 6,
    Width: 7,
    Height: 8,
  };

  const [sheetHeaders, ...validRows] = cleanSheet(shapesSheet);
  validateSheetHeaders(sheetHeaders, shapesSheet, SHAPES_SHEET_MAP, errors);

  if (!errors.length) {
    let rowValid = true;

    validRows.forEach((row, index) => {
      const documentName = row[SHAPES_SHEET_MAP.Document];
      const referenceDesignation = row[SHAPES_SHEET_MAP['Reference Designation']]
        ? normalizeItemDesignation(row[SHAPES_SHEET_MAP['Reference Designation']])
        : row[SHAPES_SHEET_MAP['Reference Designation']];
      const shapeName = row[SHAPES_SHEET_MAP['Shape Name']];
      const x1 = row[SHAPES_SHEET_MAP.X1];
      const y1 = row[SHAPES_SHEET_MAP.Y1];
      const x2 = row[SHAPES_SHEET_MAP.X2];
      const y2 = row[SHAPES_SHEET_MAP.Y2];
      const width = row[SHAPES_SHEET_MAP.Width];
      const height = row[SHAPES_SHEET_MAP.Height];

      if (
        !documentName ||
        !referenceDesignation ||
        !shapeName ||
        !x1 ||
        !y1 ||
        !x2 ||
        !y2 ||
        !width ||
        !height
      ) {
        rowValid = false;
        errors.push(
          `Shapes sheet: At least one column is not entered on row ${index + 2}. All values are required`
        );
      }

      if (!documents.some((d) => d.name === documentName)) {
        rowValid = false;
        errors.push(
          `Shapes sheet: Document on row ${index + 2} is not included in the documents sheet.`
        );
      }

      if (!itemDesignations.has(referenceDesignation)) {
        rowValid = false;
        errors.push(
          `Shapes sheet: Reference designation on row ${index + 2} is was not included in the components sheet.`
        );
      }

      if (rowValid) {
        const document = documents.find((d) => d.name === documentName);

        // The scalefactor transforms the coordinate system of visio to IdentiQ coordinate system
        // The number 250 and 88 comes from that the specific format of the .dwg always creates the drawing in the same size in THREE.js coordinates
        const scaleFactor = 250 / document.width;
        const offsetY = 88;

        // The divided by two etc is because in IdentiQ we use the center of the drawing as origa while Visio use bottom left corner as origo
        const geometry = {
          name: referenceDesignation,
          position: {
            x: x1 * scaleFactor + (width * scaleFactor) / 2,
            y: y1 * scaleFactor + offsetY - (height * scaleFactor) / 2,
            z: 0,
          },
          quaternion: {
            x: 0,
            y: 0,
            z: 0,
            w: 1,
          },
          scale: {
            x: width * scaleFactor,
            y: height * scaleFactor,
            z: 1,
          },
          geometryType: 'PlaneGeometry',
          material: {
            color: '#9898FC',
            opacity: 0.02,
            transparent: true,
          },
          type: 'object',
        };

        documentsWithShapes[documentName] = [
          ...(documentsWithShapes[documentName] || []),
          geometry,
        ];
      }
    });

    const documentsWithGeometries = documents.map((c) => ({
      ...c,
      geometries: documentsWithShapes[c.name] || [],
    }));

    // Sort by size of geometries
    documentsWithGeometries.forEach((doc) => {
      doc.geometries.sort((mappingA, mappingB) => {
        return mappingB.scale.x * mappingB.scale.y - mappingA.scale.x * mappingA.scale.y;
      });

      // Make objects clickable in correct order by changing z in accordance with size
      /* eslint-disable no-param-reassign */
      doc.geometries.forEach((geometry, index) => {
        geometry.position.z = index;
      });
      /* eslint-enable no-param-reassign */
    });

    return [documentsWithGeometries, errors];
  }
  return [[], errors];
};

const parseAutomationExcel = async ({ file, drawings }) => {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);

  const documentsSheet = workbook.worksheets.find((s) => s.name === 'Documents');

  try {
    const [parsedComponents, itemDesignations, componentErrors] = await parseComponentSheet({
      workbook,
    });
    if (componentErrors.length > 0) {
      throw new ParseExcelError(componentErrors);
    }

    const [parsedComponentsWithAttributes, attributesErrors] = await parseAttributesSheet({
      workbook,
      parsedComponents,
    });

    if (attributesErrors.length > 0) {
      throw new ParseExcelError(attributesErrors);
    }

    // Only try parse documents and shapes if there is a documents sheet
    if (documentsSheet) {
      const [documents, documentErrors] = await parseDocumentSheet({
        workbook,
        drawings,
        itemDesignations,
      });

      if (documentErrors.length > 0) {
        throw new ParseExcelError(documentErrors);
      }

      const [documentsWithShapes, shapesErrors] = await parseShapesSheet({
        workbook,
        documents,
        itemDesignations,
      });

      if (shapesErrors.length > 0) {
        throw new ParseExcelError(shapesErrors);
      }

      return [parsedComponentsWithAttributes, documentsWithShapes, []];
    }
    return [parsedComponentsWithAttributes, [], []];
  } catch (err) {
    return [
      [],
      [],
      err instanceof ParseExcelError ? err.errors : ['An error occured parsing the excel'],
    ];
  }
};

export {
  parseComponentSheet,
  parseDocumentSheet,
  parseShapesSheet,
  parseAttributesSheet,
  parseAutomationExcel,
};
