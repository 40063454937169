import React, { useEffect, useState } from 'react';
import { Icon } from '@iq/react-components';
import './PermissionsSelectorField.scss';

const PermissionsSelectorField = (props) => {
  const {
    onChange,
    name,
    formData,
    schema: { includeList },
  } = props;

  const [data, setData] = useState(formData || [false, false, false, false]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const splitCamelCase = (str) => {
    return str.replace(/([a-z](?=[A-Z]))/g, '$1 ');
  };

  return (
    <div className="permissions-wrapper">
      <div className="permission-checkbox-container">
        <div className="permission-label">{splitCamelCase(name)}</div>

        {new Array(4).fill(null).map((_, i) => {
          const key = `checkbox-${i}`;
          if (i === 0) {
            return includeList.includes(name) ? (
              <Icon
                key={key}
                icon={data[i] ? 'toggle-on' : 'toggle-off'}
                size="m"
                className={data[i] ? 'permission-checkbox-checked' : 'permission-checkbox'}
                onClick={() => setData((p) => p.with(i, !p[i]))}
              />
            ) : (
              <div
                key={key}
                style={{ width: '32px', height: '32px' }}
              ></div>
            );
          }
          return (
            <Icon
              key={key}
              icon={data[i] ? 'he-checkbox-selected' : 'he-checkbox'}
              size="m"
              className={data[i] ? 'permission-checkbox-checked' : 'permission-checkbox'}
              onClick={() => setData((p) => p.with(i, !p[i]))}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PermissionsSelectorField;
