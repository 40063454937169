import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { HvTabs, HvTab } from '@hitachivantara/uikit-react-core';
import { Button, Icon, Input, Spinner } from '@iq/react-components';

import Select from '../../../CustomSelect';
import JSONEditor from '../../../JSONEditor';
import SimpleModal from '../../../SimpleModal';
import { cleanFormData, toCapitalizedWords } from '../../../../utils';
import { getServiceAccounts, isLoadingServiceAccounts } from '../../../../bundles/auth';

const ENDPOINT_FIELDS = [
  'checkConnectivity',
  'componentLink',
  'componentSync',
  'health',
  'import',
  'latestValues',
  'series',
  'siteLink',
  'siteSync',
];

const validateIntegration = (data, { isTemplate, siteView, creating, otherSiteIntegrations }) => {
  const errors = {};
  let hasErrors = false;
  const required = 'is a required property';

  if (!data.name) {
    errors.name = required;
    hasErrors = true;
  }

  if (siteView && otherSiteIntegrations.map((i) => i.name).includes(data.name)) {
    errors.name = 'is already taken';
    hasErrors = true;
  }

  if (siteView && !data.templateId) {
    errors.templateId = required;
    hasErrors = true;
  }
  if (isTemplate && creating && data.type === 'custom' && !data.configuration.serviceAccount) {
    errors.serviceAccount = required;
    hasErrors = true;
  }
  return {
    errors: hasErrors ? errors : null,
  };
};

const EditIntegrationModal = ({
  integrationTypes,
  onCloseModal = () => {},
  siteView,
  isTemplate,
  creating,
  onSubmit,
  templates,
  integration,
  siteIntegrations,
}) => {
  const [selectedType, setSelectedType] = useState(integration?.type || null);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [integrationConfigurationSchema, setIntegrationConfigurationSchema] = useState();
  const [hasSelectedIntegrationType, setHasSelectedIntegrationType] = useState(!creating);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabKey, setTabKey] = useState();
  const [schemas, setSchemas] = useState();
  const [formData, setFormData] = useState(integration || { name: '', configuration: {} });
  const [activeTabData, setActiveTabData] = useState({});
  const [mainIntegration, setMainIntegration] = useState(!!integration?.main);
  const serviceAccounts = useSelector(getServiceAccounts);
  const serviceAccountsLoading = useSelector(isLoadingServiceAccounts);
  const shouldWaitForServiceAccounts = creating && isTemplate;
  const [payloadErrors, setPayloadErrors] = useState({});
  const [typeInitialized, setTypeInitialized] = useState();
  const [integrationName, setIntegrationName] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleCancel = () => {
    setShowCancelModal(true);
  };

  const handleCancelConfirmation = () => {
    setShowCancelModal(false);
    onCloseModal();
  };

  const formRef = useRef();

  const sortedServiceAccounts = useMemo(() => {
    if (serviceAccounts) {
      return [...serviceAccounts.sort((a, b) => a.name.localeCompare(b.name))];
    }
    return [];
  }, [serviceAccounts]);

  const subject = isTemplate ? 'integration template' : 'integration';
  const modalTitle = `${creating ? 'Add' : 'Update'} ${subject}`;

  const onChange = ({ formData: changes }) => {
    setActiveTabData(changes);
  };

  const initTabConfig = useCallback(
    (type) => {
      const integrationType = integrationTypes.find((t) => t.name === type);
      const integrationSchemaType = isTemplate && creating ? 'integrationStrict' : 'integration';
      const { properties: integrationSchema } =
        integrationType.schemas[integrationSchemaType] || integrationType.schemas.integration;
      setFormData({ ...formData, type });
      setIntegrationConfigurationSchema(integrationSchema);
      setTypeInitialized(type);
    },
    [formData, typeInitialized]
  );

  useEffect(() => {
    if (integration && typeInitialized !== integration.type) {
      initTabConfig(integration.type);
    }
  }, [integration, typeInitialized, initTabConfig]);

  useEffect(() => {
    setTabKey(Object.keys(integrationConfigurationSchema || {})[tabIndex]);
  }, [integrationConfigurationSchema, tabIndex]);

  useEffect(() => {
    if (tabKey) {
      setActiveTabData(formData.configuration?.[tabKey] || {});
    }
  }, [tabKey, formData]);

  const customValidator = useCallback(
    (data, errors) => {
      const formFields = Object.entries(data);
      formFields
        .filter(([key]) => [...ENDPOINT_FIELDS, 'tokenUrl'].includes(key))
        .forEach(([key, val]) => {
          const urlField = ENDPOINT_FIELDS.includes(key) ? val.endpoint : val;
          if (urlField !== undefined && urlField !== '') {
            try {
              // eslint-disable-next-line no-new
              const url = new URL(urlField);
              if (!url.protocol.includes('http')) {
                throw Error('is missing http(s) protocol');
              }
            } catch (e) {
              if (e.message.includes('missing')) {
                errors[key].addError(`${e.message}`);
              } else {
                errors[key].addError('should be a URL.');
              }
            }
          }
        });
      formFields.forEach(([key, val]) => {
        if (
          typeof val === 'string' &&
          val.trim() === '' &&
          (schemas?.[tabKey]?.schema.required || []).includes(key)
        ) {
          errors[key].addError(`is a required property`);
        }
      });
      return errors;
    },
    [schemas, tabKey]
  );

  const handleTabChange = useCallback(
    (e, newTabIndex) => {
      if (tabKey && activeTabData) {
        const { errors } = formRef.current.validate(activeTabData);
        if (errors.length) {
          formRef.current.onSubmit(e);
        } else {
          setTabIndex(newTabIndex);
          setFormData((data) => ({
            ...data,
            configuration: { ...data.configuration, [tabKey]: activeTabData },
          }));
          setPayloadErrors({});
        }
      }
    },
    [activeTabData, tabKey, formRef.current]
  );

  const onSelectedIntegrationType = useCallback(() => {
    initTabConfig(selectedType);
    setHasSelectedIntegrationType(true);
  }, [selectedType, initTabConfig]);

  const formattedTypes = integrationTypes.map((i) => ({ label: i.displayName, value: i.name }));
  const existingTemplateTypes = templates.map((t) => t.type);
  const availableTemplateTypes = isTemplate
    ? formattedTypes
    : formattedTypes.filter((t) => existingTemplateTypes.includes(t.value));
  const templatesBySelectedType = templates.filter((t) => t.type === selectedType);

  const integrationTypeSelect = availableTemplateTypes.length ? (
    <Select
      isMulti={false}
      rawOptions={availableTemplateTypes}
      placeholder="Select integration type"
      onChange={setSelectedType}
    />
  ) : (
    <div>
      {`No integration templates found.
      Please contact your tenant administrator.`}
    </div>
  );

  const handleTemplateSelect = (id) => {
    setSelectedTemplateId(id);
  };

  const onToggleMain = useCallback(() => {
    setMainIntegration((main) => !main);
  }, [mainIntegration]);
  const checkboxIcon = mainIntegration ? 'he-checkbox-selected' : 'he-checkbox';

  useEffect(() => {
    if (!selectedTemplateId && templatesBySelectedType.length && siteView) {
      setSelectedTemplateId(templatesBySelectedType[0].id);
    }
  }, [templatesBySelectedType, selectedTemplateId, siteView]);

  useEffect(() => {
    if (creating) {
      const selectedTemplate = templates.find((template) => template.id === selectedTemplateId);
      if (selectedTemplate) {
        const template = { ...selectedTemplate };
        delete template.id;
        setFormData(template);
      }
    }
  }, [selectedTemplateId, templates, creating]);

  const templatePicker = useMemo(() => {
    if (templatesBySelectedType.length) {
      return (
        <div className="template-picker">
          <h3>Select Template*</h3>
          <Select
            isMulti={false}
            rawOptions={templatesBySelectedType}
            value={selectedTemplateId || templatesBySelectedType[0]?.id}
            className="template-picker-select"
            onChange={handleTemplateSelect}
          />
        </div>
      );
    }
    return null;
  }, [templatesBySelectedType, selectedType]);

  const tabHeaders = Object.keys(integrationConfigurationSchema || {})
    .filter((schemaKey) => schemaKey !== 'serviceAccount' || (creating && isTemplate))
    .map((schemaKey) => (
      <HvTab
        className={payloadErrors[schemaKey] ? 'tab-errors' : null}
        key={schemaKey}
        id={schemaKey}
        label={toCapitalizedWords(schemaKey)}
      />
    ));

  useEffect(() => {
    if (integrationConfigurationSchema && sortedServiceAccounts) {
      setSchemas(() =>
        Object.entries(integrationConfigurationSchema).reduce((acc, [schemaKey, tabSchema]) => {
          // augment service account tab schema
          const schema = { ...tabSchema };
          if (schemaKey === 'serviceAccount') {
            schema.properties.id.selectOptions = sortedServiceAccounts.map((account) => ({
              label: account.name,
              value: account.userId,
            }));
            if (isTemplate) {
              schema.required = ['id'];
              const requiredTitle = schema.properties.id.title.includes('*')
                ? schema.properties.id.title
                : `${schema.properties.id.title}*`;
              schema.properties.id.title = requiredTitle;
            }
          }

          // build ui schema for tab schema
          const uiSchema = {};
          Object.keys(tabSchema.properties || {}).forEach((property) => {
            if (ENDPOINT_FIELDS.includes(property)) {
              uiSchema[property] = { 'ui:field': 'inputWithTooltip' };
            }
          });
          if (schemaKey === 'serviceAccount') {
            uiSchema.id = { 'ui:field': 'inputWithTooltip' };
          }
          return { ...acc, [schemaKey]: { schema, uiSchema } };
        }, {})
      );
    }
  }, [integrationConfigurationSchema, sortedServiceAccounts]);

  const tabContent = useMemo(() => {
    if (schemas) {
      return Object.entries(schemas).map(([schemaKey, { schema: tabSchema, uiSchema }], i) => {
        const tabFormKey = `form-${i}`;
        return (
          <React.Fragment key={tabFormKey}>
            <JSONEditor
              formRef={formRef}
              title={schemaKey}
              formData={formData.configuration?.[schemaKey] || {}}
              schema={tabSchema}
              uiSchema={uiSchema}
              showButtons={false}
              initialEditMode={true}
              showNonEditForm={false}
              showEditButton={false}
              onFormChange={onChange}
              customValidate={customValidator}
            />
          </React.Fragment>
        );
      });
    }
    return null;
  }, [schemas, formData, formRef.current]);

  const onSubmitForm = useCallback(
    (e) => {
      const name = integrationName || formData.name;
      const payload = {
        ...formData,
        name,
        main: mainIntegration,
        configuration: {
          ...formData.configuration,
          [tabKey]: activeTabData,
        },
      };
      if (selectedTemplateId) {
        payload.templateId = selectedTemplateId;
      }

      // first, validate current tab (configuration)
      const { errors: tabErrors } = formRef.current.validate(activeTabData);

      // next, validate integration (non-config)
      const otherSiteIntegrations = siteIntegrations.filter((i) => i.id !== integration?.id);
      const { errors: nonConfigErrors } = validateIntegration(payload, {
        isTemplate,
        siteView,
        creating,
        otherSiteIntegrations,
      });
      if (tabErrors.length) {
        formRef.current.onSubmit(e);
        console.error({ activeConfigErrors: tabErrors });
      }
      if (nonConfigErrors) {
        setPayloadErrors(nonConfigErrors);
        console.error({ integrationErrors: nonConfigErrors });
      }
      if (!tabErrors.length && !nonConfigErrors) {
        const cleanedPayload = cleanFormData(payload);
        if (!isTemplate) {
          delete cleanedPayload.configuration.serviceAccount;
        }
        onSubmit(cleanedPayload, !!creating);
        onCloseModal();
      }
    },
    [
      formData,
      activeTabData,
      tabKey,
      creating,
      mainIntegration,
      isTemplate,
      selectedTemplateId,
      formRef.current,
      integrationName,
      integration,
      siteIntegrations,
    ]
  );

  return (
    <>
      <SimpleModal
        onClose={!hasSelectedIntegrationType ? onCloseModal : handleCancel}
        className="integration-modal"
        title={modalTitle}
        size="s"
        overlayCanClose={false}
      >
        <div className="integration-modal-header">
          {hasSelectedIntegrationType && (
            <div className="integration-modal-name">
              <h3>{isTemplate ? 'Template name*' : 'Integration name*'}</h3>
              <Input
                value={integrationName || formData.name}
                onChange={(e) => setIntegrationName(e.target.value)}
              />
              {payloadErrors.name && <p className="payload-errors">{payloadErrors.name}</p>}
            </div>
          )}
          {siteView && hasSelectedIntegrationType && creating && templatePicker}
        </div>

        <div className="integration-modal-content">
          {!hasSelectedIntegrationType && integrationTypeSelect}

          {hasSelectedIntegrationType && shouldWaitForServiceAccounts && serviceAccountsLoading && (
            <div className="integration-config-loader">
              <Spinner
                size="s"
                className="spinner"
              />
            </div>
          )}
          {(!creating || hasSelectedIntegrationType) &&
            (!shouldWaitForServiceAccounts || !serviceAccountsLoading) && (
              <>
                <HvTabs
                  id="template-tabs"
                  onChange={handleTabChange}
                  value={tabIndex}
                  className="template-tabs"
                  variant="fullWidth"
                >
                  {tabHeaders}
                </HvTabs>
                {(tabContent || [])[tabIndex]}
              </>
            )}
        </div>
        {siteView && hasSelectedIntegrationType && integrationConfigurationSchema?.asset && (
          <div
            className="main-template"
            onClick={(e) => {
              e.stopPropagation();
              onToggleMain();
            }}
          >
            <Icon
              className={`checkbox ${mainIntegration ? 'checked' : ''}`}
              icon={checkboxIcon}
            />
            Set as main integration
          </div>
        )}
        <div className="integration-modal-button-group">
          <Button
            activity="secondary"
            onClick={!hasSelectedIntegrationType ? onCloseModal : handleCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={!(integration || selectedType)}
            onClick={!hasSelectedIntegrationType ? onSelectedIntegrationType : onSubmitForm}
          >
            {!hasSelectedIntegrationType ? 'Next' : 'Save'}
          </Button>
        </div>
      </SimpleModal>

      {showCancelModal && (
        <SimpleModal
          onClose={handleCancelConfirmation}
          title={'Cancel changes'}
          size="s"
          overlayCanClose={false}
          className="integration-cancel-modal"
        >
          <p className="integration-cancel-modal-text">
            Are you sure you want to cancel? Any unsaved changes will be lost.
          </p>
          <div className="integration-modal-button-group">
            <Button
              activity="secondary"
              onClick={() => setShowCancelModal(false)}
            >
              No, keep the changes.
            </Button>
            <Button onClick={handleCancelConfirmation}>Yes</Button>
          </div>
        </SimpleModal>
      )}
    </>
  );
};

export default EditIntegrationModal;
