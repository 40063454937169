import React, { useState, useEffect } from 'react';

import ChoiceStep from './substeps/ChoiceStep';
import ImportStep from './substeps/ImportStep';
import ManageStep from './substeps/ManageStep';
import StepNavigator from '../StepNavigator';
import SubStepIndicator from '../SubStepIndicator';

const STEPS = [
  {
    component: ChoiceStep,
    name: 'choice',
    label: 'Choose a method',
  },
  {
    component: ImportStep,
    name: 'import',
    label: 'Import components',
  },
  {
    component: ManageStep,
    name: 'manage',
    label: 'Manage components',
  },
];

const ComponentStep = ({ onNext, onBack, site }) => {
  const [steps, setSteps] = useState(STEPS);
  const [step, setStep] = useState(0);
  const [choice, setChoice] = useState('import');

  const StepComponent = steps[step].component;

  const onAdvance = () => {
    if (step === steps.length - 1) {
      onNext();
      return;
    }

    setStep(step + 1);
  };

  const onPrevious = () => (step === 0 ? onBack() : setStep(step - 1));

  useEffect(() => {
    let newStep = [STEPS[0], STEPS[1], STEPS[2]];

    if (choice === 'manual') {
      newStep = [STEPS[0], STEPS[2]];
    }
    setSteps(newStep);
  }, [choice]);

  return (
    <>
      <div className="wizard-step-top">
        <div className="wizard-step-top__header">
          <h1>Set up component tree</h1>
        </div>
        <SubStepIndicator
          steps={steps.map(({ name, label }) => ({ name, label }))}
          activeStep={steps[step].name}
          setStep={setStep}
        />
      </div>

      <StepComponent
        site={site}
        importType={choice}
        setChoice={setChoice}
        onNext={onAdvance}
      />

      <StepNavigator
        nextLabel={step === 2 ? 'Done' : null}
        onBack={onPrevious}
        onNext={onAdvance}
      />
    </>
  );
};

export default ComponentStep;
