.site-detail-view-component {
  padding-left: 4rem;
}

.no-side-margin {
  padding-left: 0 !important;
}

.base-view {
  padding-left: 4rem;
  padding-top: 1px;
}
.base-view .panel {
  padding: 0 0 1rem;
  min-height: calc(100vh - 1px);
  display: flex;
  flex-direction: column;
}
.base-view .loading-container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvQmFzZVZpZXciLCJzb3VyY2VzIjpbIkJhc2VWaWV3LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLnNpdGUtZGV0YWlsLXZpZXctY29tcG9uZW50IHtcbiAgcGFkZGluZy1sZWZ0OiA0cmVtO1xufVxuXG4ubm8tc2lkZS1tYXJnaW4ge1xuICBwYWRkaW5nLWxlZnQ6IDAgIWltcG9ydGFudDtcbn1cblxuLmJhc2UtdmlldyB7XG4gIHBhZGRpbmctbGVmdDogNHJlbTtcbiAgcGFkZGluZy10b3A6IDFweDtcblxuICAucGFuZWwge1xuICAgIHBhZGRpbmc6IDAgMCAxcmVtO1xuICAgIG1pbi1oZWlnaHQ6IGNhbGMoMTAwdmggLSAxcHgpO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgfVxuXG4gIC5sb2FkaW5nLWNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiA5MHZoO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgfVxufVxuIl19 */