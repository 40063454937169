import React, { useMemo, useRef, useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns-tz';
import { Spinner, Icon, Button, Can, contrastChart } from '@iq/react-components';
import classnames from 'classnames';

import { getActiveSite } from '../../../../bundles/sites';
import { useSubscription, getSingleVisualization } from '../../../../bundles/visualizations';
import { getTimezone } from '../../../../bundles/application';
import { getSiteVariablesIndex } from '../../../../bundles/sources';
import { useClientSize } from '../../../../utils';
import { utcToSite } from '../../../../datetimeUtils';
import { format as unitFormat } from '../../../../units';

import Loader from '../../../Loader';

const DuvalVisualization = memo(
  ({
    visualization,
    panelId,
    pollingDateRange,
    onExportData,
    isPreview,
    selected,
    onSelect,
    openSignalViewer,
  }) => {
    const dispatch = useDispatch();
    const site = useSelector(getActiveSite);
    const timezone = useSelector(getTimezone);
    const variablesIndex = useSelector(getSiteVariablesIndex);

    const [open, setIsOpen] = useState(false);

    const [errors, setErrors] = useState([]);

    const [isAreaHighlighted, setIsAreaHighlighted] = useState({});

    const [showAreaTooltip, setShowAreaTooltip] = useState(false);
    const [areaTooltipPos, setAreaTooltipPos] = useState({ x: 0, y: 0 });
    const [areaTooltipText, setAreaTooltipText] = useState('');

    const [showPointTooltip, setShowPointTooltip] = useState(false);
    const [pointTooltipPos, setPointTooltipPos] = useState({ x: 0, y: 0 });
    const [pointTooltipData, setPointTooltipData] = useState({
      time: 0,
    });

    const {
      initialLoaded,
      seriesValues: values,
      loading,
    } = useSubscription(visualization.id, panelId);
    const { variables = [], configuration = {} } = visualization;
    const [{ height, width }, clientRef] = useClientSize();
    const ref = useRef(null);

    const addLegendHeight = configuration.legend.show ? -31 : 0;
    const addIsPreviewHeight = !isPreview ? -53.5 : 0;
    const addErrorHeight = errors.length ? -32 : 0;

    const largestSize = Math.min(width, height);

    const vizSize =
      largestSize && largestSize + addLegendHeight + addIsPreviewHeight + addErrorHeight - 20;

    const L = vizSize / (1 / 2) / Math.sqrt(3);
    const W = L;

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleToggleOpen = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsOpen((prev) => !prev);
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (initialLoaded && !selected) {
        dispatch(getSingleVisualization(visualization, panelId));
      }
    }, [
      panelId,
      initialLoaded,
      JSON.stringify(`${pollingDateRange.startDate}:${pollingDateRange.endDate}`),
    ]);

    const data = useMemo(() => {
      if (!values) return [];
      const hasConfig = variables.some((variable) =>
        values.some((value) => value.variable === variable.id)
      );
      if (!hasConfig) return [];
      return values.reduce((acc, { values: dataValues, name, granularity }) => {
        dataValues.forEach(([time, value, quality]) => {
          const parsedVal = parseFloat(value);
          const cleanedVal = Number.isNaN(parsedVal) ? undefined : parsedVal;

          let axisAlignedValue = cleanedVal;

          if (quality !== 1 && !isPreview) axisAlignedValue = null;

          const index = acc.findIndex((entry) => entry.time === time);
          if (index === -1) {
            const insertIndex = acc.findIndex(
              (_, i) => acc[i].time > time && (acc[i - 1] || {}).time < time
            );
            if (insertIndex === -1) {
              acc.push({
                time,
                [name]: axisAlignedValue,
                granularity,
              });
            } else {
              // eslint-disable-next-line no-param-reassign
              acc = [
                ...acc.slice(0, insertIndex),
                {
                  time,
                  [name]: axisAlignedValue,
                  granularity,
                },
                ...acc.slice(insertIndex),
              ];
            }
          } else {
            acc[index] = {
              ...acc[index],
              [name]: axisAlignedValue,
            };
          }
        });
        return acc;
      }, []);
    }, [values, variables, variablesIndex, isPreview]);

    const series = useMemo(
      () =>
        variables.map(({ id, aggregate, label, unit, decimals }) => {
          const { name } = (variablesIndex || {})[id] || {};
          const seriesId = `${id}.${aggregate}`;
          const displayUnit =
            unit === 'variable-default' ? (variablesIndex || {})[id]?.unit || 'number' : unit;

          return {
            variableId: id,
            id: seriesId,
            label: label || name,
            decimals: decimals || 'auto',
            unit: displayUnit,
            aggregate,
          };
        }),
      [variables, variablesIndex, configuration]
    );

    const rollingAverageData = useMemo(() => {
      if (!configuration.rollingAverage?.show) {
        return [];
      }
      return configuration.plots?.map((plot) => {
        let Xrolling = 0;
        let Yrolling = 0;

        return {
          color: plot.rollingAverageColor,
          data:
            series.length >= 3 &&
            data
              ?.filter((d) => {
                return d[plot.leftId] && d[plot.rightId] && d[plot.bottomId];
              })
              .map((value, index) => {
                const left = value[plot.leftId];
                const right = value[plot.rightId];
                const bottom = value[plot.bottomId];

                const X =
                  0 +
                  L * (right / (right + left + bottom) + (left / (left + bottom + right)) * 0.5);
                const Y = vizSize - (left / (left + bottom + right)) * vizSize;

                Xrolling =
                  index === 0
                    ? X
                    : configuration.rollingAverage.decay * Xrolling +
                      (1 - configuration.rollingAverage.decay) * X;

                Yrolling =
                  index === 0
                    ? Y
                    : configuration.rollingAverage.decay * Yrolling +
                      (1 - configuration.rollingAverage.decay) * Y;
                return { X: Xrolling, Y: Yrolling };
              }),
        };
      });
    }, [configuration.rollingAverage, data, L]);

    const arealines = (duvalType) => {
      switch (duvalType) {
        case 1:
          return [
            {
              from: { P1: 0, P2: 0.71 },
              to: { P1: 0.31, P2: 0.4 },
            },
            {
              from: { P1: 0.31, P2: 0.4 },
              to: { P1: 0.47, P2: 0.4 },
            },
            {
              from: { P1: 0.47, P2: 0.4 },
              to: { P1: 0.87, P2: 0 },
            },
            {
              from: { P1: 0, P2: 0.23 },
              to: { P1: 0.64, P2: 0.23 },
            },
            {
              from: { P1: 0, P2: 0.85 },
              to: { P1: 0.35, P2: 0.5 },
            },
            {
              from: { P1: 0.35, P2: 0.5 },
              to: { P1: 0.46, P2: 0.5 },
            },
            {
              from: { P1: 0.46, P2: 0.5 },
              to: { P1: 0.96, P2: 0 },
            },
            {
              from: { P1: 0.5, P2: 0.5 },
              to: { P1: 0.46, P2: 0.5 },
            },
            {
              from: { P1: 0.8, P2: 0.2 },
              to: { P1: 0.76, P2: 0.2 },
            },
            {
              from: { P1: 0.98, P2: 0.02 },
              to: { P1: 0.98, P2: 0 },
            },
          ];
        case 4:
          return [
            {
              from: { P1: 0.54, P2: 0 },
              to: { P1: 0.09, P2: 0.45 },
            },
            {
              from: { P1: 0.09, P2: 0 },
              to: { P1: 0.09, P2: 0.61 },
            },
            {
              from: { P1: 0, P2: 0.7 },
              to: { P1: 0.15, P2: 0.55 },
            },
            {
              from: { P1: 0.15, P2: 0.55 },
              to: { P1: 0.15, P2: 0.61 },
            },
            {
              from: { P1: 0.15, P2: 0.61 },
              to: { P1: 0.4, P2: 0.36 },
            },
            {
              from: { P1: 0.4, P2: 0.36 },
              to: { P1: 0.64, P2: 0.36 },
            },
            {
              from: { P1: 0.98, P2: 0.02 },
              to: { P1: 0.97, P2: 0.02 },
            },
            {
              from: { P1: 0.97, P2: 0.02 },
              to: { P1: 0.84, P2: 0.15 },
            },
            {
              from: { P1: 0.84, P2: 0.15 },
              to: { P1: 0.85, P2: 0.15 },
            },
          ];
        case 5:
          return [
            {
              from: { P1: 0, P2: 0.1 },
              to: { P1: 0.9, P2: 0.1 },
            },
            {
              from: { P1: 0.46, P2: 0 },
              to: { P1: 0.36, P2: 0.1 },
            },
            {
              from: { P1: 0.85, P2: 0 },
              to: { P1: 0.75, P2: 0.1 },
            },
            {
              from: { P1: 0.84, P2: 0.01 },
              to: { P1: 0.97, P2: 0.01 },
            },
            {
              from: { P1: 0.97, P2: 0.01 },
              to: { P1: 0.98, P2: 0 },
            },
            {
              from: { P1: 0, P2: 0.35 },
              to: { P1: 0.35, P2: 0.35 },
            },
            {
              from: { P1: 0.6, P2: 0.1 },
              to: { P1: 0, P2: 0.7 },
            },
            {
              from: { P1: 0, P2: 0.7 },
              to: { P1: 0.16, P2: 0.7 },
            },
            {
              from: { P1: 0.16, P2: 0.7 },
              to: { P1: 0.38, P2: 0.48 },
            },
            {
              from: { P1: 0.38, P2: 0.48 },
              to: { P1: 0.4, P2: 0.48 },
            },
            {
              from: { P1: 0.4, P2: 0.48 },
              to: { P1: 0.78, P2: 0.1 },
            },
            {
              from: { P1: 0.65, P2: 0.35 },
              to: { P1: 0.53, P2: 0.35 },
            },
          ];
        default:
          return [];
      }
    };

    const tooltipLabelFormatter = (time) => {
      const dateFormat = 'yyyy-MM-dd, HH:mm:ss';
      return format(utcToSite(time, timezone), dateFormat, { timeZone: timezone });
    };

    const darkenHex = (color, amount) => {
      let R = parseInt(color[1] + color[2], 16);
      let G = parseInt(color[3] + color[4], 16);
      let B = parseInt(color[5] + color[6], 16);

      R = R > amount ? R - amount : 0o0;
      G = G > amount ? G - amount : 0o0;
      B = B > amount ? B - amount : 0o0;

      const RHex = R > 16 ? R.toString(16) : `0${R.toString(16)}`;
      const BHex = B > 16 ? B.toString(16) : `0${B.toString(16)}`;
      const GHex = G > 16 ? G.toString(16) : `0${G.toString(16)}`;

      const darkenedColor = `#${RHex}${GHex}${BHex}`;
      return darkenedColor;
    };

    function DrawAreaLine({ from, to }) {
      return (
        <line
          x1={0 + L * (from.P2 + from.P1 * 0.5)}
          y1={vizSize - from.P1 * vizSize}
          x2={0 + L * (to.P2 + to.P1 * 0.5)}
          y2={vizSize - to.P1 * vizSize}
        />
      );
    }

    function DrawArea({ points, fill, name = 'No name provided' }) {
      let pointsString = '';
      points.forEach((point) => {
        pointsString += `${0 + L * (point.P2 + point.P1 * 0.5)},${vizSize - point.P1 * vizSize} `;
      });

      // This is for checking wether the latest point is inside the area or not
      const polyCorners = points.length;
      const polyX = points.map((point) => 0 + L * (point.P2 + point.P1 * 0.5));
      const polyY = points.map((point) => vizSize - point.P1 * vizSize);

      const pointInPolygon = ({ left, right, bottom }) => {
        const P1 = left / (left + right + bottom);
        const P2 = right / (left + right + bottom);

        const x = 0 + L * (P2 + P1 * 0.5);
        const y = vizSize - P1 * vizSize;
        let j = polyCorners - 1;

        let oddNodes = false;

        for (let i = 0; i < polyCorners; i += 1) {
          if ((polyY[i] < y && polyY[j] >= y) || (polyY[j] < y && polyY[i] >= y)) {
            if (polyX[i] + ((y - polyY[i]) / (polyY[j] - polyY[i])) * (polyX[j] - polyX[i]) < x) {
              oddNodes = !oddNodes;
            }
          }
          j = i;
        }

        return oddNodes;
      };

      useEffect(() => {
        if (!data.length && !configuration.plots) {
          return;
        }
        const pointIsInArea = configuration.plots?.some((plot) => {
          const filteredData = data.filter((d) => {
            return d[plot.leftId] && d[plot.rightId] && d[plot.bottomId];
          });
          if (!filteredData.length) return false;
          return pointInPolygon({
            left: filteredData[filteredData.length - 1][plot.leftId],
            right: filteredData[filteredData.length - 1][plot.rightId],
            bottom: filteredData[filteredData.length - 1][plot.bottomId],
          });
        });
        if (pointIsInArea && !isAreaHighlighted[name.slice(0, 2)]) {
          setIsAreaHighlighted((state) => {
            return { ...state, [name.slice(0, 2)]: true };
          });
        } else if (!pointIsInArea && isAreaHighlighted[name.slice(0, 2)]) {
          setIsAreaHighlighted((state) => {
            return { ...state, [name.slice(0, 2)]: false };
          });
        }
      }, []);

      function showTooltip(e) {
        if (showAreaTooltip || showPointTooltip) {
          return;
        }
        setAreaTooltipPos({ x: e.clientX, y: e.clientY });
        setAreaTooltipText(name);
        setShowAreaTooltip(true);
      }

      return (
        <>
          <polygon
            points={pointsString}
            fill={fill}
            className="area"
            onMouseOut={() => {
              setShowAreaTooltip(false);
            }}
            onMouseOver={showTooltip}
          />
        </>
      );
    }

    function DrawAreaHighlight({ points, show = false, fill = 'none', filter = '' }) {
      let pointsString = '';
      points.forEach((point) => {
        pointsString += `${0 + L * (point.P2 + point.P1 * 0.5)},${vizSize - point.P1 * vizSize} `;
      });

      return (
        <>
          <polygon
            style={{ pointerEvents: 'none' }}
            points={pointsString}
            strokeWidth={show ? '1' : '0'}
            stroke={show ? darkenHex(fill, 80) : null}
            fill={show ? fill : 'none'}
            filter={show ? filter : ''}
          />
        </>
      );
    }
    function TickLine({ P1, P2, axis }) {
      let P1Factor = 0;
      let P2Factor = 0;
      switch (axis) {
        case 'p1':
          P2Factor = -0.01;
          break;
        case 'p2':
          P1Factor = 0.01;
          break;
        case 'p3':
          P1Factor = -0.01;
          P2Factor = 0.01;
          break;
        default:
          break;
      }

      return (
        <>
          <line
            x1={`${0 + L * (P2 - P2Factor + (P1 - P1Factor) * 0.5)}`}
            y1={`${vizSize - (P1 - P1Factor) * vizSize}`}
            x2={`${0 + L * (P2 + P2Factor + (P1 + P1Factor) * 0.5)}`}
            y2={`${vizSize - (P1 + P1Factor) * vizSize}`}
            className="tick-line"
          />
        </>
      );
    }

    const PointPlot = ({ plot, duvalMode }) => {
      const leftSerie = series.filter((serie) => serie.id === plot.leftId)[0];
      const rightSerie = series.filter((serie) => serie.id === plot.rightId)[0];
      const bottomSerie = series.filter((serie) => serie.id === plot.bottomId)[0];
      if (!leftSerie || !rightSerie || !bottomSerie) {
        return null;
      }

      const filteredData = data.filter((d) => {
        return d[leftSerie.id] && d[rightSerie.id] && d[bottomSerie.id];
      });
      if (filteredData.length === 0) {
        return null;
      }
      const left = leftSerie && filteredData[filteredData.length - 1][leftSerie.id];
      const right = rightSerie && filteredData[filteredData.length - 1][rightSerie.id];
      const bottom = bottomSerie && filteredData[filteredData.length - 1][bottomSerie.id];
      switch (duvalMode) {
        case 'current':
          return (
            <Point
              P1={left / (left + right + bottom)}
              P2={right / (left + right + bottom)}
              color={plot.color}
              length={0}
              last={true}
              left={left}
              bottom={bottom}
              right={right}
              leftUnit={leftSerie.unit}
              bottomUnit={bottomSerie.unit}
              rightUnit={rightSerie.unit}
              label={plot.label}
              time={filteredData[filteredData.length - 1].time}
            />
          );
        case 'over time':
          return (
            <OverTimePointPlot
              label={plot.label}
              data={data}
              leftSerie={leftSerie}
              rightSerie={rightSerie}
              bottomSerie={bottomSerie}
              color={plot.color}
            />
          );
        default:
          return null;
      }
    };

    function Point({
      P1,
      P2,
      idx = 0,
      left = 'Missing',
      right = 'Missing',
      bottom = 'Missing',
      color = 0,
      last = false,
      length = 1,
      time = 0,
    }) {
      if (left === 0 && right === 0 && bottom === 0) {
        if (!errors.length) {
          setErrors((state) => [
            ...state,
            `All recieved values are 0. - ${tooltipLabelFormatter(time)}`,
          ]);
        }
      }
      if (left < 0 || right < 0 || bottom < 0) {
        if (!errors.length) {
          setErrors((state) => [
            ...state,
            `Negative value recieved. - ${tooltipLabelFormatter(time)}`,
          ]);
        }
      }

      return (
        <>
          {last && configuration.lines && configuration.lines.show && (
            <g className="point-lines">
              <line
                x1={`${0 + L * (P2 + P1 * 0.5)}`}
                y1={`${vizSize - P1 * vizSize}`}
                x2={`${0 + L * (0 + P1 * 0.5)}`}
                y2={`${vizSize - P1 * vizSize}`}
                strokeDasharray={'4 4'}
              />
              <line
                x1={`${0 + L * (P2 + P1 * 0.5)}`}
                y1={`${vizSize - P1 * vizSize}`}
                x2={`${0 + L * (P2 + (1 - P2) * 0.5)}`}
                y2={`${vizSize - (1 - P2) * vizSize}`}
                strokeDasharray={'4 4'}
              />
              <line
                x1={`${0 + L * (P2 + P1 * 0.5)}`}
                y1={`${vizSize - P1 * vizSize}`}
                x2={`${0 + L * (P2 + P1 + 0 * 0.5)}`}
                y2={`${vizSize - 0 * vizSize}`}
                strokeDasharray={'4 4'}
              />
              <line />
            </g>
          )}
          <circle
            r={last ? configuration.pointRadius + 2 : configuration.pointRadius}
            cx={`${0 + L * (P2 + P1 * 0.5)}`}
            cy={`${vizSize - P1 * vizSize}`}
            className="duval-point"
            strokeWidth={2}
            stroke={
              (time === pointTooltipData.time && showPointTooltip && 'black') ||
              (last && 'white') ||
              ''
            }
            style={{ opacity: `${(100 / length) * (idx + 1)}%` }}
            fill={color}
            onMouseOut={() => {
              setShowPointTooltip(false);
            }}
            onMouseOver={(e) => {
              if (showPointTooltip || showAreaTooltip) {
                return;
              }
              setPointTooltipPos({ x: e.clientX + 10, y: e.clientY + 10 });

              setPointTooltipData({
                time,
              });
              setShowPointTooltip(true);
            }}
          />
        </>
      );
    }

    const OverTimePointPlot = ({
      leftSerie = null,
      rightSerie = null,
      bottomSerie = null,
      color,
      label = '',
    }) => {
      // Filtering out missing datapoints which cannot be plotted on the duval
      const filteredData = data.filter((d) => {
        return d[leftSerie.id] && d[rightSerie.id] && d[bottomSerie.id];
      });
      return (
        leftSerie &&
        rightSerie &&
        bottomSerie &&
        filteredData.map((point, idx) => {
          const left = point[leftSerie.id];
          const right = point[rightSerie.id];
          const bottom = point[bottomSerie.id];

          return (
            <Point
              P1={left / (left + right + bottom)}
              P2={right / (left + right + bottom)}
              color={color}
              length={filteredData.length}
              idx={idx}
              key={idx + leftSerie.id + rightSerie.id + bottomSerie.id}
              label={label}
              last={idx === filteredData.length - 1}
              left={left}
              bottom={bottom}
              right={right}
              leftUnit={leftSerie.unit}
              bottomUnit={bottomSerie.unit}
              rightUnit={rightSerie.unit}
              time={point.time}
            />
          );
        })
      );
    };

    const getAreaColor = () => {
      return {
        1: {
          PD: '#FFBFBF',
          T1: '#E8CACA',
          T2: '#BFDFBF',
          T3: '#BFBFFF',
          D1: '#DFBFDF',
          D2: '#FFE8BF',
          DT: '#BFDFDF',
        },
        4: {
          O: '#E8CACA',
          S: '#BFDFBF',
          PD: '#FFBFBF',
          C: '#BFBFFF',
          Unknown: '#DFBFDF',
        },
        5: {
          O: '#E8CACA',
          S: '#DFBFDF',
          PD: '#BFBFFF',
          O2: '#E8CACA',
          T2: '#BFDFBF',
          T3: '#FFBFBF',
          C: '#FFE8BF',
          Unknown: 'transparent',
        },
      };
    };

    const areas = (duvalType) => {
      switch (duvalType) {
        case 1:
          return [
            {
              points: [
                {
                  P1: 0,
                  P2: 0,
                },
                {
                  P1: 0,
                  P2: 0.23,
                },
                {
                  P1: 0.64,
                  P2: 0.23,
                },
                {
                  P1: 0.87,
                  P2: 0,
                },
              ],
              name: 'D1 - Low energy discharge (sparking)',
              fill: getAreaColor()['1'].D1,
            },
            {
              points: [
                {
                  P1: 0,
                  P2: 0.23,
                },
                {
                  P1: 0,
                  P2: 0.71,
                },
                {
                  P1: 0.31,
                  P2: 0.4,
                },
                {
                  P1: 0.47,
                  P2: 0.4,
                },
                {
                  P1: 0.64,
                  P2: 0.23,
                },
              ],
              name: 'D2 - High energy discharge (arcing)',
              fill: getAreaColor()['1'].D2,
            },
            {
              points: [
                {
                  P1: 0,
                  P2: 0.71,
                },
                {
                  P1: 0,
                  P2: 0.85,
                },
                {
                  P1: 0.35,
                  P2: 0.5,
                },
                {
                  P1: 0.46,
                  P2: 0.5,
                },
                {
                  P1: 0.96,
                  P2: 0.0,
                },
                {
                  P1: 0.87,
                  P2: 0.0,
                },
                {
                  P1: 0.47,
                  P2: 0.4,
                },
                {
                  P1: 0.31,
                  P2: 0.4,
                },
              ],
              name: 'DT - Mixture of thermal and electrical faults',
              fill: getAreaColor()['1'].DT,
            },
            {
              points: [
                {
                  P1: 0.76,
                  P2: 0.2,
                },
                {
                  P1: 0.8,
                  P2: 0.2,
                },
                {
                  P1: 0.98,
                  P2: 0.02,
                },
                {
                  P1: 0.98,
                  P2: 0.0,
                },
                {
                  P1: 0.96,
                  P2: 0.0,
                },
              ],
              name: 'T1 - Thermal fault below 300 degrees celcius',
              fill: getAreaColor()['1'].T1,
            },
            {
              points: [
                {
                  P1: 0.46,
                  P2: 0.5,
                },
                {
                  P1: 0.5,
                  P2: 0.5,
                },
                {
                  P1: 0.8,
                  P2: 0.2,
                },
                {
                  P1: 0.76,
                  P2: 0.2,
                },
              ],
              name: 'T2 - Thermal fault between 300 and 700 degrees celcius',
              fill: getAreaColor()['1'].T2,
            },
            {
              points: [
                {
                  P1: 0.0,
                  P2: 0.85,
                },
                {
                  P1: 0,
                  P2: 1,
                },
                {
                  P1: 0.5,
                  P2: 0.5,
                },
                {
                  P1: 0.35,
                  P2: 0.5,
                },
              ],
              name: 'T3 - Thermal fault above 700 degrees celcius',
              fill: getAreaColor()['1'].T3,
            },
            {
              points: [
                {
                  P1: 0.98,
                  P2: 0.02,
                },
                {
                  P1: 1,
                  P2: 0,
                },
                {
                  P1: 0.98,
                  P2: 0,
                },
              ],
              name: 'PD - Partial discharge',
              fill: getAreaColor()['1'].PD,
            },
          ];
        case 4:
          return [
            {
              points: [
                {
                  P1: 0.09,
                  P2: 0,
                },
                {
                  P1: 0.09,
                  P2: 0.61,
                },
                {
                  P1: 0,
                  P2: 0.7,
                },
                {
                  P1: 0,
                  P2: 0,
                },
              ],
              name: 'O - Overheating',
              fill: getAreaColor()['4'].O,
            },
            {
              points: [
                {
                  P1: 0.54,
                  P2: 0,
                },
                {
                  P1: 1,
                  P2: 0,
                },
                {
                  P1: 0.98,
                  P2: 0.02,
                },
                {
                  P1: 0.97,
                  P2: 0.02,
                },
                {
                  P1: 0.84,
                  P2: 0.15,
                },
                {
                  P1: 0.85,
                  P2: 0.15,
                },
                {
                  P1: 0.64,
                  P2: 0.36,
                },
                {
                  P1: 0.4,
                  P2: 0.36,
                },
                {
                  P1: 0.15,
                  P2: 0.61,
                },
                {
                  P1: 0.15,
                  P2: 0.55,
                },
                {
                  P1: 0.09,
                  P2: 0.61,
                },
                {
                  P1: 0.09,
                  P2: 0.45,
                },
              ],
              name: 'S - Stray gassing',
              fill: getAreaColor()['4'].S,
            },
            {
              points: [
                {
                  P1: 0.98,
                  P2: 0.02,
                },
                {
                  P1: 0.97,
                  P2: 0.02,
                },
                {
                  P1: 0.84,
                  P2: 0.15,
                },
                {
                  P1: 0.85,
                  P2: 0.15,
                },
              ],
              name: 'PD - Partial Discharge',
              fill: getAreaColor()['4'].PD,
            },
            {
              points: [
                {
                  P1: 0.64,
                  P2: 0.36,
                },
                {
                  P1: 0.4,
                  P2: 0.36,
                },
                {
                  P1: 0.15,
                  P2: 0.61,
                },
                {
                  P1: 0.15,
                  P2: 0.55,
                },
                {
                  P1: 0.0,
                  P2: 0.7,
                },
                {
                  P1: 0,
                  P2: 1,
                },
              ],
              name: 'C - Carbonisation',
              fill: getAreaColor()['4'].C,
            },
            {
              points: [
                {
                  P1: 0.54,
                  P2: 0,
                },
                {
                  P1: 0.09,
                  P2: 0.45,
                },
                {
                  P1: 0.09,
                  P2: 0,
                },
              ],
              name: 'NA - Unknown',
              fill: getAreaColor()['4'].Unknown,
            },
          ];
        case 5:
          return [
            {
              points: [
                {
                  P1: 0,
                  P2: 0,
                },
                {
                  P1: 0.46,
                  P2: 0,
                },
                {
                  P1: 0.36,
                  P2: 0.1,
                },
                {
                  P1: 0,
                  P2: 0.1,
                },
              ],
              name: 'O - Overheating',
              fill: getAreaColor()['5'].O,
            },
            {
              points: [
                {
                  P1: 0.46,
                  P2: 0,
                },
                {
                  P1: 0.36,
                  P2: 0.1,
                },
                {
                  P1: 0.75,
                  P2: 0.1,
                },
                {
                  P1: 0.85,
                  P2: 0,
                },
              ],
              name: 'S - Stray Gassing',
              fill: getAreaColor()['5'].S,
            },
            {
              points: [
                {
                  P1: 0.85,
                  P2: 0,
                },
                {
                  P1: 0.84,
                  P2: 0.01,
                },
                {
                  P1: 0.97,
                  P2: 0.01,
                },
                {
                  P1: 0.98,
                  P2: 0,
                },
              ],
              name: 'PD - Partial Discharge',
              fill: getAreaColor()['5'].PD,
            },
            {
              points: [
                {
                  P1: 0.84,
                  P2: 0.01,
                },
                {
                  P1: 0.75,
                  P2: 0.1,
                },
                {
                  P1: 0.9,
                  P2: 0.1,
                },
                {
                  P1: 1,
                  P2: 0,
                },
                {
                  P1: 0.98,
                  P2: 0,
                },
                {
                  P1: 0.97,
                  P2: 0.01,
                },
                {
                  P1: 0.84,
                  P2: 0.01,
                },
                {
                  P1: 0.85,
                  P2: 0,
                },
              ],
              name: 'O2 - Overheating 2',
              fill: getAreaColor()['5'].O2,
            },
            {
              points: [
                {
                  P1: 0.9,
                  P2: 0.1,
                },
                {
                  P1: 0.78,
                  P2: 0.1,
                },
                {
                  P1: 0.53,
                  P2: 0.35,
                },
                {
                  P1: 0.65,
                  P2: 0.35,
                },
              ],
              name: 'T2 - Thermal Fault',
              fill: getAreaColor()['5'].T2,
            },
            {
              points: [
                {
                  P1: 0.65,
                  P2: 0.35,
                },
                {
                  P1: 0.53,
                  P2: 0.35,
                },
                {
                  P1: 0.4,
                  P2: 0.48,
                },
                {
                  P1: 0.38,
                  P2: 0.48,
                },
                {
                  P1: 0.16,
                  P2: 0.7,
                },
                {
                  P1: 0,
                  P2: 0.7,
                },
                {
                  P1: 0.35,
                  P2: 0.35,
                },
                {
                  P1: 0,
                  P2: 0.35,
                },
                {
                  P1: 0,
                  P2: 1,
                },
              ],
              name: 'T3 - Thermal Fault',
              fill: getAreaColor()['5'].T3,
            },
            {
              points: [
                {
                  P1: 0.53,
                  P2: 0.35,
                },
                {
                  P1: 0.4,
                  P2: 0.48,
                },
                {
                  P1: 0.38,
                  P2: 0.48,
                },
                {
                  P1: 0.16,
                  P2: 0.7,
                },
                {
                  P1: 0,
                  P2: 0.7,
                },
                {
                  P1: 0.6,
                  P2: 0.1,
                },
                {
                  P1: 0.78,
                  P2: 0.1,
                },
              ],
              name: 'C - Carbonisation',
              fill: getAreaColor()['5'].C,
            },
            {
              points: [
                {
                  P1: 0,
                  P2: 0.35,
                },
                {
                  P1: 0.35,
                  P2: 0.35,
                },
                {
                  P1: 0.6,
                  P2: 0.1,
                },
                {
                  P1: 0,
                  P2: 0.1,
                },
              ],
              name: 'Unknown',
              fill: getAreaColor()['5'].Unknown,
            },
          ];
        default:
          return [];
      }
    };

    function getCurrentArea({ left, right, bottom }) {
      const currentArea = areas(configuration.duvalType || 1).find((area) => {
        const polyCorners = area.points.length;
        const polyX = area.points.map((point) => 0 + L * (point.P2 + point.P1 * 0.5));
        const polyY = area.points.map((point) => vizSize - point.P1 * vizSize);

        const P1 = left / (left + right + bottom);
        const P2 = right / (left + right + bottom);

        const x = 0 + L * (P2 + P1 * 0.5);
        const y = vizSize - P1 * vizSize;
        let j = polyCorners - 1;

        let oddNodes = false;

        for (let i = 0; i < polyCorners; i += 1) {
          if ((polyY[i] < y && polyY[j] >= y) || (polyY[j] < y && polyY[i] >= y)) {
            if (polyX[i] + ((y - polyY[i]) / (polyY[j] - polyY[i])) * (polyX[j] - polyX[i]) < x) {
              oddNodes = !oddNodes;
            }
          }
          j = i;
        }
        return oddNodes;
      });
      return currentArea.name.slice(0, 2);
    }

    const axislabels = (duvalType) => {
      switch (duvalType) {
        case 1:
          return { left: 'CH4', right: 'C2H4', base: 'C2H2' };
        case 4:
          return { left: 'H2', right: 'CH4', base: 'C2H6' };
        case 5:
          return { left: 'CH4', right: 'C2H4', base: 'C2H6' };
        default:
          return { left: 'CH4', right: 'C2H4', base: 'C2H2' };
      }
    };

    const handleDownloadClick = () => {
      onExportData(data[0].granularity);
    };
    const handleSelect = () => {
      onSelect(visualization);
    };
    const classes = classnames('menu', { open });

    const vizHeight = height - 28; // less header
    const loaderHeight = vizHeight - 28; // less loader padding

    if (series.length < 3) {
      return (
        <div className="duval-error-container">
          At least 3 Signals are required to create a duval plot.
        </div>
      );
    }

    return (
      <div
        className={`duval-visualization-component ${selected ? 'checked' : ''}`}
        ref={clientRef}
      >
        {!isPreview ? (
          <div
            className="duval-header"
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="header-title">
              <div
                className={`select ${selected ? 'checked' : ''}`}
                onClick={handleSelect}
              >
                <Icon
                  icon={`${selected ? 'he-checkbox-selected' : 'he-checkbox'}`}
                  size="s"
                />
              </div>
              <span className="title">{configuration.title || visualization.name || '\u00A0'}</span>
            </div>

            <div className={classes}>
              <div ref={ref}>
                <Button
                  design="text"
                  tooltip="More...  "
                  onClick={handleToggleOpen}
                >
                  <Icon
                    icon="he-moreoptionsvertical"
                    size="s"
                  />
                </Button>
              </div>
              <div className="content">
                <div className="choices">
                  {!loading && !isPreview && data?.length > 0 && (
                    <Can
                      permission="variables/Write"
                      scope={{
                        org: site.org,
                        site: site.id,
                      }}
                    >
                      <div
                        className="choice"
                        onClick={handleDownloadClick}
                      >
                        Download
                      </div>
                    </Can>
                  )}

                  <div
                    className="choice"
                    onClick={() => openSignalViewer(visualization)}
                  >
                    Open in Signal Viewer
                  </div>
                </div>
              </div>
            </div>

            {initialLoaded && loading && (
              <Spinner
                size="s"
                className="spinner"
              />
            )}
          </div>
        ) : (
          <div className="duval-preview-title">
            <span className="title">{configuration.title || visualization.name || '\u00A0'}</span>
          </div>
        )}
        {!initialLoaded && loading && data.length === 0 ? (
          <Loader
            text="Loading ..."
            height={loaderHeight}
          />
        ) : (
          <div className="duval-wrapper">
            {showAreaTooltip && (
              <div
                className="tooltip"
                style={{ left: areaTooltipPos.x, top: areaTooltipPos.y }}
              >
                {areaTooltipText}
              </div>
            )}
            {showPointTooltip && (
              <div
                // Positioning tooltip so it doesn't overflow screen
                ref={(el) => {
                  if (!el) return;

                  if (
                    window.innerWidth - pointTooltipPos.x - el.getBoundingClientRect().width <
                    0
                  ) {
                    setPointTooltipPos((state) => {
                      return {
                        ...state,
                        x: pointTooltipPos.x - el.getBoundingClientRect().width - 20,
                      };
                    });
                  }

                  if (
                    window.innerHeight - pointTooltipPos.y - el.getBoundingClientRect().height <
                    0
                  ) {
                    setPointTooltipPos((state) => {
                      return {
                        ...state,
                        y: pointTooltipPos.y - el.getBoundingClientRect().height - 20,
                      };
                    });
                  }
                }}
                className="tooltip"
                style={{
                  left: pointTooltipPos.x,
                  top: pointTooltipPos.y,
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th colSpan={5}> {tooltipLabelFormatter(pointTooltipData.time)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Plot</th>
                      <th>{axislabels(configuration.duvalType || 1).left}</th>
                      <th>{axislabels(configuration.duvalType || 1).right}</th>
                      <th>{axislabels(configuration.duvalType || 1).base}</th>
                      <th>Area</th>
                    </tr>

                    {configuration.plots.map((plot, i) => {
                      const leftSerie = series.filter((serie) => serie.id === plot.leftId)[0];
                      const rightSerie = series.filter((serie) => serie.id === plot.rightId)[0];
                      const bottomSerie = series.filter((serie) => serie.id === plot.bottomId)[0];

                      const timeData =
                        leftSerie &&
                        bottomSerie &&
                        rightSerie &&
                        data.find((d) => d.time === pointTooltipData.time);

                      const left = timeData[leftSerie.id];
                      const bottom = timeData[bottomSerie.id];
                      const right = timeData[rightSerie.id];
                      if (!left || !bottom || !right) {
                        return null;
                      }
                      return (
                        <tr
                          style={{ color: plot.color }}
                          key={i}
                        >
                          <td>{plot.label ? plot.label : 'No label'}</td>
                          <td>
                            {`${unitFormat(leftSerie.unit, left, leftSerie.decimals).value} ${
                              unitFormat(leftSerie.unit, left).unit
                            }`}
                          </td>
                          <td>
                            {`${unitFormat(rightSerie.unit, right, rightSerie.decimals).value} ${
                              unitFormat(rightSerie.unit, right).unit
                            }`}
                          </td>
                          <td>
                            {`${unitFormat(bottomSerie.unit, bottom, bottomSerie.decimals).value} ${
                              unitFormat(bottomSerie.unit, bottom).unit
                            }`}
                          </td>
                          <td>{getCurrentArea({ left, right, bottom })}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            <div width="100%">
              <div
                className="duval-container"
                onMouseOut={() => {
                  setShowAreaTooltip(false);
                  setShowPointTooltip(false);
                }}
              >
                <svg
                  id={`duval${visualization.id}`}
                  className="duval-surface"
                  width={W}
                  height={vizSize - 20 * -1}
                >
                  <g name="labels">
                    <defs>
                      <marker
                        className="axis-label-arrow"
                        id="arrow"
                        viewBox="0 0 10 10"
                        refX="3"
                        refY="5"
                        markerWidth="6"
                        markerHeight="6"
                        orient="auto-start-reverse"
                      >
                        <path d="M 0 0 L 10 5 L 0 10 L 3 5 z" />
                      </marker>
                    </defs>
                    <path
                      id={`LeftPath${visualization.id}${isPreview ? '2' : '1'}`}
                      d={`M ${0 + L * (-0.045 + 0.3 * 0.5)},${vizSize - 0.3 * vizSize}
                                  L ${0 + L * (-0.045 + 0.7 * 0.5)},${vizSize - 0.7 * vizSize}`}
                    />
                    <path
                      markerEnd="url(#arrow)"
                      className="axis-label-line"
                      d={`M ${0 + L * (0 - 0.032 * 0.5)},${vizSize - 0.032 * vizSize}
                                  L ${0 + L * (-0.032 + 1 * 0.5)},${vizSize - 1 * vizSize}`}
                    />
                    <text
                      textAnchor="middle"
                      className="axis-label"
                    >
                      <textPath
                        href={`#LeftPath${visualization.id}${isPreview ? '2' : '1'}`}
                        startOffset="55%"
                      >
                        {axislabels(configuration.duvalType || 1).left} %
                      </textPath>
                    </text>
                    <path
                      id={`RightPath${visualization.id}${isPreview ? '2' : '1'}`}
                      d={`M ${0 + L * (0.3 + 0.745 * 0.5)},${vizSize - 0.745 * vizSize}
                                  L ${0 + L * (0.7 + 0.345 * 0.5)},${vizSize - 0.345 * vizSize}`}
                    />
                    <path
                      markerEnd="url(#arrow)"
                      className="axis-label-line"
                      d={`M ${0 + L * (0.032 + 1.0 * 0.5)},${vizSize - 1 * vizSize}
                                  L ${0 + L * (1 + 0.032 * 0.5)},${vizSize - 0.032 * vizSize}`}
                    />
                    <text
                      textAnchor="middle"
                      className="axis-label"
                    >
                      <textPath
                        href={`#RightPath${visualization.id}${isPreview ? '2' : '1'}`}
                        startOffset="55%"
                      >
                        {axislabels(configuration.duvalType || 1).right} %
                      </textPath>
                    </text>
                    <path
                      id={`BottomPath${visualization.id}${isPreview ? '2' : '1'}`}
                      d={`M ${0 + L * (0.345 + -0.045 * 0.5)},${vizSize - -0.045 * vizSize}
                                  L ${0 + L * (0.745 + -0.045 * 0.5)},${vizSize - -0.045 * vizSize}`}
                    />
                    {0 + L * (0 + 0.032 * 0.5)},${vizSize - -0.032 * vizSize}
                    <path
                      markerEnd="url(#arrow)"
                      className="axis-label-line"
                      d={`M ${0 + L * (1 + -0.032 * 0.5)},${vizSize - -0.032 * vizSize}
                                  L ${0 + L * (0 + 0.032 * 0.5)},${vizSize - -0.032 * vizSize}`}
                    />
                    <text
                      textAnchor="middle"
                      transform="translate(0 11)"
                      className="axis-label"
                    >
                      <textPath
                        href={`#BottomPath${visualization.id}${isPreview ? '2' : '1'}`}
                        startOffset="45%"
                        textAnchor="middle"
                      >
                        {axislabels(configuration.duvalType || 1).base} %
                      </textPath>
                    </text>
                  </g>

                  <g name="Areas">
                    {areas(configuration.duvalType || 1).map((area) => {
                      return (
                        <DrawArea
                          points={area.points}
                          fill={area.fill}
                          name={area.name}
                          key={area.name}
                        />
                      );
                    })}
                  </g>

                  <g
                    className={`area-lines area-lines-stroke`}
                    name="area-lines"
                    strokeOpacity="0.5"
                    strokeWidth={1}
                  >
                    {arealines(configuration.duvalType || 1).map((line, idx) => {
                      return (
                        <DrawAreaLine
                          key={idx}
                          from={line.from}
                          to={line.to}
                        />
                      );
                    })}
                  </g>
                  {configuration.highlightAreas?.show && (
                    <g
                      name="Area-highlights"
                      className="area-highlights"
                    >
                      <defs>
                        <filter
                          id="highlightFilter"
                          width="120"
                          height="120"
                        >
                          <feFlood
                            result="flood"
                            floodColor="#ffffff"
                            floodOpacity="1"
                          ></feFlood>
                          <feComposite
                            in="flood"
                            result="mask"
                            in2="SourceGraphic"
                            operator="in"
                          ></feComposite>
                          <feMorphology
                            in="mask"
                            result="dilated"
                            operator="dilate"
                            radius="1"
                          ></feMorphology>
                          <feGaussianBlur
                            in="dilated"
                            result="blurred"
                            stdDeviation="1"
                          ></feGaussianBlur>
                          <feMerge>
                            <feMergeNode in="blurred"></feMergeNode>
                            <feMergeNode in="SourceGraphic"></feMergeNode>
                          </feMerge>
                        </filter>
                      </defs>
                      {areas(configuration.duvalType || 1).map((area) => {
                        return (
                          <DrawAreaHighlight
                            points={area.points}
                            show={isAreaHighlighted[area.name.slice(0, 2)]}
                            key={area.name}
                            fill={area.fill}
                            filter="url(#highlightFilter)"
                          />
                        );
                      })}
                    </g>
                  )}
                  <g
                    className="triangle-side"
                    fill="none"
                  >
                    <line
                      x1="0%"
                      y1={vizSize}
                      x2="50%"
                      y2="0%"
                    />
                    <line
                      x1="0%"
                      y1={vizSize}
                      x2="100%"
                      y2={vizSize}
                    />
                    <line
                      x1="100%"
                      y1={vizSize}
                      x2="50%"
                      y2="0%"
                    />
                  </g>
                  <g
                    name="Ticklines"
                    className="tick-lines"
                  >
                    <g>
                      <TickLine
                        P1={0.1}
                        P2={0}
                        axis="p1"
                      />
                      <TickLine
                        P1={0.2}
                        P2={0}
                        axis="p1"
                      />
                      <TickLine
                        P1={0.3}
                        P2={0}
                        axis="p1"
                      />
                      <TickLine
                        P1={0.4}
                        P2={0}
                        axis="p1"
                      />
                      <TickLine
                        P1={0.5}
                        P2={0}
                        axis="p1"
                      />
                      <TickLine
                        P1={0.6}
                        P2={0}
                        axis="p1"
                      />
                      <TickLine
                        P1={0.7}
                        P2={0}
                        axis="p1"
                      />
                      <TickLine
                        P1={0.8}
                        P2={0}
                        axis="p1"
                      />
                      <TickLine
                        P1={0.9}
                        P2={0}
                        axis="p1"
                      />
                    </g>

                    <g>
                      <TickLine
                        P1={0.9}
                        P2={0.1}
                        axis="p2"
                      />
                      <TickLine
                        P1={0.8}
                        P2={0.2}
                        axis="p2"
                      />
                      <TickLine
                        P1={0.7}
                        P2={0.3}
                        axis="p2"
                      />
                      <TickLine
                        P1={0.6}
                        P2={0.4}
                        axis="p2"
                      />
                      <TickLine
                        P1={0.5}
                        P2={0.5}
                        axis="p2"
                      />
                      <TickLine
                        P1={0.4}
                        P2={0.6}
                        axis="p2"
                      />
                      <TickLine
                        P1={0.3}
                        P2={0.7}
                        axis="p2"
                      />
                      <TickLine
                        P1={0.2}
                        P2={0.8}
                        axis="p2"
                      />
                      <TickLine
                        P1={0.1}
                        P2={0.9}
                        axis="p2"
                      />
                    </g>

                    <g>
                      <TickLine
                        P1={0}
                        P2={0.1}
                        axis="p3"
                      />
                      <TickLine
                        P1={0}
                        P2={0.2}
                        axis="p3"
                      />
                      <TickLine
                        P1={0}
                        P2={0.3}
                        axis="p3"
                      />
                      <TickLine
                        P1={0}
                        P2={0.4}
                        axis="p3"
                      />
                      <TickLine
                        P1={0}
                        P2={0.5}
                        axis="p3"
                      />
                      <TickLine
                        P1={0}
                        P2={0.6}
                        axis="p3"
                      />
                      <TickLine
                        P1={0}
                        P2={0.7}
                        axis="p3"
                      />
                      <TickLine
                        P1={0}
                        P2={0.8}
                        axis="p3"
                      />
                      <TickLine
                        P1={0}
                        P2={0.9}
                        axis="p3"
                      />
                    </g>
                  </g>

                  {configuration.plots &&
                    configuration.plots.map((plot, idx) => {
                      return (
                        <PointPlot
                          key={idx}
                          plot={plot}
                          duvalMode={configuration.duvalMode ? configuration.duvalMode : ''}
                        />
                      );
                    })}

                  {configuration.rollingAverage?.show && (
                    <g>
                      {rollingAverageData?.map((rollingPlot, idx) => (
                        <g key={idx}>
                          {configuration.duvalMode === 'over time' ? (
                            <g
                              name="rolling-plot"
                              style={{ pointerEvents: 'none' }}
                            >
                              {rollingPlot.data.length &&
                                rollingPlot.data.map((value, i) => {
                                  if (configuration.rollingAverage?.type === 'Lines') {
                                    if (i === rollingPlot.data.length - 1) {
                                      return null;
                                    }
                                    return (
                                      <line
                                        key={i}
                                        x1={value.X}
                                        y1={value.Y}
                                        x2={rollingPlot.data[i + 1].X}
                                        y2={rollingPlot.data[i + 1].Y}
                                        stroke={rollingPlot.color || contrastChart[1]}
                                        strokeWidth={configuration.pointRadius / 3}
                                        opacity={((100 / rollingPlot.data.length) * i) / 100}
                                      />
                                    );
                                  }
                                  if (configuration.rollingAverage?.type === 'Both') {
                                    return (
                                      <g key={i}>
                                        {i !== rollingPlot.data.length - 1 && (
                                          <line
                                            x1={value.X}
                                            y1={value.Y}
                                            x2={rollingPlot.data[i + 1].X}
                                            y2={rollingPlot.data[i + 1].Y}
                                            stroke={rollingPlot.color || contrastChart[1]}
                                            strokeWidth={configuration.pointRadius / 3}
                                            opacity={((100 / rollingPlot.data.length) * i) / 100}
                                          />
                                        )}
                                        <circle
                                          cx={value.X}
                                          cy={value.Y}
                                          r={configuration.pointRadius / 2}
                                          opacity={((100 / rollingPlot.data.length) * i) / 100}
                                          fill={rollingPlot.color || contrastChart[1]}
                                          stroke=""
                                        />
                                      </g>
                                    );
                                  }

                                  return (
                                    <circle
                                      key={i}
                                      cx={value.X}
                                      cy={value.Y}
                                      r={configuration.pointRadius / 2}
                                      opacity={((100 / rollingPlot.data.length) * i) / 100}
                                      fill={rollingPlot.color || contrastChart[1]}
                                      stroke=""
                                    />
                                  );
                                })}
                            </g>
                          ) : (
                            <g
                              name="rolling-plot"
                              style={{ pointerEvents: 'none' }}
                            >
                              {rollingPlot.length && (
                                <circle
                                  cx={rollingPlot[rollingPlot.length - 1].X}
                                  cy={rollingPlot[rollingPlot.length - 1].Y}
                                  r={2}
                                  fill={rollingPlot.color}
                                  stroke=""
                                />
                              )}
                            </g>
                          )}
                        </g>
                      ))}
                    </g>
                  )}
                </svg>

                {errors.length ? (
                  <div style={{ textAlign: 'center', maxWidth: width }}>
                    Signal Error: {errors[0]}
                  </div>
                ) : null}
                {configuration.legend && configuration.legend.show && (
                  <div className="duval-legend">
                    {visualization.configuration.plots &&
                      visualization.configuration.plots.map((plot, idx) => {
                        return (
                          <div
                            key={idx}
                            className="duval-legend-item"
                          >
                            <svg
                              width="10"
                              height="10"
                              style={{ marginRight: '0.5rem' }}
                            >
                              <rect
                                width="10"
                                height="10"
                                fill={plot.color}
                              />
                            </svg>
                            {plot.label}
                          </div>
                        );
                      })}
                    {configuration.rollingAverage?.show &&
                      configuration.plots?.map((plot, idx) => {
                        return (
                          <div
                            key={idx}
                            className="duval-legend-item"
                          >
                            <svg
                              width="10"
                              height="10"
                              style={{ marginRight: '0.5rem' }}
                            >
                              <rect
                                width="10"
                                height="10"
                                fill={plot.rollingAverageColor}
                              />
                            </svg>
                            {plot.label} rolling average {`(${configuration.rollingAverage.decay})`}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
  (prevProps, props) => JSON.stringify(prevProps) === JSON.stringify(props)
);

export default DuvalVisualization;
