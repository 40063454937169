import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Icon, Can, Button, Spinner } from '@iq/react-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  importSource,
  getImportableSourceTypes,
  getInjectableSourceTypes,
  uploadAOExcel,
  requestSources,
} from '../../../../../bundles/sources';
import { INTERNAL_DATA_SOURCE } from '../../../../../constants';
import JSONEditor from '../../../../JSONEditor';
import TypeLabel from '../../../../TypeLabel';
import StyledItem from '../../../../StyledItem';
import ConfirmationDialog from '../../../../ConfirmationDialog';

const SourceListItem = ({
  site = {},
  title,
  isOpen: isColumnOpen,
  source,
  schema,
  onDelete,
  onSubmit,
  transformErrors,
  customFormats,
  customValidate,
}) => {
  const [isImporting, setIsImporting] = useState(false);
  const [isImportingAO, setIsImportingAO] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(isColumnOpen);
  const importTypes = useSelector(getImportableSourceTypes);
  const aoTypes = useSelector(getInjectableSourceTypes);
  const inputRef = useRef(null);
  const updatedSource = source;
  if (source?.options?.importedFileName) {
    updatedSource.importedFileName = source.options.importedFileName;
  }
  if (source?.options?.riskStatusEnabled !== undefined) {
    updatedSource.fetchRiskStatus = source.options.riskStatusEnabled ? 'Enabled' : 'Disabled';
  }
  const onImport = () => {
    setIsImporting(true);
    dispatch(importSource(site.id, source.id, () => setIsImporting(false)));
  };

  useEffect(() => {
    setOpen(isColumnOpen);
  }, [isColumnOpen]);

  const onUpdateSourceProps = useCallback(
    ({ formData }) => {
      const fdCopy = { ...formData };
      if (fdCopy.options) {
        Object.keys(fdCopy.options).forEach((optKey) => {
          if (
            !fdCopy.options[optKey] &&
            fdCopy.options[optKey] !== false &&
            !(schema.options?.required || []).includes(optKey)
          ) {
            delete fdCopy.options[optKey];
          }
        });
      }
      onSubmit(source.id, formData);
    },
    [onSubmit, source.id]
  );

  const importDisabled =
    isImporting ||
    (source.type === 'custom' &&
      !(source.options?.import?.endpoint && source.options?.import?.enabled));

  const fileImport = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.value = '';
    inputRef.current.click();
  };
  const aoParse = (e) => {
    setIsImportingAO(true);
    const fileObj = e.target.files && e.target.files[0];
    dispatch(
      uploadAOExcel(source.id, fileObj, site.id, site.org, () => {
        dispatch(requestSources(site.id));
        setIsImportingAO(false);
      })
    );
  };

  return (
    <StyledItem
      itemClass="source-list-item"
      active={isOpen}
      onClick={() => setOpen(!isOpen)}
      headerLabel={<TypeLabel text={source.type} />}
      headerContent={<div className="title">{title}</div>}
      headerActions={<Icon icon={isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'} />}
      bodyActions={
        source.type !== INTERNAL_DATA_SOURCE ? (
          <Can
            permission="sources/Delete"
            scope={{ org: site.org, site: site.id }}
          >
            <Button
              activity="secondary"
              onClick={() => setConfirmDelete(true)}
            >
              Delete source
            </Button>
          </Can>
        ) : null
      }
      bodyActionsPosition="center"
    >
      <JSONEditor
        title="Source properties"
        formData={source}
        schema={schema}
        initialEditMode={false}
        ignoreKeys={['options', 'variables']}
        onFormSubmit={onUpdateSourceProps}
        saveButtonText={'Update source'}
        customTransformErrors={(errors) => transformErrors(errors)}
        customFormats={customFormats}
        customValidate={customValidate}
      />
      {confirmDelete && (
        <ConfirmationDialog
          onCancel={() => setConfirmDelete(false)}
          onConfirm={(e) => {
            e.preventDefault();
            onDelete(source.id);
            setConfirmDelete(false);
          }}
          confirmType="danger"
          title="Remove source"
          body={
            'This action is irreversible and may affect visualizations which depend on this data source. Do you want to proceed?'
          }
          confirmText="Remove"
        />
      )}
      {importTypes.includes(source.type) && (
        <Can
          permission="components/Write"
          scope={{ org: site.org, site: site.id }}
        >
          <Button
            onClick={onImport}
            title="Import components"
            disabled={importDisabled}
          >
            {!isImporting && 'Import signals'}
            {isImporting && <Spinner />}
          </Button>
        </Can>
      )}
      {aoTypes.includes(source.type) && (
        <>
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            onChange={(e) => aoParse(e)}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <Can
            permission="sources/Write"
            scope={{ org: site.org, site: site.id }}
          >
            <Button
              icon={
                !isImportingAO && (
                  <Icon
                    style={{ width: '80%' }}
                    icon="he-upload"
                  />
                )
              }
              tooltip={'Upload Event Analyzer Configuration File'}
              activity="primary"
              onClick={() => fileImport()}
            >
              {!isImportingAO && <div className="button-text">Event Analyzer</div>}
              {isImportingAO && <Spinner />}
            </Button>
          </Can>
        </>
      )}
    </StyledItem>
  );
};

export default SourceListItem;
