import React from 'react';

const UserBadge = ({ first, last, defaultName = 'A' }) => {
  let initials = defaultName;
  let name = defaultName;
  if (first) {
    initials = first[0].toUpperCase();
    name = first;

    if (last) {
      initials = initials.concat(last[0].toUpperCase());
      name = name.concat(` ${last}`);
    }
  } else if (last) {
    initials = last[0].toUpperCase();
    name = last;
  }

  return (
    <div
      className="user-badge"
      title={name}
    >
      {initials}
    </div>
  );
};

export default UserBadge;
