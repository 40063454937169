import React, { useEffect, useLayoutEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Button, Icon } from '@iq/react-components';

import {
  setActiveComponentId,
  setActiveModelId,
  setZoomComponentId,
} from '../../../../../bundles/application';

import TreeItem from './TreeItem';

const ComponentTree = ({
  activeModelId,
  autoLoad = false,
  canDelete,
  canEdit,
  componentTree = {},
  displayStatus: shouldDisplayStatus = false,
  endingDepth,
  expandedDepth,
  flat = false,
  modelsHashmap,
  viewerReady,
  onCreate,
  onDelete,
  onUpdate,
  pageIndex,
  selectedId,
  shown = [],
  startingDepth,
  toolbar = false,
  createdComponent,
  showModels = true,
  showDeleteTreeButton = false,
  setShowDeleteDialog,
}) => {
  const dispatch = useDispatch();

  const findExpanded = (node, depth = 1) => {
    if (!node) return shown.map((c) => c.id);
    const expanded = [];
    const xDepth = !expandedDepth ? Infinity : expandedDepth;
    if (depth <= xDepth && node.children?.length) {
      expanded.push(node.id);
      node.children.forEach((child) => expanded.push(...findExpanded(child, depth + 1)));
    }
    return expanded;
  };

  const [shownIds, setShownIds] = useState(shown.map((c) => c.id));
  const [expandedIds, setExpandedIds] = useState(flat ? [] : findExpanded(componentTree));
  const [showFilters, setShowFilters] = useState(false);
  const [searchTextByPage, setSearchTextByPage] = useState({});
  const [searchText, setSearchText] = useState('');
  const [scrollToActive, setScrollToActive] = useState(true);
  const [newComponent, setNewComponent] = useState({});

  const filterPanel = useRef(null);
  const searchInput = useRef(null);
  const clearSearch = useRef(null);
  const componentsRef = useRef({});
  const compsList = useRef(null);

  const unfilteredShownIds = useMemo(() => shown.map((c) => c.id), [shown]);

  const handleClear = () => {
    setSearchTextByPage({ ...searchTextByPage, [pageIndex]: '' });
    setShownIds(unfilteredShownIds);
    if (searchInput.current) {
      searchInput.current.focus();
    }
  };

  const handleKeydown = (e) => {
    if (
      clearSearch.current &&
      clearSearch.current === document.activeElement &&
      (e.key === 'Enter' || e.code === 'Enter')
    ) {
      handleClear();
    }
  };

  const reset = () => {
    dispatch(setActiveComponentId(null));
    setExpandedIds(findExpanded(componentTree));
    setSearchTextByPage({ ...searchTextByPage, [pageIndex]: '' });
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, []);

  useEffect(() => {
    setSearchText(searchTextByPage[pageIndex] || '');
  }, [searchTextByPage, pageIndex]);

  useEffect(() => {
    setExpandedIds(findExpanded(componentTree));
  }, [endingDepth, startingDepth, expandedDepth, flat]);

  useEffect(() => {
    if (selectedId) {
      const selected = shown.find((comp) => comp.id === selectedId);
      const selectedIds = selected ? selected.ancestorIds : [];
      setExpandedIds(() => [...expandedIds, ...selectedIds, selectedId]);
    }
  }, [selectedId, flat]);

  useEffect(() => {
    setNewComponent(createdComponent);
  }, [createdComponent]);

  useLayoutEffect(() => {
    const item = componentsRef.current?.[selectedId];
    const list = compsList.current;

    if (item && list && expandedIds.includes(selectedId)) {
      const { top: iT, bottom: iB } = item.getBoundingClientRect();
      const { top: lT, bottom: lB } = list.getBoundingClientRect();
      const shouldScroll = (list.scrollHeight > list.clientHeight && (iT < lT || iB > lB)) || false;

      if (shouldScroll && scrollToActive && selectedId) {
        item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
      setScrollToActive(true);
    }

    if (newComponent) {
      const newComponentRef = componentsRef.current[newComponent.id];
      if (newComponentRef) {
        newComponentRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
      setNewComponent();
    }
  }, [expandedIds]);

  useLayoutEffect(() => {
    if (newComponent) {
      setExpandedIds(() => [...expandedIds, newComponent.parent]);
    }
  }, [newComponent]);

  const getShownIds = () => {
    if (searchText) {
      const text = searchText.toLowerCase();
      return shown
        .filter(
          (c) =>
            c.itemDesignation.toLowerCase().includes(text) || c.name.toLowerCase().includes(text)
        )
        .reduce((acc, comp) => {
          acc.push(comp.id);
          comp.ancestorIds.forEach((id) => {
            if (unfilteredShownIds.includes(id)) {
              acc.push(id);
            }
          });
          return acc;
        }, []);
    }
    return unfilteredShownIds;
  };

  useEffect(() => {
    setShownIds(getShownIds());
  }, [shown, searchText]);

  const handleClick = (e) => {
    if (!filterPanel.current?.contains(e.target)) {
      e.stopPropagation();
      setShowFilters(false);
    }
  };

  useEffect(() => {
    if (showFilters) {
      document.addEventListener('click', handleClick, true);
    } else {
      document.removeEventListener('click', handleClick, true);
    }
    return () => document.removeEventListener('click', handleClick, true);
  }, [showFilters]);

  const toggleExpansion = (id) => {
    setScrollToActive(false);
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter((i) => i !== id));
    } else {
      setExpandedIds(expandedIds.concat(id));
    }
  };

  const handleChange = (e) => {
    const text = e.target.value;
    setSearchTextByPage(() => ({ ...searchTextByPage, [pageIndex]: text }));
  };

  const onComponentClick = (id) => {
    if (id === componentTree.id) {
      dispatch(setActiveComponentId(null));
    } else {
      setScrollToActive(false);
      dispatch(setActiveComponentId(id));
    }
    dispatch(setZoomComponentId(null));
  };

  const onComponentDoubleClick = (id) => {
    // click events not propagated to prevent select/deselect, so we need to handle selection
    if (id !== selectedId) {
      onComponentClick(id);
    }
    dispatch(setZoomComponentId(id));
  };

  const onModelClick = (modelId) => {
    setScrollToActive(false);
    dispatch(setActiveModelId(modelId));
  };

  const renderTree = (node, depth = 1, parentDesignation = '') => {
    const { id, children = [] } = node;

    const end = endingDepth === 0 ? Infinity : endingDepth;
    let nodes = null;
    let hasVisibleChildren = false;

    if (!id) return null;
    if (children.length) {
      const itemDesignationRef = depth >= startingDepth ? node.itemDesignation : '';
      nodes = children.map((child) => renderTree(child, depth + 1, itemDesignationRef));
      children.forEach((child) => {
        if (!hasVisibleChildren && shownIds.includes(child.id) && depth < end) {
          hasVisibleChildren = true;
        }
      });
    }

    const item = shown.find((s) => s.id === node.id);
    if (!item) return nodes;

    if (!flat) {
      if (parentDesignation && node.itemDesignation.indexOf(parentDesignation) === 0) {
        item.simpleDesignation = item.itemDesignation.replace(parentDesignation, '');
      }
      item.models = (item.models || []).map((m) => ({ ...m, ...modelsHashmap[m.modelId] }));
    }

    if (shownIds.includes(id) && depth >= startingDepth && depth <= end) {
      return (
        <TreeItem
          key={id}
          ref={(el, compId) => {
            componentsRef.current[compId] = el;
          }}
          activeModelId={activeModelId}
          autoLoad={autoLoad}
          canDelete={canDelete}
          canEdit={canEdit}
          depth={depth - startingDepth + 1}
          expanded={searchText || expandedIds.includes(id)}
          flat={flat}
          hasChildren={hasVisibleChildren}
          item={item}
          viewerReady={viewerReady}
          onComponentClick={onComponentClick}
          onComponentDoubleClick={onComponentDoubleClick}
          onCreate={onCreate}
          onDelete={onDelete}
          onModelClick={onModelClick}
          onUpdate={onUpdate}
          selected={id === selectedId}
          shouldDisplayStatus={shouldDisplayStatus}
          toggleExpansion={toggleExpansion}
          treeRoot={compsList.current}
          showModels={showModels}
        >
          {nodes}
        </TreeItem>
      );
    }
    return nodes;
  };

  return (
    <div
      className="components-panel--tree"
      role="tree"
    >
      {toolbar ? (
        <div className="tree-toolbar">
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={handleChange}
            ref={searchInput}
          />
          <Button
            onClick={reset}
            activity="secondary"
            icon={<Icon icon="refresh" />}
            tooltip="Reset component tree"
          />
          {canDelete && showDeleteTreeButton ? (
            <Button
              onClick={() => setShowDeleteDialog(true)}
              activity="danger"
            >
              Delete
            </Button>
          ) : null}
        </div>
      ) : null}
      <div
        className={`tree-root ${!flat && !toolbar ? 'full' : ''} custom-thin-scrollbar`}
        ref={compsList}
        onClick={() => dispatch(setActiveComponentId(selectedId))}
      >
        {renderTree(componentTree)}
      </div>
    </div>
  );
};

export default ComponentTree;
