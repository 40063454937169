import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import FiletypeIcon from '../FiletypeIcon';
import { checkIsOnline } from '../../bundles/notifications';

const FileThumbnail = ({
  file,
  online,
  iconSize = 'l',
  loaded = () => {
    // do something on loaded
  },
}) => {
  const dispatch = useDispatch();
  const [thumbnailError, setThumbnailError] = useState(false);
  const { shouldHaveThumbnail } = file;

  useEffect(() => {
    if (online) {
      setThumbnailError(false);
    }
  }, [online]);

  useEffect(() => {
    if (!shouldHaveThumbnail || thumbnailError) {
      loaded();
    }
  }, [thumbnailError, shouldHaveThumbnail]);

  const handleImgError = () => {
    setThumbnailError(true);
    dispatch(checkIsOnline());
  };

  if (!thumbnailError && shouldHaveThumbnail) {
    return (
      <img
        src={`${window.location.protocol}//${file.thumbnailUrl}&width=150&height=150`}
        onLoad={loaded}
        onError={handleImgError}
      />
    );
  }

  return (
    <FiletypeIcon
      size={iconSize}
      file={file}
    />
  );
};

export default FileThumbnail;
