export const schema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      title: 'Title',
    },
    pieType: {
      title: 'Pie type',
      type: 'string',
      enum: ['Solid', 'Hollow'],
      default: 'Solid',
    },
    sliceGap: {
      title: 'Slice gap',
      type: 'number',
      default: 0,
      minimum: 0,
      maximum: 15,
    },
    legend: {
      type: 'object',
      title: 'Legend',
      properties: {
        show: { type: 'boolean', default: false },
      },
    },
  },
};

export const uiSchema = {
  sliceGap: {
    'ui:widget': 'range',
  },
};
