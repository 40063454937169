const versionSchema = {
  type: 'array',
  title: 'Versions',
  items: {
    type: 'object',
    properties: {
      comments: {
        type: 'string',
        title: 'Comments',
      },
      sceneConfig: {
        type: 'boolean',
        title: 'Has Scene Configuration',
      },
      mappings: {
        type: 'string',
        title: 'Component Designations',
      },
    },
  },
};

const versionUiSchema = {
  'ui:options': {
    orderable: false,
  },
  mappings: {
    'ui:widget': 'textarea',
  },
};

export { versionSchema, versionUiSchema };
