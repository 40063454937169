/* eslint-disable no-sparse-arrays */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from 'react';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  interval,
  EMPTY,
  from,
  defer,
  Observable,
  merge,
  of,
} from 'rxjs';
import {
  map,
  tap,
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  finalize,
  throttle,
  throttleTime,
  exhaustMap,
  take,
  refCount,
  publishReplay,
  debounceTime,
  skip,
  catchError,
} from 'rxjs/operators';
import { useStore } from 'react-redux';
import ExcelJS from 'exceljs';
import { REFRESH_VALUES } from './bundles/application';
import { useSetLoading } from './components/LoadingLayer';
import appConfig from './config';
import { GRANULARITY_OPTIONS } from './constants';
import { getNested, getLocalDateString } from './datetimeUtils';

let uniqueId = 0;
// eslint-disable-next-line no-plusplus
const getUniqueId = () => uniqueId++;

const getRealm = () => {
  const { auth: authConfig, domain } = appConfig;
  let { realm } = authConfig;
  const domainIndex = window.location.hostname.indexOf(domain);
  if (domainIndex >= 0) {
    realm = window.location.hostname.slice(0, domainIndex).split('.')[0] || 'master';
  }
  return realm;
};

const checkNetworkStatus = async () => {
  if (!navigator.onLine) {
    return false;
  }
  return fetch('//google.com', { mode: 'no-cors' })
    .then(() => true)
    .catch(() => false);
};

const uniqueValues = (value, index, self) => self.indexOf(value) === index;

const useComponentId = () => {
  const idRef = useRef(getUniqueId());
  return idRef.current;
};

const truncateFilename = (s, n = 20, tail = 8) => {
  if (s.length <= n + 2 || s.length <= tail) {
    return [s, false];
  }
  return [`${s.substr(0, n - tail)}…${s.substr(-1 * tail)}`, true];
};

const isFunction = (func) => !!(func && func.constructor && func.call && func.apply);

const updateSettingsParams = (search, update) => {
  const params = new URLSearchParams(search);
  Object.entries(update).forEach(([k, v]) => {
    if (v !== null) {
      params.set(k, v);
    } else {
      params.delete(k);
    }
  });
  return `?${params.toString()}`;
};

const upsert = (array, item) => {
  const index = array.findIndex((_item) => _item.id === item.id);

  if (index > -1) {
    // eslint-disable-next-line no-param-reassign
    array[index] = item;
  } else {
    array.push(item);
  }

  return array;
};

const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) {
      return () => {
        /* no action */
      };
    }

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

/**
 * When an onClick handler is added on a parent element the event fires even if
 * you mousedown in a child element and mouseup in the parent element. This is
 * sometimes unwanted behavior. In modals for example, where the modal will
 * close if you mousedown in the modal and mouseup in the overlay.
 *
 * This hook solves that.
 */
const useParentOnlyClickHandler = (handler, parent, parentCanClose = true) => {
  const savedHandler = useRef();
  const mouseDown = useRef(false);

  const mouseDownHandler = (e) => {
    if (e.currentTarget === e.target) {
      mouseDown.current = true;
    }
  };

  const mouseUpHandler = (e) => {
    if (
      typeof savedHandler.current === 'function' &&
      e.currentTarget === e.target &&
      mouseDown.current &&
      parentCanClose
    ) {
      savedHandler.current(e);
    }
    mouseDown.current = false;
  };

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = parent && parent.addEventListener;
    if (!isSupported) {
      return () => {
        /* no action */
      };
    }

    parent.addEventListener('mousedown', mouseDownHandler);
    parent.addEventListener('mouseup', mouseUpHandler);

    return () => {
      parent.removeEventListener('mousedown', mouseDownHandler);
      parent.removeEventListener('mouseup', mouseUpHandler);
    };
  }, [parent, mouseDown]);
};

/**
 * When modals are present nothing behind the modal should be scrollable.
 * Touch devices running Webkit don't honor overflow:hidden in the body tag.
 * So we need to do this. https://bugs.webkit.org/show_bug.cgi?id=153852#c43
 */
const useBackgroundFreeze = () => {
  useEffect(() => {
    const offsetY = window.pageYOffset;
    const body = document.querySelector('body');
    body.style.top = `${-offsetY}px`;
    body.classList.add('no-scroll');
    document.scrollingElement.style['scroll-behavior'] = 'auto';

    return () => {
      body.classList.remove('no-scroll');
      body.style.removeProperty('top');
      window.scrollTo(0, offsetY);
      document.scrollingElement.style['scroll-behavior'] = 'smooth';
    };
  }, []);
};

const useDoubleClick = (
  callback = () => {
    /* no action */
  }
) => {
  const [elem, setElem] = useState(null);
  const countRef = useRef(0);
  const timerRef = useRef(null);
  const inputCallbackRef = useRef(null);
  const callbackRef = useCallback((node) => {
    setElem(node);
    callbackRef.current = node;
  }, []);

  useEffect(() => {
    inputCallbackRef.current = callback;
  });

  useEffect(() => {
    function handler(e) {
      const isDoubleClick = countRef.current + 1 === 2;
      const timerIsPresent = timerRef.current;
      if (isDoubleClick) {
        e.stopPropagation();
      }
      if (timerIsPresent && isDoubleClick) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        countRef.current = 0;
        if (inputCallbackRef.current) {
          inputCallbackRef.current();
        }
      }
      if (!timerIsPresent) {
        countRef.current += 1;
        const timer = setTimeout(() => {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          countRef.current = 0;
        }, 200);
        timerRef.current = timer;
      }
    }
    if (elem) {
      elem.addEventListener('click', handler);
    }

    return () => {
      if (elem) {
        elem.removeEventListener('click', handler);
      }
    };
  }, [elem]);
  return [callbackRef, elem];
};

// https://gist.github.com/reecelucas/2f510e6b8504008deaaa52732202d2da
const useScrollBlock = () => {
  const scroll = useRef(false);

  const blockScroll = () => {
    if (typeof document === 'undefined') return;

    const html = document.documentElement;
    const { body } = document;

    if (!body || !body.style || scroll.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue('padding-right'), 10) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scroll.current = true;
  };

  const allowScroll = () => {
    if (typeof document === 'undefined') return;

    const { body } = document;

    if (!body || !body.style || !scroll.current) return;

    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scroll.current = false;
  };

  return [blockScroll, allowScroll];
};

const useActiveSiteId = () => {
  const path = window.location.pathname;
  const sitesIndex = path.indexOf('sites/');
  if (sitesIndex < 0) return null;
  const idAndPath = path.slice(sitesIndex + 6);
  return idAndPath.slice(0, idAndPath.indexOf('/'));
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const useForceUpdate = () => {
  const [, dispatch] = useState(Object.create(null));

  const memoizedDispatch = useCallback(() => {
    dispatch(Object.create(null));
  }, [dispatch]);

  return memoizedDispatch;
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const ki = k ** i;
  return `${parseFloat((bytes / ki).toFixed(dm))}  ${sizes[i]}`;
};

const debounce = (func, wait) => {
  let timeout;
  // eslint-disable-next-line func-names
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const useThrottledCallback = (callback, duration, config, dependencies) => {
  const subject = useMemo(() => new Subject(), []);
  useEffect(
    () => {
      const sub = subject.pipe(throttleTime(duration, undefined, config)).subscribe(callback);
      return () => sub.unsubscribe();
    },
    dependencies ? [callback] : dependencies
  );
  return useCallback((arg) => subject.next(arg), [subject]);
};

const useIsMounted = () => {
  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );
  return () => isMountedRef.current;
};

const useDebounce = (cb, delay) => {
  const inputsRef = useRef(cb);
  const isMounted = useIsMounted();
  useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  return useCallback(
    debounce((...args) => {
      // Don't execute callback, if (1) component has been unmounted
      // (2) when inputs meanwhile have changed (old callback)
      if (inputsRef.current.delay === delay && isMounted()) {
        inputsRef.current.cb(...args);
      }
    }, delay),
    [delay, debounce]
  );
};

const sortNumbers = (values, attr, descending = false) =>
  descending ? values.sort((a, b) => b[attr] - a[attr]) : values.sort((a, b) => a[attr] - b[attr]);

const sortStrings = (values, attr, descending = false) =>
  descending
    ? values.sort((a, b) => b[attr].localeCompare(a[attr]))
    : values.sort((a, b) => a[attr].localeCompare(b[attr]));

const sortDates = (values, attr, descending = false) =>
  descending
    ? values.sort((a, b) => new Date(b[attr]) - new Date(a[attr]))
    : values.sort((a, b) => new Date(a[attr]) - new Date(b[attr]));

const sort = (values, attr, descending = false) => {
  if (values.length < 1) {
    return values;
  }

  const value = values[0][attr];
  let type = 'string';
  if (Number.isFinite(value)) {
    type = 'number';
  } else if (value instanceof Date) {
    type = 'date';
  }

  let sortedItems;
  switch (type) {
    case 'number':
      sortedItems = sortNumbers(values, attr, descending);
      break;
    case 'date':
      sortedItems = sortDates(values, attr, descending);
      break;
    default:
      sortedItems = sortStrings(values, attr, descending);
  }

  return sortedItems;
};

// Get file contents.
function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (e) => reject(e);
    reader.readAsText(file);
  });
}

// Remove site specific data from layout files, such as pushPins and panel filters.
function removeSiteSpecificData(site) {
  const rules = {
    // pageSchema
    components: { replaceWith: [], hasSibling: { key: 'filterType' } },
    // componentAttributesPanelSchema
    categoriesFilter: { replaceWith: [] },
    // componentsPanelSchema
    limitComponents: { replaceWith: [] },
    excludeComponents: { replaceWith: [] },
    // filesPanelSchema
    files: { replaceWith: [] },
    tags: { replaceWith: [] },
    // viewerPanelSchema
    pushPins: { replaceWith: [] },
    componentTooltips: { replaceWith: [] },
    // visualizationsPanelSchema
    visualizations: { replaceWith: [], hasSibling: { key: 'visualizationTypes' } },
  };

  /**
   * Modifies a given property of an object according to a rule.
   * If the rule has an object called 'hasSibling', then it will modify the value only
   * if the given property has a sibling with the correct key (and optionally value, if specified).
   *    {
   *      Will modify all the keys named 'id' that has sibling property { type: 'pt' }
   *      id: { replaceWith: '', hasSibling: { key: 'type', value: 'pt' } },
   *
   *      Will modify all the keys named 'someOtherId' that has a sibling property 'type'
   *      someOtherId: { replaceWith: '', hasSibling: { key: 'type' } },
   *    }
   */
  function modify(key, context) {
    if (!Object.prototype.hasOwnProperty.call(rules, key)) {
      return;
    }

    const rule = rules[key];

    if (!Object.prototype.hasOwnProperty.call(rule, 'hasSibling')) {
      // eslint-disable-next-line no-param-reassign
      context[key] = rule.replaceWith;
      return;
    }

    if (
      Object.prototype.hasOwnProperty.call(rule.hasSibling, 'key') &&
      Object.prototype.hasOwnProperty.call(rule.hasSibling, 'value')
    ) {
      if (
        Object.prototype.hasOwnProperty.call(context, rule.hasSibling.key) &&
        context[rule.hasSibling.key] === rule.hasSibling.value
      ) {
        // eslint-disable-next-line no-param-reassign
        context[key] = rule.replaceWith;
      }

      return;
    }

    if (
      Object.prototype.hasOwnProperty.call(rule.hasSibling, 'key') &&
      Object.prototype.hasOwnProperty.call(context, rule.hasSibling.key)
    ) {
      // eslint-disable-next-line no-param-reassign
      context[key] = rule.replaceWith;
    }
  }

  /**
   * Recursive walk of a object, modifying each key/value pair that has a rule associated
   * with it.
   *
   * Context is the current scope of the key/value pair.
   * For instance:
   * {
   *   hello: { world: 123, bye: 345 }
   * }
   *
   * the keys 'world' and 'bye' share the same context, whereas 'hello' does not.
   */
  function walkAndModify(key, value, context) {
    if (Array.isArray(value) && Object.prototype.hasOwnProperty.call(rules, key)) {
      modify(key, context);
    } else if (value !== null && typeof value === 'object') {
      Object.entries(value).forEach(([k, v]) => {
        walkAndModify(k, v, value);
      });
    } else {
      modify(key, context);
    }
  }

  walkAndModify('', site, null);
}

/**
 * Similar to the num.toFixed() but it properly rounds numbers
 *
 * @param {String|Number} val Value to convert
 * @param {Number} num Number of decimals
 * @param {Boolean} fill Fill remaining decimals with zero
 */
function toNumDecimals(val, num = 3, fill = false) {
  let value = val;

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (Number.isNaN(value) || typeof value !== 'number') {
    throw new Error(`${val} is not a number`);
  }

  const dec = 10 ** num;

  // Round it
  value = Math.round(value * dec) / dec;

  // convert to string
  value = value.toString();

  if (fill) {
    const baseNum = value.split('.')[0];
    const fraction = value.split('.')[1] || '';

    value = [baseNum, fraction.padEnd(num, '0')].join('.');
  }

  return value;
}

function capitalize(text = '') {
  return text[0].toUpperCase() + text.slice(1);
}

function toCapitalizedWords(str) {
  const words = str.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(' ');
}

function camelToSentence(text) {
  // assumes first char lowercase, e.g. 'camelCase' -> 'Camel case'
  const result = text.replace(/([A-Z])/g, ' $1').toLowerCase();
  return capitalize(result);
}

function downloadBlob(blob, filename = 'download.zip', type = '') {
  const url = window.URL.createObjectURL(new Blob([blob], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.download = filename || 'download';

  const clickHandler = () => {
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      link.removeEventListener('click', clickHandler);
    }, 150);
  };

  link.addEventListener('click', clickHandler, false);
  link.click();
}

const getFilenameWithoutExt = (filename) => filename.substring(0, filename.lastIndexOf('.'));
const getFileExtension = (filename) => filename.split('.').pop();

const formatVideoTime = (secondsFromStart) => {
  const hours = `${Math.floor(secondsFromStart / 60 / 60)}`.padStart(2, '0');
  const minutes = `${Math.floor(secondsFromStart / 60)}`.padStart(2, '0');
  const seconds = `${Math.floor(secondsFromStart % 60)}`.padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

const clientSizeContext = createContext({
  change$: new Subject(),
});

function useClientSize() {
  const ref = useRef();
  const { change$ } = useContext(clientSizeContext);
  const [size, setSize] = useState({
    height: 0,
    width: 0,
  });
  const resize = useCallback(() => {
    if (ref.current) {
      setSize((old) => {
        const { clientHeight: height, clientWidth: width } = ref.current;
        if (old.height === height && old.width === width) {
          return old;
        }
        return {
          height,
          width,
        };
      });
    } else {
      setSize({
        height: null,
        width: null,
      });
    }
  }, [ref, setSize]);
  useEffect(() => {
    const sub = change$.subscribe(resize);
    return () => sub.unsubscribe();
  }, [change$, resize]);
  useEventListener('resize', resize);
  const refCallback = useCallback(
    (node) => {
      ref.current = node;
      if (node) {
        resize();
      }
    },
    [ref, resize]
  );
  return [size, refCallback];
}

const useClientSizeRefresh = () => {
  const { change$ } = useContext(clientSizeContext);
  return useCallback(() => setTimeout(() => change$.next(), 0), [change$]);
};

const useIndicatedCallback = (...callbackArgs) => {
  const callback = useCallback(...callbackArgs);
  const [isLoading, setIsLoading] = useState(false);
  const decoratedCallback = useCallback(
    async (...args) => {
      setIsLoading(true);
      try {
        const res = await callback(...args);
        return res;
      } finally {
        setIsLoading(false);
      }
    },
    [callback, setIsLoading]
  );
  return [decoratedCallback, isLoading];
};

const observableStateContext = createContext({ state$: null });
const ObservableStateProvider = ({ children }) => {
  const store = useStore();
  const subject = useMemo(() => new BehaviorSubject(store.getState()), [store]);
  useEffect(() => {
    const sub = store.subscribe(() => subject.next(store.getState()));
    return () => sub();
  }, [store, subject]);
  const ctx = useMemo(() => ({ state$: subject.asObservable() }), [subject]);
  return <observableStateContext.Provider value={ctx}>{children}</observableStateContext.Provider>;
};

const useObservableState = () => {
  const { state$ } = useContext(observableStateContext);
  return state$;
};

const useSelect$ = (fn, dependencies = []) => {
  const state$ = useObservableState();
  return useMemo(() => state$.pipe(map(fn), distinctUntilChanged()), dependencies);
};

const actions$ = new Subject();

const actionObserveMiddleware = () => (next) => (action) => {
  actions$.next(action);
  return next(action);
};

const useActions$ = (filterFn, dependencies = []) => {
  return useMemo(() => actions$.pipe(filter(filterFn)), dependencies);
};

const useGlobalRefresh$ = (leading = true) => {
  const refresh$ = useActions$((action) => action.type === REFRESH_VALUES, []);
  const poll$ = useSelect$((state) => state.application.pollingInterval);
  const leadingStart = useMemo(() => (leading ? [startWith(0)] : []), [leading]);
  return useMemo(
    () =>
      combineLatest([
        refresh$.pipe(...leadingStart),
        poll$.pipe(
          switchMap((pollDuration) => {
            if (!pollDuration) {
              return EMPTY;
            }
            return interval(pollDuration).pipe(...leadingStart);
          })
        ),
      ]).pipe(map(() => 0)),
    [refresh$, poll$, leadingStart]
  );
};

const useSubscribe = (observable, initialValue) => {
  const [state, setState] = useState(initialValue);
  useEffect(() => {
    if (!observable) {
      return () => 0;
    }
    setState(initialValue);
    const sub = observable.subscribe(setState);
    return () => {
      sub.unsubscribe();
    };
  }, [setState, observable]);
  return state;
};

const useSubscribedRef = (observable, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    const sub = observable.subscribe((value) => {
      ref.current = value;
    });
    return () => sub.unsubscribe();
  }, [observable]);
  return ref;
};

function exhaustMapWithTrailing(project) {
  return (source) =>
    defer(() => {
      const release = new Subject();

      return source.pipe(
        throttle(() => release, { leading: true, trailing: true }),
        // eslint-disable-next-line max-len
        exhaustMap((value, index) =>
          from(project(value, index)).pipe(finalize(() => release.next()))
        )
      );
    });
}

// Async hooks

function buildSharedContextInstance() {
  const store = {};

  function cache(factory, cacheKey) {
    if (!store[cacheKey]) {
      store[cacheKey] = new Observable((subscriber) => {
        const refresh$ = new BehaviorSubject();
        const continuation$ = new Subject();
        function refresh() {
          refresh$.next();
          return continuation$.pipe(take(1)).toPromise();
        }
        const sub = refresh$
          .pipe(
            exhaustMapWithTrailing(factory),
            tap(() => continuation$.next()),
            map((response) => ({ response, refresh }))
          )
          .subscribe(subscriber);
        return () => {
          sub.unsubscribe();
          delete store[cacheKey];
        };
      }).pipe(publishReplay(1), refCount());
    }
    return store[cacheKey];
  }
  cache.refresh = (...keys) =>
    Promise.all(
      keys
        .filter((key) => store[key])
        .map((key) =>
          store[key]
            .pipe(
              take(1),
              map((item) => item.refresh())
            )
            .toPromise()
        )
    );
  return {
    cache,
  };
}

const sharedRequestContext = createContext(buildSharedContextInstance());

function useAsyncCache() {
  const { cache } = useContext(sharedRequestContext);
  return cache;
}

function getSharedCacheKey(prefix, ...args) {
  function cachify(arg) {
    if (Array.isArray(arg)) {
      return arg.map(cachify).join('|');
    }
    if (typeof arg === 'object') {
      throw new Error(
        'Objects are not supported for shared caching. Use primitives and arrays instead'
      );
    }
    return arg.toString();
  }
  return cachify([prefix.toLowerCase(), ...args]);
}

const cacheConnector = (cache, onUpdate) => {
  const keySet = new Set();
  const store = {};
  let updateSub = null;
  function unsub() {
    if (updateSub) {
      updateSub.unsubscribe();
      updateSub = null;
    }
  }
  return {
    begin() {
      unsub();
      keySet.clear();
    },
    cache(factory, cacheKey) {
      if (!store[cacheKey]) {
        const connectable = cache(factory, cacheKey).pipe(publishReplay(1));
        store[cacheKey] = {
          observable: connectable,
          sub: connectable.connect(),
        };
      }
      keySet.add(cacheKey);
      return store[cacheKey].observable;
    },
    end() {
      Object.keys(store)
        .filter((key) => !keySet.has(key))
        .forEach((key) => {
          store[key].sub.unsubscribe();
          delete store[key];
        });
      keySet.clear();
      unsub();
      updateSub = merge(...Object.values(store).map((item) => item.observable.pipe(skip(1))))
        .pipe(debounceTime(100), take(1))
        .subscribe(onUpdate);
    },
    disconnect() {
      unsub();
      Object.keys(store).forEach((key) => {
        store[key].sub.unsubscribe();
        delete store[key];
      });
    },
  };
};

/**
 * Creates an async context used for running multiple async operations combined into one
 * observable result.
 * @param {(cache: <T>(factory: () => Promise<T>, cacheKey: string) => Promise<T>)} factory
 * @param {unknown[]} dependencies
 * @returns {{ response?: any, loading: boolean, error?: any, refresh: () => Promise<void>}}
 */
function useAsync$(factory, dependencies) {
  const factory$ = useMemo(() => new BehaviorSubject(factory), []);
  const updateTrigger$ = useMemo(() => new BehaviorSubject(), []);
  const cache = useAsyncCache();
  const connector = useMemo(
    () => cacheConnector(cache, () => updateTrigger$.next()),
    [cache, updateTrigger$]
  );
  useEffect(() => () => connector.disconnect(), [connector]);

  const refresh = useRef(undefined);
  const handleRefresh = useCallback(() => {
    if (isFunction(refresh.current)) {
      return refresh.current();
    }
    return Promise.resolve();
  }, []);

  useEffect(() => {
    if (factory$.value !== factory) {
      factory$.next(factory);
    }
  }, [factory$, ...dependencies]);

  const setLoading = useSetLoading();

  const observable = useMemo(
    () =>
      combineLatest([factory$, updateTrigger$]).pipe(
        tap(() => connector.begin()),
        tap(() => setLoading(true)),
        exhaustMapWithTrailing(async ([factoryFn]) => {
          const refreshes = [];
          function wrapCache(fn, cacheKey, cacheDuration) {
            return connector
              .cache(fn, cacheKey, cacheDuration)
              .pipe(
                take(1),
                tap((item) => refreshes.push(item.refresh)),
                map(({ response: res }) => res)
              )
              .toPromise();
          }
          const response = await factoryFn(wrapCache);
          refresh.current = () => Promise.all(refreshes.map((r) => r()));
          return {
            response,
            loading: false,
            refresh: handleRefresh,
          };
        }),
        tap(() => connector.end()),
        catchError((error) =>
          of({
            error,
            loading: false,
            refresh: handleRefresh,
          })
        ),
        tap(() => setLoading(false))
      ),
    [factory$, connector, refresh, updateTrigger$]
  );
  return [observable, handleRefresh];
}

/**
 * Creates an async context used for running multiple async operations combined into one
 * observable result.
 * @param {(cache: <T>(factory: () => Promise<T>, cacheKey: string) => Promise<T>)} factory
 * @param {unknown[]} dependencies
 * @returns {{ response?: any, loading: boolean, error?: any, refresh: () => Promise<void>}}
 */
function useAsync(factory, dependencies) {
  const [observable, handleRefresh] = useAsync$(factory, dependencies);
  return useSubscribe(observable, {
    loading: true,
    refresh: handleRefresh,
  });
}

function useSingleAsync(factory, ...cacheKeys) {
  if (cacheKeys.length < 1) {
    console.warn('Did you forget to add an appropriate cache key?');
  }
  const cacheKey = getSharedCacheKey(...cacheKeys);
  return useAsync((cache) => cache(() => factory(), cacheKey), cacheKey);
}

function useAsyncCallback(factory, dependencies) {
  const cache = useAsyncCache();
  return useCallback((...args) => factory(...args, cache), [cache, ...dependencies]);
}

// END Async hooks

const getUpdatedFields = (path, node, formData) => {
  const part = path.shift();
  if (Array.isArray(node[part])) {
    const idx = path.shift();
    return {
      [part]: [
        ...node[part].slice(0, idx),
        { ...node[part][idx], ...getUpdatedFields(path, node[part][idx], formData) },
        ...node[part].slice(idx + 1),
      ],
    };
  }
  return {
    [part]: {
      ...node[part],
      ...(path.length > 0 ? getUpdatedFields(path, node[part], formData) : formData),
    },
  };
};

const getHexaDecimal = (hexColor) => parseInt(hexColor.slice(1), 16);

const hexToRGB = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};
const removeTrailingSlash = (url) => url.replace(/\/$/, '');
// eslint-disable-next-line import/prefer-default-export

const replacer = (match, params) => {
  const configValue = params[match.substring(1, match.length - 1)];
  if (configValue) {
    return configValue;
  }
  throw new Error(`URI replacement for ${match.substring(1, match.length - 1)} not found!`);
};

const downloadExcel = async (exportType, workbook, fileName, onExportCompleted) => {
  try {
    switch (exportType) {
      case 'xlsx': {
        const xlsxData = await workbook.xlsx.writeBuffer();
        downloadBlob(
          xlsxData,
          fileName,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        break;
      }
      case 'csv': {
        const csvData = await workbook.csv.writeBuffer();
        downloadBlob(csvData, fileName, 'text/csv;charset=utf-8');
        break;
      }
      case 'xml': {
        downloadBlob(workbook, fileName, 'text/xml;charset=utf-8');
        break;
      }
      default: {
        const buffer = await workbook.xlsx.writeBuffer();
        downloadBlob(buffer, fileName, '');
      }
    }
    onExportCompleted();
  } catch (error) {
    console.error('<<<ERRROR>>>', error);
    console.error('Something Went Wrong', error.message);
  }
};

const formatDateString = (date) => {
  return getLocalDateString(date, 'en-CA').replace(/[^a-zA-Z0-9 ]/g, '');
};

const createWorkbook = () => {
  return new ExcelJS.Workbook({
    created: new Date(),
    modified: new Date(),
    views: [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
      },
    ],
  });
};

const formatSeriesData = (seriesData, variables) => {
  return seriesData.reduce((acc, series) => {
    const itemDes = variables.find((v) => v.id === series.variable)?.itemDesignation;
    if (
      !acc.find(
        (i) =>
          i.name === series.variableName &&
          i.aggregate === series.aggregate &&
          i.itemDesignation === itemDes
      )
    ) {
      acc.push({
        name: series.variableName,
        id: `${series.variable}_${series.aggregate}`,
        aggregate: series.aggregate,
        dateTimestamp: series.values.map((v) => new Date(v[0]).toISOString()),
        values: series.values.flatMap((v) => v[1]),
        itemDesignation: itemDes,
      });
    }
    return acc;
  }, []);
};

function generateFileName(siteName, type, fromDate, toDate, suffix) {
  const formattedSiteName = siteName.replaceAll(' ', '_');
  return `${formattedSiteName}-${type}-${fromDate}_${toDate}${suffix ? `-${suffix}` : ''}`;
}

const exportTimeSeriesToFile = async (data) => {
  const {
    seriesData,
    siteName,
    startDate,
    endDate,
    exportType,
    variables,
    granularity,
    onExportCompleted,
  } = data;
  const fromDate = formatDateString(startDate);
  const toDate = formatDateString(endDate);
  const granularityValue = GRANULARITY_OPTIONS.find((g) => g.value === granularity).label;
  const fileName = generateFileName(siteName, 'TimeSeries', fromDate, toDate, granularityValue);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Time series');
  const formattedData = formatSeriesData(seriesData, variables);

  // header column
  const uniqueCols = Array.from(
    new Set(
      formattedData.map(({ name, id, aggregate, itemDesignation }) => ({
        header: `${name}${itemDesignation || ''} (${aggregate})`,
        key: id,
        width: 40,
        type: 'string',
      }))
    )
  );
  worksheet.columns = [
    { header: 'DateTime', key: 'timestamp', width: 30, type: 'string' },
    ...uniqueCols,
  ];

  worksheet.getRow(1).font = { bold: true };

  formattedData.forEach((ts) => {
    const timestampColumn = worksheet.getColumn('timestamp');
    if (!timestampColumn.values?.[2] && ts.dateTimestamp?.length > 0) {
      timestampColumn.values = [, , ...ts.dateTimestamp];
    }
    const colSignalVal = worksheet.getColumn(ts.id);
    if (colSignalVal) {
      colSignalVal.numFmt = '#,##0.0000';
      const formattedVal = ts.values.map((v) => (v ? parseFloat(v) : ''));
      colSignalVal.values = [, , ...formattedVal];
    }
  });
  downloadExcel(exportType, workbook, fileName, onExportCompleted);
};

const exportEventsToFile = (data) => {
  const { siteName, startDate, endDate, exportType, eventType, events, onExportCompleted } = data;
  const fromDate = getLocalDateString(startDate, 'en-CA').replace(/[^a-zA-Z0-9 ]/g, '');
  const toDate = getLocalDateString(endDate, 'en-CA').replace(/[^a-zA-Z0-9 ]/g, '');
  const fileName = generateFileName(siteName, `${eventType}_events`, fromDate, toDate, null);

  const createNestedXml = (attributes) => {
    let xml = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(attributes)) {
      const tagName = key.charAt(0).toUpperCase() + key.slice(1);
      xml += `<${tagName}>${value}</${tagName}>`;
    }
    return xml;
  };

  if (exportType === 'xlsx') {
    const workbook = createWorkbook();
    const worksheet = workbook.addWorksheet('Events');

    if (eventType === 'iot') {
      // header column
      worksheet.columns = [
        { header: 'Timestamp', key: 'timestamp', width: 30, type: 'string' },
        { header: 'Severity', key: 'severity', width: 30, type: 'string' },
        { header: 'Message (Name)', key: 'message', width: 30, type: 'string' },
        { header: 'Active', key: 'active', width: 10, type: 'string' },
        { header: 'Sender', key: 'sender', width: 30, type: 'string' },
        { header: 'Point Group (Source)', key: 'point-group', width: 30, type: 'string' },
        { header: 'Point ID', key: 'point-id', width: 30, type: 'string' },
      ];
      worksheet.getRow(1).font = { bold: true };
      worksheet.getRow(1).alignment = { horizontal: 'center' };
      worksheet.getColumn(4).alignment = { horizontal: 'center' };

      const formattedIotEvents = events.map((e) => [
        new Date(e.timestamp).toISOString(),
        e.context?.externalData?.severity || 'Unknown',
        e.message,
        e.active ? '\u2714' : '',
        e.sender,
        e.remoteSource,
        e.origin,
      ]);

      worksheet.addRows(formattedIotEvents);
    } else {
      // handle user events

      // header column
      worksheet.columns = [
        { header: 'Start date', key: 'start', width: 25, type: 'string' },
        { header: 'End date', key: 'end', width: 25, type: 'string' },
        { header: 'Name', key: 'name', width: 30, type: 'string' },
        { header: 'Type', key: 'type', width: 20, type: 'string' },
        { header: 'State', key: 'state', width: 15, type: 'string' },
        { header: 'Activity Type', key: 'activity-type', width: 15, type: 'string' },
        { header: 'Created By', key: 'created-by', width: 15, type: 'string' },
        { header: 'Assigned to', key: 'assigned-to', width: 30, type: 'string' },
        { header: 'Execution start date', key: 'execution-start', width: 30, type: 'string' },
        { header: 'Execution end date', key: 'execution-end', width: 30, type: 'string' },
        { header: 'Description', key: 'description', width: 50, type: 'string' },
        { header: 'Tags', key: 'tags', width: 30, type: 'string' },
        { header: 'Components', key: 'components', width: 30, type: 'string' },
        { header: 'Attachments', key: 'attachments', width: 30, type: 'string' },
        { header: 'Has Related Events', key: 'related', width: 30, type: 'string' },
        { header: 'Attributes', key: 'attributes', width: 50, type: 'string' },
        { header: 'Part of series', key: 'series', width: 15, type: 'string' },
      ];
      worksheet.getRow(1).font = { bold: true };

      const formattedUserEvents = events.map((e) => [
        new Date(e.from).toISOString(),
        new Date(e.to).toISOString(),
        e.name,
        capitalize(e.type),
        e.task ? e.state : '',
        // eslint-disable-next-line no-nested-ternary
        e.task && e.planned ? 'planned' : e.task && !e.planned ? 'reactive' : '',
        e.createdBy,
        e.assignedTo,
        e.executionFrom,
        e.executionTo,
        e.description,
        e.tags.join(', '),
        e.components.join(', '),
        e.files.length ? '\u2714' : '',
        e.events.from && e.events.to ? '\u2714' : '',
        JSON.stringify(e.attributes),
        e.seriesId ? '\u2714' : '',
      ]);

      worksheet.addRows(formattedUserEvents);
    }

    downloadExcel(exportType, workbook, fileName, onExportCompleted);
  }

  if (exportType === 'xml') {
    let workbook = '<?xml version="1.0" encoding="utf-8"?>';
    workbook += '<ArchivedEvents>';

    if (eventType === 'iot') {
      events.forEach((e) => {
        workbook += '<Event>';
        workbook += `<EventDateTime>${new Date(e.timestamp).toISOString()}</EventDateTime>`;
        workbook += `<EventSeverity>${
          e.context?.externalData?.severity || 'Unknown'
        }</EventSeverity>`;
        workbook += `<EventText>${e.message}</EventText>`;
        workbook += `<EventActive>${e.active}</EventActive>`;
        workbook += `<Sender>${e.sender}</Sender>`;
        workbook += `<PointGroup>${e.remoteSource}</PointGroup>`;
        workbook += `<PointId>${e.origin}</PointId>`;
        workbook += '</Event>';
      });
    } else {
      events.forEach((e) => {
        workbook += '<Event>';
        workbook += `<StartDate>${new Date(e.from).toISOString()}</StartDate>`;
        workbook += `<EndDate>${new Date(e.to).toISOString()}</EndDate>`;
        workbook += `<Name>${e.name}</Name>`;
        workbook += `<Type>${capitalize(e.type)}</Type>`;
        workbook += `<State>${e.task ? capitalize(e.state) : ''}</State>`;
        let activity = '';
        if (e.task) {
          activity = e.planned ? 'Planned' : 'Reactive';
        }
        workbook += `<ActivityType>${activity}</ActivityType>`;
        workbook += `<CreatedBy>${e.createdBy ? e.createdBy : ''}</CreatedBy>`;
        workbook += `<AssignedTo>${e.assignedTo ? e.assignedTo : ''}</AssignedTo>`;
        workbook += `<ExecutionStartDate>${
          e.executionFrom ? e.executionFrom : ''
        }</ExecutionStartDate>`;
        workbook += `<ExecutionEndDate>${e.executionTo ? e.executionTo : ''}</ExecutionEndDate>`;
        workbook += `<Description>${e.description ? e.description : ''}</Description>`;
        workbook += `<Tags>${e.tags ? e.tags.join(', ') : ''}</Tags>`;
        workbook += `<Components>${e.components ? e.components.join(', ') : ''}</Components>`;
        workbook += `<Attachments>${e.files.length ? 'True' : ''}</Attachments>`;
        workbook += `<HasRelatedEvents>${
          e.events.from && e.events.to ? 'True' : ''
        }</HasRelatedEvents>`;
        workbook += `<Attributes>${e.attributes ? createNestedXml(e.attributes) : ''}</Attributes>`;
        workbook += `<PartOfSeries>${e.seriesId ? 'True' : ''}</PartOfSeries>`;
        workbook += '</Event>';
      });
    }
    workbook += '</ArchivedEvents>';
    downloadExcel(exportType, workbook, fileName, onExportCompleted);
  }
};

// removes key, value pairs where value is empty string or undefined.
const cleanFormData = (data) => {
  if (Array.isArray(data)) {
    return data.map(cleanFormData);
  }

  if (typeof data === 'object' && data !== null) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const cleanedValue = typeof value === 'string' ? value.trim() : value;
      if (cleanedValue !== '' && cleanedValue !== undefined && cleanedValue !== null) {
        if (typeof cleanedValue === 'object') {
          return { ...acc, [key]: cleanFormData(cleanedValue) };
        }
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});
  }

  return data;
};

// coerces attribute lastValue to defined dataType
const withTypedValues = (attribute) => {
  switch (attribute.dataType) {
    case 'number':
      return { ...attribute, lastValue: parseFloat(attribute.lastValue) };
    // rjsf boolean select expects string
    case 'boolean':
    case 'string':
    default:
      return attribute;
  }
};

export {
  upsert,
  formatBytes,
  getUniqueId,
  getRealm,
  checkNetworkStatus,
  uniqueValues,
  useComponentId,
  useEventListener,
  useParentOnlyClickHandler,
  useBackgroundFreeze,
  useDoubleClick,
  useScrollBlock,
  useActiveSiteId,
  useInterval,
  useForceUpdate,
  useClientSize,
  useClientSizeRefresh,
  debounce,
  useDebounce,
  useThrottledCallback,
  sortNumbers,
  sortStrings,
  sortDates,
  sort,
  removeSiteSpecificData,
  toNumDecimals,
  capitalize,
  toCapitalizedWords,
  camelToSentence,
  getFilenameWithoutExt,
  getFileExtension,
  downloadBlob,
  formatVideoTime,
  truncateFilename,
  getNested,
  updateSettingsParams,
  useIndicatedCallback,
  exhaustMapWithTrailing,
  useSubscribe,
  useSubscribedRef,
  useGlobalRefresh$,
  useActions$,
  actionObserveMiddleware,
  useSelect$,
  useObservableState,
  ObservableStateProvider,
  getSharedCacheKey,
  useAsync$,
  useAsync,
  useSingleAsync,
  useAsyncCallback,
  readFile,
  getHexaDecimal,
  isFunction,
  removeTrailingSlash,
  replacer,
  hexToRGB,
  getUpdatedFields,
  exportTimeSeriesToFile,
  exportEventsToFile,
  cleanFormData,
  withTypedValues,
};
