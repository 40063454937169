import React from 'react';

import { Button } from '@iq/react-components';

const InvalidFile = ({ fileName, handleCancelClick }) => (
  <div className="invalid-file-container">
    <div className="info">The uploaded file does not seem to contain any valid root files.</div>
    <div className="invalid-file">Invalid file: {fileName}</div>
    <div className="button-container">
      <Button
        className="action"
        onClick={handleCancelClick}
      >
        Cancel
      </Button>
    </div>
  </div>
);

export default InvalidFile;
