.tfr-view .tfr-view-details .signal-viewer {
  padding-left: 0px;
}
.tfr-view .tfr-view-details .select-period-component .header {
  min-height: 38px;
  width: 100%;
  justify-content: flex-start;
}
.tfr-view .tfr-view-details .select-period-component .header:hover {
  border-color: #b3b3b3;
}
.tfr-view .tfr-view-details .select-period-component .header .label {
  display: flex;
  flex: 1;
  height: 21px;
  align-items: center;
}
.tfr-view .tfr-view-details .select-period-component .header > div:last-child {
  margin-left: 8px;
  margin-right: 0;
  position: relative;
  color: #cccccc;
}
.tfr-view .tfr-view-details .select-period-component .header > div:last-child svg {
  transform: scale(1.4);
}
.tfr-view .tfr-view-details .select-period-component .header > div:last-child :hover {
  color: #999999;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2lnbmFsVmlld2VyQ29udGFpbmVyIiwic291cmNlcyI6WyJTaWduYWxWaWV3ZXJDb250YWluZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtFQUNFOztBQUlBO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi50ZnItdmlldyB7XG4gIC50ZnItdmlldy1kZXRhaWxzIHtcbiAgICAuc2lnbmFsLXZpZXdlciB7XG4gICAgICBwYWRkaW5nLWxlZnQ6IDBweDtcbiAgICB9XG5cbiAgICAuc2VsZWN0LXBlcmlvZC1jb21wb25lbnQge1xuICAgICAgLmhlYWRlciB7XG4gICAgICAgIG1pbi1oZWlnaHQ6IDM4cHg7XG4gICAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG5cbiAgICAgICAgJjpob3ZlciB7XG4gICAgICAgICAgYm9yZGVyLWNvbG9yOiAjYjNiM2IzO1xuICAgICAgICB9XG5cbiAgICAgICAgLmxhYmVsIHtcbiAgICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICAgIGZsZXg6IDE7XG4gICAgICAgICAgaGVpZ2h0OiAyMXB4O1xuICAgICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICAgIH1cblxuICAgICAgICA+IGRpdjpsYXN0LWNoaWxkIHtcbiAgICAgICAgICBtYXJnaW4tbGVmdDogOHB4O1xuICAgICAgICAgIG1hcmdpbi1yaWdodDogMDtcbiAgICAgICAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgICAgICAgY29sb3I6ICNjY2NjY2M7XG5cbiAgICAgICAgICBzdmcge1xuICAgICAgICAgICAgdHJhbnNmb3JtOiBzY2FsZSgxLjQpO1xuICAgICAgICAgIH1cblxuICAgICAgICAgIDpob3ZlciB7XG4gICAgICAgICAgICBjb2xvcjogIzk5OTk5OTtcbiAgICAgICAgICB9XG4gICAgICAgIH1cbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */