const getTenantAdminInvitationSchema = (
  rolesWithSites,
  tenantUsers,
  createdEmails,
  handleCreatable,
  validateCreatable
) => {
  const roleItems = rolesWithSites.reduce(
    (acc, roleWithSites) => ({
      ...acc,
      [roleWithSites.type]: {
        title: roleWithSites.type,
        isMulti: true,
        type: 'array',
        uniqueItems: true,
        placeholder: 'Select site',
        default: [],
        noOptionsMessage: 'No sites available',
        items: {
          type: 'string',
          oneOf: roleWithSites?.siteIds?.length
            ? roleWithSites.siteIds.map((siteId, i) => ({
                const: siteId,
                title: roleWithSites.siteNames[i],
              }))
            : [],
        },
      },
    }),
    {}
  );

  return {
    type: 'object',
    title: 'Users',
    required: ['email'],
    properties: {
      email: {
        label: 'Email*',
        default: [],
        type: 'array',
        uniqueItems: true,
        isMulti: true,
        placeholder: 'Search or add',
        handleCreatable: (value) => handleCreatable(value),
        validateCreatable: (value) => validateCreatable(value),
        items: {
          type: 'string',
          oneOf: [
            ...createdEmails.map((email) => ({ const: email, title: email })),
            ...tenantUsers.map((user) => ({ const: user.email, title: user.email })),
          ],
        },
      },
      roles: {
        title: 'roles',
        type: 'object',
        properties: roleItems,
      },
    },
  };
};

const getUiSchema = (creatable = false) => {
  const schema = {
    roles: {
      'ui:options': {
        orderable: false,
      },
    },
  };
  if (creatable) {
    schema.email = {
      'ui:field': 'creatableField',
    };
  }
  return schema;
};

export { getUiSchema, getTenantAdminInvitationSchema };
