import React, { useEffect, useState } from 'react';
import { Button } from '@iq/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import SimpleModal from '../../../../SimpleModal';
import FileDetail from '../FileDetail';

import { uploadFiles } from '../../../../../bundles/files';
import { getActiveSite } from '../../../../../bundles/sites';

import { getUniqueId } from '../../../../../utils';
import fileUpsertValidation, { validateFileOnPropertyChange } from '../fileUpsertValidation';

const FileUploadModal = ({
  files: initialFiles = [],
  activeComponentId,
  availableComponents,
  availableTags,
  availableTypes,
  includeTags,
  limitTags,
  onCloseModal,
  typeFilter,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const site = useSelector(getActiveSite);
  const isUploadComplete = useSelector((state) => {
    let isCompleted = true;

    const { uploaded: filesUploaded } = state.files;
    files.forEach((file) => {
      if (
        !filesUploaded[file.clientIdentifier] ||
        filesUploaded[file.clientIdentifier].status !== 'SUCCESS'
      ) {
        isCompleted = false;
      }
    });
    return isCompleted;
  });

  const isUploadInProgress = useSelector((state) => {
    let inProgress = false;

    const { uploading: filesUploading } = state.files;
    files.forEach((file) => {
      if (filesUploading[file.clientIdentifier]) {
        inProgress = true;
      }
    });
    return inProgress;
  });

  const addInitialFileproperties = (filesToPopulate) => {
    const filesWithProps = filesToPopulate.map((file) => ({
      file,
      type: typeFilter && typeFilter.length === 1 ? typeFilter[0] : availableTypes[0],
      tags: includeTags && includeTags.length > 0 ? [...includeTags] : [],
      clientIdentifier: `${getUniqueId()}-${Math.round(Math.random() * 1000)}`,
    }));
    return filesWithProps;
  };

  useEffect(() => {
    if (initialFiles.length) {
      const updatedFiles = addInitialFileproperties(initialFiles);
      setFiles([...updatedFiles]);
    }
  }, [initialFiles]);

  const onDrop = (acceptedFiles) => {
    const updatedFiles = files.concat(addInitialFileproperties(acceptedFiles));
    setFiles([...updatedFiles]);
  };

  const validateForm = (filesToValidate) => {
    let errors = {};
    const validFiles = [];
    if (limitTags == null) return { filesToValidate, errors };

    filesToValidate.forEach((file) => {
      const { hasError, objectOfErrors } = fileUpsertValidation({
        file,
        identifier: file.clientIdentifier,
        limitTags,
        availableTags,
        filesToValidate,
      });

      errors = { ...errors, ...objectOfErrors };
      if (!hasError) validFiles.push(file);
    });

    return { validFiles, errors };
  };

  const handleClickRemoveFile = (index) => {
    const remainingFiles = files.filter((i, itemIndex) => itemIndex !== index);
    const hasError =
      remainingFiles.filter(
        ({ errors }) => errors !== undefined && Object.values((v) => !v.name).length > 0
      ).length > 0;
    setHasErrors(hasError);
    setFiles(remainingFiles);
  };

  const handleSetFileProperty = (key, value, index) => {
    const updatedFiles = files;
    updatedFiles[index] = {
      ...files[index],
      [key]: value,
    };

    const { file, errors, hasError } = validateFileOnPropertyChange({
      key,
      file: updatedFiles[index],
      identifier: updatedFiles[index].clientIdentifier,
      limitTags,
      availableTags,
    });
    setHasErrors(hasError);
    updatedFiles[index] = { ...file, errors };

    setFiles([...updatedFiles]);
  };

  const handleClickUpload = () => {
    const { validFiles, errors } = validateForm(files);
    if (!Object.keys(errors).length) {
      const preparedFiles = validFiles.map((file) => ({
        ...file,
        site: site.id,
        org: site.org,
        context: JSON.stringify([{ type: 'general', identifier: 'files-panel' }]),
      }));
      dispatch(uploadFiles(site.id, preparedFiles, site.org));
      onCloseModal();
    } else {
      const filesWithErrors = files.map((file) => ({
        ...file,
        errors: errors[file.clientIdentifier],
      }));
      setFiles(filesWithErrors);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragCounter(dragCounter + 1);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragCounter(dragCounter - 1);
  };

  const handleCopyData = (fileData) => {
    Object.keys(fileData).forEach((key) => {
      files.forEach((file, i) => {
        handleSetFileProperty(key, fileData[key], i);
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    onDrop,
  });

  return (
    <SimpleModal
      onClose={onCloseModal}
      title={`Upload files ${files.length > 0 ? `(${files.length})` : ''}`}
      subtitle={
        files.length === 0
          ? ''
          : 'Please set appropriate components, tags and type for each file you want to upload'
      }
      size="s"
    >
      <div
        className="file-upload-modal"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={(e) => {
          e.preventDefault();
          setDragCounter(dragCounter - 1);
        }}
      >
        <div
          {...getRootProps()}
          className="file-upload-content"
        >
          {(files.length === 0 || (dragCounter > 0 && isDragActive)) && (
            <div
              className="drop-zone"
              onClick={open}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          )}

          <div className="file-upload-item-wrapper">
            {files.map((file, i) => (
              <FileDetail
                file={file}
                activeComponentId={activeComponentId}
                availableComponents={availableComponents}
                availableTags={availableTags}
                availableTypes={availableTypes}
                limitTags={limitTags}
                onClickRemove={() => handleClickRemoveFile(i)}
                onCopyData={files.length > 1 ? handleCopyData : undefined}
                onSetProperty={(key, value) => handleSetFileProperty(key, value, i)}
                key={`${file.name}-${i}`}
                files={files}
              />
            ))}
          </div>
        </div>

        {hasErrors && (
          <div className="form-wide-errors">
            Please correct the errors in the form above to proceed.
          </div>
        )}

        <div className="bottom-toolbar">
          {!!files.length && !isUploadComplete && (
            <React.Fragment>
              <input {...getInputProps()} />
              <Button
                activity="danger"
                onClick={onCloseModal}
                disabled={isUploadInProgress}
              >
                Cancel upload
              </Button>
              <div className="primary-actions">
                <Button
                  activity="secondary"
                  onClick={open}
                  disabled={isUploadInProgress}
                >
                  Add files
                </Button>
                <Button
                  activity="primary"
                  onClick={handleClickUpload}
                  disabled={hasErrors || isUploadInProgress}
                >
                  Upload files
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

export default FileUploadModal;
