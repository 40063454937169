import React, { useState } from 'react';
import { Icon, Button } from '@iq/react-components';

const InlineEditor = ({ value, onSave, uniqueItems = [], uniqueError = '' }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const [error, setError] = useState(null);

  const handleOnSave = () => {
    const isSame = localValue.trim().toLowerCase() === value.toLowerCase();
    if (!isSame && uniqueItems.includes(localValue.trim().toLowerCase())) {
      setError(uniqueError);
    } else {
      onSave(localValue.trim());
      setEditMode(false);
    }
  };

  const handleChange = (e) => {
    setLocalValue(e.target.value);
    setError(null);
  };

  return (
    <div className="inline-editor-component">
      {!isEditMode && <React.Fragment>{value}</React.Fragment>}
      {isEditMode && (
        <input
          type="text"
          value={localValue}
          onChange={handleChange}
        />
      )}
      <span className="controls">
        <Button
          activity="secondary"
          slim
          onClick={() => setEditMode(!isEditMode)}
          icon={<Icon icon={isEditMode ? 'he-close' : 'he-edit'} />}
        />
        {isEditMode && (
          <>
            <Button
              activity="primary"
              slim
              onClick={handleOnSave}
              icon={<Icon icon="he-check" />}
            />
            <div className="error">{error}</div>
          </>
        )}
      </span>
    </div>
  );
};

export default InlineEditor;
