import React from 'react';
import classnames from 'classnames';

const TypeLabel = (props) => {
  const { text, type, color = 'grey', children } = props;
  const classes = classnames('type-label-component', {
    [`type-${type}`]: type,
    [`color-${color}`]: !type && color,
  });

  return (
    <div className={classes}>
      {text || type || null}
      {children}
    </div>
  );
};

export default TypeLabel;
