.hv-tab-content .list-container {
  height: 100%;
}
.hv-tab-content .integration-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.hv-tab-content .integration-name div:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hv-tab-content .integration-name .blocked-item {
  margin: 0 0 0 1rem;
}
.hv-tab-content .integration-name .dropdown-menu--trigger {
  border-radius: 0.3rem;
  padding: 0.1rem 0.5rem;
  font-size: 0.9rem;
  white-space: nowrap;
  display: flex;
  margin: 0;
}
.hv-tab-content .integration-name .dropdown-menu--trigger-label {
  text-transform: capitalize;
}

.transfer-button-spinner {
  width: 4rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvVGVuYW50QWRtaW5WaWV3L2NvbXBvbmVudHMvVGVuYW50QWRtaW5JbnRlZ3JhdGlvblZpZXcvU2VydmljZUFjY291bnRzVGFiIiwic291cmNlcyI6WyJTZXJ2aWNlQWNjb3VudHNUYWIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOzs7QUFNUjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmh2LXRhYi1jb250ZW50IHtcbiAgLmxpc3QtY29udGFpbmVyIHtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gIH1cblxuICAuaW50ZWdyYXRpb24tbmFtZSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICB3aWR0aDogMTAwJTtcblxuICAgIGRpdjpmaXJzdC1jaGlsZCB7XG4gICAgICBvdmVyZmxvdzogaGlkZGVuO1xuICAgICAgdGV4dC1vdmVyZmxvdzogZWxsaXBzaXM7XG4gICAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgIH1cblxuICAgIC5ibG9ja2VkLWl0ZW0ge1xuICAgICAgbWFyZ2luOiAwIDAgMCAxcmVtO1xuICAgIH1cblxuICAgIC5kcm9wZG93bi1tZW51LS10cmlnZ2VyIHtcbiAgICAgIGJvcmRlci1yYWRpdXM6IDAuM3JlbTtcbiAgICAgIHBhZGRpbmc6IDAuMXJlbSAwLjVyZW07XG4gICAgICBmb250LXNpemU6IDAuOXJlbTtcbiAgICAgIHdoaXRlLXNwYWNlOiBub3dyYXA7XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgbWFyZ2luOiAwO1xuXG4gICAgICAmLWxhYmVsIHtcbiAgICAgICAgdGV4dC10cmFuc2Zvcm06IGNhcGl0YWxpemU7XG4gICAgICB9XG4gICAgfVxuICB9XG59XG5cbi50cmFuc2Zlci1idXR0b24tc3Bpbm5lciB7XG4gIHdpZHRoOiA0cmVtO1xufVxuIl19 */