import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { colors } from '@iq/react-components';

import TopNotification from '../TopNotification';
import { getAggregatedConnectivity } from '../../../bundles/sources';

const SourceConnectivityNotification = () => {
  const [notifications, setNotifications] = useState({});
  const aggregatedConnectivity = useSelector(getAggregatedConnectivity);

  useEffect(() => {
    const updatedNotifications = { ...notifications };
    const aggEntries = Object.entries(aggregatedConnectivity);

    if (!aggEntries.length) setNotifications({});
    aggEntries.forEach(([name, status]) => {
      if (!updatedNotifications[name] && status === 0) {
        updatedNotifications[name] = {
          status,
          dismissed: false,
        };
      } else if (updatedNotifications[name]?.status !== status) {
        if (status === 0) {
          updatedNotifications[name].dismissed = false;
          updatedNotifications[name].status = status;
        } else {
          delete updatedNotifications[name];
        }
      }
    });
    setNotifications(updatedNotifications);
  }, [aggregatedConnectivity]);

  const onDismiss = (i) => {
    setNotifications((prev) => {
      const notificationKey = Object.keys(prev)[i];

      return {
        ...prev,
        [notificationKey]: {
          ...prev[notificationKey],
          dismissed: true,
        },
      };
    });
  };

  return (
    <>
      {Object.entries(notifications)
        .filter(([, status]) => !status.dismissed)
        .map(([name], i) => {
          const key = `name-${i}`;
          return (
            <TopNotification
              key={key}
              icon="he-disconnect"
              iconColor={colors.StatusRed}
              message={`${name} is disconnected!`}
              action={() => onDismiss(i)}
              actionText="Dismiss"
              activity="secondary"
            />
          );
        })}
    </>
  );
};

export default SourceConnectivityNotification;
