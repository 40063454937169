import { Button } from '@iq/react-components';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { removeSiteSpecificData } from '../../../../utils';
import LayoutImportPanel from '../components/LayoutImportPanel';
import { getLayouts } from '../../../../bundles/layouts';
import { getSites } from '../../../../bundles/sites';

export default function CloseImportButton({
  activeTree,
  applyImport,
  createPage,
  exportLayout,
  setActiveTree,
}) {
  const [showImportOptions, setShowImportOptions] = useState(false);
  const [importError, setImportError] = useState(null);
  const [importedFileName, setImportedFileName] = useState(null);
  const [selectedLayoutKey, setSelectedLayoutKey] = useState(null);
  const [importedLayout, setImportedLayout] = useState(null);
  const layouts = useSelector(getLayouts);
  const sites = useSelector(getSites);

  const resetActivePage = () => {
    setActiveTree({});
  };

  const mapSiteLayouts = () =>
    Object.keys(layouts).map((siteIdKey) => ({
      value: siteIdKey,
      label: sites.find(({ id }) => id === siteIdKey).name,
    }));

  const importLayout = (event) => {
    const [file] = event.target.files;
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      try {
        const parsedLayout = JSON.parse(text);
        setImportedLayout(parsedLayout);
        setImportedFileName(file.name);
        setImportError(null);
      } catch (e) {
        setImportError('Invalid layout file. File must contain JSON structure.');
      }
    };
    reader.readAsText(file);
  };

  const clearImport = () => {
    setShowImportOptions(false);
    setImportedFileName(null);
    setImportedLayout(null);
    setSelectedLayoutKey(null);
    setImportError(null);
  };

  useEffect(() => {
    setImportedLayout(layouts[selectedLayoutKey]);
  }, [selectedLayoutKey]);

  const _applyImport = () => {
    if (importedLayout !== null) {
      removeSiteSpecificData(importedLayout);
    }
    applyImport(importedLayout);
    clearImport();
  };
  return activeTree.page >= 0 ? (
    <Button
      onClick={resetActivePage}
      activity="secondary"
    >
      Close
    </Button>
  ) : (
    <>
      <div className="file-import">
        <Button
          onClick={() => setShowImportOptions(true)}
          activity="secondary"
        >
          Import
        </Button>
        {showImportOptions && (
          <LayoutImportPanel
            layouts={mapSiteLayouts()}
            selectedLayoutKey={selectedLayoutKey}
            onFileInput={importLayout}
            fileName={importedFileName}
            importError={importError}
            onLayoutSelection={setSelectedLayoutKey}
            disabled={!importedLayout}
            onCancel={clearImport}
            onApply={_applyImport}
          />
        )}
      </div>
      <Button
        onClick={exportLayout}
        activity="secondary"
      >
        Export
      </Button>
      <Button onClick={createPage}>Add page</Button>
    </>
  );
}
