/* eslint-disable default-param-last */
/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const SET_QR_LIST = 'dt/rq-codes/SET_QR_LIST';
export const SET_CUTTING_LINES = 'dt/qr-codes/SET_CUTTING_LINES';
export const SET_ELEMS_IN_ROW = 'dt/qr-codes/SET_ELEMS_IN_ROW';
export const SET_SCAN_FOR_PAGE = 'dt/qr-codes/SET_SCAN_FOR_PAGE';
export const CLEAR_SELECTED_QRS = 'dt/qr-codes/CLEAR_SELECTED_QRS';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const setSelectedQRS = (selectedQRS) => ({
  type: SET_QR_LIST,
  selectedQRS,
});

export const setCuttingLines = (cuttingLinesShown) => ({
  type: SET_CUTTING_LINES,
  cuttingLinesShown,
});

export const setScanForPage = (scanForPageId, scanForPagePath) => ({
  type: SET_SCAN_FOR_PAGE,
  scanForPageId,
  scanForPagePath,
});

export const setElemsInRow = (elemsInRow) => ({
  type: SET_ELEMS_IN_ROW,
  elemsInRow,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  scanForPageId: 0,
  scanForPagePath: 'overview',
  elemsInRow: 1,
  cuttingLinesShown: true,
  selectedQRS: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_QR_LIST: {
      const { selectedQRS } = action;
      return {
        ...state,
        selectedQRS,
      };
    }
    case SET_CUTTING_LINES: {
      const { cuttingLinesShown } = action;
      return {
        ...state,
        cuttingLinesShown,
      };
    }
    case SET_SCAN_FOR_PAGE: {
      const { scanForPageId, scanForPagePath } = action;
      return {
        ...state,
        scanForPageId,
        scanForPagePath,
      };
    }
    case SET_ELEMS_IN_ROW: {
      const { elemsInRow } = action;
      return {
        ...state,
        elemsInRow,
      };
    }
    case CLEAR_SELECTED_QRS: {
      return { ...state, selectedQRS: [] };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getSelectedQRS = (state) => state.qrCodes.selectedQRS;
export const getQRSSettings = (state) => state.qrCodes;
