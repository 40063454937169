/* eslint-disable default-param-last */

import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';

import {
  getUsers as getUsersApi,
  getOneUser as getOneUserApi,
  getLimitedUsers as getLimitedUsersApi,
  deleteUser as deleteUserApi,
} from '../services';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */
const REQUEST_USERS = 'dt/ad/REQUEST_USERS';
const REQUEST_LIMITED_USERS = 'dt/ad/REQUEST_LIMITED_USERS';
const RECEIVE_USERS = 'dt/ad/RECEIVE_USERS';
const RECEIVE_LIMITED_USERS = 'dt/ad/RECEIVE_LIMITED_USERS';
const REQUEST_USER = 'dt/ad/REQUEST_USER';
const RECEIVE_USER = 'dt/ad/RECEIVE_USER';
const DELETE_USER = 'dt/ad/DELETE_USER';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestUsers = () => ({
  type: REQUEST_USERS,
});

export const requestLimitedUsers = (siteId) => ({
  type: REQUEST_LIMITED_USERS,
  siteId,
});

export const receiveUsers = (users) => ({
  type: RECEIVE_USERS,
  users,
});

export const receiveLimitedUsers = (users) => ({
  type: RECEIVE_LIMITED_USERS,
  users,
});

export const requestUser = (oid) => ({
  type: REQUEST_USER,
  oid,
});

export const receiveUser = (oid, user) => ({
  type: RECEIVE_USER,
  oid,
  user,
});

export const deleteUser = (oid) => ({
  type: DELETE_USER,
  oid,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  users: {},
  limitedUsers: [],
  photos: {},
  loadingUsers: true,
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_USERS: {
      return { ...state, loadingUsers: true };
    }
    case RECEIVE_USERS: {
      const { users } = action;

      return {
        ...state,
        users,
        loadingUsers: false,
      };
    }
    case RECEIVE_LIMITED_USERS: {
      return { ...state, limitedUsers: action.users };
    }
    case RECEIVE_USER: {
      const { oid, user } = action;
      return {
        ...state,
        users: {
          ...state.users,
          [oid]: user,
        },
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        users: {},
        limitedUsers: [],
        photos: {},
        loadingUsers: true,
      };
    }
    default:
      return state;
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getMappedUsers = (state) => state.ad.users;
export const getUsers = createSelector(getMappedUsers, (mappedUsers) =>
  Object.values(mappedUsers).sort((a, b) => (a.name || '').localeCompare(b.name || ''))
);

export const getLimitedUsers = createSelector(
  (state) => state.ad.limitedUsers,
  (limitedUsers) => limitedUsers.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
);

export const getUser = createSelector(
  getMappedUsers,
  (_, objectId) => objectId,
  (mappedUsers, objectId) => mappedUsers[objectId]
);

export const getUsersLoading = (state) => state.ad.loadingUsers;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doRequestUser(action) {
  try {
    const { oid } = action;
    const user = yield call(getOneUserApi, oid);

    yield put(receiveUser(oid, user));
  } catch (e) {
    console.error('Unable to fetch user: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getOneUser', 'error')()));
  }
}

function* doRequestUsers() {
  try {
    const { values: users } = yield call(getUsersApi, { limit: 0 });
    yield put(receiveUsers(users.reduce((acc, user) => ({ ...acc, [user.id]: user }), {})));
  } catch (e) {
    console.error('Unable to fetch users: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getUsers', 'error')()));
  }
}

function* doRequestLimitedUsers(action) {
  try {
    const { siteId } = action;
    const { values: users } = yield call(getLimitedUsersApi, { site: siteId });
    yield put(receiveLimitedUsers(users));
  } catch (e) {
    console.error('Unable to fetch users: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getUsers', 'error')()));
  }
}

function* doDeleteUser(action) {
  try {
    const { oid } = action;
    yield call(deleteUserApi, oid);
    yield put(displayNotification(getNotification('deleteUser', 'success')(oid)));
  } catch (e) {
    console.error('Unable to delete user: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteUser', 'error')('delete-user')));
  }
}

export const sagas = [
  takeEvery(REQUEST_USER, doRequestUser),
  takeLatest(REQUEST_USERS, doRequestUsers),
  takeLatest(REQUEST_LIMITED_USERS, doRequestLimitedUsers),
  takeLatest(DELETE_USER, doDeleteUser),
];
