import React from 'react';

/* eslint-disable import/no-unresolved */
import QrCodeIconLink from '../../../images/icons/qr-code.svg';
/* eslint-enable import/no-unresolved */

const QrCodeIcon = ({ ...props }) => (
  <div
    className="qrcode-icon-component"
    {...props}
  >
    <img src={QrCodeIconLink} />
  </div>
);

export default QrCodeIcon;
