import { camelToSentence } from '../../../../utils';

const NON_SPARE_ASSET_STATES = [
  { const: 'installed', title: 'Installed' },
  { const: 'repair', title: 'On repair' },
  { const: 'retired', title: 'Retired' },
];
const ASSET_STATES = [...NON_SPARE_ASSET_STATES, { const: 'spare', title: 'Spare part' }];
// const REPLACEABLE_COMPONENT_TYPES = ['component']; // include group?

const buildMainDependencies = (
  component,
  commonNonSiteProps,
  areaStateSets,
  variables,
  sources
  // components,
) => {
  const statusSignalProp = {
    ...(variables.length
      ? {
          statefulVariable: {
            type: 'string',
            title: 'Stateful signal',
            placeholder: 'Select a signal',
            parseDescription: true,
            description: JSON.stringify([
              'A signal on which a defined state set has been added.',
              'Signal-based state can be displayed in the component panel and in pushpins within the signal viewer.',
            ]),
            action: 'info',
            selectOptions: variables.map((variable) => ({
              value: variable.id,
              label: variable.name,
            })),
          },
        }
      : {}),
  };

  const sourceProp = {
    source: {
      type: 'string',
      title: 'Data Source',
      default: null,
      nullable: true,
      description:
        'Linked data source for iot events. Data source is typically attached to the site component.',
      action: 'info',
      selectOptions: [
        {
          value: null,
          label: 'None',
        },
        ...sources.map((source) => ({
          value: source.id,
          label: source.name,
        })),
      ],
    },
  };

  return {
    type: {
      oneOf: [
        {
          properties: {
            type: { enum: ['virtual'] },
            ...(component.type !== 'site' ? commonNonSiteProps : {}),
            ...(areaStateSets.length
              ? {
                  stateset_id: {
                    type: 'string',
                    title: 'Area status',
                    placeholder: 'Select a state',
                    description:
                      'User-defined state displayed in the component panel and in pushpins within the signal viewer.',
                    action: 'info',
                    selectOptions: areaStateSets.map((stateset) => ({
                      value: stateset.id,
                      label: stateset.name,
                    })),
                  },
                }
              : {}),
          },
        },
        {
          properties: {
            // awaiting decision on whether group shoud be included here (have asset state, source, etc)
            type: { enum: ['component', 'group'] },
            ...(component.type !== 'site' ? commonNonSiteProps : {}),
            // ...(component.type === 'component'
            //   ? {
            //       assetState: {
            //         type: 'string',
            //         title: 'Equipment state',
            //         default: 'installed',
            //         oneOf: ASSET_STATES,
            //       },
            //     }
            //   : {}),
            ...sourceProp,
            ...statusSignalProp,
          },
          // dependencies: {
          //   assetState: {
          //     oneOf: [
          //       {
          //         properties: {
          //           assetState: { enum: NON_SPARE_ASSET_STATES.map((stateOpt) => stateOpt.const) },
          //         },
          //       },
          //       {
          //         properties: {
          //           assetState: { enum: ['spare'] },

          //           spareTo: {
          //             type: 'array',
          //             title: 'Spare part to',
          //             placeholder: 'Select component(s) this can replace',
          //             default: [],
          //             isMulti: true,
          //             uniqueItems: true,
          //             description: 'Spare part to description...',
          //             action: 'info',
          //             selectOptions: components.map((comp) => ({
          //               value: comp.id,
          //               label: `[ ${comp.itemDesignation} ] ${comp.name}`,
          //             })),
          //           },
          //         },
          //       },
          //     ],
          //   },
          // },
        },
        {
          properties: {
            type: { enum: ['site'] },
            ...sourceProp,
            ...statusSignalProp,
          },
        },
      ],
    },
  };
};

const getMainSchema = (
  component = {},
  components = [],
  stateSets = [],
  variables = [],
  sources = [],
  sourceTypes = []
) => {
  const areaStateSets = stateSets.filter((stateset) => !!stateset.isAreaStateSet);
  const filteredSources = sources.filter((s) => sourceTypes.map((st) => st.name).includes(s.type));

  const sortedCompsNotSelfOrDescendant = components
    .filter((c) => c.id !== component.id && !component.descendantIds?.includes(c.id))
    .toSorted((a, b) =>
      a.itemDesignation.toLowerCase().localeCompare(b.itemDesignation.toLowerCase())
    );

  // const sortedReplaceableCompsNotSelf = components
  //   .filter((c) => c.id !== component.id && REPLACEABLE_COMPONENT_TYPES.includes(c.type))
  //   .toSorted((a, b) =>
  //     a.itemDesignation.toLowerCase().localeCompare(b.itemDesignation.toLowerCase())
  //   );

  const commonNonSiteProps = {
    parent: {
      type: 'string',
      title: 'Parent',
      anyOf: sortedCompsNotSelfOrDescendant.map((comp) => ({
        const: comp.id,
        title: `${comp.name} (${comp.itemDesignation})`,
      })),
    },
  };

  const schema = {
    type: 'object',
    required: ['name', 'type', 'itemDesignation'],
    properties: {
      name: {
        type: 'string',
        title: 'Name',
        default: '',
      },
      itemDesignation: {
        type: 'string',
        title: 'Reference designation*',
        parseDescription: true,
        description: JSON.stringify([
          'Unique component identifier used in Hitachi Energy.',
          'Changing this may lead to unwanted consequences.',
        ]),
        action: 'info',
      },
      customId: {
        type: 'string',
        title: 'Custom ID',
        // Update this to include view in comp panel after implementation
        description: 'Alternative component identifier that can be defined by site owners.',
        action: 'info',
      },
    },

    dependencies: buildMainDependencies(
      component,
      commonNonSiteProps,
      areaStateSets,
      variables,
      filteredSources
      // sortedReplaceableCompsNotSelf
    ),
  };

  if (component.type !== 'site') {
    schema.properties.type = {
      type: 'string',
      title: 'Type*',
      default: 'component',
      parseDescription: true,
      description: JSON.stringify([
        'Equipment: Electrical / mechanical assets (replaceable)',
        'Group: Logical collection of equipment',
        'Area: Spatial representation, e.g. safety zone',
      ]),
      action: 'info',
      selectOptions: [
        { value: 'component', label: 'Equipment' },
        { value: 'group', label: 'Group' },
        { value: 'virtual', label: 'Area' },
      ],
    };
    schema.required.push('parent');
  } else {
    schema.properties.type = {
      type: 'string',
      title: 'Type*',
      default: 'site',
      description:
        'The "site" type designates the root component, from which all other components are derived.',
      action: 'info',
      selectOptions: [{ value: 'site', label: 'Site' }],
    };
  }
  return schema;
};

const VALID_ORDERED_COMPONENT_MAIN_KEYS = [
  'type',
  'name',
  'itemDesignation',
  'parent',
  'customId',
  'stateset_id',
  // 'assetState',
  // 'spareTo',
  'statefulVariable',
  'source', // component.source_id re-keyed (duplicate key error with event source keys)
];

const mainUiSchema = {
  'ui:order': VALID_ORDERED_COMPONENT_MAIN_KEYS,
  type: {
    'ui:field': 'inputWithTooltip',
  },
  itemDesignation: {
    'ui:field': 'inputWithTooltip',
  },
  customId: {
    'ui:field': 'inputWithTooltip',
  },
  source: {
    'ui:field': 'inputWithTooltip',
  },
  stateset_id: {
    'ui:field': 'inputWithTooltip',
  },
  // spareTo: {
  //   'ui:classNames': 'multi-select-array',
  //   'ui:field': 'inputWithTooltip',
  // },
  statefulVariable: {
    'ui:classNames': 'multi-select-array',
    'ui:field': 'inputWithTooltip',
  },
};

const getModelSchema = (models = [], thumbnails = []) => {
  if (!models.length) {
    return null;
  }
  return {
    type: 'object',
    title: '',
    required: ['modelId', 'customName'],
    properties: {
      modelId: {
        type: 'string',
        title: 'Model',
        placeholder: 'Select a model',
        oneOf: models.map((model) => ({
          const: model.id,
          title: `${model.name} ${model.id}`,
        })),
      },
      customName: {
        type: 'string',
        title: 'Display name',
      },
    },
    dependencies: {
      modelId: {
        oneOf: models.map((m) => {
          const hasCustomThumbnail =
            m.versions?.[0]?.thumbnail?.split(':')[1]?.split('/')[0] === 'image';
          return {
            properties: {
              modelId: {
                enum: [m.id],
              },
              image: {
                type: 'string',
                title: ' ',
                thumbnail:
                  (hasCustomThumbnail && m.versions?.[0]?.thumbnail) ||
                  thumbnails[`${m.id}/${m.versions?.[0]?.id}`],
              },
            },
          };
        }),
      },
    },
  };
};

const modelUiSchema = {
  image: {
    'ui:field': 'modelArrayField',
    'ui:classNames': 'thumbnail-field',
  },
};

const getEventSourcesSchema = (filteredEventSources = [], sourceTypes = []) => ({
  title: '',
  type: 'object',

  required: ['source_id'],
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    description: {
      type: 'string',
      title: 'Description',
    },
    source_id: {
      type: ['string', 'null'],
      title: 'Event Source',
      oneOf: [
        ...filteredEventSources.map((source) => ({
          const: source.id,
          title: source.name,
        })),
      ],
    },
  },

  dependencies: {
    source_id: {
      oneOf: [
        ...filteredEventSources.map((source) => {
          const sourceType = sourceTypes.find((st) => st.name === source.type);

          return {
            properties: {
              source_id: { enum: [source.id] },

              options: {
                type: 'object',
                title: '',
                required: sourceType.schemas.event.required,
                properties: {
                  ...Object.entries(sourceType.schemas.event.properties).reduce((acc, [k, v]) => {
                    acc[k] = { ...v, title: camelToSentence(k) };
                    return acc;
                  }, {}),
                },
              },
            },
          };
        }),
      ],
    },
  },
});

const VALID_COMPONENT_KEYS = [
  ...VALID_ORDERED_COMPONENT_MAIN_KEYS,
  'models',
  'options',
  'sourceConfig',
  'eventSources',
];

export {
  ASSET_STATES,
  getMainSchema,
  mainUiSchema,
  getModelSchema,
  modelUiSchema,
  getEventSourcesSchema,
  VALID_COMPONENT_KEYS,
};
