import './ViewerPanel.scss';

import ViewerPanel from './ViewerPanel';
import {
  getPushPinSchema,
  getViewerPanelSchema,
  getViewerPanelAdminSchema,
  viewerPanelUiSchema,
} from './viewerPanelSchema';

export {
  ViewerPanel,
  getPushPinSchema,
  getViewerPanelSchema,
  getViewerPanelAdminSchema,
  viewerPanelUiSchema,
};
