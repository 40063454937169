import React, { useEffect, useState } from 'react';

import './Tabs.scss';

const RenderTabs = ({ tabs, activeTabId, onSelect }) => (
  <>
    {tabs.map(({ id, title, ...props }) => (
      <div
        className={`avt-react-components--tab ${id === activeTabId ? 'active' : ''}`}
        key={id}
        id={id}
        onClick={() => onSelect(id)}
        {...props}
      >
        {title}
      </div>
    ))}
  </>
);

const Tabs = ({ children, onTabChange, defaultSelected, selectedTab, actions, ...props }) => {
  const tabs = React.Children.toArray(children).map(({ props: tabProps }) => tabProps);
  const [activeTabId, setActiveTabId] = useState(defaultSelected || (tabs.length && tabs[0].id));

  useEffect(() => {
    if (selectedTab) {
      setActiveTabId(selectedTab);
    }
  }, [selectedTab]);

  const activeTab = tabs.find(({ id }) => id === activeTabId);

  const onSelect = (id) => {
    setActiveTabId(id);
    if (typeof onTabChange === 'function') {
      onTabChange(id);
    }
  };

  return (
    <>
      <div
        className="avt-react-components--tabs"
        {...props}
      >
        <RenderTabs
          tabs={tabs}
          activeTabId={activeTabId}
          onSelect={onSelect}
        />
        {typeof actions !== 'undefined' && (
          <div className="avt-react-components--action">{actions}</div>
        )}
      </div>
      {activeTab !== undefined && activeTab.children}
    </>
  );
};

export const Tab = ({ id, title, children, ...props }) => (
  <div
    id={id}
    {...props}
  >
    {children}
  </div>
);

export default Tabs;
