import React, { useState, useEffect, useCallback } from 'react';
import { Icon } from '@iq/react-components';

import Delete from '../Delete';
import StyledItem from '../StyledItem';
import JSONEditor from '../JSONEditor';

/*
 * This component is a collapsible list item which uses standard styles
 * from StyledItem and expects to render and handle a JSONEditor based form.
 */
const FormListItem = ({
  context,
  entity,
  formData, // expects data that is controlled in parent state
  isOpen: isColumnOpen,
  onDelete,
  onChange = () => null,
  onSubmit,
  onCancel = () => null,
  schema,
  title,
  identifier,
  uiSchema,
  itemIndex,
  editorOnly = false,
  customValidate,
}) => {
  const [isOpen, setOpen] = useState(isColumnOpen);

  useEffect(() => {
    setOpen(isColumnOpen);
  }, [isColumnOpen]);

  const onFormChange = useCallback(
    ({ formData: data }) => {
      onChange(data, itemIndex);
    },
    [itemIndex, onChange]
  );

  const onFormSubmit = useCallback(
    ({ formData: data }) => {
      const uIdOrIndex = identifier || identifier === 0 ? identifier : itemIndex;
      onSubmit(data, uIdOrIndex);
      setOpen(false);
    },
    [identifier, itemIndex, onSubmit]
  );

  const handleDelete = useCallback(() => {
    const uIdOrIndex = identifier || identifier === 0 ? identifier : itemIndex;
    onDelete(uIdOrIndex);
    setOpen(false);
  }, [identifier, itemIndex, onDelete]);

  const onFormCancel = useCallback(() => {
    const uIdOrIndex = identifier || identifier === 0 ? identifier : itemIndex;
    onCancel(uIdOrIndex);
    setOpen(false);
  }, [setOpen, onCancel]);

  const handleToggle = () => {
    if (isOpen) {
      onFormCancel();
    } else {
      setOpen(true);
    }
  };

  return (
    <StyledItem
      itemClass="form-list-item"
      active={isOpen}
      onClick={handleToggle}
      headerContent={<div className="title">{title}</div>}
      headerActions={<Icon icon={isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'} />}
    >
      <JSONEditor
        title={`${entity} properties`}
        formData={formData}
        context={context}
        schema={schema}
        uiSchema={uiSchema}
        onFormChange={onFormChange}
        onFormSubmit={onFormSubmit}
        saveButtonText={`Update ${entity.toLowerCase()}`}
        cancelCallback={onFormCancel}
        showEditButton={false}
        initialEditMode
        customValidate={customValidate}
        editorOnly={editorOnly}
      />
      <Delete
        onDelete={handleDelete}
        title={`Delete ${entity.toLowerCase()}`}
      />
    </StyledItem>
  );
};

export default FormListItem;
