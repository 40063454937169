import thresholdSchema from './thresholdSchema';
import { units } from '../../../../units';

export const schema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      title: 'Title',
    },
    min: {
      type: 'integer',
      title: 'Minimum',
      default: 0,
    },
    max: {
      type: 'integer',
      title: 'Maximum',
      default: 100,
    },
    unit: {
      type: 'string',
      title: 'Unit',
      default: units[0].id,
      anyOf: units.map((unit) => ({
        const: unit.id,
        title: `${unit.category}: ${unit.name}`,
      })),
    },
    unitLabel: {
      type: 'string',
      title: 'Unit label',
    },
    decimals: {
      type: 'string',
      title: 'Decimals',
      default: 'auto',
      enum: ['auto', '0', '1', '2', '3', '4', '5'],
    },
    ...thresholdSchema,
  },
};

export const uiSchema = {
  thresholds: {
    areaItems: {
      'ui:options': {
        orderable: false,
      },
    },
    linearItems: {
      'ui:options': {
        orderable: false,
      },
    },
  },
};
