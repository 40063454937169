import React from 'react';
import { Icon } from '@iq/react-components';

const FileListBookmarks = ({ file, onPreview, bookmarks, bookmarkListToggled }) => {
  if (!bookmarkListToggled) {
    return null;
  }

  const sortedBookmarks = bookmarks.sort((a, b) => {
    const sortKey = file.fileType === 'video' ? 'time' : 'page';
    return a.bookmarkData[sortKey] - b.bookmarkData[sortKey];
  });

  return (
    <div className="file-list-bookmarks">
      {sortedBookmarks.map((bookmark) => (
        <div
          className="bookmark-list-item"
          key={bookmark.id}
          onClick={() => onPreview(file, bookmark)}
        >
          <div className="bookmark-name">
            <Icon
              icon="bookmark"
              size="m"
            />
            <p>{bookmark.name}</p>
          </div>
          <div className="bookmark-context">
            {bookmark.bookmarkData.page
              ? `Page ${bookmark.bookmarkData.page}`
              : `Time ${bookmark.bookmarkData.time}s`}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FileListBookmarks;
