import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { colors, useTheme } from '@iq/react-components';
import { scaleTime, scaleOrdinal, scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Grid } from '@visx/grid';
import { localPoint } from '@visx/event';
import {
  useTooltip,
  useTooltipInPortal,
  defaultStyles as defaultTooltipStyle,
} from '@visx/tooltip';

import {
  usePanelDateDomain,
  useSelectedDateDomain,
  useActiveTimelineData,
  useOnSelectedDateDomainChange,
  useStyling,
  useZScale,
  useEventTypeFilter,
} from './EventTimelineState';
import { IotBucketTooltip, IotEventTooltip } from './EventTooltips';
import { getTimezone } from '../../../bundles/application';
import { useClientSize } from '../../../utils';
import { getDateTickFormatter } from '../../../datetimeUtils';
import EventLegends from './EventLegends';
import { EVENT_DEFAULT_MARGIN, EVENT_TYPE_ALL, EVENT_TYPE_IOT } from '../../../constants';
import { formatYTick, useEventPicker } from './utils';

function renderIotEventTooltip(event, timezone) {
  return {
    eventId: event.id,
    tooltip: (
      <IotEventTooltip
        event={event}
        timezone={timezone}
      />
    ),
  };
}

function renderIotBucketTooltip(bucket, timezone) {
  return {
    tooltip: (
      <IotBucketTooltip
        bucket={bucket}
        timezone={timezone}
      />
    ),
  };
}

const IotTimeline = ({ margin = EVENT_DEFAULT_MARGIN, plotSize = 4, onOpenEvent, fullWidth }) => {
  const timezone = useSelector(getTimezone);
  const theme = useTheme();
  const plotSize2 = plotSize * 2;

  const zScale = useZScale();

  const [{ width }, clientRef] = useClientSize();

  const { tickStroke, background, indicatorStroke, xTickLabelProps, yTickLabelProps } =
    useStyling();

  const selectedDateDomain = useSelectedDateDomain();
  const panelDateDomain = usePanelDateDomain();
  const [eventTypeFilter] = useEventTypeFilter();
  const onZoomChange = useOnSelectedDateDomainChange();

  let periodInMs = panelDateDomain ? panelDateDomain[1] - panelDateDomain[0] : 0;

  if (selectedDateDomain) {
    periodInMs = selectedDateDomain[1] - selectedDateDomain[0];
  }

  const hitMaxZoom = periodInMs <= 2;

  const {
    response: {
      userEvents = [],
      userEventTypes = [],
      iotBuckets = [],
      dateDomain = selectedDateDomain?.length ? selectedDateDomain : panelDateDomain,
    } = {},
  } = useActiveTimelineData();

  const userEventsRef = useRef(userEvents);
  userEventsRef.current = userEvents;
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });
  const onOpenIOTEvent = (event) => {
    if (event && onOpenEvent) {
      onOpenEvent({ event, id: event.id, type: 'iot' });
    }
  };

  const userEventHeight = 25;
  const paddingBottom = userEventHeight;
  const iotEventHeight = userEventHeight * 4;

  const height = iotEventHeight + margin.top + margin.bottom + paddingBottom;

  const xSize = width - margin.right - margin.left;
  const xScale = useMemo(
    () =>
      scaleTime({
        domain: [dateDomain[0], dateDomain[1]],
        range: [margin.left, width - margin.right],
        clamp: true,
      }),
    [dateDomain, width]
  );
  const yScale = useMemo(() => {
    const withIot = iotBuckets.length > 0;
    const domain = withIot ? ['iot'] : ['default'];
    let range = [margin.top + iotEventHeight / 2];
    if (!withIot && userEventTypes.length === 0) {
      range = [margin.top + iotEventHeight / 2];
    }
    return scaleOrdinal({ domain, range });
  }, [iotBuckets.length, userEventTypes]);

  const { maxNumEvents, minNumEvents } = useMemo(() => {
    const values = iotBuckets.filter((d) => d.numEvents > 1).map((d) => d.numEvents);
    return {
      maxNumEvents: Math.max(...values),
      minNumEvents: Math.min(...values),
    };
  }, [iotBuckets]);

  const heightScale = useMemo(
    () =>
      scaleLinear({
        domain: [Math.max(0, minNumEvents), Math.max(100, maxNumEvents)],
        range: [4, iotEventHeight],
      }),
    [iotBuckets]
  );

  const maxBucketIndex = useMemo(
    () => iotBuckets.findIndex((item) => item.numEvents === maxNumEvents),
    [iotBuckets, maxNumEvents]
  );

  const minBucketIndex = useMemo(
    () => iotBuckets.findIndex((item) => item.numEvents === minNumEvents),
    [iotBuckets, minNumEvents]
  );

  const dragState = useRef({
    isDragging: false,
    startX: 0,
  });
  const zoomRef = useRef();

  const onDragStart = useCallback((e) => {
    dragState.current.isDragging = true;
    const { x } = localPoint(e);
    dragState.current.startX = x;
  }, []);
  const onDrag = useCallback(
    (e) => {
      if (dragState.current.isDragging) {
        const { x: x2 } = localPoint(e);
        const x = dragState.current.startX;
        const min = Math.max(xScale.range()[0], Math.min(x, x2));
        const max = Math.min(xScale.range()[1], Math.max(x, x2));
        if (zoomRef.current) {
          const el = zoomRef.current;
          el.setAttribute('x', min);
          el.setAttribute('width', Math.max(0, max - min));
        }
      }
    },
    [xScale]
  );
  const onDragEnd = useCallback(
    (e) => {
      if (dragState.current.isDragging) {
        const { x: endX } = localPoint(e);
        dragState.current.isDragging = false;
        if (endX !== dragState.current.startX && !hitMaxZoom) {
          const cx1 = Math.min(endX, dragState.current.startX);
          const cx2 = Math.max(endX, dragState.current.startX);
          onZoomChange([xScale.invert(cx1).getTime(), xScale.invert(cx2).getTime(), null]);
        }
        if (zoomRef.current) {
          const el = zoomRef.current;
          el.setAttribute('x', 0);
          el.setAttribute('width', 0);
        }
      }
    },
    [xSize, onZoomChange, xScale]
  );

  const criticalColor = zScale('critical');
  const warningColor = zScale('warning');
  const infoColor = zScale('info');

  const iotGrads = useMemo(
    () => (
      <defs>
        {iotBuckets.map((bucket, bucketIndex) => {
          if (bucket.numEvents < 2) {
            return null;
          }

          const { critical, warning } = bucket.numEventsPerType.reduce(
            (sum, item) => ({
              ...sum,
              [item.severity]: Math.max(5, Math.ceil((item.count / bucket.numEvents) * 100)),
            }),
            {
              critical: 0,
              warning: 0,
              minor: 0,
              info: 0,
              internal: 0,
            }
          );
          return (
            <linearGradient
              key={bucketIndex}
              id={`bucket-gradient-${bucketIndex}`}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop
                offset="0%"
                style={{ stopColor: criticalColor, stopOpacity: 1 }}
              />
              <stop
                offset={`${critical}%`}
                style={{ stopColor: criticalColor, stopOpacity: 1 }}
              />
              <stop
                offset={`${critical}%`}
                style={{ stopColor: warningColor, stopOpacity: 1 }}
              />
              <stop
                offset={`${warning + critical}%`}
                style={{ stopColor: warningColor, stopOpacity: 1 }}
              />
              <stop
                offset={`${warning + critical}%`}
                style={{ stopColor: infoColor, stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: infoColor, stopOpacity: 1 }}
              />
            </linearGradient>
          );
        })}
      </defs>
    ),
    [iotBuckets, criticalColor, warningColor, infoColor]
  );

  const [eventPicker, openEventPicker] = useEventPicker();
  const iotShapes = useMemo(
    () => (
      <>
        {iotBuckets.map((bucket, bucketIndex) => {
          if (bucket.numEvents === 1) {
            const event = bucket.events[0];
            const mouseOver = (e) => {
              const coords = localPoint(e.target.ownerSVGElement, e);
              showTooltip({
                tooltipLeft: coords.x,
                tooltipTop: coords.y,
                tooltipData: renderIotEventTooltip(event, timezone),
              });
            };
            return (
              <rect
                className="timeline-chart-event"
                key={bucket.from}
                x={xScale(Number(event.timestamp)) - plotSize}
                y={yScale('iot') - plotSize}
                rx={plotSize}
                ry={plotSize}
                width={plotSize2}
                height={plotSize2}
                fill={zScale(event.severity)}
                onMouseOver={mouseOver}
                onMouseOut={hideTooltip}
                onClick={() => onOpenIOTEvent(event)}
              />
            );
          }
          const onClick = (e) => {
            if (hitMaxZoom) {
              const { x, y, width: w, height: h } = e.target.getBBox();
              const { top, right } = e.target.getBoundingClientRect();
              const placeAbove = top > window.innerHeight / 2;
              const alignLeft = right < (window.innerWidth * 2) / 3;

              openEventPicker({
                events: bucket.events,
                onSelect: onOpenIOTEvent,
                position: {
                  placeAbove,
                  alignLeft,
                  top: placeAbove ? y : y + h,
                  left: alignLeft ? x : x + w,
                },
                timezone,
              });
            } else if (bucket.numEvents < 10 && !hitMaxZoom) {
              const timestamps = bucket.events.map((ev) => Number(ev.timestamp));
              const from = Math.min(...timestamps);
              const to = Math.max(...timestamps);
              const diff = Math.max(1, to - from);
              onZoomChange([from - diff, to + diff, null]);
            } else if (bucket.numEvents >= 10) {
              onZoomChange([Number(bucket.from), Number(bucket.to), null]);
            }
            hideTooltip();
          };
          const mouseOver = (e) => {
            const coords = localPoint(e.target.ownerSVGElement, e);
            showTooltip({
              tooltipLeft: coords.x,
              tooltipTop: coords.y,
              tooltipData: renderIotBucketTooltip(bucket, timezone),
            });
          };

          const { numEvents } = bucket;
          const x1 = xScale(Number(bucket.from)) + 1;
          const x2 = xScale(Number(bucket.to)) - 1;
          const y = yScale('iot');
          const isHighest = bucketIndex === maxBucketIndex;
          const numLabelY = (isHighest ? y - 10 : y) - iotEventHeight / 2 - 4;
          const x = x1 + (x2 - x1) / 2;
          const scaledStart = xScale(dateDomain[0]);
          const scaledEnd = xScale(dateDomain[1]);
          const showNumLabel =
            x > scaledStart && x < scaledEnd && (bucketIndex === minBucketIndex || isHighest);
          let textAnchor = 'middle';
          if (x < scaledEnd / 3) {
            textAnchor = 'start';
          }
          if (x > (scaledEnd / 3) * 2) {
            textAnchor = 'end';
          }

          return (
            <React.Fragment key={bucket.from}>
              {showNumLabel && (
                <>
                  <text
                    x={x}
                    y={numLabelY - 5}
                    {...xTickLabelProps()}
                    textAnchor={textAnchor}
                  >
                    {numEvents} events
                  </text>
                  <line
                    x1={x}
                    x2={x}
                    y1={y}
                    y2={numLabelY - 2}
                    stroke={tickStroke}
                  />
                </>
              )}
              <rect
                className="timeline-chart-event"
                x={x1}
                y={y - (plotSize2 + heightScale(numEvents)) / 2}
                rx={plotSize}
                ry={plotSize}
                width={Math.max(0, x2 - x1)}
                height={plotSize2 + heightScale(numEvents)}
                fill={`url(#bucket-gradient-${bucketIndex})`}
                onClick={onClick}
                onMouseOver={mouseOver}
                onMouseOut={hideTooltip}
              />
            </React.Fragment>
          );
        })}
      </>
    ),
    [
      onZoomChange,
      hideTooltip,
      showTooltip,
      xScale,
      yScale,
      zScale,
      heightScale,
      iotBuckets,
      plotSize,
      plotSize2,
    ]
  );

  const presentIndicator = useMemo(() => {
    const now = Date.now();

    if (dateDomain[1] - now <= 0) {
      return null;
    }

    const x = xScale(now) || 0;
    return (
      <line
        x1={x}
        y1={0}
        x2={x}
        y2={height - margin.bottom}
        stroke={indicatorStroke}
        strokeOpacity={0.5}
      />
    );
  }, [xScale, height, dateDomain]);

  return (
    <>
      {[EVENT_TYPE_IOT, EVENT_TYPE_ALL].includes(eventTypeFilter) && (
        <>
          <EventLegends
            fullWidth={fullWidth}
            type={'iot'}
          />
          <div
            ref={clientRef}
            className="event-timeline-chart-area"
          >
            <svg
              width={width}
              height={height}
              ref={containerRef}
              onTouchMove={onDrag}
              onTouchEnd={onDragEnd}
              onMouseUp={onDragEnd}
              onMouseLeave={onDragEnd}
              onMouseMove={onDrag}
            >
              {presentIndicator}
              {iotGrads}
              <rect
                ref={zoomRef}
                fillOpacity={0}
                stroke="currentColor"
                strokeDasharray="1,2"
                width={0}
                height={height - margin.top - margin.bottom - 10}
                y={margin.top - 5}
              />
              <rect
                fillOpacity={0}
                x={margin.left}
                stroke={tickStroke}
                width={Math.max(0, width - margin.left - margin.right - 2)}
                height={height - margin.bottom}
                onTouchStart={onDragStart}
                onMouseDown={onDragStart}
              />
              <Grid
                xScale={xScale}
                yScale={yScale}
                width={Math.max(0, width - margin.left - margin.right)}
                height={height}
                left={margin.left}
                columnLineStyle={{ display: 'none' }}
                stroke={tickStroke}
              />
              {iotShapes}

              <rect
                // covers any overflow from chart
                x={0}
                y={0}
                width={margin.left}
                height={height}
                fill={background}
              />
              <AxisLeft
                scale={yScale}
                left={margin.left}
                hideAxisLine
                stroke={tickStroke}
                tickStroke="transparent"
                tickLabelProps={yTickLabelProps}
                tickFormat={(val) => formatYTick(val)}
              />
              <AxisBottom
                scale={xScale}
                top={height - margin.bottom}
                hideAxisLine
                stroke={tickStroke}
                tickStroke={tickStroke}
                tickLabelProps={xTickLabelProps}
                numTicks={Math.max(Math.floor(xSize / 80), 1)}
                tickFormat={getDateTickFormatter(periodInMs, timezone, {
                  scale: xScale,
                  width: width - margin.right,
                })}
              />
              <rect
                // covers removed end tick line
                x={width - margin.right - 20}
                y={height - margin.bottom + 1}
                width={30}
                height={8}
                fill={background}
              />
            </svg>
            {tooltipOpen && (
              <TooltipInPortal
                // set this to random so it correctly updates with parent bounds
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft}
                style={{
                  ...defaultTooltipStyle,
                  padding: 0,
                  borderRadius: '0.2rem',
                  backgroundColor: 'transparent',
                  border: `1px solid ${theme === 'dark' ? colors.Grey85 : colors.Grey10}`,
                  zIndex: 10000,
                }}
              >
                {tooltipData.tooltip}
              </TooltipInPortal>
            )}
            {eventPicker}
          </div>
        </>
      )}
    </>
  );
};

export default IotTimeline;
