.job-list-item.styled-item .job-status-bar {
  width: 1rem;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.job-list-item.styled-item .styled-item__header {
  cursor: default;
}
.job-list-item.styled-item .job-status {
  width: 120px;
}
.job-list-item.styled-item .job-error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}
.job-list-item.styled-item .job-error__content {
  padding: 1rem;
}
.job-list-item.styled-item .job-error__content pre {
  margin-top: 10px;
  padding: 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvVGFza0RldGFpbFZpZXcvY29tcG9uZW50cy9Kb2JMaXN0SXRlbSIsInNvdXJjZXMiOlsiSm9iTGlzdEl0ZW0uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7O0FBSUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUE7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmpvYi1saXN0LWl0ZW0uc3R5bGVkLWl0ZW0ge1xuICAuam9iLXN0YXR1cy1iYXIge1xuICAgIHdpZHRoOiAxcmVtO1xuICAgIGJvcmRlci10b3AtbGVmdC1yYWRpdXM6IDAuM3JlbTtcbiAgICBib3JkZXItYm90dG9tLWxlZnQtcmFkaXVzOiAwLjNyZW07XG4gIH1cblxuICAuc3R5bGVkLWl0ZW1fX2hlYWRlciB7XG4gICAgY3Vyc29yOiBkZWZhdWx0O1xuICB9XG5cbiAgLmpvYi1zdGF0dXMge1xuICAgIHdpZHRoOiAxMjBweDsgLy8gaGFuZGxlcyBsb25nZXN0IHN0YXR1cywgXCJET1dOTE9BRElOR1wiXG4gIH1cblxuXG4gIC5qb2ItZXJyb3Ige1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gICAgYWxpZ24taXRlbXM6IGZsZXgtc3RhcnQ7XG4gICAgb3ZlcmZsb3c6IGF1dG87XG5cbiAgICAmX19jb250ZW50IHtcbiAgICAgIHBhZGRpbmc6IDFyZW07XG5cbiAgICAgIHByZSB7XG4gICAgICAgIG1hcmdpbi10b3A6IDEwcHg7XG4gICAgICAgIHBhZGRpbmc6IDAuNXJlbTtcbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */