import React from 'react';

import VisualizationView from '../../../../../VisualizationView';

const VisualizeStep = ({ wizardType }) => (
  <>
    <div className="wizard-step-main__body--framed custom-thin-scrollbar">
      <VisualizationView wizardType={wizardType} />
    </div>
  </>
);

export default VisualizeStep;
