import React, { useMemo, useRef, useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns-tz';
import { Spinner, Icon, Button, Can, contrastChart } from '@iq/react-components';
import classnames from 'classnames';
import { getActiveSite } from '../../../../bundles/sites';
import { useSubscription, getSingleVisualization } from '../../../../bundles/visualizations';
import { getTimezone } from '../../../../bundles/application';
import { getSiteVariablesIndex } from '../../../../bundles/sources';
import { useClientSize } from '../../../../utils';
import { utcToSite } from '../../../../datetimeUtils';
import { format as unitFormat } from '../../../../units';

import Loader from '../../../Loader';

function getCoordinates({ M1, M2, M3 }) {
  const [X, Y] = [
    (0 + M2 * Math.cos((7 * Math.PI) / 6) + M3 * Math.cos((11 * Math.PI) / 6)).toFixed(2),
    (M1 + M2 * Math.sin((7 * Math.PI) / 6) + M3 * Math.sin((11 * Math.PI) / 6)).toFixed(2),
  ];

  return [-X, -Y];
}

const StarVisualization = memo(
  ({
    visualization,
    panelId,
    pollingDateRange,
    onExportData,
    isPreview,
    selected,
    onSelect,
    openSignalViewer,
  }) => {
    const dispatch = useDispatch();
    const site = useSelector(getActiveSite);
    const timezone = useSelector(getTimezone);
    const variablesIndex = useSelector(getSiteVariablesIndex);
    const [open, setIsOpen] = useState(false);

    const [showPointTooltip, setShowPointTooltip] = useState(false);
    const [pointTooltipPos, setPointTooltipPos] = useState({ x: 0, y: 0 });
    const [pointTooltipData, setPointTooltipData] = useState({
      label: '',
      time: 0,
      M1: 0,
      M2: 0,
      M3: 0,
      color: '',
    });

    const {
      initialLoaded,
      seriesValues: values,
      loading,
    } = useSubscription(visualization.id, panelId);
    const { variables = [], configuration = {} } = visualization;
    const [{ height, width }, clientRef] = useClientSize();
    const ref = useRef(null);

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleToggleOpen = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsOpen((prev) => !prev);
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (initialLoaded && !selected) {
        dispatch(getSingleVisualization(visualization, panelId));
      }
    }, [
      panelId,
      initialLoaded,
      JSON.stringify(`${pollingDateRange.startDate}:${pollingDateRange.endDate}`),
    ]);

    const data = useMemo(() => {
      if (!values) return [];
      const hasConfig = variables.some((variable) =>
        values.some((value) => value.variable === variable.id)
      );
      if (!hasConfig) return [];
      return values.reduce((acc, { values: dataValues, name, granularity }) => {
        dataValues.forEach(([time, value, quality]) => {
          const parsedVal = parseFloat(value);
          const cleanedVal = Number.isNaN(parsedVal) ? undefined : parsedVal;

          let axisAlignedValue = cleanedVal;

          if (quality !== 1 && !isPreview) axisAlignedValue = null;

          const index = acc.findIndex((entry) => entry.time === time);
          if (index === -1) {
            const insertIndex = acc.findIndex(
              (_, i) => acc[i].time > time && (acc[i - 1] || {}).time < time
            );
            if (insertIndex === -1) {
              acc.push({
                time,
                [name]: axisAlignedValue,
                granularity,
              });
            } else {
              // eslint-disable-next-line no-param-reassign
              acc = [
                ...acc.slice(0, insertIndex),
                {
                  time,
                  [name]: axisAlignedValue,
                  granularity,
                },
                ...acc.slice(insertIndex),
              ];
            }
          } else {
            acc[index] = {
              ...acc[index],
              [name]: axisAlignedValue,
            };
          }
        });
        return acc;
      }, []);
    }, [values, variables, variablesIndex, isPreview]);

    const series = useMemo(
      () =>
        variables.map(({ id, aggregate, label, unit, decimals }) => {
          const { name } = (variablesIndex || {})[id] || {};
          const seriesId = `${id}.${aggregate}`;
          const displayUnit =
            unit === 'variable-default' ? (variablesIndex || {})[id]?.unit || 'number' : unit;

          return {
            variableId: id,
            id: seriesId,
            label: label || name,
            decimals: decimals || 'auto',
            unit: displayUnit,
            aggregate,
          };
        }),
      [variables, variablesIndex, configuration]
    );
    const filteredData = useMemo(() => {
      return data.filter((d) => {
        return d[configuration.plot?.L1] && d[configuration.plot?.L2] && d[configuration.plot?.L3];
      });
    }, [data]);

    const rollingData = useMemo(() => {
      let Xrolling = 0;
      let Yrolling = 0;

      return (
        series.length >= 3 &&
        filteredData?.map((value, index) => {
          const [X, Y] = getCoordinates({
            M1: value[configuration.plot?.L1 || series[0].id],
            M2: value[configuration.plot?.L2 || series[1].id],
            M3: value[configuration.plot?.L3 || series[2].id],
          });

          Xrolling =
            index === 0
              ? X
              : configuration.rollingAverage.decay * Xrolling +
                (1 - configuration.rollingAverage.decay) * X;

          Yrolling =
            index === 0
              ? Y
              : configuration.rollingAverage.decay * Yrolling +
                (1 - configuration.rollingAverage.decay) * Y;
          return { X: Xrolling, Y: Yrolling };
        })
      );
    }, [configuration.rollingAverage, data]);

    const tooltipLabelFormatter = (time) => {
      const dateFormat = 'yyyy-MM-dd, HH:mm:ss';
      return format(utcToSite(time, timezone), dateFormat, { timeZone: timezone });
    };

    function PointTooltip() {
      return (
        <div
          className="tooltip"
          style={{ left: pointTooltipPos.x, top: pointTooltipPos.y }}
        >
          <div className="tooltip-time">
            {pointTooltipData.time === 0 ? 'Error' : tooltipLabelFormatter(pointTooltipData.time)}
          </div>
          <div style={{ color: pointTooltipData.color }}>
            <div className="tooltip-item">{configuration.plot?.label}</div>
            <div className="tooltip-item">
              {series.find((s) => s.id === configuration.plot?.L1).label || series[0].label} :{' '}
              {pointTooltipData.M1}
            </div>
            <div className="tooltip-item">
              {series.find((s) => s.id === configuration.plot?.L2).label || series[1].label} :{' '}
              {pointTooltipData.M2}
            </div>
            <div className="tooltip-item">
              {series.find((s) => s.id === configuration.plot?.L3).label || series[2].label} :{' '}
              {pointTooltipData.M3}
            </div>
          </div>
        </div>
      );
    }

    const handleDownloadClick = () => {
      onExportData(data[0].granularity);
    };
    const handleSelect = () => {
      onSelect(visualization);
    };
    const classes = classnames('menu', { open });

    const vizHeight = height - (!isPreview ? 28 : 16) - (configuration.legend?.show ? 32 : 0);
    // less loader padding
    const loaderHeight = vizHeight - 28;
    const vizSize = vizHeight < width ? vizHeight : width;

    const scaleMemo = useMemo(() => {
      if (!filteredData.length) {
        if (configuration.scale?.scaleMode === 'Automatic') {
          // Initial value for automatic to scale off once data is loaded
          return [0.2];
        }

        return [
          0.01 *
            ((vizSize / 2 -
              -getCoordinates({ M1: configuration.scale?.value || 1, M2: 0, M3: 0 })[1] * 0.01) /
              ((-getCoordinates({ M1: configuration.scale?.value || 1, M2: 0, M3: 0 })[1] || 1) *
                0.01)),
        ];
      }
      const coords =
        filteredData.length &&
        getCoordinates({
          M1: filteredData[0][configuration.plot?.L1 || series[0].id],
          M2: filteredData[0][configuration.plot?.L2 || series[1].id],
          M3: filteredData[0][configuration.plot?.L3 || series[2].id],
        });

      // Variable to put largest coordinate into
      let hold = 0;

      // Mapping over data using the selected vectors or the 3 first signals
      filteredData?.forEach((d) => {
        const c = getCoordinates({
          M1: d[configuration.plot?.L1 || series[0].id],
          M2: d[configuration.plot?.L2 || series[1].id],
          M3: d[configuration.plot?.L3 || series[2].id],
        });

        // If coords(?) set l to largest absolute (counting negative as positive) value of X or Z
        const l = coords ? Math.max(parseFloat(Math.abs(c[0])), parseFloat(Math.abs(c[1]))) : '';

        // If l is larger than hold (previous largest) set hold to this new largest
        if (l > hold) {
          hold = l;
        }
      });

      const largest =
        configuration.scale?.scaleMode === 'Automatic'
          ? hold * 1.1
          : -getCoordinates({ M1: configuration.scale?.value || 1, M2: 0, M3: 0 })[1];

      const distToEdge = vizSize / 2;

      const scaleFactor = distToEdge / ((largest || 1) * 0.01);

      return [0.01 * scaleFactor, largest];
    }, [data, configuration.scale, vizSize]);
    const [scale, largest] = scaleMemo;

    function DrawLinearThreshold({ threshold }) {
      function getThresholdCoordinates(pos) {
        switch (pos) {
          case 'first': {
            const coordinates = getCoordinates({
              M1: filteredData[0][configuration.plot?.L1 || series[0].id],
              M2: filteredData[0][configuration.plot?.L2 || series[1].id],
              M3: filteredData[0][configuration.plot?.L3 || series[2].id],
            });
            return {
              cx: vizSize / 2 + coordinates[0] * scale,
              cy: vizSize / 2 + coordinates[1] * scale,
            };
          }
          case 'custom': {
            const coordinates = getCoordinates({
              M1: configuration.thresholdsPosition.manualPos?.L1 || 0,
              M2: configuration.thresholdsPosition.manualPos?.L2 || 0,
              M3: configuration.thresholdsPosition.manualPos?.L3 || 0,
            });
            return {
              cx: vizSize / 2 + coordinates[0] * scale,
              cy: vizSize / 2 + coordinates[1] * scale,
            };
          }
          case 'center':
          default: {
            const coordinates = getCoordinates({
              M1: 0,
              M2: 0,
              M3: 0,
            });
            return {
              cx: vizSize / 2 + coordinates[0] * scale,
              cy: vizSize / 2 + coordinates[1] * scale,
            };
          }
        }
      }
      return (
        <circle
          {...getThresholdCoordinates(
            filteredData.length && configuration.thresholdsPosition?.selection
          )}
          r={threshold.value * scale > 0 ? threshold.value * scale : 0}
          fill={'none'}
          stroke={threshold.fill}
          strokeWidth={2}
        />
      );
    }

    function DrawAreaThreshold({ threshold }) {
      function getThresholdCoordinates(pos) {
        switch (pos) {
          case 'first': {
            const coordinates = getCoordinates({
              M1: filteredData[0][configuration.plot?.L1 || series[0].id],
              M2: filteredData[0][configuration.plot?.L2 || series[1].id],
              M3: filteredData[0][configuration.plot?.L3 || series[2].id],
            });
            return {
              cx: vizSize / 2 + coordinates[0] * scale,
              cy: vizSize / 2 + coordinates[1] * scale,
            };
          }
          case 'custom': {
            const coordinates = getCoordinates({
              M1: configuration.thresholdsPosition.manualPos?.L1 || 0,
              M2: configuration.thresholdsPosition.manualPos?.L2 || 0,
              M3: configuration.thresholdsPosition.manualPos?.L3 || 0,
            });
            return {
              cx: vizSize / 2 + coordinates[0] * scale,
              cy: vizSize / 2 + coordinates[1] * scale,
            };
          }
          case 'center':
          default: {
            const coordinates = getCoordinates({
              M1: 0,
              M2: 0,
              M3: 0,
            });
            return {
              cx: vizSize / 2 + coordinates[0] * scale,
              cy: vizSize / 2 + coordinates[1] * scale,
            };
          }
        }
      }
      return (
        <>
          <circle
            {...getThresholdCoordinates(
              filteredData.length && configuration.thresholdsPosition?.selection
            )}
            r={
              (threshold.max > threshold.min
                ? ((threshold.min + threshold.max) / 2) * scale
                : threshold.min * scale) || 0
            }
            stroke={threshold.fill}
            fill="none"
            opacity={'50%'}
            strokeWidth={
              threshold.max > threshold.min ? (threshold.max - threshold.min) * scale : 2
            }
          />
        </>
      );
    }

    function DrawAxes() {
      function Axis({ coords, stroke }) {
        return (
          <line
            x1={vizSize / 2}
            y1={vizSize / 2}
            x2={vizSize / 2 + coords[0]}
            y2={vizSize / 2 + coords[1]}
            stroke={stroke}
          />
        );
      }

      return (
        <>
          <Axis
            coords={getCoordinates({ M1: 500, M2: 0, M3: 0 })}
            stroke={'blue'}
          />
          <Axis
            coords={getCoordinates({ M1: 0, M2: 500, M3: 0 })}
            stroke={'red'}
          />
          <Axis
            coords={getCoordinates({ M1: 0, M2: 0, M3: 500 })}
            stroke={'green'}
          />
        </>
      );
    }

    function getAxisCoordinates({ axisValues }) {
      let X = 0;
      let Y = 0;

      axisValues.forEach((value, index) => {
        X += value * Math.cos((((12 / axisValues.length) * index + 3) * Math.PI) / 6);
        Y += value * Math.sin((((12 / axisValues.length) * index + 3) * Math.PI) / 6);
      });

      return [-X, -Y];
    }

    function RadialGrid() {
      const maxTicks = 20;

      // Used when radialGrid.size is unassigned to automatically create good tick values to fit ~4 ticks.
      // Rounding of the most signifigant digit
      function tickRounder(val, split) {
        if (val === 0) {
          return 0;
        }
        return (
          Math.round((val / split) * 10 ** Math.ceil(-Math.log10(val / split))) /
          10 ** Math.ceil(-Math.log10(val / split))
        );
      }

      const forceAutomaticTicks =
        Math.ceil(
          vizSize > 0
            ? Math.sqrt(vizSize ** 2 * 2) / 2 / (configuration.radialGrid.size * scale)
            : 0
        ) > maxTicks;

      const tick =
        configuration.radialGrid?.size && !forceAutomaticTicks
          ? configuration.radialGrid.size * scale
          : tickRounder(largest, 4) * scale;

      const tickAmount = Math.ceil(vizSize > 0 ? Math.sqrt(vizSize ** 2 * 2) / 2 / tick : 0);

      function Axis({ coords }) {
        return (
          <line
            className="grid-axis"
            x1={vizSize / 2}
            y1={vizSize / 2}
            x2={vizSize / 2 + coords[0]}
            y2={vizSize / 2 + coords[1]}
          />
        );
      }

      return (
        <g className="star-grid">
          {[...Array(configuration.radialGrid?.axes)].map((vector, i) => {
            return (
              <Axis
                key={i}
                coords={getAxisCoordinates({
                  axisValues: [...Array(configuration.radialGrid?.axes)].map((v, j) => {
                    return i === j ? 500 : 0;
                  }),
                })}
              />
            );
          })}

          {tick > 0 &&
            [...Array(tickAmount)].map((c, idx) => {
              return (
                <circle
                  className="grid-radius"
                  key={idx}
                  cx="50%"
                  cy="50%"
                  r={tick * idx}
                />
              );
            })}
          <line
            className="grid-axis-line"
            x1={vizSize / 2}
            y1={vizSize / 2}
            x2={0}
            y2={vizSize / 2}
          />
          {tick > 0 &&
            [...Array(tickAmount)].map((t, idx) => {
              return (
                <g key={idx}>
                  <line
                    x1={vizSize / 2 - tick * idx}
                    y1={vizSize / 2 - 5}
                    x2={vizSize / 2 - tick * idx}
                    y2={vizSize / 2}
                    className="grid-tick"
                  />
                  <g>
                    <text
                      className="star-grid-text"
                      x={vizSize / 2 - tick * idx}
                      y={vizSize / 2 + 2}
                      style={{
                        transformOrigin: `${vizSize / 2 - tick * idx}px ${vizSize / 2 + 2}px`,
                        rotate: '90deg',
                        translate: '-3px 2px',
                      }}
                    >
                      {configuration.radialGrid?.size && !forceAutomaticTicks
                        ? configuration.radialGrid.size * idx
                        : parseFloat(parseFloat((tick / scale) * idx).toPrecision(10))}
                    </text>
                  </g>
                </g>
              );
            })}
        </g>
      );
    }

    function Point({ fill, value, index, pointSeries }) {
      const [X, Y] = getCoordinates({ ...value });

      return (
        <circle
          className="point"
          onMouseOver={(e) => {
            if (showPointTooltip) {
              return;
            }
            setShowPointTooltip(true);
            setPointTooltipPos({ x: e.clientX + 10, y: e.clientY + 10 });
            setPointTooltipData({
              M1: `${unitFormat(pointSeries.M1.unit, value.M1, pointSeries.M1.decimals).value} ${
                unitFormat(pointSeries.M1.unit, value.M1, pointSeries.M1.decimals).unit
              }`,
              M2: `${unitFormat(pointSeries.M2.unit, value.M2, pointSeries.M2.decimals).value} ${
                unitFormat(pointSeries.M2.unit, value.M2, pointSeries.M2.decimals).unit
              }`,
              M3: `${unitFormat(pointSeries.M3.unit, value.M3, pointSeries.M3.decimals).value} ${
                unitFormat(pointSeries.M3.unit, value.M3, pointSeries.M3.decimals).unit
              }`,
              time: value.time,
              color: '',
            });
          }}
          onMouseOut={() => {
            setShowPointTooltip(false);
          }}
          cx={parseFloat(vizSize) / 2 + parseFloat(X) * scale}
          cy={parseFloat(vizSize) / 2 + parseFloat(Y) * scale}
          r={
            index === filteredData.length - 1
              ? configuration.pointRadius + 2
              : configuration.pointRadius
          }
          fill={fill}
          stroke={index === filteredData.length - 1 ? 'white' : 'transparent'}
          fillOpacity={(1 / filteredData.length) * (index + 1)}
          strokeWidth={2}
        />
      );
    }

    if (series.length < 3) {
      return (
        <div className="star-error-container">3 Signals are required to plot a star point.</div>
      );
    }

    return (
      <div
        className={`star-visualization-component ${selected ? 'checked' : ''}`}
        ref={clientRef}
      >
        {!isPreview ? (
          <div
            className="star-header"
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="header-title">
              <div
                className={`select ${selected ? 'checked' : ''}`}
                onClick={handleSelect}
              >
                <Icon
                  icon={`${selected ? 'he-checkbox-selected' : 'he-checkbox'}`}
                  size="s"
                />
              </div>
              <span className="title">{configuration.title || visualization.name || '\u00A0'}</span>
            </div>
            <div className={classes}>
              <div ref={ref}>
                <Button
                  design="text"
                  tooltip="More...  "
                  onClick={handleToggleOpen}
                >
                  <Icon
                    icon="he-moreoptionsvertical"
                    size="s"
                  />
                </Button>
              </div>
              <div className="content">
                <div className="choices">
                  {!loading && !isPreview && data?.length > 0 && (
                    <Can
                      permission="variables/Write"
                      scope={{
                        org: site.org,
                        site: site.id,
                      }}
                    >
                      <div
                        className="choice"
                        onClick={handleDownloadClick}
                      >
                        Download
                      </div>
                    </Can>
                  )}

                  <div
                    className="choice"
                    onClick={() => openSignalViewer(visualization)}
                  >
                    Open in Signal Viewer
                  </div>
                </div>
              </div>
            </div>
            {initialLoaded && loading && (
              <Spinner
                size="s"
                className="spinner"
              />
            )}
          </div>
        ) : (
          <div className="star-preview-title">
            <span className="title">{configuration.title || visualization.name || '\u00A0'}</span>
          </div>
        )}

        {!initialLoaded && loading ? (
          <Loader
            text="Loading ..."
            height={loaderHeight}
          />
        ) : (
          <div className="star-wrapper">
            <>
              {showPointTooltip && <PointTooltip />}
              <div className="star-container">
                <svg
                  height={vizSize > 0 ? vizSize : 0}
                  width={vizSize > 0 ? vizSize : 0}
                  onMouseLeave={() => {
                    setShowPointTooltip(false);
                  }}
                >
                  <rect
                    className="star-border"
                    width={vizSize > 0 ? vizSize : 0}
                    height={vizSize > 0 ? vizSize : 0}
                    fill="transparent"
                    strokeWidth={2}
                  />
                  {configuration.thresholds.thresholdType === 'linear' &&
                    configuration.thresholds.linearItems?.map((threshold, i) => {
                      return (
                        <DrawLinearThreshold
                          threshold={threshold}
                          key={i}
                        />
                      );
                    })}
                  {configuration.thresholds.thresholdType === 'area' &&
                    configuration.thresholds.areaItems?.map((threshold, i) => {
                      return (
                        <DrawAreaThreshold
                          threshold={threshold}
                          key={i}
                        />
                      );
                    })}
                  <RadialGrid />
                  <g
                    name="axes"
                    strokeWidth={1}
                    strokeDasharray="4 4"
                  >
                    <DrawAxes />
                  </g>
                  {configuration.starMode === 'current' && filteredData.length && (
                    <g>
                      <Point
                        value={{
                          M1: filteredData[filteredData.length - 1][
                            configuration.plot?.L1 || series[0].id
                          ],
                          M2: filteredData[filteredData.length - 1][
                            configuration.plot?.L2 || series[1].id
                          ],
                          M3: filteredData[filteredData.length - 1][
                            configuration.plot?.L3 || series[2].id
                          ],
                          time: filteredData[filteredData.length - 1].time,
                        }}
                        pointSeries={{
                          M1: series.find((s) => s.id === configuration.plot?.L1) || series[0],
                          M2: series.find((s) => s.id === configuration.plot?.L2) || series[1],
                          M3: series.find((s) => s.id === configuration.plot?.L3) || series[2],
                        }}
                        fill={configuration.plot?.color || 'rgb(245, 124, 100)'}
                        index={filteredData.length - 1}
                      />
                    </g>
                  )}
                  {configuration.starMode === 'over time' && (
                    <g>
                      {filteredData?.map((value, index) => {
                        return (
                          <Point
                            key={index}
                            value={{
                              M1: value[configuration.plot?.L1 || series[0].id],
                              M2: value[configuration.plot?.L2 || series[1].id],
                              M3: value[configuration.plot?.L3 || series[2].id],
                              time: value.time,
                            }}
                            pointSeries={{
                              M1: series.find((s) => s.id === configuration.plot?.L1) || series[0],
                              M2: series.find((s) => s.id === configuration.plot?.L2) || series[1],
                              M3: series.find((s) => s.id === configuration.plot?.L3) || series[2],
                            }}
                            fill={configuration.plot?.color || 'rgb(245, 124, 100)'}
                            index={index}
                          />
                        );
                      })}
                    </g>
                  )}
                  {configuration.rollingAverage?.show && (
                    <g>
                      {configuration.starMode === 'over time' ? (
                        <g
                          name="rolling-plot"
                          style={{ pointerEvents: 'none' }}
                        >
                          {rollingData.length &&
                            rollingData.map((value, i) => {
                              if (i === rollingData.length - 1) {
                                return null;
                              }
                              if (configuration.rollingAverage?.type === 'Lines') {
                                return (
                                  <line
                                    key={i}
                                    x1={vizSize / 2 + value.X * scale}
                                    y1={vizSize / 2 + value.Y * scale}
                                    x2={vizSize / 2 + rollingData[i + 1].X * scale}
                                    y2={vizSize / 2 + rollingData[i + 1].Y * scale}
                                    stroke={configuration.rollingAverage.color}
                                    strokeWidth={configuration.pointRadius / 3}
                                    opacity={((100 / rollingData.length) * i) / 100}
                                  />
                                );
                              }
                              if (configuration.rollingAverage?.type === 'Both') {
                                return (
                                  <g key={i}>
                                    <line
                                      x1={vizSize / 2 + value.X * scale}
                                      y1={vizSize / 2 + value.Y * scale}
                                      x2={vizSize / 2 + rollingData[i + 1].X * scale}
                                      y2={vizSize / 2 + rollingData[i + 1].Y * scale}
                                      stroke={
                                        configuration.rollingAverage.color || contrastChart[1]
                                      }
                                      strokeWidth={configuration.pointRadius / 3}
                                      opacity={((100 / rollingData.length) * i) / 100}
                                    />
                                    <circle
                                      cx={vizSize / 2 + value.X * scale}
                                      cy={vizSize / 2 + value.Y * scale}
                                      r={configuration.pointRadius / 2}
                                      opacity={((100 / rollingData.length) * i) / 100}
                                      fill={configuration.rollingAverage.color || contrastChart[1]}
                                      stroke=""
                                    />
                                  </g>
                                );
                              }
                              return (
                                <circle
                                  key={i}
                                  cx={vizSize / 2 + value.X * scale}
                                  cy={vizSize / 2 + value.Y * scale}
                                  r={configuration.pointRadius / 2}
                                  opacity={((100 / rollingData.length) * i) / 100}
                                  fill={configuration.rollingAverage.color || contrastChart[1]}
                                  stroke=""
                                />
                              );
                            })}
                        </g>
                      ) : (
                        <g
                          name="rolling-plot"
                          style={{ pointerEvents: 'none' }}
                        >
                          {rollingData.length && (
                            <circle
                              cx={vizSize / 2 + rollingData[rollingData.length - 1].X * scale}
                              cy={vizSize / 2 + rollingData[rollingData.length - 1].Y * scale}
                              r={2}
                              fill={configuration.rollingAverage.color || contrastChart[1]}
                              stroke=""
                            />
                          )}
                        </g>
                      )}
                    </g>
                  )}
                </svg>
                {configuration.legend && configuration.legend.show && (
                  <div className="star-legend">
                    <div className="star-legend-item">
                      <svg
                        width="20"
                        height="2"
                        style={{ marginRight: '0.5rem' }}
                      >
                        <line
                          className="star-legend-axis"
                          x1="0"
                          y1="50%"
                          x2="100%"
                          y2="50%"
                          stroke={'blue'}
                        />
                      </svg>
                      {series.find((s) => s.id === configuration.plot?.L1)?.label ||
                        series[0].label}
                    </div>
                    <div className="star-legend-item">
                      <svg
                        width="20"
                        height="2"
                        style={{ marginRight: '0.5rem' }}
                      >
                        <line
                          className="star-legend-axis"
                          x1="0"
                          y1="50%"
                          x2="100%"
                          y2="50%"
                          stroke={'red'}
                        />
                      </svg>
                      {series.find((s) => s.id === configuration.plot?.L2)?.label ||
                        series[1].label}
                    </div>
                    <div className="star-legend-item">
                      <svg
                        width="20"
                        height="2"
                        style={{ marginRight: '0.5rem' }}
                      >
                        <line
                          className="star-legend-axis"
                          x1="0"
                          y1="50%"
                          x2="100%"
                          y2="50%"
                          stroke={'green'}
                        />
                      </svg>
                      {series.find((s) => s.id === configuration.plot?.L3)?.label ||
                        series[2].label}
                    </div>
                    <div>
                      <svg
                        width="10"
                        height="10"
                        style={{ marginRight: '0.5rem' }}
                      >
                        <rect
                          width="10"
                          height="10"
                          fill={configuration.plot?.color}
                        />
                      </svg>
                      {configuration.plot?.label}
                    </div>
                    {configuration.thresholds.thresholdType === 'linear' &&
                      configuration.thresholds?.linearItems.map((threshold, i) => {
                        return (
                          <div key={i}>
                            <svg
                              width="10"
                              height="10"
                              style={{ marginRight: '0.5rem', overflow: 'visible' }}
                            >
                              <circle
                                r="5"
                                cx="50%"
                                cy="50%"
                                stroke={threshold.fill}
                                strokeWidth={2}
                                fill="none"
                              ></circle>
                            </svg>
                            {threshold.description}
                          </div>
                        );
                      })}
                    {configuration.thresholds.thresholdType === 'area' &&
                      configuration.thresholds?.areaItems?.map((threshold, i) => {
                        return (
                          <div key={i}>
                            <svg
                              width="10"
                              height="10"
                              style={{ marginRight: '0.5rem' }}
                            >
                              <circle
                                r="5"
                                cx="50%"
                                cy="50%"
                                fill={threshold.fill}
                                opacity="50%"
                              ></circle>
                            </svg>
                            {threshold.description}
                          </div>
                        );
                      })}
                    {configuration.rollingAverage.show && (
                      <div>
                        <svg
                          width="10"
                          height="10"
                          style={{ marginRight: '0.5rem' }}
                        >
                          <rect
                            width="10"
                            height="10"
                            fill={configuration.rollingAverage.color}
                          />
                        </svg>
                        {`Rolling average (${configuration.rollingAverage.decay})`}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          </div>
        )}
      </div>
    );
  },
  (prevProps, props) => JSON.stringify(prevProps) === JSON.stringify(props)
);

export default StarVisualization;
