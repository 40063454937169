import React, { memo } from 'react';
import JSONEditor from '../JSONEditor';
import SimpleModal from '../SimpleModal';

const EditItemModal = memo(
  ({
    entity,
    action = 'Add',
    schema,
    uiSchema,
    onSubmit,
    onCloseModal,
    withTitle = true,
    customValidate,
    formData,
    onChange = () => {},
    flexEndButtons = false,
    saveButtonText = '',
    modalClass = '',
    modalSize = 'm',
    extraErrors = {},
  }) => (
    <SimpleModal
      onClose={onCloseModal}
      className={`create-item-modal ${modalClass}`}
      title={`${action} ${entity}`}
      size={modalSize}
    >
      <div className="create-item-content">
        <JSONEditor
          editorOnly
          title={withTitle ? entity : ''}
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          onFormSubmit={onSubmit}
          onFormChange={onChange}
          saveButtonText={saveButtonText || action}
          initialEditMode={true}
          showEditButton={false}
          customValidate={customValidate}
          cancelCallback={onCloseModal}
          flexEndButtons={flexEndButtons}
          extraErrors={extraErrors}
        />
      </div>
    </SimpleModal>
  )
);

export default EditItemModal;
