import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { useSubscribe } from '../../utils';
import Loader from '../Loader';

function createLoadingContext() {
  const refs = new Set();
  const isLoading$ = new BehaviorSubject(false);
  const setLoading = (ref, isLoading = false) => {
    if (isLoading) {
      refs.add(ref);
    } else {
      refs.delete(ref);
    }
    isLoading$.next(refs.size > 0);
  };
  return {
    setLoading,
    isLoading$: isLoading$.pipe(distinctUntilChanged()),
  };
}
const loadingContext = createContext(createLoadingContext());
export const LoadingProvider = ({ children }) => {
  const ctx = useMemo(createLoadingContext, []);
  return <loadingContext.Provider value={ctx}>{children}</loadingContext.Provider>;
};

export const useSetLoading = () => {
  const ref = useMemo(() => Symbol('Loading reference'), []);
  const { setLoading } = useContext(loadingContext);
  const callback = useCallback(
    (isLoading) => {
      setLoading(ref, isLoading);
    },
    [ref]
  );
  useEffect(() => () => callback(false), [callback]);
  return callback;
};

export const useIsLoading = () => {
  const { isLoading$ } = useContext(loadingContext);
  return useSubscribe(isLoading$, false);
};

const LoadingOverlay = () => {
  const isLoading = useIsLoading();
  if (!isLoading) {
    return null;
  }
  return <Loader />;
};

export const LoadingLayer = ({ children }) => (
  <div className="loading-layer">
    <LoadingProvider>
      {children}
      <LoadingOverlay />
    </LoadingProvider>
  </div>
);
