import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import app from './reducers';
import watchAll from './sagas';
import { actionObserveMiddleware } from './utils';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  app,
  composeEnhancers(applyMiddleware(sagaMiddleware, actionObserveMiddleware))
);

sagaMiddleware.run(watchAll);

export default store;
