import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { HvTabs, HvTab } from '@hitachivantara/uikit-react-core';
import { useAuth, Can } from '@iq/react-components';

import UsersTab from './components/UserTab/UserTab';
import InvitationsTab from './components/InvitationTab/InvitationTab';
import { requestMemberTypes, requestMembers, requestInvitations } from '../../../bundles/auth';
import { getActiveSite } from '../../../bundles/sites';
import { requestUsers } from '../../../bundles/ad';

const MemberView = ({ wizardType }) => {
  const dispatch = useDispatch();
  const { realm } = useAuth();
  const site = useSelector(getActiveSite);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (site.id) {
      dispatch(requestUsers());
      dispatch(requestMemberTypes());
      dispatch(requestInvitations(realm));
      dispatch(requestMembers(`site/${site.id}`));
    }
  }, [site.id, realm]);

  const handleChange = (e, newTabIndex) => setTabIndex(newTabIndex);

  return (
    <div className="site-admin--member-view">
      <HvTabs
        id="tabs-content-change"
        value={tabIndex}
        onChange={handleChange}
      >
        <HvTab
          id="users"
          label="Users"
        />
        <HvTab
          id="invitations"
          label="Invitations"
        />
      </HvTabs>
      <div className="site-admin--member-view__content">
        {tabIndex === 0 && (
          <UsersTab
            siteId={site.id}
            wizardType={wizardType}
          />
        )}
        {tabIndex === 1 && (
          <Can
            permission="invitations/Read"
            scope={site.id ? { org: realm, site: site.id } : { org: realm }}
          >
            <InvitationsTab
              siteId={site.id}
              wizardType={wizardType}
            />
          </Can>
        )}
      </div>
    </div>
  );
};

export default MemberView;
