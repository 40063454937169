import React, { useState, useMemo } from 'react';
import { Button } from '@iq/react-components';
import ConfirmationDialog from '../../ConfirmationDialog';
import { RelatedEvents } from '../../panels/EventTimelinePanel';
import './DateTimePickerField.scss';
import { getRangeFromLabel } from '../../../bundles/application';

const RelatedEventsField = (props) => {
  const {
    schema: {
      title,
      site,
      dateDomain,
      eventId,
      hideInactiveSystemEvents,
      eventListColumns,
      editable = true,
      onNext,
    },
    formData = {},
    onChange,
  } = props;
  const { from: relatedFrom, to: relatedTo } = formData; // ISO strings
  const [timelineFrom, timelineTo, timelineRelative] = dateDomain; // UTC timestamps, relative string
  const [addRelatedEvents, setAddRelatedEvents] = useState(false);
  const [confirmRemoveEvents, setConfirmRemoveEvents] = useState(false);

  const defaultFromDate =
    relatedFrom || timelineFrom ? new Date(relatedFrom || timelineFrom).getTime() : Date.now();
  const defaultToDate =
    relatedTo || timelineTo ? new Date(relatedTo || timelineTo).getTime() : Date.now();

  const [initialFrom, initialTo] = useMemo(() => {
    if (defaultFromDate !== defaultToDate) {
      return [defaultFromDate, defaultToDate];
    }
    if (timelineRelative) {
      const range = getRangeFromLabel(timelineRelative);
      return [range.startDate, range.endDate];
    }
    return [defaultFromDate, defaultToDate];
  }, [defaultFromDate, defaultToDate, timelineRelative]);

  return (
    <>
      <div className="event-modal__label-row">
        <label>{`${title}`}</label>
        {editable && (
          <div className="button-group">
            <Button
              activity="secondary"
              slim
              onClick={() => setAddRelatedEvents(true)}
              type="button"
            >
              Add
            </Button>
            <Button
              activity="secondary"
              onClick={(e) => {
                e.preventDefault();
                setConfirmRemoveEvents(true);
              }}
              slim
            >
              Remove
            </Button>
          </div>
        )}
      </div>
      <RelatedEvents
        site={site}
        sourceEventId={eventId}
        value={formData}
        initialFrom={initialFrom}
        initialTo={initialTo}
        addEvents={addRelatedEvents}
        onAddDone={() => setAddRelatedEvents(false)}
        onChange={onChange}
        onSelectEvent={onNext}
        hideInactiveSystemEvents={hideInactiveSystemEvents}
        eventListColumns={eventListColumns}
        readOnly={!editable}
      />
      {confirmRemoveEvents && (
        <ConfirmationDialog
          onCancel={() => setConfirmRemoveEvents(false)}
          onConfirm={(e) => {
            e.preventDefault();
            onChange({});
            setConfirmRemoveEvents(false);
          }}
          confirmType="danger"
          title="Remove events"
          body={
            <div className="remove-event-confirm">
              <div>Are you sure you want to remove all related events?</div>
              <div>
                {`This will not delete events, but rather clear any event relations
                                       defined via the add related event filters.`}
              </div>
            </div>
          }
          confirmText="Remove"
        />
      )}
    </>
  );
};

export default RelatedEventsField;
