import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ExportTimeSeriesModal from '../SiteAdminView/VariableView/components/ExportTimeSeriesModal';
import { getSiteVariables } from '../../bundles/sources';

import GraphVisualization, {
  schema as graphSchema,
  uiSchema as graphUiSchema,
} from './visualizations/GraphVisualization';
import MetricVisualization, {
  schema as metricSchema,
  uiSchema as metricUiSchema,
} from './visualizations/MetricVisualization';
import StatusVisualization, {
  schema as statusSchema,
  uiSchema as statusUiSchema,
} from './visualizations/StatusVisualization';
import BarGaugeVisualization, {
  schema as barGaugeSchema,
  uiSchema as barGaugeUiSchema,
} from './visualizations/BarGaugeVisualization';
import GaugeVisualization, {
  schema as gaugeSchema,
  uiSchema as gaugeUiSchema,
} from './visualizations/GaugeVisualization';
import StateVisualization, {
  schema as stateSchema,
  uiSchema as stateUiSchema,
} from './visualizations/StateVisualization';
import PieVisualization, {
  schema as pieSchema,
  uiSchema as pieUiSchema,
} from './visualizations/PieVisualization';
import StarVisualization, {
  schema as starSchema,
  uiSchema as starUiSchema,
} from './visualizations/StarVisualization';
import DuvalVisualization, {
  schema as duvalSchema,
  uiSchema as duvalUiSchema,
} from './visualizations/DuvalVisualization';

const visualizations = [
  {
    name: 'Graph',
    type: 'graph',
    category: 'graphs',
    icon: 'he-line-chart-alt',
    component: GraphVisualization,
    schema: graphSchema,
    uiSchema: graphUiSchema,
  },
  {
    name: 'Metric',
    type: 'metric',
    category: 'metrics',
    icon: 'he-numericals',
    component: MetricVisualization,
    schema: metricSchema,
    uiSchema: metricUiSchema,
  },
  {
    name: 'Status',
    type: 'status',
    category: 'metrics',
    icon: 'he-status',
    component: StatusVisualization,
    schema: statusSchema,
    uiSchema: statusUiSchema,
  },
  {
    name: 'Bar gauge',
    type: 'bar-gauge',
    category: 'graphs',
    icon: 'he-speedometer',
    component: BarGaugeVisualization,
    schema: barGaugeSchema,
    uiSchema: barGaugeUiSchema,
  },
  {
    name: 'Gauge',
    type: 'gauge',
    category: 'graphs',
    icon: 'he-speedometer',
    component: GaugeVisualization,
    schema: gaugeSchema,
    uiSchema: gaugeUiSchema,
  },
  {
    name: 'State',
    type: 'state',
    category: 'State',
    icon: 'he-states',
    component: StateVisualization,
    schema: stateSchema,
    uiSchema: stateUiSchema,
  },
  {
    name: 'Pie',
    type: 'pie',
    category: 'Pie',
    icon: 'he-piechart',
    component: PieVisualization,
    schema: pieSchema,
    uiSchema: pieUiSchema,
  },
  {
    name: 'Star',
    type: 'star',
    category: 'Star',
    icon: 'he-favoritefill',
    component: StarVisualization,
    schema: starSchema,
    uiSchema: starUiSchema,
  },
  {
    name: 'Duval',
    type: 'duval',
    category: 'duval',
    icon: 'he-upempty',
    component: DuvalVisualization,
    schema: duvalSchema,
    uiSchema: duvalUiSchema,
  },
];

const config = (type) =>
  visualizations.find(({ type: _type }) => _type === type) || {
    schema: {},
    component: () => 'No component',
  };

const schema = (type) => config(type).schema;

const uiSchema = (type) => config(type).uiSchema;

const component = (type) => config(type).component;

const Visualization = ({ visualization, exportGranularity, ...props }) => {
  const siteVariables = useSelector(getSiteVariables);
  const variables = visualization.variables.map((v) => {
    const variable = siteVariables.find(({ id }) => id === v.id);
    return {
      ...v,
      name: variable?.name,
      source: variable?.source,
      itemDesignation: variable?.itemDesignation,
    };
  });
  const Component = component(visualization.type);

  const [showExportForm, setShowExportForm] = useState(false);

  const handleCloseExportModal = useCallback(() => setShowExportForm(false), [setShowExportForm]);

  const handleExportData = useCallback(() => {
    setShowExportForm(true);
  }, []);

  // Since the duval and star point don't display all signals,
  // we need to extract the signals that are actually assigned to plots
  const exportVariables = () => {
    switch (visualization.type) {
      case 'duval': {
        return visualization.configuration.plots
          .flatMap((plot) => [plot.leftId, plot.rightId, plot.bottomId])
          .map((signalId) => variables.find((v) => v.id === signalId?.split('.')[0]));
      }
      case 'star': {
        const { L1, L2, L3 } = visualization.configuration.plot;
        return [L1, L2, L3]
          .map((signal) => {
            return variables.find((v) => v.id === signal?.split('.')[0]);
          })
          .filter((variable) => variable);
      }
      default:
        return variables;
    }
  };

  return (
    <>
      <div className="corevisualization-component">
        <Component
          visualization={{ ...visualization, variables }}
          onExportData={handleExportData}
          {...props}
        />
      </div>
      {showExportForm && visualization.variables?.length > 0 && (
        <ExportTimeSeriesModal
          selectedVizVariables={exportVariables()}
          pollingDateRange={props.pollingDateRange}
          onCloseModal={handleCloseExportModal}
          showAggregation={false}
          exportGranularity={exportGranularity}
          onExportCompleted={handleCloseExportModal}
          parentPanelId={props.panelId}
        />
      )}
    </>
  );
};

export default Visualization;
export { schema, uiSchema, config, component, visualizations };
