/* eslint-disable default-param-last */
import { debounce, takeLatest, select, put, call, race, take, delay } from 'redux-saga/effects';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { addDays, addMonths } from 'date-fns';

import { getQueryParams, updateUrlQuery } from './utils';
import { getSecondsFromNow, getBestFitGranularity } from '../datetimeUtils';
import { DEFAULT_PAGETYPE, EXPORT_TIMESERIES_PANEL, EXPORT_EVENT_PANEL } from '../constants';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const SET_ACTIVE_PAGE_TYPE = 'dt/application/SET_ACTIVE_PAGE_TYPE';
export const SET_ACTIVE_COMPONENT_ID = 'dt/application/SET_ACTIVE_COMPONENT_ID';
export const SET_ACTIVE_COMPONENT_FILTER = 'dt/application/SET_ACTIVE_COMPONENT_FILTER';
export const SET_ACTIVE_MODEL = 'dt/application/SET_ACTIVE_MODEL';
export const SET_ZOOM_COMPONENT = 'dt/application/SET_ZOOM_COMPONENT';
export const SET_VIEWER_READY = 'dt/application/SET_VIEWER_READY';
export const SET_TIMEZONE = 'dt/application/SET_TIMEZONE';

export const RECEIVE_ACTIVE_COMPONENT = 'dt/application/RECEIVE_ACTIVE_COMPONENT';
export const RECEIVE_POLLING_DATE_RANGE = 'dt/application/RECEIVE_POLLING_DATE_RANGE';
export const RECEIVE_POLLING_INTERVAL = 'dt/application/RECEIVE_POLLING_INTERVAL';

export const REFRESH_VALUES = 'dt/application/REFRESH_VALUES';
export const RECEIVE_REFRESH = 'dt/application/RECEIVE_REFRESH';
export const CLEAR_SITE_DATA = 'dt/application/CLEAR_SITE_DATA';

export const INITIALIZE_POLLING = 'dt/application/INITIALIZE_POLLING';
export const START_POLLING = 'dt/application/START_POLLING';
export const STOP_POLLING = 'dt/application/STOP_POLLING';

export const SET_POLLING_ACTIVE = 'dt/application/IS_POLLING_ACTIVE';
export const SET_POLLING_ACTIVE_DONE = 'dt/application/IS_POLLING_ACTIVE_DONE';
export const CREATE_PANEL_DATE_SUBSCRIPTION = 'dt/application/CREATE_PANEL_DATE_SUBSCRIPTION';
export const DESTROY_PANEL_DATE_SUBSCRIPTION = 'dt/application/DESTROY_PANEL_DATE_SUBSCRIPTION';

/** ********************************************
 *                                             *
 *                   Helpers                   *
 *                                             *
 ********************************************* */

export const DATA_POINT_COUNT = 100;

export const periods = [
  {
    label: 'Last 5 minutes',
    secondsFromNow: 5 * 60,
  },
  {
    label: 'Last 15 minutes',
    secondsFromNow: 15 * 60,
  },
  {
    label: 'Last hour',
    secondsFromNow: 60 * 60,
  },
  {
    label: 'Last 6 hours',
    secondsFromNow: 6 * 60 * 60,
  },
  {
    label: 'Last 24 hours',
    secondsFromNow: 24 * 60 * 60,
  },
  {
    label: 'Last 48 hours',
    secondsFromNow: 48 * 60 * 60,
  },
  {
    label: 'Last 7 days',
    secondsFromNow: 7 * 24 * 60 * 60,
  },
  {
    label: 'Last 14 days',
    secondsFromNow: 14 * 24 * 60 * 60,
  },
  {
    label: 'Last 30 days',
    secondsFromNow: 30 * 24 * 60 * 60,
  },
  {
    label: 'Last 100 days',
    secondsFromNow: 100 * 24 * 60 * 60,
  },
];

export const pollingIntervals = [
  {
    label: 'Paused',
    value: 0,
  },
  {
    label: '30s',
    value: 30000,
  },
  {
    label: '1m',
    value: 60000,
  },
  {
    label: '5m',
    value: 60000 * 5,
    default: true,
  },
  {
    label: '10m',
    value: 60000 * 10,
  },
];

export function getGranularityForDateRange(dateRange = {}) {
  let timeWindow = 0;
  const { startDate: start, endDate: end, label } = dateRange;

  if (label) {
    const period = periods.find((p) => p.label === label);
    timeWindow = period.secondsFromNow;
  } else if (start && end) {
    const diff = end - start;
    timeWindow = diff > 0 ? diff / 1000 : 86400;
  }
  return getBestFitGranularity(timeWindow);
}

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const initializeSitePolling = (siteId) => ({
  type: INITIALIZE_POLLING,
  siteId,
});

export const startSitePolling = () => ({
  type: START_POLLING,
});

export const stopSitePolling = () => ({
  type: STOP_POLLING,
});

export const setActiveComponentId = (id) => ({
  type: SET_ACTIVE_COMPONENT_ID,
  id,
});

export const setActiveComponentFilter = (activeComponentFilter) => ({
  type: SET_ACTIVE_COMPONENT_FILTER,
  activeComponentFilter,
});

export const setPageType = (pageType) => ({
  type: SET_ACTIVE_PAGE_TYPE,
  pageType,
});

export const setZoomComponentId = (id) => ({
  type: SET_ZOOM_COMPONENT,
  id,
});

export const setActiveModelId = (modelId) => ({
  type: SET_ACTIVE_MODEL,
  modelId,
});

export const setViewerReady = (viewerReady) => ({
  type: SET_VIEWER_READY,
  viewerReady,
});

export const setPollingInterval = (pollingInterval) => ({
  type: RECEIVE_POLLING_INTERVAL,
  pollingInterval,
});

export const setPollingDateRange = (pollingDateRange, panelId) => ({
  type: RECEIVE_POLLING_DATE_RANGE,
  pollingDateRange: {
    ...pollingDateRange,
    granularity: getGranularityForDateRange(pollingDateRange),
  },
  panelId,
});

export const setTimezone = (timezone) => ({
  type: SET_TIMEZONE,
  timezone,
});

export const refreshData = (siteId) => ({
  type: REFRESH_VALUES,
  siteId,
});

export const clearSiteData = () => ({
  type: CLEAR_SITE_DATA,
});

export const setPollingActive = (component) => ({
  type: SET_POLLING_ACTIVE,
  component,
});

export const setPollingActiveDone = (component) => ({
  type: SET_POLLING_ACTIVE_DONE,
  component,
});

export const subscribe = (panelId) => ({
  type: CREATE_PANEL_DATE_SUBSCRIPTION,
  panelId,
});

export const unsubscribe = (panelId) => ({
  type: DESTROY_PANEL_DATE_SUBSCRIPTION,
  panelId,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

export const getInitialPollingDateRange = (panelId) => {
  let defaultStart = addMonths(Date.now(), -2).getTime();
  let defaultEnd = addMonths(Date.now(), 1).getTime();

  if (panelId === EXPORT_TIMESERIES_PANEL) {
    defaultStart = addDays(Date.now(), -1).getTime();
    defaultEnd = new Date().getTime();
  }

  return {
    startDate: defaultStart,
    endDate: defaultEnd,
    label: null,
    duration: defaultEnd - defaultStart,
    granularity: getGranularityForDateRange({ startDate: defaultStart, endDate: defaultEnd }),
  };
};

const initialPollingDateRange = getInitialPollingDateRange();
const initialPollingDateRangeTimeseriesExportPanel =
  getInitialPollingDateRange(EXPORT_TIMESERIES_PANEL);
const { endDate, startDate, activeComponent } = getQueryParams();

if (startDate && endDate) {
  const start = parseInt(startDate, 10);
  const end = parseInt(endDate, 10);
  const cleanStart = Number.isNaN(start) ? Date.now() - 36000 : start;
  const cleanEnd = Number.isNaN(end) ? Date.now() : end;
  initialPollingDateRange.startDate = cleanStart;
  initialPollingDateRange.endDate = cleanEnd;
  initialPollingDateRange.duration = cleanEnd - cleanStart;
  initialPollingDateRange.granularity = getGranularityForDateRange({
    startDate: cleanStart,
    endDate: cleanEnd,
  });
}

const initialState = {
  activeComponentId: activeComponent || null,
  activeComponentFilter: null,
  pageType: DEFAULT_PAGETYPE,
  zoomComponentId: null,
  activeModelId: null,
  viewerReady: false,
  pollingDateRanges: {
    global: initialPollingDateRange,
    timeseriesExportPanel: initialPollingDateRangeTimeseriesExportPanel,
  },
  pollingInterval: pollingIntervals.find((p) => p.default).value,
  isPollingActive: {
    components: false,
    files: false,
    sources: false,
    variables: false,
    visualizations: false,
  },
  refreshedAt: null,
  timezone: 'Europe/Stockholm',
};

let lastRequestedPollingAt = 0;

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ACTIVE_COMPONENT: {
      return { ...state, activeComponentId: action.activeComponentId };
    }
    case SET_ACTIVE_COMPONENT_FILTER: {
      return { ...state, activeComponentFilter: action.activeComponentFilter };
    }
    case SET_ACTIVE_PAGE_TYPE: {
      return { ...state, pageType: action.pageType };
    }
    case SET_ZOOM_COMPONENT: {
      return { ...state, zoomComponentId: action.id };
    }
    case SET_ACTIVE_MODEL: {
      return { ...state, activeModelId: action.modelId };
    }
    case SET_VIEWER_READY: {
      return { ...state, viewerReady: action.viewerReady };
    }
    case RECEIVE_POLLING_DATE_RANGE: {
      if (action.panelId) {
        return {
          ...state,
          pollingDateRanges: {
            ...state.pollingDateRanges,
            [action.panelId]: action.pollingDateRange,
          },
        };
      }
      return {
        ...state,
        pollingDateRanges: Object.keys(state.pollingDateRanges).reduce(
          (acc, panelId) => ({ ...acc, [panelId]: action.pollingDateRange }),
          {}
        ),
      };
    }
    case RECEIVE_POLLING_INTERVAL: {
      return { ...state, pollingInterval: action.pollingInterval };
    }
    case SET_TIMEZONE: {
      return { ...state, timezone: action.timezone };
    }
    case RECEIVE_REFRESH: {
      return { ...state, refreshedAt: action.refreshedAt };
    }
    case SET_POLLING_ACTIVE: {
      return {
        ...state,
        isPollingActive: {
          ...state.isPollingActive,
          [action.component]: true,
        },
      };
    }
    case SET_POLLING_ACTIVE_DONE: {
      return {
        ...state,
        isPollingActive: {
          ...state.isPollingActive,
          [action.component]: false,
        },
      };
    }
    case CREATE_PANEL_DATE_SUBSCRIPTION: {
      const { panelId } = action;

      let range = state.pollingDateRanges.global;
      if (panelId === EXPORT_TIMESERIES_PANEL && state.pollingDateRanges.timeseriesExportPanel) {
        range = state.pollingDateRanges.timeseriesExportPanel;
      }

      return {
        ...state,
        pollingDateRanges: {
          ...state.pollingDateRanges,
          [panelId]: range,
        },
      };
    }
    case DESTROY_PANEL_DATE_SUBSCRIPTION: {
      const { panelId } = action;
      const pollingDateRanges = { ...state.pollingDateRanges };
      if (panelId === EXPORT_TIMESERIES_PANEL || panelId === EXPORT_EVENT_PANEL) {
        pollingDateRanges[panelId] = getInitialPollingDateRange(panelId);
      } else {
        delete pollingDateRanges[panelId];
      }
      return {
        ...state,
        pollingDateRanges,
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        activeComponentId: null,
        activeComponentFilter: null,
        zoomComponentId: null,
        activeModelId: null,
        viewerReady: false,
        pollingDateRanges: {
          global: getInitialPollingDateRange(),
          timeseriesExportPanel: getInitialPollingDateRange(EXPORT_TIMESERIES_PANEL),
        },
        pollingInterval: pollingIntervals.find((p) => p.default).value,
        isPollingActive: {
          components: false,
          files: false,
          sources: false,
          variables: false,
          visualizations: false,
        },
        refreshedAt: null,
      };
    }
    default:
      return state;
  }
}

/** ********************************************
 *                                             *
 *                   Helpers                   *
 *                                             *
 ********************************************* */

export const getRangeFromLabel = (label) => {
  const range = { label };
  const { secondsFromNow = 0 } = periods.find((x) => x.label === label) || {};
  range.startDate = getSecondsFromNow(-secondsFromNow);
  range.endDate = new Date().getTime();
  return {
    ...range,
    granularity: getGranularityForDateRange(range),
    duration: range.endDate - range.startDate,
  };
};

export const getRangeFromPartial = (partial) => {
  const range = { ...partial };
  return {
    ...range,
    granularity: getGranularityForDateRange(range),
    duration: range.endDate - range.startDate,
    label: null,
  };
};

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

// used internaly in getFilteredActiveComponentId in components bundle
// pt should always get activeComponent there (components bundle)
export const getActiveComponentId = (state) => state.application.activeComponentId;

export const getZoomComponentId = (state) => state.application.zoomComponentId;

export const getActiveModelId = (state) => state.application.activeModelId;

export const getViewerReady = (state) => state.application.viewerReady;

export const getPollingInterval = (state) => state.application.pollingInterval;

export const getPollingDateRange = createSelector(
  (state) => state.application.pollingDateRanges,
  (_, panelId) => panelId,
  (pollingDateRanges, panelId) => pollingDateRanges[panelId] || pollingDateRanges.global
);

export const getTimezone = (state) => state.application.timezone;

export const getActiveComponentFilter = (state) => state.application.activeComponentFilter;

export const getLastRefresh = (state) => state.application.refreshedAt;

export const getPollingActive = createSelector(
  [(state) => state.application.isPollingActive, (_, slice) => slice],
  (isPollingActive, slice) => {
    if (slice) {
      return !!isPollingActive[slice];
    }
    return Object.values(isPollingActive).some((part) => !!part);
  }
);

export const getPageType = (state) => state.application.pageType;
// Hooks

export const useDateSubscription = (panelId) => {
  const dispatch = useDispatch();
  const pollingDateRange = useSelector((state) => getPollingDateRange(state, panelId));

  useEffect(() => {
    dispatch(subscribe(panelId));

    return () => {
      dispatch(unsubscribe(panelId));
    };
  }, []);

  return pollingDateRange;
};

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doSetActiveComponentId(action) {
  const activeComponentId = yield select((state) => state.application.activeComponentId);

  // Resets to actual root component (null) if 'selecting' already existing root
  yield put({
    type: RECEIVE_ACTIVE_COMPONENT,
    activeComponentId: action.id === activeComponentId ? null : action.id,
  });

  updateUrlQuery({ activeComponent: action.id === activeComponentId ? undefined : action.id });
}

function doReceivePollingDateRange(action) {
  const {
    pollingDateRange: { startDate: start, endDate: end },
    panelId,
  } = action;

  if (!panelId) {
    updateUrlQuery({
      startDate: start || undefined,
      endDate: end || undefined,
    });
  }
}

function* doReceiveRefresh() {
  yield put({
    type: RECEIVE_REFRESH,
    refreshedAt: Date.now(),
  });
}

function* doPoll(siteId) {
  while (true) {
    const pollingInterval = yield select(getPollingInterval);
    const now = new Date().getTime();
    if (pollingInterval && lastRequestedPollingAt + pollingInterval < now) {
      lastRequestedPollingAt = new Date().getTime();
      yield put(refreshData(siteId));
    }
    yield delay(1000);
  }
}

function* doInitializePolling(action) {
  while (true) {
    yield take(START_POLLING);
    yield race([call(doPoll, action.siteId), take(STOP_POLLING)]);
  }
}

export const sagas = [
  takeLatest(INITIALIZE_POLLING, doInitializePolling),
  takeLatest(SET_ACTIVE_COMPONENT_ID, doSetActiveComponentId),
  takeLatest(RECEIVE_POLLING_DATE_RANGE, doReceivePollingDateRange),

  debounce(500, REFRESH_VALUES, doReceiveRefresh),
];
