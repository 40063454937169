import React from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import { colors, Icon } from '@iq/react-components';

import { removeUpload } from '../../bundles/uploads';

// https://stackoverflow.com/a/20732091
function humanFileSize(size) {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

const UploadProgress = ({ id, name, status, size, progress }) => {
  const dispatch = useDispatch();

  const dismiss = (event) => {
    event.preventDefault();
    dispatch(removeUpload(id));
  };

  const statusText = () => {
    switch (status) {
      case 'uploading':
        return humanFileSize(size);
      default:
        return status;
    }
  };

  const renderIcon = () => {
    const percentage = progress === 0 ? 0 : (progress / size) * 100;

    switch (status) {
      case 'failed':
        return (
          <Icon
            icon="he-systemfeedback"
            fill={colors.Red60}
          />
        );
      default:
        return (
          <CircularProgressbar
            value={percentage}
            strokeWidth={10}
            styles={{ path: { stroke: colors.StatusBlue } }}
          />
        );
    }
  };

  return (
    <div
      className="progress-component upload-progress-component"
      onClick={status === 'failed' ? dismiss : () => {}}
    >
      <div className="meta">
        <div className="filename">{name}</div>
        <div className="status">{statusText()}</div>
      </div>
      <div className="icon">{renderIcon()}</div>
    </div>
  );
};

export default UploadProgress;
