import React, { useCallback, useState } from 'react';
import Indicator from './Indicator';
import { datetimeString, utcToSite } from '../../../datetimeUtils';
import { DropdownMenu } from '../../DropdownMenu';

export function formatYTick(value, eventTypes = []) {
  if (!value || value === 'default') {
    return '';
  }
  if (value === 'iot') {
    return 'IOT events';
  }
  const eventType = eventTypes.find(({ id }) => id === value);
  if (eventType?.name) {
    return eventType.name.charAt(0).toUpperCase() + eventType.name.slice(1);
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}

const EventPicker = (props) => {
  const {
    events,
    onSelect,
    position: { placeAbove = false, alignLeft = true, top = 0, left = 0 },
    onClose,
    timezone,
  } = props;
  let translate = 'translate(0%, 2%)';
  if (placeAbove || !alignLeft) {
    translate = `translate(${!alignLeft ? '-100%' : '0%'}, ${placeAbove ? '-102%' : '2%'})`;
  }

  const buildEventOptions = () =>
    events.map((event) => {
      const { severity, timestamp, id } = event;
      return {
        component: (
          <div
            key={id}
            className="iot-event-option"
            onClick={() => onSelect(event)}
          >
            <Indicator severity={severity} />
            <div>{severity}</div>
            <div>{datetimeString(utcToSite(timestamp, timezone), timezone, true)}</div>
          </div>
        ),
      };
    });

  return (
    <DropdownMenu
      trigger={<div></div>}
      menuOptions={buildEventOptions()}
      className="event-picker"
      onMenuClose={onClose}
      menuIsOpen={true}
      autoFocus
      styles={{
        dropdownIndicator: { padding: '0 0.5rem', filter: 'none', visibility: 'hidden' },
        option: { cursor: 'pointer' },
        container: { position: 'initial' },
        menu: {
          left: `${left}px`,
          top: `${top}px`,
          marginTop: '0',
          marginBottom: '0',
          transform: translate,
          minWidth: 'auto',
        },
        menuList: { maxHeight: '11rem' },
      }}
    />
  );
};

export function useEventPicker(onClose, dependencies) {
  const [view, setView] = useState(null);
  const openModal = useCallback(
    (props = {}) =>
      new Promise((resolve) => {
        const handleOnClose = () => {
          if (onClose) {
            onClose();
          }
          if (props.onClose) {
            props.onClose();
          }
          setView(null);
          resolve();
        };
        setView(
          <EventPicker
            {...props}
            onClose={handleOnClose}
          />
        );
      }),
    dependencies
  );
  return [view, openModal];
}

export const getGroupedUserEventOptions = (userEventsArray) => {
  const groupedTypes = userEventsArray.reduce(
    (acc, eventType) => {
      if (!eventType.task) {
        acc[0] = {
          ...acc[0],
          options: [...acc[0].options, { value: eventType.id, label: eventType.name }],
        };
      } else {
        acc[1] = {
          ...acc[1],
          options: [...acc[1].options, { value: eventType.id, label: eventType.name }],
        };
      }
      return acc;
    },
    [
      { label: 'EVENTS', options: [] },
      { label: 'TASKS', options: [] },
    ]
  );

  return [
    {
      ...groupedTypes[0],
      options: groupedTypes[0].options.sort(({ value: a }, { value: b }) => a.localeCompare(b)),
    },
    {
      ...groupedTypes[1],
      options: groupedTypes[1].options.sort(({ value: a }, { value: b }) => a.localeCompare(b)),
    },
  ];
};

export default {
  formatYTick,
  useEventPicker,
  getGroupedUserEventOptions,
};
