import React from 'react';

/* eslint-disable import/no-unresolved */
import PDFIcon from '../../../images/icons/pdf.min.svg';
import XICon from '../../../images/icons/x.min.svg';
import ExeIcon from '../../../images/icons/exe.min.svg';
import DocIcon from '../../../images/icons/doc.min.svg';
import TFRIcon from '../../../images/icons/tfr.min.svg';
import VideoIcon from '../../../images/icons/video.min.svg';
import ImageIcon from '../../../images/icons/image.min.svg';
import DefaultIcon from '../../../images/icons/default.min.svg';
import ZipIcon from '../../../images/icons/zip.min.svg';
/* eslint-enable import/no-unresolved */

const iconForFile = (file) => {
  if (!file) {
    return DefaultIcon;
  }

  const { fileType, filename, mime } = file;
  const last = filename ? filename.split('.').pop() : undefined;
  const ext = typeof last !== 'string' ? last : last.toLowerCase();
  const [typePre, typePost] = typeof mime === 'string' ? mime.split('/') : 'n/a';

  if (fileType === 'comtrade') {
    return TFRIcon;
  }

  if (fileType === 'pdf' || fileType === 'application/pdf') {
    return PDFIcon;
  }

  if (['doc', 'docx', 'docm'].includes(ext)) {
    return DocIcon;
  }

  if (['xls', 'xlsx', 'xlsb', 'xlsm'].includes(ext)) {
    return XICon;
  }

  if (ext === 'zip') {
    return ZipIcon;
  }

  if (ext === 'exe') {
    return ExeIcon;
  }

  if (typePre === 'video' || typePost === 'video') {
    return VideoIcon;
  }

  if (typePre === 'image' || typePost === 'image') {
    return ImageIcon;
  }

  return DefaultIcon;
};

const FiletypeIcon = ({ file, ...props }) => (
  <div
    className="filetype-icon-component"
    {...props}
  >
    <img src={iconForFile(file)} />
  </div>
);

export default FiletypeIcon;
