import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from '@iq/react-components';
import { HvTabs, HvTab } from '@hitachivantara/uikit-react-core';

import {
  // ASSET_STATES,
  getMainSchema,
  mainUiSchema,
  getModelSchema,
  modelUiSchema,
  getEventSourcesSchema,
} from '../../schemas/componentSchemas';
import { getAttributeSchema, attributeUiSchema } from '../../schemas/attributeSchema';
import EditItemModal from '../../../../EditItemModal';
import JSONEditor from '../../../../JSONEditor';
import SimpleModal from '../../../../SimpleModal';
import ListItem from '../../../../ListItem';
import { getComponents } from '../../../../../bundles/components';
import {
  getSources,
  getSourceTypes,
  getExtendedSiteVariables,
  requestExtendedSiteVariables,
  getExtendedSiteVariablesUpdatedAt,
} from '../../../../../bundles/sources';
import { getStateSets } from '../../../../../bundles/statesets';
import { getThumbnails, requestModelThumbnail } from '../../../../../bundles/sites';
import {
  capitalize,
  cleanFormData,
  toCapitalizedWords,
  camelToSentence,
  getUniqueId,
  withTypedValues,
} from '../../../../../utils';
import { INTERNAL_DATA_SOURCE, IOT_EVENT_SOURCE_TYPES } from '../../../../../constants';

const getMainComponentFormData = (component) => ({
  type: component.type || 'component',
  name: component.name || '',
  itemDesignation: component.itemDesignation,
  descendantIds: component.descendantIds || [],
  parent: component.parent,
  stateset_id: component.stateset_id,
  // assetState: component.asset?.state || ASSET_STATES[0].const,
  // spareTo: component.spareTo || [],
  customId: component.customId,
  statefulVariable: component.variables?.[0],
  source: component.source || null,
});

const BASE_TAB_KEYS = ['attributes', 'models', 'eventSources'];

const getSourceOptionsSchemaByType = (sourceType) => {
  // handle schema-specific formatting
  if (sourceType.name === 'dummy') {
    return {
      ...Object.entries(sourceType.schemas.component.properties).reduce((schema, [k, v]) => {
        // eslint-disable-next-line no-param-reassign
        schema[k] = {
          ...v,
          title: '',
          properties: {
            ...Object.entries(v.properties).reduce((innerSchema, [healthProp, config]) => {
              // eslint-disable-next-line no-param-reassign
              innerSchema[healthProp] = {
                ...config,
                title: camelToSentence(healthProp),
              };
              return innerSchema;
            }, {}),
          },
        };
        return schema;
      }, {}),
    };
  }
  return {};
};

const ComponentModal = (props) => {
  const {
    modelsHashmap,
    // eslint-disable-next-line no-unused-vars
    onSubmit,
    onCloseModal,
    updateId, // if defined, we are updating
    parentId, // if defined, we are creating
    saveButtonText,
  } = props;
  const isCreating = parentId;
  const dispatch = useDispatch();

  const [attribute, setAttribute] = useState(null);
  const [editModelId, setEditModelId] = useState(null);
  const [editEventSourceId, setEditEventSourceId] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const sources = useSelector(getSources);
  const sourceTypes = useSelector(getSourceTypes);
  const stateSets = useSelector(getStateSets);
  const components = useSelector(getComponents);
  const variables = useSelector(getExtendedSiteVariables);
  const variablesUpdatedAt = useSelector(getExtendedSiteVariablesUpdatedAt);
  const thumbnails = useSelector(getThumbnails);

  const columnLeftContainerRef = useRef();
  const columnLeftComponentFormRef = useRef();

  const models = useMemo(() => Object.values(modelsHashmap), [JSON.stringify(modelsHashmap)]);
  const statefulVariables = useMemo(
    () => (variablesUpdatedAt ? variables.filter((v) => v.stateset_id) : null),
    [variables, variablesUpdatedAt]
  );

  useEffect(() => {
    if (columnLeftContainerRef.current) {
      columnLeftContainerRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (!variablesUpdatedAt) {
      dispatch(requestExtendedSiteVariables());
    }
  }, [variablesUpdatedAt]);

  useEffect(() => {
    models.forEach((model) => {
      model.versions.forEach((version) => dispatch(requestModelThumbnail(model.id, version.id)));
    });
  }, [models]);

  const component = useMemo(() => {
    const comp = { ...(components.find((c) => c.id === updateId) || {}) };
    if (comp.source_id) {
      comp.source = comp.source_id;
      delete comp.source_id;
    }
    if (comp.options) {
      comp.sourceOptions = comp.options;
      delete comp.options;
    }
    if (comp.variables) {
      comp.variables = comp.variables.map((v) => v.id);
    }
    return comp;
  }, [JSON.stringify(components), updateId]);

  const [mainData, setMainData] = useState(
    isCreating
      ? getMainComponentFormData({ parent: parentId })
      : getMainComponentFormData(component)
  );
  const [attributesData, setAttributesData] = useState(
    component.asset?.variables
      ?.map(withTypedValues)
      .toSorted((a, b) => a.name.localeCompare(b.name)) || []
  );

  const isNewAttribute = useMemo(
    () =>
      attribute?.id.startsWith('new-attribute') &&
      !attributesData.some((attr) => attr.id === attribute?.id),
    [attribute, attributesData]
  );

  const [attributeEditIds, setAttributeEditIds] = useState([]);
  const [attributeDeleteIds, setAttributeDeleteIds] = useState([]);
  const [modelsData, setModelsData] = useState(component.models || []);
  const [eventSourcesData, setEventSourcesData] = useState(component.eventSources || []);
  const [sourceOptionsData, setSourceOptionsData] = useState(component.sourceOptions || {});

  const modelFormData = useMemo(
    () => modelsData.find((modelLink) => modelLink.id === editModelId) || {}
  );
  const isNewLink = !modelFormData.id;
  const eventSourcesFormData = useMemo(
    () => eventSourcesData.find((evtSource) => evtSource.source_id === editEventSourceId) || {}
  );

  const filteredSources = sources.filter((s) =>
    sourceTypes
      .filter((st) => st.name !== INTERNAL_DATA_SOURCE)
      .map((st) => st.name)
      .includes(s.type)
  );

  const mainSchema = useMemo(() => {
    if (component && components && statefulVariables) {
      return getMainSchema(
        component,
        components,
        stateSets,
        statefulVariables,
        filteredSources,
        sourceTypes
      );
    }
    return null;
  }, [component, components, stateSets, statefulVariables, filteredSources, sourceTypes]);

  const modelSchema = useMemo(
    () =>
      getModelSchema(
        models.filter((model) =>
          isNewLink
            ? !modelsData.map((md) => md.modelId).includes(model.id)
            : !modelsData
                .filter((md) => md.id !== editModelId)
                .map((md) => md.modelId)
                .includes(model.id)
        ),
        thumbnails
      ),
    [JSON.stringify(models), JSON.stringify(thumbnails), modelsData, editModelId, isNewLink]
  );

  const filteredEventSources = filteredSources.filter((s) =>
    IOT_EVENT_SOURCE_TYPES.includes(s.type)
  );

  const eventSourcesSchema = useMemo(
    () => getEventSourcesSchema(filteredEventSources, sourceTypes),
    [filteredEventSources, sourceTypes]
  );

  const sourceOptionsSchemas = filteredSources.reduce((sourceSchemaMap, source) => {
    const sourceType = sourceTypes.find((st) => st.name === source.type);

    if (sourceType && Object.keys(sourceType.schemas.component.properties).length) {
      // eslint-disable-next-line no-param-reassign
      sourceSchemaMap[source.id] = {
        type: 'object',
        title: '',
        required: sourceType.schemas.component.required,
        properties: getSourceOptionsSchemaByType(sourceType),
      };
    }
    return sourceSchemaMap;
  }, {});

  const modalTitle = isCreating ? 'Create component' : 'Edit component';

  const tabKeys = useMemo(() => {
    if (Object.keys(sourceOptionsSchemas).includes(mainData.source)) {
      return [...BASE_TAB_KEYS, 'health']; // currently only source options are health... make dynamic if necessary
    }
    return BASE_TAB_KEYS;
  }, [mainData.source, sourceOptionsSchemas]);

  const tabHeaders = tabKeys.map((key) => (
    <HvTab
      key={key}
      id={key}
      label={toCapitalizedWords(key)}
    />
  ));

  /*
   ***************************
   * Main Component Form
   **************************
   */
  // If we upgrade to RJSFv5, we can try to get rid of the custom validation.
  const mainCustomValidate = useCallback(
    (formData, errors) => {
      if (formData.itemDesignation) {
        components.forEach((comp) => {
          if (
            comp.id !== component.id &&
            comp.itemDesignation.toLowerCase() === formData.itemDesignation.toLowerCase()
          ) {
            errors.itemDesignation.addError('Reference designation should be unique.');
          }
        });
      }

      mainSchema.required.forEach((fieldName) => {
        const validValue = formData[fieldName] || formData[fieldName] === 0;

        if (!validValue) {
          if (fieldName === 'itemDesignation') {
            errors[fieldName].addError('Reference designation is required');
          } else {
            errors[fieldName].addError(`${capitalize(fieldName)} is required`);
          }
        }
      });
      return errors;
    },
    [component, components, mainSchema]
  );

  // This is added to handle a duplicate error message for itemDesignations.
  const mainTransformErrors = (errors) =>
    errors.map((error) => {
      /* eslint-disable no-return-assign, no-param-reassign */
      if (error.name === 'required') {
        if (error.property === 'itemDesignation' || error.property === 'name') {
          error.message = '';
        }
      }
      return error;
    });

  const onMainComponentChange = useCallback(({ formData: updateMainCompData }) => {
    setMainData(updateMainCompData);
  }, []);

  /*
   ***************************
   * Attributes Tab
   **************************
   */
  const onAddAttribute = useCallback(
    ({ formData: attrUpdate }) => {
      setAttributesData((prev) =>
        [
          ...prev.filter((attr) => attr.id !== attribute.id),
          {
            id: attribute.id,
            ...attrUpdate,
          },
        ].toSorted((a, b) => a.name.localeCompare(b.name))
      );
      setAttributeEditIds((prev) => [...prev, attribute.id]);
      setAttribute(null);
    },
    [attribute]
  );

  const onChangeAttribute = useCallback(
    ({ formData }) => {
      if (formData.dataType !== attribute.dataType) {
        setAttribute({
          ...formData,
          id: attribute.id,
          lastValue: formData.dataType === 'boolean' ? 'true' : undefined,
        });
      } else {
        setAttribute({ ...formData, id: attribute.id });
      }
    },
    [attribute]
  );

  const onCloseAttributeModal = useCallback(() => {
    setAttribute(null);
  }, []);

  const onDeleteAttribute = useCallback((attrId) => {
    setAttributesData((prev) => prev.filter((attr) => attr.id !== attrId));
    setAttributeDeleteIds((prev) => [...prev, attrId]);
  }, []);

  const attributeCustomValidate = useCallback(
    ({ id, name }, errors) => {
      if (name) {
        attributesData.forEach((att) => {
          if (
            (!id || id !== att.id) &&
            att.name.trim().toLowerCase() === name.trim().toLowerCase()
          ) {
            errors.name.addError('Attribute name must be unique.');
          }
        });
      }
      return errors;
    },
    [attributesData, mainSchema]
  );

  const attributeListHeaders = ['Name', 'Data type', 'Category', 'Value'].map((header, i) => {
    const key = `model-header-${i}`;
    return (
      <div
        key={key}
        className="ellipsed-text"
      >
        {header}
      </div>
    );
  });

  const getAttributeListItemColumns = (attr, i) => [
    <div key={`attr-name-${i}`}>{attr.name}</div>,
    <div key={`attr-datatype-${i}`}>{attr.dataType}</div>,
    <div key={`attr-category-${i}`}>{attr.category || ''}</div>,
    <div key={`attr-value-${i}`}>{attr.lastValue?.toString() || ''}</div>,
  ];

  const attributeList = useMemo(() => {
    if (!attributesData || !variablesUpdatedAt) {
      return (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      );
    }

    const colWidths = {
      type: 'percent',
      widths: [40, 20, 20, 20],
    };

    return [
      <ListItem
        key="attr-list-header"
        isHeader
        columns={attributeListHeaders}
        columnWidths={colWidths}
      />,
      <div
        key="model-list-body"
        className="custom-thin-scrollbar"
      >
        {attributesData.map((attr, i) => (
          <ListItem
            key={attr.id}
            itemIndex={i}
            entity={attr.name}
            item={attr}
            columns={getAttributeListItemColumns(attr, i)}
            columnWidths={colWidths}
            customEdit
            onEdit={() => setAttribute(attr)}
            onDelete={() => onDeleteAttribute(attr.id)}
            confirmationDialogTitle="Remove Attribute"
            confimationDialogBody={
              <>
                <p>{'This will remove all historical data connected to this attribute.'}</p>
                <p style={{ paddingTop: '1.5rem' }}>
                  {'This is a destructive action and cannot be un-done.'}
                </p>
              </>
            }
          />
        ))}
      </div>,
    ];
  }, [attributesData, variablesUpdatedAt]);

  /*
   ***************************
   * Models Tab
   **************************
   */
  const onLinkModel = useCallback(
    ({ formData: modelLinkUpdate }) => {
      setModelsData((prev) => [
        ...prev.filter((modelLink) => modelLink.id !== editModelId),
        { ...modelLinkUpdate, id: editModelId },
      ]);
      setEditModelId(null);
    },
    [editModelId]
  );

  const onCancelLinkModel = useCallback(() => setEditModelId(null), []);

  const onUnlinkModel = useCallback((id) => {
    // looks at id when creating a new component, otherwise modelId
    setModelsData((prev) => prev.filter((modelLink) => (modelLink.id || modelLink.modelId) !== id));
  }, []);

  const modelsListHeaders = ['Display name', 'Type', 'Preview'].map((header, i) => {
    const key = `model-header-${i}`;
    return (
      <div
        key={key}
        className="ellipsed-text"
      >
        {header}
      </div>
    );
  });

  const getModelsListItemColumns = (model, modelData, i, thumbs = {}) => {
    const hasCustomThumbnail =
      model.versions?.[0]?.thumbnail?.split(':')[1]?.split('/')[0] === 'image';
    const thumb =
      (hasCustomThumbnail && model.versions[0].thumbnail) ||
      thumbs[`${model.id}/${model.versions?.[0]?.id}`];

    return [
      <div key={`model-name-${i}`}>{modelData.customName}</div>,
      <div key={`model-type-${i}`}>{model.modelType}</div>,
      thumb && thumb !== 'none' ? (
        <img
          key={`model-thumb-${i}`}
          className="model-thumbnail"
          src={thumb}
        />
      ) : (
        <div key={`model-thumb-${i}`}>{'None'}</div>
      ),
    ];
  };

  const modelList = useMemo(() => {
    if (!modelsData || !thumbnails) {
      return (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      );
    }

    const colWidths = {
      type: 'percent',
      widths: [50, 25, 25],
    };

    return [
      <ListItem
        key="model-list-header"
        isHeader
        columns={modelsListHeaders}
        columnWidths={colWidths}
      />,
      <div
        key="model-list-body"
        className="custom-thin-scrollbar"
      >
        {modelsData.length ? (
          modelsData.map((modelData, i) => {
            const model = models.find((m) => m.id === modelData.modelId);
            if (!model) {
              return null;
            }
            return (
              <ListItem
                key={model.id}
                itemIndex={i}
                entity={modelData.customName}
                item={modelData}
                columns={getModelsListItemColumns(model, modelData, i, thumbnails)}
                columnWidths={colWidths}
                customEdit
                onEdit={() => setEditModelId(modelData.id)}
                // looks at id when creating a new component, otherwise modelId
                onDelete={() => onUnlinkModel(modelData.id || modelData.modelId)}
                confirmationDialogTitle="Unlink Model"
                confimationDialogBody={<p>{'This will unlink this model from this component.'}</p>}
              />
            );
          })
        ) : (
          <div className="list-item--empty-list">No models found.</div>
        )}
      </div>,
    ];
  }, [models, modelsData, thumbnails]);

  /*
   ***************************
   * Events (sources) Tab
   **************************
   */
  const onAddEventSource = useCallback(({ formData: eventSource }) => {
    setEventSourcesData((prev) => [
      ...prev.filter((evtSource) => evtSource.source_id !== eventSource.source_id),
      eventSource,
    ]);
    setEditEventSourceId(null);
  }, []);

  const onCancelAddEventSource = useCallback(() => setEditEventSourceId(null), []);

  const onRemoveEventSource = useCallback((eventSourceId) => {
    setEventSourcesData((prev) =>
      prev.filter((evtSource) => evtSource.source_id !== eventSourceId)
    );
  }, []);

  const eventSourcesListHeaders = ['Name', 'Description', 'Type'].map((header, i) => {
    const key = `model-header-${i}`;
    return (
      <div
        key={key}
        className="ellipsed-text"
      >
        {header}
      </div>
    );
  });

  const getEventSourceListItemColumns = (linkedSource, i) => {
    const sourceType = sources.find((s) => s.id === linkedSource.source_id)?.name;
    return [
      <div key={`event-source-name-${i}`}>{linkedSource.name}</div>,
      <div key={`event-source-description-${i}`}>{linkedSource.description}</div>,
      <div key={`event-source-type-${i}`}>{sourceType}</div>,
    ];
  };

  const eventList = useMemo(() => {
    if (!eventSourcesData) {
      return (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      );
    }

    const colWidths = {
      type: 'percent',
      widths: [35, 35, 30],
    };

    return [
      <ListItem
        key="event-sources-list-header"
        isHeader
        columns={eventSourcesListHeaders}
        columnWidths={colWidths}
      />,
      <div
        key="event-sources-list-body"
        className="custom-thin-scrollbar"
      >
        {eventSourcesData.length ? (
          eventSourcesData.map((source, i) => (
            <ListItem
              key={source.source_id}
              itemIndex={i}
              entity={source.name}
              item={source}
              columns={getEventSourceListItemColumns(source, i)}
              columnWidths={colWidths}
              customEdit
              onEdit={() => setEditEventSourceId(source.source_id)}
              onDelete={() => onRemoveEventSource(source.source_id)}
              confirmationDialogTitle="Remove source"
              confimationDialogBody={<p>{'This will remove this source from this component.'}</p>}
            />
          ))
        ) : (
          <div className="list-item--empty-list">No event sources found.</div>
        )}
      </div>,
    ];
  }, [eventSourcesData, thumbnails]);

  /*
   ***************************
   * Health Tab
   **************************
   */

  const hasSourceOptions = sourceOptionsSchemas[mainData.source];

  const onChangeSourceOptions = useCallback(({ formData: optionsUpdate }) => {
    setSourceOptionsData(optionsUpdate);
  }, []);

  /*
   ***************************
   * Tab content
   **************************
   */
  const tabContent = useMemo(() => {
    const content = [
      <div
        key="attributes-tab"
        className="tab-content"
      >
        <div className="tab-content--header">
          <Button onClick={() => setAttribute({ id: `new-attribute-${getUniqueId()}` })}>
            Add attribute
          </Button>
        </div>
        <div className="list-container attribute-list-body">{attributeList}</div>
      </div>,
      <div
        key="models-tab"
        className="tab-content"
      >
        <div className="tab-content--header">
          <Button
            disabled={!models.length || !modelSchema}
            onClick={() => setEditModelId(`new-model-${getUniqueId()}`)}
          >
            Link model
          </Button>
        </div>
        <div className="list-container models-list-body">{modelList}</div>
      </div>,
      <div
        key="events-tab"
        className="tab-content"
      >
        <div className="tab-content--header">
          <Button
            disabled={!filteredEventSources.length}
            onClick={() => setEditEventSourceId(`new-event-source-${getUniqueId()}`)}
          >
            Link event source
          </Button>
        </div>
        <div className="list-container events-list-body">{eventList}</div>
      </div>,
    ];
    if (hasSourceOptions) {
      content.push(
        <div
          key="source-options-tab"
          className="tab-content custom-thin-scrollbar"
        >
          <JSONEditor
            schema={sourceOptionsSchemas[mainData.source]}
            formData={sourceOptionsData}
            onFormChange={onChangeSourceOptions}
            initialEditMode
            editorOnly
            showEditButton={false}
            showButtons={false}
          />
        </div>
      );
    }
    return content;
  }, [
    attributeList,
    models,
    modelSchema,
    modelList,
    eventList,
    hasSourceOptions,
    sourceOptionsSchemas,
    mainData.source,
    sourceOptionsData,
  ]);

  /*
   ***************************
   * Component create/update
   **************************
   */
  const prepAttributeData = (attributeData) => {
    let value = attributeData.lastValue;
    if (attributeData.dataType === 'boolean') {
      value = attributeData.lastValue === 'true';
    }
    return {
      ...attributeData,
      asset_id: component.asset?.id,
      values: [{ value, timestamp: new Date().toISOString() }],
    };
  };

  const onSubmitForm = useCallback(
    (e) => {
      const compPayload = {
        ...mainData,
        models: modelsData?.map(({ customName, modelId }) => ({ customName, modelId })),
        eventSources: eventSourcesData,
        sourceConfig: sourceOptionsData,
        attributeData: {
          updates: attributesData
            .filter(
              (attr) => attributeEditIds.includes(attr.id) && !attr.id.includes('new-attribute')
            )
            .map(prepAttributeData),
          creates: attributesData
            .filter(
              (attr) => attributeEditIds.includes(attr.id) && attr.id.includes('new-attribute')
            )
            .map(prepAttributeData),
          deletes: attributeDeleteIds.filter((id) => !id.includes('new-attribute')),
        },
        options: sourceOptionsData,
      };
      delete compPayload.descendantIds;

      // validate main form
      const { errors } = columnLeftComponentFormRef.current?.validate(compPayload) || {
        errors: [],
      };
      if (errors.length) {
        columnLeftComponentFormRef.current.onSubmit(e);
      } else {
        const cleanedPayload = cleanFormData(compPayload);
        onSubmit(cleanedPayload);
        onCloseModal();
      }
    },
    [
      mainData,
      modelsData,
      eventSourcesData,
      sourceOptionsData,
      attributesData,
      attributeEditIds,
      attributeDeleteIds,
    ]
  );

  return mainSchema ? (
    <SimpleModal
      title={modalTitle}
      className="component-modal"
      size="l"
      overlayCanClose={false}
      onClose={onCloseModal}
      noBodyPadding
    >
      <div className="modal-column-2">
        <div
          ref={columnLeftContainerRef}
          className="modal-column custom-thin-scrollbar"
        >
          <JSONEditor
            formRef={columnLeftComponentFormRef}
            schema={mainSchema}
            uiSchema={mainUiSchema}
            formData={mainData}
            onFormChange={onMainComponentChange}
            cancelCallback={onCloseModal}
            saveButtonText={saveButtonText}
            editorOnly
            initialEditMode
            showEditButton={false}
            customValidate={mainCustomValidate}
            customTransformErrors={mainTransformErrors}
            showButtons={false}
          />
        </div>

        <div className="modal-column">
          <div className="modal-column--content">
            {mainData.type !== 'virtual' ? (
              <>
                <HvTabs
                  id="comp-modal-tabs"
                  onChange={(e, newTabIndex) => setTabIndex(newTabIndex)}
                  value={tabIndex}
                  className="template-tabs"
                  variant="fullWidth"
                >
                  {tabHeaders}
                </HvTabs>
                {(tabContent || [])[tabIndex]}
              </>
            ) : (
              <div className="empty">No additional configuration for component type: Area</div>
            )}
          </div>
          <div className="modal-column--footer">
            <div className="button-group">
              <Button
                activity="secondary"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <Button onClick={onSubmitForm}>Save</Button>
            </div>
          </div>
        </div>
      </div>

      {attribute && (
        <EditItemModal
          entity="Attribute"
          withTitle={false}
          action={isNewAttribute ? 'Add' : 'Edit'}
          saveButtonText={'OK'}
          schema={getAttributeSchema()}
          onSubmit={onAddAttribute}
          onChange={onChangeAttribute}
          uiSchema={attributeUiSchema}
          onCloseModal={onCloseAttributeModal}
          customValidate={attributeCustomValidate}
          formData={attribute}
          flexEndButtons
          modalClass="component-sub-modal"
        />
      )}

      {editModelId && (
        <EditItemModal
          entity="Model"
          withTitle={false}
          action="Link"
          schema={modelSchema}
          uiSchema={modelUiSchema}
          onSubmit={onLinkModel}
          onCloseModal={onCancelLinkModel}
          formData={modelFormData}
          flexEndButtons
          modalClass="component-sub-modal"
        />
      )}

      {editEventSourceId && (
        <EditItemModal
          entity="Event Source"
          withTitle={false}
          action="Link"
          schema={eventSourcesSchema}
          onSubmit={onAddEventSource}
          onCloseModal={onCancelAddEventSource}
          formData={eventSourcesFormData}
          flexEndButtons
          modalClass="component-sub-modal"
        />
      )}
    </SimpleModal>
  ) : null;
};

ComponentModal.propTypes = {
  modelsHashmap: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
  updateId: PropTypes.string,
  parentId: PropTypes.string,
};

export default ComponentModal;
