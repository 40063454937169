import React from 'react';
import './ModelArrayField.scss';

const ModelArrayField = (props) => {
  const {
    schema: { thumbnail },
  } = props;
  const hasValidThumbnail = thumbnail?.split(':')[1]?.split('/')[0] === 'image';

  if (hasValidThumbnail) {
    return (
      <img
        className="component-model-thumbnail"
        src={thumbnail}
      />
    );
  }
  return <div className="component-model-thumbnail missing">No thumbnail set</div>;
};

export default ModelArrayField;
