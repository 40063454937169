.file-link {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}
.file-link button {
  font-weight: 400;
  line-height: 3;
}
.file-link button img {
  max-width: 3em;
  max-height: 3em;
  margin-right: 0.5rem;
  border: 2px solid transparent;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvRmlsZUxpbmsiLCJzb3VyY2VzIjpbIkZpbGVMaW5rLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuZmlsZS1saW5rIHtcbiAgZGlzcGxheTogZmxleDtcbiAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcblxuICBidXR0b24ge1xuICAgIGZvbnQtd2VpZ2h0OiA0MDA7XG4gICAgbGluZS1oZWlnaHQ6IDM7XG5cbiAgICBpbWcge1xuICAgICAgbWF4LXdpZHRoOiAzZW07XG4gICAgICBtYXgtaGVpZ2h0OiAzZW07XG4gICAgICBtYXJnaW4tcmlnaHQ6IDAuNXJlbTtcbiAgICAgIGJvcmRlcjogMnB4IHNvbGlkIHRyYW5zcGFyZW50O1xuICAgIH1cbiAgfVxufVxuIl19 */