/* eslint-disable default-param-last */

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const UPDATE_TRAIL = 'dt/trails/UPDATE_TRAIL';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */
export const updateTrail = (trail = []) => ({
  type: UPDATE_TRAIL,
  trail,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  trail: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  if (action.type === UPDATE_TRAIL) {
    return { ...state, trail: action.trail };
  }
  return state;
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getTrail = (state) => state.trails.trail;
