import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getActiveSite, setCreatedSite } from '../../bundles/sites';
import { getLayout } from '../../bundles/layouts';
import { requestComponents, getComponentsLoaded } from '../../bundles/components';
import { requestVisualizations, getVisualizationsLoaded } from '../../bundles/visualizations';
import { requestSources, getSourcesLoaded } from '../../bundles/sources';
import {
  setViewerReady,
  startSitePolling,
  stopSitePolling,
  initializeSitePolling,
  getTimezone,
} from '../../bundles/application';
import { requestModels, getModelsLoaded } from '../../bundles/models';
import { requestStateSets, getStateSetsLoaded } from '../../bundles/statesets';
import { requestIntegrations, getIntegrationsLoaded } from '../../bundles/integrations';
import { displayNotification, getOnline } from '../../bundles/notifications';
import getNotification from '../../bundles/notification-defaults';
import AppContext from '../../AppContext';
import { datetimeString } from '../../datetimeUtils';

import Page from '../Page';
import { requestNotifyEvents, getEventNotifications } from '../../bundles/events';

const SiteDetailView = ({
  match: {
    params: { id: siteId, page },
  },
}) => {
  const dispatch = useDispatch();

  const layout = useSelector((state) => getLayout(state, siteId));
  const site = useSelector(getActiveSite);
  const sourcesLoaded = useSelector(getSourcesLoaded);
  const modelsLoaded = useSelector(getModelsLoaded);
  const componentsLoaded = useSelector(getComponentsLoaded);
  const visualizationsLoaded = useSelector(getVisualizationsLoaded);
  const integrationsLoaded = useSelector(getIntegrationsLoaded);
  const statesetsLoaded = useSelector(getStateSetsLoaded);
  const online = useSelector(getOnline);
  const timezone = useSelector(getTimezone);
  const eventNotifications = useSelector(getEventNotifications);

  const { setIsFullScreen } = useContext(AppContext);

  useEffect(() => {
    dispatch(setCreatedSite(null));
    return () => {
      setIsFullScreen(false);
    };
  }, []);

  useEffect(() => {
    if (siteId) {
      dispatch(initializeSitePolling(siteId));
    }
  }, [siteId]);

  useEffect(() => {
    if (online) {
      dispatch(setViewerReady(false));
    }
  }, [online]);

  useEffect(() => {
    if (online && siteId) {
      dispatch(requestModels(siteId));
    }
  }, [siteId, online]);

  useEffect(() => {
    if (online && siteId) {
      dispatch(requestSources({ siteId, withVariables: true }));
    }
  }, [siteId, online]);

  useEffect(() => {
    if (online && siteId) {
      dispatch(requestStateSets(siteId));
    }
  }, [siteId, online]);

  useEffect(() => {
    if (online && siteId) {
      dispatch(requestComponents(siteId));
    }
  }, [siteId, online]);

  useEffect(() => {
    if (online && siteId) {
      dispatch(requestVisualizations(siteId));
    }
  }, [siteId, online]);

  useEffect(() => {
    if (online && siteId) {
      dispatch(requestIntegrations({ site: siteId }));
    }
  }, [siteId, online]);

  useEffect(() => {
    if (online && siteId) {
      dispatch(requestNotifyEvents(siteId));
    }
  }, [siteId, online]);

  useEffect(() => {
    if (eventNotifications.todoCount > 0 || eventNotifications.overdueCount > 0) {
      const timeStamp = datetimeString(Date.now(), timezone);

      dispatch(
        displayNotification(
          getNotification(
            'eventState',
            'eventNotification'
          )({
            todoCount: eventNotifications.todoCount,
            overdueCount: eventNotifications.overdueCount,
            timeStamp,
          })
        )
      );
    }
  }, [eventNotifications]);

  const assetsLoaded =
    sourcesLoaded &&
    modelsLoaded &&
    componentsLoaded &&
    visualizationsLoaded &&
    statesetsLoaded &&
    integrationsLoaded;

  useEffect(() => {
    if (assetsLoaded) {
      dispatch(startSitePolling());
    }
    return () => {
      dispatch(stopSitePolling());
    };
  }, [assetsLoaded]);

  const { pages } = layout || { pages: [], type: 'pt' };

  if (!page) {
    if (pages && pages[0] && pages[0].path) {
      return <Redirect to={`/sites/${siteId}/${pages[0].path}`} />;
    }
  }

  const pageConfig = pages && pages.find(({ path }) => path.toLowerCase() === page);

  return (
    <>
      <Page
        pages={pages}
        site={site}
        page={page}
        pageConfig={pageConfig}
      />
    </>
  );
};
export default SiteDetailView;
