import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getLayout, saveLayout } from '../../../bundles/layouts';
import SimpleModal from '../../SimpleModal';
import ViewerSettings from './ViewerSettings';
import FilesSettings from './FilesSettings';
import VisualizationsSettings from './VisualizationsSettings';
import EventTimelineSettings from './EventTimelineSettings';
import { getUpdatedFields } from '../../../utils';

const PanelSettings = ({
  site,
  type: panelType,
  pageIndex,
  sectionIndex,
  columnIndex,
  panelIndex,
  onFullSettings,
  onClose,
}) => {
  const dispatch = useDispatch();
  const layout = useSelector((state) => getLayout(state, site.id));

  const [settings, setSettings] = useState();

  const getSettingsPath = () => {
    if (panelType === 'viewer') {
      return ['pages', pageIndex, 'viewer'];
    }
    if (sectionIndex === 'viewer') {
      return ['pages', pageIndex, 'viewer', 'panels', 0, 'options'];
    }
    return [
      'pages',
      pageIndex,
      'sections',
      sectionIndex,
      'columns',
      columnIndex,
      'panels',
      panelIndex,
      'options',
    ];
  };

  const getSettingsData = useCallback(
    (path = [], dataPart = JSON.parse(JSON.stringify(layout))) => {
      const data = dataPart;
      const part = path.shift();
      if (path.length > 0) {
        return getSettingsData(path, data[part]);
      }
      return data[part];
    },
    [layout]
  );

  useEffect(() => {
    const path = getSettingsPath();
    setSettings(() => getSettingsData(path));
  }, [setSettings, getSettingsData]);

  const onUpdate = useCallback(
    (settingsUpdate) => setSettings(() => settingsUpdate),
    [setSettings]
  );

  const onSave = useCallback(
    ({ formData }) => {
      const path = getSettingsPath();
      const currentPageIndex = path[1];
      const update = getUpdatedFields(path, JSON.parse(JSON.stringify(layout)), formData);
      if (formData.pageType) {
        update.pages[currentPageIndex].pageType = formData.pageType;
      }
      dispatch(saveLayout(site.id, { ...layout, ...update }));
      onClose();
    },
    [layout]
  );

  return (
    <SimpleModal
      onClose={onClose}
      title="Settings"
      className="panel-settings-modal"
    >
      {panelType === 'viewer' && (
        <ViewerSettings
          settings={settings}
          onUpdate={onUpdate}
          onSave={onSave}
          onFullSettings={onFullSettings}
          onClose={onClose}
        />
      )}
      {panelType === 'files' && (
        <FilesSettings
          settings={settings}
          site={site}
          onSave={onSave}
          onFullSettings={onFullSettings}
          onClose={onClose}
        />
      )}
      {panelType === 'visualizations' && (
        <VisualizationsSettings
          settings={settings}
          onSave={onSave}
          onFullSettings={onFullSettings}
          onClose={onClose}
        />
      )}
      {panelType === 'event-timeline' && (
        <EventTimelineSettings
          settings={settings}
          onSave={onSave}
          onFullSettings={onFullSettings}
          onClose={onClose}
        />
      )}
    </SimpleModal>
  );
};

export default PanelSettings;
