import React from 'react';

import ErrorDisplay from './ErrorDisplay';

const SyncHealthNotification = ({ syncErrors = [] }) => {
  const errors = syncErrors.map((err, i) => (
    <div key={`${err.source}_sync_errors-${i}`}>
      <p className="source">Source {err.source} reported: </p>
      <ErrorDisplay
        error={err?.syncErrors?.error}
        action="synchronizing"
      />
      <ErrorDisplay
        error={err?.backfillErrors?.error}
        action="backfilling"
      />
    </div>
  ));

  return (
    <div className="sync-health-component">
      <div className="sync-health-component--text">
        The information presented in this panel might not be complete.
      </div>
      {errors}
      <div className="sync-health-component--text">
        Don't worry. These errors typically resolve themselves.
      </div>
      <div className="sync-health-component--text">
        If the issue persists for a longer period please contact support.
      </div>
    </div>
  );
};
export default SyncHealthNotification;
