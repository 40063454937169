/* eslint-disable default-param-last */
import { call, put, takeLatest } from 'redux-saga/effects';

import { updateLayout as updateLayoutApi } from '../services';
import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { SET_SITES, REMOVE_SITE } from './sites';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const UPDATE_LAYOUT = 'dt/layouts/UPDATE_LAYOUT';
const RESET_LAYOUT = 'dt/layouts/RESET_LAYOUT';
const SAVE_LAYOUT = 'dt/layouts/SAVE_LAYOUT';
const LAYOUT_SAVED = 'dt/layouts/LAYOUT_SAVED';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const updateLayout = (siteId, layout) => ({
  type: UPDATE_LAYOUT,
  siteId,
  layout,
});

export const resetLayout = (siteId) => ({
  type: RESET_LAYOUT,
  siteId,
});

export const saveLayout = (siteId, layout) => ({
  type: SAVE_LAYOUT,
  siteId,
  layout,
});

const layoutSaved = (siteId, layout) => ({
  type: LAYOUT_SAVED,
  layout,
  siteId,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  originalLayouts: {},
  layouts: {},
  updatedLayouts: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

const defaultLayout = {
  pages: [],
  type: 'pt',
};

const getLatestLayout = (layouts) =>
  layouts.length > 0
    ? layouts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0]
    : { ...defaultLayout };

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SITES: {
      const { sites } = action;
      return {
        ...state,
        layouts: sites.reduce((acc, { id, layouts = [] }) => {
          acc[id] = getLatestLayout(layouts);
          return acc;
        }, {}),
        originalLayouts: sites.reduce((acc, { id, layouts = [] }) => {
          acc[id] = getLatestLayout(layouts);
          return acc;
        }, {}),
      };
    }
    case UPDATE_LAYOUT: {
      const { siteId, layout } = action;
      const { layouts, updatedLayouts } = state;

      return {
        ...state,
        layouts: {
          ...layouts,
          [siteId]: layout,
        },
        updatedLayouts: [...updatedLayouts, siteId],
      };
    }
    case RESET_LAYOUT: {
      const { siteId } = action;
      const { layouts, updatedLayouts, originalLayouts } = state;

      return {
        ...state,
        layouts: {
          ...layouts,
          [siteId]: { ...originalLayouts[siteId] },
        },
        updatedLayouts: updatedLayouts.filter((id) => id !== siteId),
      };
    }
    case LAYOUT_SAVED: {
      const { siteId, layout } = action;
      const { layouts, updatedLayouts, originalLayouts } = state;

      return {
        ...state,
        originalLayouts: {
          ...originalLayouts,
          [siteId]: layout,
        },
        layouts: {
          ...layouts,
          [siteId]: layout,
        },
        updatedLayouts: updatedLayouts.filter((id) => id !== siteId),
      };
    }
    case REMOVE_SITE: {
      const { siteId } = action;
      const { layouts, updatedLayouts, originalLayouts } = state;
      const newLayouts = { ...layouts };
      const newOriginalLayouts = { ...originalLayouts };
      delete newLayouts[siteId];
      delete newOriginalLayouts[siteId];

      return {
        ...state,
        layouts: newLayouts,
        originalLayouts: newOriginalLayouts,
        updatedLayouts: updatedLayouts.filter((id) => id !== siteId),
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getLayout = ({ layouts: { layouts } }, siteId) => layouts[siteId] || {};
export const getLayouts = (state) => state.layouts.layouts;
export const hasBeenUpdated = ({ layouts: { updatedLayouts } }, siteId) =>
  updatedLayouts.includes(siteId);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doSaveLayout(action) {
  const { siteId, layout } = action;

  try {
    const savedLayout = yield call(updateLayoutApi, siteId, layout.id, layout);
    yield put(layoutSaved(siteId, savedLayout));
    yield put(displayNotification(getNotification('saveLayout', 'success')(layout.id)));
  } catch (e) {
    console.error('Unable to save layout: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('saveLayout', 'error')(layout.id)));
  }
}

export const sagas = [takeLatest(SAVE_LAYOUT, doSaveLayout)];
