/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Spinner, Button, Icon } from '@iq/react-components';
import {
  ResponsiveContainer,
  Cell,
  BarChart,
  YAxis,
  XAxis,
  Bar,
  LabelList,
  ReferenceLine,
  Legend,
} from 'recharts';

import Loader from '../../../Loader';

import { useSubscription } from '../../../../bundles/visualizations';
import { useClientSize } from '../../../../utils';
import { THRESHOLD_TYPES } from '../../../../constants';
import { lightenDarkenColor } from '../../../../colors';

const BarGaugeVisualization = ({ visualization, panelId }) => {
  const {
    latestValues: values,
    initialLoaded,
    loading,
  } = useSubscription(visualization.id, panelId);

  const { configuration = {}, variables = [] } = visualization;
  const { thresholds = {} } = configuration;
  const thresholdType = thresholds.thresholdType || THRESHOLD_TYPES.linear.value;
  const isLinearThresholdType = thresholdType === THRESHOLD_TYPES.linear.value;
  const isVertical = configuration.layout === 'vertical';

  const [{ width, height }, clientRef] = useClientSize();

  const sortedThresholds = useMemo(
    () =>
      thresholds[THRESHOLD_TYPES[thresholdType].key]
        ?.slice()
        .sort((a, b) => (isLinearThresholdType ? a.value - b.value : a.min - b.min || [])),
    [thresholds, thresholdType]
  );

  const getThresholdValue = (item) => {
    if (isLinearThresholdType) return item?.value || 0;
    const min = item?.min || 0;
    const max = item?.max || item?.min || 0;
    return [min, max];
  };

  const data = useMemo(() => {
    if (initialLoaded && values) {
      return (values || []).reduce((acc, { variable, value }) => {
        const { label, name, unit } = variables.find((v) => v.id === variable) || {};

        const valueWithDecimals = (rawValue) => {
          const val = rawValue?.at(-2) ?? 0;
          const { decimals } = configuration;
          return Number(val).toFixed(decimals);
        };

        return [
          ...acc,
          {
            name: label || name || variable,
            unit: unit || 'number',
            value:
              configuration.decimals || configuration.decimals === 0
                ? valueWithDecimals(value)
                : value,
            quality: value?.at(-1) || 0,
          },
        ];
      }, []);
    }
    return [];
  }, [values, initialLoaded, variables]);

  const linearData = useMemo(() => {
    if (data && sortedThresholds) {
      return data.reduce((acc, cur) => {
        let valueFill = 'grey';
        const sizedThresholds = sortedThresholds.map((threshold, i, arr) => ({
          ...threshold,
          size:
            i === 0
              ? getThresholdValue(threshold)[0]
              : getThresholdValue(threshold) - getThresholdValue(arr[i - 1]),
        }));

        const filteredThresholds = sizedThresholds.filter(
          (th) => getThresholdValue(th) > cur.value
        );

        if (filteredThresholds.length > 0) {
          filteredThresholds[0].size = getThresholdValue(filteredThresholds[0]) - cur.value;

          // if there's a threshold bigger than current value, use previous threshold color
          const nextThresholdIndex = sortedThresholds.findIndex(
            (th) => getThresholdValue(th) === getThresholdValue(filteredThresholds[0])
          );
          if (sortedThresholds[nextThresholdIndex - 1]) {
            valueFill = sortedThresholds[nextThresholdIndex - 1].fill;
          }
        }

        const lastThreshold = sortedThresholds[sortedThresholds.length - 1];
        if (cur.value >= getThresholdValue(lastThreshold) && lastThreshold?.fill) {
          valueFill = lastThreshold.fill;
        }
        return [
          ...acc,
          {
            ...cur,
            valueFill,
          },
        ];
      }, []);
    }
    return [];
  }, [data, sortedThresholds]);

  const sortedData = data?.sort((a, b) => a.value - b.value) || [];
  const dataRange = [sortedData[0]?.value || 0, sortedData[sortedData.length - 1]?.value || 0];

  // only invalidate if quality 2 (invalid) or 3 (questionable)
  const isDataInvalid = data?.some((value) => value.quality > 1);

  /*
   * For area charts we transform data to include the stacked sizes of thresholds
   * as keyed values (keyedBars below), as well as returning a separate array of
   * thresholds that is used to iterate over to add the threshold specific fill.
   */

  const { areaData, areaThresholds } = useMemo(() => {
    if (data && sortedThresholds && !isLinearThresholdType) {
      return data.reduce(
        (acc, cur) => {
          let valueFill = 'grey';
          const sizedBars = [];
          sortedThresholds.forEach((threshold, i, arr) => {
            const [thresholdMin, thresholdMax] = getThresholdValue(threshold);
            const [dataMin] = dataRange;
            const prevThreshold = arr[i - 1];
            const thresholdSize = thresholdMax - thresholdMin;

            if (i === 0) {
              if (dataMin < thresholdMin) {
                sizedBars.push({
                  min: dataMin,
                  max: thresholdMin,
                  size: thresholdMin - dataMin,
                });
              }
              sizedBars.push({ ...threshold, size: thresholdSize });
            } else if (thresholdMin < prevThreshold.max) {
              if (thresholdMax > prevThreshold.max) {
                sizedBars.push({
                  ...threshold,
                  min: prevThreshold.max,
                  size: thresholdMax - prevThreshold.max,
                });
              }
            } else {
              if (thresholdMin > prevThreshold.max) {
                sizedBars.push({
                  min: prevThreshold.max,
                  max: thresholdMin,
                  size: thresholdMin - prevThreshold.max,
                });
              }
              sizedBars.push({ ...threshold, size: thresholdSize });
            }
          });

          const filteredBars = sizedBars.filter((b) => b.max >= cur.value);

          if (filteredBars.length > 0) {
            filteredBars[0].size = filteredBars[0].max - cur.value;
            valueFill = filteredBars[0].fill || 'grey';
          }

          const keyedBars = filteredBars.reduce(
            (accTh, currTh) => ({
              ...accTh,
              [`th-${currTh.max}`]: currTh.size,
            }),
            {}
          );

          return {
            areaData: [...acc.areaData, { ...cur, ...keyedBars, valueFill }],
            areaThresholds: [...acc.areaThresholds, ...filteredBars],
          };
        },
        { areaData: [], areaThresholds: [] }
      );
    }
    return { areaData: [], areaThresholds: [] };
  }, [data, sortedThresholds]);

  const darkenThresholdColor = (color) => lightenDarkenColor(color, -10);
  const CustomLegend = ({ thresholdsData }) => {
    const size = 10;
    const linearHeight = 15;
    const linearWidth = 25;

    const linearThresholdClass = 'bar-gauge-legend-linear-threshold';
    const areaThresholdClass = 'bar-gauge-legend-area-threshold';

    return (
      <ul className="bar-gauge-legend">
        {thresholdsData?.map((thd, i) =>
          thd.description ? (
            <li
              className="bar-gauge-legend-item"
              key={`${thd.fill} + ${i}`}
            >
              <svg
                width={isLinearThresholdType && !isVertical ? linearWidth : size}
                height={isLinearThresholdType ? linearHeight : size}
                className={isLinearThresholdType ? linearThresholdClass : areaThresholdClass}
              >
                {isLinearThresholdType ? (
                  <line
                    x1="0"
                    x2={isVertical ? '0' : linearWidth}
                    y1={isVertical ? '0' : linearHeight / 2}
                    y2={isVertical ? '15' : linearHeight / 2}
                    strokeWidth={isVertical ? '5' : '3'}
                    stroke={thd.fill}
                  />
                ) : (
                  <rect
                    x="0"
                    y="0"
                    width={size}
                    height={size}
                    fill={`${thd.fill}40`}
                  />
                )}
              </svg>
              <span>{thd.description}</span>
            </li>
          ) : null
        )}
      </ul>
    );
  };

  const barMax = Math.max(sortedThresholds?.at(-1)?.max || 0, sortedData?.at(-1)?.value || 0);

  const getPaddedValue = (value) => {
    if (width < 500) {
      return (value * 100) / 75;
    }
    if (width < 1000) {
      return (value * 100) / 85;
    }
    return (value * 100) / 90;
  };

  const dataDomain = useMemo(() => {
    if (!isLinearThresholdType && sortedThresholds?.length) {
      return [sortedThresholds.at(0).min, sortedThresholds.at(-1).max];
    }
    const numberDigitsInMax = Math.floor(barMax).toString().length;
    let padX = 2;
    if (numberDigitsInMax > 1) {
      padX = 5 * 10 ** (numberDigitsInMax - 2);
    }
    const domainMax = getPaddedValue(barMax);
    // adjust to move up to the nearest sensible padX factor
    const adjustedDomainMax = Math.ceil(domainMax / padX) * padX;
    return ['dataMin', adjustedDomainMax];
  }, [barMax, width, isLinearThresholdType, sortedThresholds]);

  const barChart = isLinearThresholdType ? (
    <BarChart
      data={linearData}
      layout={configuration.layout}
      maxBarSize={width / 8}
    >
      <XAxis
        type={isVertical ? 'number' : 'category'}
        dataKey={isVertical ? null : 'name'}
        ticks={
          isVertical
            ? sortedThresholds?.map((th) => th.value)
            : linearData.map((d) => d.name || null)
        }
        minTickGap={0}
        tick={{ fontSize: 11 }}
        domain={isVertical && dataDomain}
      />
      <YAxis
        type={isVertical ? 'category' : 'number'}
        dataKey={isVertical ? 'name' : null}
        ticks={
          !isVertical
            ? sortedThresholds?.map((th) => th.value)
            : linearData.map((d) => d.name || null)
        }
        minTickGap={0}
        tick={{ fontSize: 11 }}
        domain={isVertical ? ['dataMin', 'dataMax'] : dataDomain}
      />
      <Bar
        stackId="a"
        dataKey="value"
        background={true} // background color is set in css
        isAnimationActive={false} // Disable animation for <LabelList /> to display. Bugfix
      >
        {linearData.map(({ valueFill }, i) => (
          <Cell
            key={`cell-${i}`}
            fill={`${valueFill}`}
          />
        ))}
        <LabelList
          dataKey="value"
          position={isVertical ? 'right' : 'top'}
        />
      </Bar>
      {sortedThresholds?.map((threshold, i) => (
        <ReferenceLine
          key={`th-${i}-${threshold.value}-${threshold.fill}`}
          y={!isVertical ? `${threshold.value}` : null}
          x={isVertical ? `${threshold.value}` : null}
          stroke={darkenThresholdColor(threshold.fill)}
          strokeWidth={2}
          isFront
        />
      ))}
      <Legend content={<CustomLegend thresholdsData={sortedThresholds} />} />
    </BarChart>
  ) : (
    <BarChart
      data={areaData}
      margin={isVertical ? { top: 0, right: 0, left: 15 } : { top: 15, right: 0 }}
      maxBarSize={width / 8}
      layout={configuration.layout}
    >
      <XAxis
        type={isVertical ? 'number' : 'category'}
        dataKey={isVertical ? null : 'name'}
        tick={{ fontSize: 11 }}
        minTickGap={0}
        domain={isVertical && dataDomain}
      />
      <YAxis
        type={isVertical ? 'category' : 'number'}
        dataKey={isVertical ? 'name' : null}
        tick={{ fontSize: 11 }}
        minTickGap={0}
        domain={isVertical ? ['dataMin', 'dataMax'] : dataDomain}
      />
      <Bar
        stackId="a"
        dataKey="value"
        background
      >
        {areaData.map(({ valueFill }, i) => (
          <Cell
            key={`cell-${i}`}
            fill={valueFill}
          />
        ))}
        <LabelList
          dataKey="value"
          position={isVertical ? 'right' : 'top'}
        />
      </Bar>
      {areaThresholds.map((threshold, i) => (
        <Bar
          key={`th-${i}-${threshold.max}`}
          stackId="a"
          dataKey={`th-${threshold.max}`}
          fill={threshold.fill ? `${threshold.fill}25` : 'transparent'}
          name={`Threshold ${i + 1}`}
        />
      ))}
      <Legend content={<CustomLegend thresholdsData={sortedThresholds} />} />
    </BarChart>
  );

  const vizHeight = height - 28; // less header
  const loaderHeight = vizHeight - 28; // less loader padding

  return (
    <div
      className={`bar-gauge-visualization-component ${isDataInvalid ? 'invalid-data' : ''}`}
      ref={clientRef}
    >
      <div className="bar-gauge-header">
        <div className="bar-gauge-title">
          <span className="title">{configuration.title || '\u00A0'}</span>
          {isDataInvalid ? (
            <Button
              tooltip="Invalid signal value"
              design="text"
            >
              <Icon
                icon="he-help"
                size="s"
                color="#FF7300"
              />
            </Button>
          ) : null}
        </div>
        {initialLoaded && loading && (
          <Spinner
            size="s"
            className="viz-loading-spinner"
          />
        )}
      </div>
      {!initialLoaded && loading ? (
        <Loader
          text="Loading ..."
          height={loaderHeight}
        />
      ) : (
        <div className="bar-gauge-wrapper">
          <ResponsiveContainer
            width="100%"
            height={vizHeight}
          >
            {barChart}
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default BarGaugeVisualization;
