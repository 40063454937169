import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Spinner } from '@iq/react-components';

import { getActiveSite } from '../../../bundles/sites';
import { getTags, updateTag, deleteTags, requestTags } from '../../../bundles/tags';
import { displayNotification } from '../../../bundles/notifications';
import services from '../../../services';

import ConfirmationDialog from '../../ConfirmationDialog';
import SimpleModal from '../../SimpleModal';
import TagSelect from '../../TagSelect';
import Heading from '../../Heading';

const TagsView = () => {
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);
  const tags = useSelector(getTags);

  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [tagsToDelete, setTagsToDelete] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tagName, setTagName] = useState('');
  const [checkingTags, setCheckingTags] = useState(false);

  useEffect(() => {
    if (site.id) {
      dispatch(requestTags(site.id));
    }
  }, [site.id]);

  useEffect(() => {
    if (selectedTagIds.length === 1) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [selectedTagIds]);

  useEffect(() => setIsEditing(false), [tags]);

  useEffect(() => {
    if (isEditing) {
      setTagName(() => (tags.find((t) => t.id === selectedTagIds[0]) || {}).name || '');
    }
  }, [isEditing]);

  const onDeleteTags = async () => {
    await setCheckingTags(true);
    const checkedTags = await Promise.all(
      selectedTagIds.map(async (tagId) => {
        try {
          const { total: connectedEvents } = await services.event.getEvents({
            site: site.id,
            org: site.org,
            tags: [tagId],
          });
          const { total: connectedFiles } = await services.file.getFiles({
            site: site.id,
            org: site.org,
            tags: [
              {
                filterType: 'include',
                operator: 'or',
                tags: [tagId],
              },
            ],
          });
          return {
            ...(tags.find((t) => t.id === tagId) || {}),
            connectedEvents,
            connectedFiles,
          };
        } catch (error) {
          return { error };
        }
      })
    );
    if (checkedTags.some((t) => t.error)) {
      dispatch(
        displayNotification({
          title: 'Unable to delete tags',
          message: 'Encountered error when checking for entities connected to tags.',
          type: 'persistent',
          status: 'warning',
          id: 'delete-tag',
        })
      );
    } else {
      await setTagsToDelete(() => checkedTags.filter((t) => t.id));
    }
    setCheckingTags(false);
  };

  const getConfirmDeleteBody = useCallback(() => {
    if (checkingTags) {
      return (
        <div className="checking-tags">
          <Spinner size="s" />
          <span>Finding tag associations...</span>
        </div>
      );
    }
    const plural = tagsToDelete.length > 1;

    return (
      <>
        <p>{`Are you sure you want to delete the following tag${plural ? 's' : ''}?`}</p>
        <div className="tag-container">
          {tagsToDelete.map((t) => (
            <div
              key={t.id}
              className="tag-connections"
            >
              <span>{t.name}</span>
              {(t.connectedFiles > 0 || t.connectedEvents > 0) && (
                <span>
                  Used in
                  {t.connectedFiles > 0 &&
                    ` ${t.connectedFiles} file${t.connectedFiles > 1 ? 's' : ''}`}
                  {t.connectedFiles > 0 && t.connectedEvents > 0 ? ' and' : ''}
                  {t.connectedEvents > 0 &&
                    ` ${t.connectedEvents} event${t.connectedEvents > 1 ? 's' : ''}`}
                </span>
              )}
            </div>
          ))}
        </div>
      </>
    );
  }, [tagsToDelete, checkingTags]);

  const onConfirmDeleteTags = () => {
    dispatch(
      deleteTags(
        site.id,
        tagsToDelete.map((t) => t.id)
      )
    );
    setTagsToDelete([]);
    setSelectedTagIds([]);
  };

  const onUpdateTag = () => {
    const editTag = tags.find((t) => t.id === selectedTagIds[0]);
    if (editTag) {
      const tagUpdate = { ...editTag, name: tagName };
      // remove color, icon as not being used
      delete tagUpdate.color;
      delete tagUpdate.icon;
      dispatch(updateTag(tagUpdate));
    }
  };

  return (
    <div className="tags-view">
      <Heading contentLeft={<div className="title">Create, edit or delete tags</div>} />
      <div className="tags-view__row">
        <div className="tags-view__select-container">
          <TagSelect
            availableTags={tags}
            value={selectedTagIds}
            onChange={setSelectedTagIds}
            placeholder="Create or select tags"
            isDisabled={isEditing}
          />
        </div>

        <div className="tags-view__tag-actions">
          <Button
            activity="danger"
            type="button"
            onClick={onDeleteTags}
            disabled={!selectedTagIds.length}
          >
            Delete
          </Button>
          <Button
            activity="primary"
            type="button"
            onClick={() => setIsEditing(true)}
            disabled={!isEditable}
          >
            Edit
          </Button>
        </div>
      </div>

      {checkingTags || tagsToDelete.length ? (
        <ConfirmationDialog
          onCancel={() => setTagsToDelete([])}
          onConfirm={onConfirmDeleteTags}
          confirmType="danger"
          title="Delete Tags"
          body={getConfirmDeleteBody()}
          confirmText="Delete"
          disabled={checkingTags}
        />
      ) : null}

      {isEditing && (
        <SimpleModal
          size="s"
          onClose={() => setIsEditing(false)}
          className="tag-edit-modal-wrapper"
          title="Edit Tag"
        >
          <div className="tag-edit-modal">
            <Input
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              required
            />
            <div className="tag-edit-modal__actions">
              <Button
                activity="secondary"
                type="button"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
              <Button
                activity="primary"
                type="button"
                onClick={onUpdateTag}
                disabled={!tagName}
              >
                Update
              </Button>
            </div>
          </div>
        </SimpleModal>
      )}
    </div>
  );
};

export default TagsView;
