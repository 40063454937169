const getTransferSchema = (serviceAccounts) => {
  const serviceAcctOptions = serviceAccounts.map((serviceAccount) => ({
    const: serviceAccount.userId,
    title: serviceAccount.name,
  }));

  return {
    type: 'object',
    required: ['fromId', 'toId'],
    properties: {
      fromId: {
        type: 'string',
        title: 'From',
        placeholder: 'Select service account...',
        anyOf: serviceAcctOptions,
      },
      toId: {
        type: 'string',
        title: 'To',
        placeholder: 'Select service account...',
        anyOf: serviceAcctOptions,
      },
    },
  };
};

export default getTransferSchema;
