import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Icon, Button } from '@iq/react-components';
import { NotFoundError } from '@iq/services';
import printJS from 'print-js';

import Tabs, { Tab } from '../Tabs/Tabs';
import BigModal from '../BigModal';
import PdfPreview from './types/PdfPreview';
import VideoPreview from './types/VideoPreview';
import ImagePreview from './types/ImagePreview';
import TextPreview from './types/TextPreview';
import BookmarkPanel from './BookmarkPanel';
import FileBookmarks from './FileBookmarks';
import FiletypeIcon from '../FiletypeIcon';

import Loader from '../Loader';
import FileLink from '../FileLink';
import { useClientSizeRefresh } from '../../utils';
import { checkIsOnline, displayNotification } from '../../bundles/notifications';
import getNotification from '../../bundles/notification-defaults';

const supportedFiles = [
  // Text
  'json',
  'md',
  'txt',

  // Pdf
  'pdf',

  // Images
  'apng',
  'bmp',
  'gif',
  'ico',
  'jpeg',
  'jpg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'tif',
  'tiff',
  'webp',

  // Video
  '3gp',
  'avi',
  'mov',
  'mp4',
  'ogv',
  'webm',
];

const RelatedFiles = ({ files, onPreview }) => (
  <div className="related-files-component custom-thin-scrollbar">
    {files.map((item) => (
      <FileLink
        file={item}
        key={item.id}
        onClick={() => onPreview(item)}
      />
    ))}
  </div>
);

const ChangeFile = ({ onStepPrevious, onStepNext, canToggleNext, file }) => (
  <div className="change-file">
    {onStepPrevious && (
      <Button
        activity="secondary"
        disabled={!canToggleNext}
        onClick={() => onStepPrevious(file.id)}
        icon={
          <Icon
            size="xs"
            icon="he-backwards"
          />
        }
        tooltip="Previous"
        tooltipPosition="bottom"
      />
    )}
    {onStepNext && (
      <Button
        activity="secondary"
        disabled={!canToggleNext}
        onClick={() => onStepNext(file.id)}
        icon={
          <Icon
            size="xs"
            icon="he-forwards"
          />
        }
        tooltip="Next"
        tooltipPosition="bottom"
      />
    )}
  </div>
);

const Toolbar = ({
  handleClickPrint,
  handleClickDownload,
  handleClickCreateBookmark,
  handleToggleFullscreen,
  inlineResourceUrl,
  downloadedFile,
  fullScreenRef,
  file,
  enableBookmarks,
  contractedSidebar,
}) => (
  <div className={contractedSidebar ? 'bottom-toolbar-contracted' : 'bottom-toolbar'}>
    {(file.ext === 'pdf' || file.fileType === 'image') && (
      <Button
        activity="secondary"
        onClick={() => handleClickPrint(file.ext === 'pdf' ? 'pdf' : 'image')}
        icon={<Icon icon="he-print" />}
        tooltip="Print"
        tooltipPosition={contractedSidebar ? 'top-end' : 'top'}
      />
    )}
    <Button
      activity="secondary"
      onClick={handleClickDownload}
      icon={<Icon icon="he-download" />}
      tooltip="Download"
      tooltipPosition={contractedSidebar ? 'top-end' : 'top'}
    />
    {downloadedFile && fullScreenRef.current && (
      <>
        {enableBookmarks && (
          <Button
            activity="secondary"
            onClick={handleClickCreateBookmark}
            icon={<Icon icon="he-tag" />}
            tooltip="Create bookmark"
            tooltipPosition={contractedSidebar ? 'top-end' : 'top'}
          />
        )}
        <Button
          activity="secondary"
          onClick={handleToggleFullscreen}
          icon={
            <Icon
              size="s"
              icon="he-fullscreen"
            />
          }
          tooltip="Full screen"
          tooltipPosition={contractedSidebar ? 'top-end' : 'top'}
        />
        {(file.ext === 'pdf' || file.fileType === 'image') && (
          <a
            href={inlineResourceUrl}
            target="_blank"
          >
            <Button
              activity="secondary"
              icon={<Icon icon="he-popup" />}
              tooltip="Open in new window"
              tooltipPosition={contractedSidebar ? 'top-end' : 'top'}
            />
          </a>
        )}
      </>
    )}
  </div>
);

const PreviewComponent = ({
  shouldDownloadFile,
  downloadedFile,
  downloadedFileType,
  file,
  fullScreenRef,
  setCurrentBookmarkState,
  activeBookmark,
  setActiveBookmark,
  setFileInfo,
  zoomLevel,
  displayBookmarkPanel,
  contractedSidebar,
  setIsPdf,
  onStepPrevious,
  onStepNext,
}) => {
  if (shouldDownloadFile && (!downloadedFile || !downloadedFileType)) {
    return (
      <div className="file-preview-loader">
        <Loader text="Loading file..." />
      </div>
    );
  }

  const bookmark =
    activeBookmark !== null ? file.bookmarks.find(({ id }) => id === activeBookmark) : null;

  useEffect(() => {
    setIsPdf(downloadedFileType === 'pdf');
  }, [downloadedFileType]);

  if (downloadedFile instanceof Error) {
    return <div style={{ paddingLeft: '1.5rem' }}>{downloadedFile.message}</div>;
  }

  if (downloadedFileType === 'pdf') {
    return (
      <PdfPreview
        activeBookmark={bookmark}
        setActiveBookmark={setActiveBookmark}
        file={downloadedFile}
        fullScreenRef={fullScreenRef}
        setBookmarkState={setCurrentBookmarkState}
        setFileInfo={setFileInfo}
        contractedSidebar={contractedSidebar}
        onStepPrevious={() => onStepPrevious(file.id)}
        onStepNext={() => onStepNext(file.id)}
      />
    );
  }

  if (downloadedFileType === 'video') {
    return (
      <VideoPreview
        file={file}
        videoRef={fullScreenRef}
        setBookmarkState={setCurrentBookmarkState}
        activeBookmark={bookmark}
        setActiveBookmark={setActiveBookmark}
        setFileInfo={setFileInfo}
        displayBookmarkPanel={displayBookmarkPanel}
      />
    );
  }

  if (downloadedFileType === 'image') {
    return (
      <ImagePreview
        file={downloadedFile}
        fullScreenRef={fullScreenRef}
        zoomLevel={zoomLevel}
        setFileInfo={setFileInfo}
      />
    );
  }

  if (downloadedFileType === 'text') {
    return (
      <TextPreview
        file={downloadedFile}
        fullScreenRef={fullScreenRef}
        setFileInfo={setFileInfo}
      />
    );
  }

  return (
    <div className="file-preview-icon-container">
      <div className="file-preview-icon">
        <FiletypeIcon file={file} />
      </div>
    </div>
  );
};

const FilePreviewModal = ({
  fileId,
  files = [],
  activeBookmark,
  setActiveBookmark,
  onCloseModal,
  onStepNext,
  onStepPrevious,
  onPreview,
  canEdit,
}) => {
  const fullScreenRef = useRef(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [canToggleNext, setCanToggleNext] = useState(false);
  const [shouldDownloadFile, setShouldDownloadFile] = useState(false);
  const [downloadedFile, setDownloadedFile] = useState(null);
  const [downloadedFileType, setDownloadedFileType] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [displayBookmarkPanel, setDisplayBookmarkPanel] = useState(false);
  const [currentBookmarkState, setCurrentBookmarkState] = useState();
  const [contractedSidebar, setContractedSidebar] = useState(true);
  const [isPdf, setIsPdf] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  const refreshClientSize = useClientSizeRefresh();
  const dispatch = useDispatch();

  const file = useMemo(() => files.find((f) => f.id === fileId), [fileId, files]);

  const onKeyDown = (e) => {
    if (displayBookmarkPanel) return;

    if (e.keyCode === 39 && onStepNext) {
      e.preventDefault();
      e.stopPropagation();
      onStepNext(file.id);
    }
    if (e.keyCode === 37 && onStepPrevious) {
      e.preventDefault();
      e.stopPropagation();
      onStepPrevious(file.id);
    }
  };

  const relatedFiles = files.filter(({ id }) => id !== file.id);

  let fileType;
  if (file.mime && file.mime.includes('pdf')) {
    fileType = 'pdf';
  } else {
    fileType = file.mime ? file.mime.split('/').shift() : null;
  }

  const enableBookmarks = ['video', 'pdf'].includes(fileType);
  const enableEditBookmarks = enableBookmarks && canEdit;

  const downloadFile = async () => {
    if (!fileType.includes('video')) {
      let hasError = false;
      const blob = await fetch(`${window.location.protocol}//${file.resourceUrl}`)
        .then((res) => {
          if (!res.ok) {
            if (res.status === 404) {
              throw new NotFoundError('The requested file may have been removed.');
            }
            throw new Error('An error was encountered when trying to retrieve the file.');
          }
          return res.blob();
        })
        .catch((e) => {
          hasError = true;
          console.error('Unable to download file: ', e);
          dispatch(checkIsOnline());
          dispatch(displayNotification(getNotification('filePreviewDownload', 'error')()));
          return e;
        });
      const fileObject = !hasError ? new File([blob], file.filename) : blob;
      setDownloadedFile(fileObject);
    } else {
      setDownloadedFile(file);
    }
    setDownloadedFileType(fileType);

    if (fileType !== 'pdf') setCanToggleNext(true);
  };

  const handleClickDownload = () => {
    setTimeout(() => {
      window.location = `${window.location.protocol}//${file.resourceUrl}`;
    });
  };

  const inlineResourceUrl = useMemo(
    () => `${window.location.protocol}//${file.resourceUrl}&disp=inline`,
    [file]
  );

  const handleClickPrint = (typeOfFile) => {
    printJS({
      printable: URL.createObjectURL(downloadedFile),
      type: typeOfFile,
    });
  };

  const handleToggleFullscreen = () => {
    const ref = fullScreenRef.current;
    if (ref.requestFullscreen) {
      ref.requestFullscreen();
    }
    if (ref.webkitRequestFullscreen) {
      ref.webkitRequestFullscreen();
    }
  };

  const handleClickCreateBookmark = () => {
    setDisplayBookmarkPanel(!displayBookmarkPanel);
  };

  const handleEditBookmark = (bookmark) => {
    setCurrentBookmarkState(bookmark);
    setDisplayBookmarkPanel(true);
  };

  const handleToggleSidebar = (tab) => {
    setSelectedTab(tab);
    setContractedSidebar(!contractedSidebar);
    refreshClientSize();
  };

  const keyListener = (e) => {
    if (e.keyCode === 40) {
      fullScreenRef.current.scrollBy(0, 40);
    }

    if (e.keyCode === 38) {
      fullScreenRef.current.scrollBy(0, -40);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, []);

  useEffect(() => {
    if (canToggleNext) {
      document.addEventListener('keydown', onKeyDown);
    }

    if (displayBookmarkPanel) {
      document.removeEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [canToggleNext, displayBookmarkPanel]);

  useEffect(() => {
    setDownloadedFile(null);
    setDownloadedFileType(null);
    setZoomLevel(0);
    setFileInfo(null);

    if (supportedFiles.includes(file.filename.toLowerCase().split('.').pop())) {
      setCanToggleNext(false);
      setShouldDownloadFile(true);
      downloadFile();
    } else {
      setCanToggleNext(true);
      setShouldDownloadFile(false);
      setDownloadedFile(null);
      setDownloadedFileType(null);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [file.id]);

  return (
    <BigModal
      onClose={onCloseModal}
      className="file-preview-modal-component"
      overlayClassName="file-preview-modal-overlay"
    >
      <div className="close-preview-modal">
        <Icon
          size="xs"
          icon="he-close"
          style={{ color: '#f2f2f2' }}
          onClick={onCloseModal}
        />
      </div>
      <div className="file-preview-modal">
        <div className="preview-area">
          <div className={isPdf ? 'preview-content' : 'preview-content non-pdf-padding'}>
            {!isPdf && (
              <div className="top-toolbar">
                <div className="title">{file.filename}</div>
                <ChangeFile
                  onStepNext={onStepNext}
                  onStepPrevious={onStepPrevious}
                  canToggleNext={canToggleNext}
                  file={file}
                />
              </div>
            )}
            <PreviewComponent
              shouldDownloadFile={shouldDownloadFile}
              downloadedFile={downloadedFile}
              downloadedFileType={downloadedFileType}
              file={file}
              fullScreenRef={fullScreenRef}
              setCurrentBookmarkState={setCurrentBookmarkState}
              zoomLevel={zoomLevel}
              activeBookmark={activeBookmark}
              setActiveBookmark={setActiveBookmark}
              setFileInfo={setFileInfo}
              displayBookmarkPanel={displayBookmarkPanel}
              contractedSidebar={contractedSidebar}
              setIsPdf={setIsPdf}
              onStepPrevious={onStepPrevious}
              onStepNext={onStepNext}
            />
          </div>
          <div className={contractedSidebar ? 'preview-sidebar contracted' : 'preview-sidebar'}>
            <div
              className={isPdf ? 'sidebar-icons pdf-top-margin' : 'sidebar-icons'}
              style={{ display: contractedSidebar ? 'initial' : 'none' }}
            >
              {enableBookmarks && (
                <Button
                  activity="secondary"
                  onClick={() => handleToggleSidebar('bookmarks')}
                  icon={<Icon icon="bookmark" />}
                  tooltip="View bookmarks"
                  tooltipPosition="bottom-end"
                />
              )}
              <Button
                activity="secondary"
                onClick={() => handleToggleSidebar('related')}
                icon={<Icon icon="he-list" />}
                tooltip="View related items"
                tooltipPosition="bottom-end"
              />
            </div>
            <div
              style={{
                display: contractedSidebar ? 'none' : 'initial',
                height: 'calc(100% - 56px)',
              }}
            >
              <Tabs
                selectedTab={selectedTab}
                actions={
                  <Button
                    activity="secondary"
                    onClick={handleToggleSidebar}
                    style={{ background: 'none' }}
                    icon={<Icon icon="he-forwards" />}
                    tooltip="Close sidebar"
                    tooltipPosition="bottom-end"
                  />
                }
              >
                {enableBookmarks && (
                  <Tab
                    id="bookmarks"
                    title={
                      <Button
                        activity="secondary"
                        className="tab-button"
                        icon={<Icon icon="bookmark" />}
                        tooltip="Bookmarks"
                        tooltipPosition="bottom-start"
                      />
                    }
                  >
                    <FileBookmarks
                      file={file}
                      activeBookmark={activeBookmark}
                      setActiveBookmark={setActiveBookmark}
                      onEditBookmark={handleEditBookmark}
                    />
                  </Tab>
                )}
                <Tab
                  id="related"
                  title={
                    <Button
                      activity="secondary"
                      className="tab-button"
                      icon={<Icon icon="he-list" />}
                      tooltip="Related items"
                      tooltipPosition="bottom-start"
                    />
                  }
                >
                  <RelatedFiles
                    files={relatedFiles}
                    onPreview={onPreview}
                  />
                </Tab>
              </Tabs>
            </div>
            <Toolbar
              onStepNext={onStepNext}
              onStepPrevious={onStepPrevious}
              canToggleNext={canToggleNext}
              handleClickPrint={handleClickPrint}
              handleClickDownload={handleClickDownload}
              downloadedFile={downloadedFile}
              inlineResourceUrl={inlineResourceUrl}
              fullScreenRef={fullScreenRef}
              handleClickCreateBookmark={handleClickCreateBookmark}
              handleToggleFullscreen={handleToggleFullscreen}
              file={file}
              enableBookmarks={enableEditBookmarks}
              fileInfo={fileInfo}
              contractedSidebar={contractedSidebar}
            />
          </div>
        </div>

        {/* {!!file.bookmarkData && (
          <BookmarkData bookmark={file} fileType={downloadedFileType} />
        )} */}

        {fileInfo !== null && displayBookmarkPanel && (
          <div className="create-bookmark-backdrop">
            <BookmarkPanel
              file={file}
              bookmark={currentBookmarkState}
              fileInfo={fileInfo}
              onClose={() => {
                setCurrentBookmarkState((prevState) => ({
                  bookmarkData: prevState.bookmarkData,
                }));
                setDisplayBookmarkPanel(false);
              }}
            />
          </div>
        )}
      </div>
    </BigModal>
  );
};

export default FilePreviewModal;
