import React from 'react';

import SimpleModal from '../../../../../SimpleModal';
import JSONEditor from '../../../../../JSONEditor';

const AddInvitationModal = ({
  formData,
  onCloseModal = () => {},
  onSubmit = () => {},
  onChange = () => {},
  schema,
  uiSchema,
  submitDisabled,
}) => {
  const handleSubmit = ({ formData: userData }) => {
    onSubmit(userData);
  };

  const onFormChange = ({ formData: updatedFormData }) => {
    onChange({ ...updatedFormData });
  };

  return (
    <SimpleModal
      onClose={onCloseModal}
      allowOverlayScroll
      className="add-user-modal-component"
      title="Invite users"
      size="s"
    >
      <div className="add-user-modal">
        <JSONEditor
          submitDisabled={submitDisabled}
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          saveButtonText={'Save'}
          onFormChange={onFormChange}
          initialEditMode
          showEditButton={false}
          cancelCallback={onCloseModal}
          onFormSubmit={handleSubmit}
          editorOnly
        />
      </div>
    </SimpleModal>
  );
};

export default AddInvitationModal;
