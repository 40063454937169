/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input, Spinner } from '@iq/react-components';

import CustomSelect from '../../CustomSelect';
import ListItem from '../../ListItem';
import EditItemModal from '../../EditItemModal';
import { getVariableSchema, variableUiSchema } from './schemas/variableSchema';
import {
  requestSources,
  getSources,
  getSourceTypes,
  getSourcesLoaded,
  createVariables,
  updateVariable,
  deleteVariable,
} from '../../../bundles/sources';
import { getActiveSite } from '../../../bundles/sites';
import { getStateSets, requestStateSets, getStateSetsLoaded } from '../../../bundles/statesets';
import getNotification from '../../../bundles/notification-defaults';
import { displayNotification, checkIsOnline } from '../../../bundles/notifications';
import { getVariables } from '../../../services';
import { camelToSentence, useDebounce, useClientSize } from '../../../utils';
import Heading from '../../Heading';
import Pagination from '../../Pagination';
import ExportTimeSeriesModal from './components/ExportTimeSeriesModal';
import { INTERNAL_DATA_SOURCE } from '../../../constants';

const VariableView = ({ wizardType }) => {
  const dispatch = useDispatch();
  const { id: siteId } = useSelector(getActiveSite);
  const sources = useSelector(getSources);
  const sourcesLoaded = useSelector(getSourcesLoaded);
  const sourceTypes = useSelector(getSourceTypes);
  const statesets = useSelector(getStateSets);
  const statesetsLoaded = useSelector(getStateSetsLoaded);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [sourceFilter, setSourceFilter] = useState(null);
  const [sortBy, setSortBy] = useState({ sortKey: 'name', desc: false });
  const [page, setPage] = useState(1);
  const [variables, setVariables] = useState(null);
  const [totalVariables, setTotalVariables] = useState(0);
  const [varSchema, setVarSchema] = useState(null);
  const [createVarSchema, setCreateVarSchema] = useState({});
  const [updateVarUiSchema, setUpdateVarUiSchema] = useState({});
  const [createVarData, setCreateVarData] = useState({});
  const [showCreateVariableForm, setShowCreateVariableForm] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showDownloadForm, setShowDownloadForm] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState(0);

  const [{ height }, clientRef] = useClientSize();

  // list body height - header (46) - pagination (54) / row height (51); min limit 5
  const queryLimit = useMemo(() => Math.max(Math.floor((height - 46 - 54) / 51 - 1), 5), [height]);

  useEffect(() => {
    if (siteId && !sourcesLoaded) {
      dispatch(requestSources({ siteId }));
      dispatch(requestStateSets(siteId));
    }
  }, [siteId, sourcesLoaded]);

  useEffect(() => {
    if (sourcesLoaded && sources.length) {
      const filteredSources = sources.filter((source) => source.type !== INTERNAL_DATA_SOURCE); // Ignore internal source
      setSourceFilter(filteredSources[0]?.id || '');
    }
  }, [sourcesLoaded, sources]);

  const baseQuery = useMemo(
    () => ({
      limit: queryLimit,
      page,
      order: sortBy.desc ? 'DESC' : 'ASC',
      orderBy: sortBy.sortKey,
      extended: true,
    }),
    [queryLimit, page, sortBy]
  );

  const onRequestVariables = useDebounce(() => {
    const query = { ...baseQuery };
    const vizFilters = selectedFilters.filter((filterValue) =>
      filterValue.includes('visualizations')
    );
    const vizFilter = vizFilters.length === 1 ? vizFilters[0].split('-')[1] : 'all';

    const statesetFilters = selectedFilters.filter((filterValue) =>
      filterValue.includes('stateset')
    );
    const statesetFilter = statesetFilters.length === 1 ? statesetFilters[0].split('-')[1] : 'all';

    query.visualizations = vizFilter;
    query.stateset = statesetFilter;

    if (searchFilter) {
      query.search = searchFilter;
    }

    const fetchVariables = async () => {
      try {
        const { values, total } = await getVariables(sourceFilter, query);
        setVariables(values);
        setTotalVariables(total);
      } catch (e) {
        console.error('Unable to fetch signals: ', e);
        dispatch(checkIsOnline());
        dispatch(displayNotification(getNotification('getVariables', 'error')('variable')));
      }
    };

    fetchVariables();
  }, 500);

  useEffect(() => {
    if (sourceFilter) {
      onRequestVariables();
    } else {
      setVariables([]);
    }
  }, [sourceFilter, baseQuery, queryLimit, searchFilter, selectedFilters, needsRefresh]);

  const sourceFilterOptions = useMemo(() => {
    if (sourcesLoaded && sources) {
      const filteredSources = sources.filter((source) => source.type !== INTERNAL_DATA_SOURCE); // Ignore internal source
      return filteredSources.map((source) => ({
        value: source.id,
        label: source.name,
      }));
    }
    return [];
  }, [sourcesLoaded, sources]);

  const filterOptions = [
    { value: 'visualizations-with', label: 'With visualizations' },
    { value: 'visualizations-without', label: 'Without visualizations' },
    { value: 'stateset-with', label: 'With state set' },
    { value: 'stateset-without', label: 'Without state set' },
  ];

  const getSourceOptionsSchema = (source, sourceType) => ({
    properties: {
      source_id: { enum: [source.id] },
      options: {
        type: 'object',
        title: 'Options',
        description: 'Additional identifiers and settings. Generally these should not be changed.',
        required: sourceType.schemas.variable.properties.options.required,
        properties: {
          ...Object.entries(sourceType.schemas.variable.properties.options.properties).reduce(
            (acc, [k, v]) => {
              acc[k] = { ...v, title: camelToSentence(k) };
              return acc;
            },
            {}
          ),
        },
      },
    },
  });

  const variableSourceOptions = useMemo(
    () =>
      sources.map((source) => {
        const sourceType = sourceTypes.find((st) => st.name === source.type);
        if (
          Object.keys(sourceType.schemas.variable?.properties?.options?.properties || {}).length
        ) {
          return getSourceOptionsSchema(source, sourceType);
        }
        return {
          properties: {
            source_id: { enum: [source.id] },
          },
        };
      }),
    [sources, sourceTypes]
  );

  // don't include statesets if in wizard
  const variableSchema = useMemo(() => {
    const states = wizardType ? null : (statesetsLoaded && statesets) || null;
    return getVariableSchema(states);
  }, [statesets, statesetsLoaded, wizardType]);

  useEffect(() => {
    if (sources.length) {
      setVarSchema({
        ...variableSchema,
        required: ['name', 'source_id'],
        properties: {
          ...variableSchema.properties,
          id: { type: 'string' },
          source_id: {
            ...variableSchema.properties.source_id,
            default: sources[0].id,
            oneOf: sources.map((source) => ({
              const: source.id,
              title: source.name,
            })),
          },
        },
        dependencies: {
          source_id: {
            oneOf: variableSourceOptions,
          },
        },
      });
    }
  }, [variableSchema, sources, variableSourceOptions]);

  useEffect(() => {
    setUpdateVarUiSchema({
      ...variableUiSchema,
      source_id: { 'ui:widget': 'hidden' },
    });
  }, [variableUiSchema]);

  useEffect(() => {
    const filteredSources = sources.filter((source) => source.type !== INTERNAL_DATA_SOURCE); // Ignore internal source
    if (filteredSources.length) {
      setCreateVarSchema({
        ...variableSchema,
        required: ['name', 'source_id'],
        properties: {
          ...variableSchema.properties,
          source_id: {
            ...variableSchema.properties.source_id,
            default: filteredSources[0].id,
            oneOf: filteredSources
              .filter((source) => source.type !== INTERNAL_DATA_SOURCE)
              .map((source) => ({
                const: source.id,
                title: source.name,
              })),
          },
        },
        dependencies: {
          source_id: {
            oneOf: variableSourceOptions,
          },
        },
      });
    }
  }, [variableSchema, sources, variableSourceOptions]);

  const prepUpdate = (rawData) => {
    const selectedVar = JSON.parse(rawData.options.variableSelect);
    return {
      ...rawData,
      name: rawData.name || selectedVar.name,
      itemDesignation: rawData.itemDesignation || selectedVar.itemDesignation,
      dataType: rawData.dataType || selectedVar.dataType,
      unit: rawData.unit || selectedVar.unit,
      options: {
        ...rawData.options,
        objectId: selectedVar.objectId,
        variableId: selectedVar.variableId,
        itemDesignation: selectedVar.itemDesignation,
      },
    };
  };

  const onUpdateVariable = (sourceId, updatedVariable) => {
    const data = { ...updatedVariable };
    if (!data.dataType) {
      data.dataType = null;
    }
    if (!data.unit) {
      data.unit = null;
    }
    dispatch(updateVariable(sourceId, data.id, data, () => setNeedsRefresh((prev) => prev + 1)));
  };

  const onCreateVariable = ({ formData: { source_id: sourceId, ...createData } }) => {
    dispatch(createVariables(sourceId, createData, () => setNeedsRefresh((prev) => prev + 1)));
    setShowCreateVariableForm(false);
    setCreateVarData({});
  };

  const onChangeCreateVariable = ({ formData: newVariable }) => {
    if (
      newVariable.options?.variableSelect &&
      newVariable.options.variableSelect !== createVarData.options.variableSelect
    ) {
      setCreateVarData(() => prepUpdate(newVariable));
    } else {
      setCreateVarData(() => newVariable);
    }
  };

  const handleClose = useCallback(
    () => setShowCreateVariableForm(false),
    [setShowCreateVariableForm]
  );
  const handleCloseExportModal = useCallback(
    () => setShowDownloadForm(false),
    [setShowDownloadForm]
  );
  const handleExportCompleted = useCallback(() => {
    handleClose();
    setSelectedIds([]);
  }, [variables]);

  const onDeleteVariable = useCallback((sourceId, variableId) => {
    dispatch(
      deleteVariable(sourceId, variableId, false, () => setNeedsRefresh((prev) => prev + 1))
    );
  }, []);

  const onToggleSelection = useCallback(
    (id) => {
      const isSelected = selectedIds.includes(id);
      setSelectedIds((prev) =>
        isSelected ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
      );
    },
    [selectedIds]
  );

  const toggleSelectAllVariables = useCallback(
    (e) => {
      e.stopPropagation();
      setSelectedIds((prev) =>
        prev.length === variables.length ? [] : variables.map((v) => v.id)
      );
    },
    [variables]
  );

  const handleSort = (sortKey) => {
    const desc = sortBy.sortKey === sortKey ? !sortBy.desc : true;
    setSortBy(() => ({ sortKey, desc }));
  };

  let headers = [
    {}, // For select column
    { sortKey: 'name', label: 'Signal name' },
    { sortKey: 'itemDesignation', label: 'Reference designation' },
    { label: 'Data source' },
  ];

  if (!wizardType) {
    headers = [
      ...headers,
      { sortKey: 'stateset', label: 'Linked state set' },
      { sortKey: undefined, label: 'Linked components' },
    ];
  }

  const onSearchFilter = useCallback((e) => {
    setPage(1);
    setSearchFilter((e.target.value || '').trim());
  }, []);

  const handleSourceFilterChange = useCallback((sourceId) => {
    setPage(1);
    setSourceFilter(sourceId);
  }, []);

  const handleSelectedFiltersChange = useCallback((filters) => {
    setPage(1);
    setSelectedFilters(filters);
  }, []);

  const headerColumns = headers.map((h, i) => {
    const key = `${h.sortKey || h.label}-${i}`;
    if (!h.sortKey) {
      return <div key={key}>{h.label}</div>;
    }
    const icon = sortBy.sortKey === h.sortKey && sortBy.desc ? 'he-down' : 'he-up';
    return (
      <Button
        key={key}
        onClick={() => handleSort(h.sortKey)}
        icon={<Icon icon={icon} />}
        iconPosition="right"
        activity="secondary"
      >
        {h.label}
      </Button>
    );
  });

  const getListItemColumns = (variable) => {
    const isSelected = selectedIds.includes(variable.id);
    const cols = [
      <div
        key={`${variable.id}-col-1`}
        className="table-cell select-column"
      >
        <Button design="text">
          <Icon
            className={`checkbox ${isSelected ? 'checked' : ''}`}
            icon={`${isSelected ? 'he-checkbox-selected' : 'he-checkbox'}`}
            size="s"
            onClick={(e) => {
              e.stopPropagation();
              onToggleSelection(variable.id);
            }}
          />
        </Button>
      </div>,
      <>
        {variable.visualizations?.length ? (
          <Icon
            icon={'he-line-chart-alt'}
            size="s"
          />
        ) : null}
        <div className="ellipsed-text">{variable.name}</div>
      </>,
      <div
        key={`${variable.id}-col-3`}
        className="ellipsed-text"
      >
        {variable.itemDesignation}
      </div>,
      <div
        key={`${variable.id}-col-4`}
        className="ellipsed-text"
      >
        {variable.source.name}
      </div>,
      <div
        key={`${variable.id}-col-5`}
        className="ellipsed-text"
      >{`${variable.stateset?.name || ''}`}</div>,
      <div
        key={`${variable.id}-col-6`}
        className="blocked-item-container"
      >
        {variable.components.map((c, i) => (
          <div
            key={`comp-${i}`}
            className="blocked-item"
          >
            {`${c.name} (${c.itemDesignation})`}
          </div>
        ))}
      </div>,
    ];

    // don't include statesets if in wizard
    if (wizardType) {
      return cols.slice(0, 2);
    }
    return cols;
  };

  const allSelected = variables?.length && variables?.length === selectedIds.length;
  const columnWidths = {
    type: 'percent',
    widths: ['-', 20, 20, 15, 15, 30],
  };

  const signalList = useMemo(() => {
    return (
      <div
        ref={clientRef}
        className="manage-variables-body"
      >
        <p className="signals">{`Manage signals (${totalVariables})`} </p>

        <div className="handle-selected-signals">
          <label className="select-all-label">
            <Icon
              className={`select-all ${allSelected ? 'checked' : ''}`}
              icon={`${allSelected ? 'he-checkbox-selected' : 'he-checkbox'}`}
              size="s"
              onClick={toggleSelectAllVariables}
            />
            Select all
          </label>
          {selectedIds.length > 0 && (
            <Button
              onClick={() => setShowDownloadForm(true)}
              activity="secondary"
              icon={<Icon icon="he-download" />}
              tooltip="Download selected"
              slim={true}
            />
          )}
        </div>
        <div className="list-variable-container manage-variables-body">
          <ListItem
            isHeader
            columns={headerColumns}
            columnWidths={columnWidths}
            withSelect
          />
          <div className="custom-thin-scrollbar">
            {variables?.map((v) => (
              <ListItem
                key={v.id}
                entity={`Signal (${v.name})`}
                item={v}
                columns={getListItemColumns(v)}
                columnWidths={columnWidths}
                onSubmit={(update) => onUpdateVariable(v.source_id, update)}
                onDelete={() => onDeleteVariable(v.source_id, v.id)}
                confirmationDialogTitle={'Delete signal'}
                confimationDialogBody={
                  <p style={{ paddingTop: '1.5rem' }}>
                    Are you sure you want to remove this signal?
                  </p>
                }
                schema={varSchema}
                uiSchema={updateVarUiSchema}
                withSelect
              />
            ))}
          </div>
        </div>
      </div>
    );
  }, [variables, varSchema]);

  const wizClass = wizardType ? `--${wizardType}` : '';

  if (!variables) {
    return (
      <div className="loading-container">
        <Spinner
          size="s"
          className="spinner"
        />
      </div>
    );
  }

  return (
    <>
      <Heading
        contentLeft={
          <div className="signals-header">
            <div className="title">Signals</div>
            <Input
              type="text"
              onChange={onSearchFilter}
              value={searchFilter}
              placeholder="Search signal name"
            />

            <div className="source-select">
              <CustomSelect
                isMulti={false}
                onChange={handleSourceFilterChange}
                creatable={false}
                value={sourceFilter}
                rawOptions={sourceFilterOptions}
                closeMenuOnSelect={true}
                placeholder="No sources available"
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '100%',
                    height: '36px',
                    minHeight: '36px',
                  }),
                }}
              />
            </div>

            <div className="filter-select">
              <CustomSelect
                isMulti={true}
                popout
                onChange={handleSelectedFiltersChange}
                label="Signal filters"
                creatable={false}
                value={selectedFilters}
                rawOptions={filterOptions}
                closeMenuOnSelect={true}
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '100%',
                    minHeight: '36px',
                  }),
                }}
              />
            </div>
          </div>
        }
        contentRight={
          <Button
            onClick={() => setShowCreateVariableForm(true)}
            className={`button-create${wizardType ? wizClass : ''}`}
            icon={<Icon icon="he-add" />}
          >
            Add Signal
          </Button>
        }
      />
      {signalList}
      <Pagination
        pages={Math.ceil(totalVariables / queryLimit)}
        page={page}
        setPage={setPage}
      />
      {showCreateVariableForm && (
        <EditItemModal
          entity="Signal"
          formData={createVarData}
          schema={createVarSchema}
          uiSchema={variableUiSchema}
          onChange={onChangeCreateVariable}
          onSubmit={onCreateVariable}
          onCloseModal={handleClose}
        />
      )}
      {showDownloadForm && !!selectedIds.length && (
        <ExportTimeSeriesModal
          selectedVariables={variables.filter((v) => selectedIds.includes(v.id))}
          onCloseModal={handleCloseExportModal}
          onExportCompleted={handleExportCompleted}
        />
      )}
    </>
  );
};

export default VariableView;
