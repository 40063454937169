import React from 'react';
import { useSelector } from 'react-redux';
import parseISO from 'date-fns/parseISO';

import { datetimeString, utcToSite } from '../../datetimeUtils';
import { getTimezone } from '../../bundles/application';

const formatDate = (dateString, timezone) =>
  datetimeString(utcToSite(parseISO(dateString), timezone), timezone);

const ErrorDisplay = ({ error, action }) => {
  const timezone = useSelector(getTimezone);

  if (!error) return null;

  return (
    <div className="error-display">
      {error.failedRuns} failures when {action} between
      <span className="time">{formatDate(error.minDate, timezone)}</span>
      and
      <span className="time">{formatDate(error.maxDate, timezone)}</span>
    </div>
  );
};

export default ErrorDisplay;
