#global-modal-container .simple-modal-container.signal-viewer-template-modal, #global-modal-container .simple-modal-container.tfr-template-modal {
  z-index: 10000;
}
#global-modal-container .simple-modal-container.signal-viewer-template-modal .simple-modal-overlay .simple-modal-wrapper, #global-modal-container .simple-modal-container.tfr-template-modal .simple-modal-overlay .simple-modal-wrapper {
  margin-top: 5rem;
}
#global-modal-container .simple-modal-container.signal-viewer-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog, #global-modal-container .simple-modal-container.tfr-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog {
  margin-top: 5rem;
}
#global-modal-container .simple-modal-container.signal-viewer-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog.delete-template .template-name, #global-modal-container .simple-modal-container.tfr-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog.delete-template .template-name {
  font-weight: bold;
}
#global-modal-container .simple-modal-container.signal-viewer-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog .body, #global-modal-container .simple-modal-container.tfr-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog .body {
  padding: 0.5rem 0;
}
#global-modal-container .simple-modal-container.signal-viewer-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog .body span, #global-modal-container .simple-modal-container.tfr-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog .body span {
  font-weight: 700;
}
#global-modal-container .simple-modal-container.signal-viewer-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog .actions, #global-modal-container .simple-modal-container.tfr-template-modal .simple-modal-overlay .simple-modal-wrapper.confirmation-dialog .actions {
  width: auto;
  margin-left: 1rem;
}
#global-modal-container .simple-modal-container.signal-viewer-template-modal .text-danger, #global-modal-container .simple-modal-container.tfr-template-modal .text-danger {
  margin-top: 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2lnbmFsVmlld2VyQ29udGFpbmVyL2NvbXBvbmVudHMvTmV3VGVtcGxhdGVNb2RhbCIsInNvdXJjZXMiOlsiTmV3VGVtcGxhdGVNb2RhbC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0VBRUU7O0FBR0U7RUFDRTs7QUFFQTtFQUNFOztBQUdFO0VBQ0U7O0FBSUo7RUFDRTs7QUFFQTtFQUNFOztBQUlKO0VBQ0U7RUFDQTs7QUFNUjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiI2dsb2JhbC1tb2RhbC1jb250YWluZXIge1xuICAuc2ltcGxlLW1vZGFsLWNvbnRhaW5lciB7XG4gICAgJi5zaWduYWwtdmlld2VyLXRlbXBsYXRlLW1vZGFsLFxuICAgICYudGZyLXRlbXBsYXRlLW1vZGFsIHtcbiAgICAgIHotaW5kZXg6IDEwMDAwO1xuXG4gICAgICAuc2ltcGxlLW1vZGFsLW92ZXJsYXkge1xuICAgICAgICAuc2ltcGxlLW1vZGFsLXdyYXBwZXIge1xuICAgICAgICAgIG1hcmdpbi10b3A6IDVyZW07XG5cbiAgICAgICAgICAmLmNvbmZpcm1hdGlvbi1kaWFsb2cge1xuICAgICAgICAgICAgbWFyZ2luLXRvcDogNXJlbTtcblxuICAgICAgICAgICAgJi5kZWxldGUtdGVtcGxhdGUge1xuICAgICAgICAgICAgICAudGVtcGxhdGUtbmFtZSB7XG4gICAgICAgICAgICAgICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgICAgICAgICAgIH1cbiAgICAgICAgICAgIH1cblxuICAgICAgICAgICAgLmJvZHkge1xuICAgICAgICAgICAgICBwYWRkaW5nOiAwLjVyZW0gMDtcblxuICAgICAgICAgICAgICBzcGFuIHtcbiAgICAgICAgICAgICAgICBmb250LXdlaWdodDogNzAwO1xuICAgICAgICAgICAgICB9XG4gICAgICAgICAgICB9XG5cbiAgICAgICAgICAgIC5hY3Rpb25zIHtcbiAgICAgICAgICAgICAgd2lkdGg6IGF1dG87XG4gICAgICAgICAgICAgIG1hcmdpbi1sZWZ0OiAxcmVtO1xuICAgICAgICAgICAgfVxuICAgICAgICAgIH1cbiAgICAgICAgfVxuICAgICAgfVxuXG4gICAgICAudGV4dC1kYW5nZXIge1xuICAgICAgICBtYXJnaW4tdG9wOiAwLjVyZW07XG4gICAgICB9XG4gICAgfVxuICB9XG59XG5cbiJdfQ== */