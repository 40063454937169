import React, { useState } from 'react';

import ImportStep from './substeps/ImportStep';
import ManageStep from './substeps/ManageStep';
import VisualizeStep from './substeps/VisualizeStep';
import StepNavigator from '../StepNavigator';
import SubStepIndicator from '../SubStepIndicator';

const STEPS = [
  {
    component: ImportStep,
    name: 'upload',
    label: 'Upload signals',
  },
  {
    component: ManageStep,
    name: 'manage',
    label: 'Manage signals',
  },
  {
    component: VisualizeStep,
    name: 'visualize',
    label: 'Visualize components',
  },
];

const VariableStep = ({ site, onNext, onBack, wizardType }) => {
  const [step, setStep] = useState(0);

  const StepComponent = STEPS[step].component;

  const onAdvance = () => (step === STEPS.length - 1 ? onNext() : setStep(step + 1));
  const onPrevious = () => (step === 0 ? onBack() : setStep(step - 1));

  const getNextLabel = () => {
    if (step === 0) {
      return 'Skip';
    }
    if (step === 2) {
      return 'Done';
    }
    return null;
  };

  return (
    <>
      <div className="wizard-step-top">
        <div className="wizard-step-top__header">
          <h1>Visualize signals</h1>
        </div>
        <SubStepIndicator
          steps={STEPS.map(({ name, label }) => ({ name, label }))}
          activeStep={STEPS[step].name}
          setStep={setStep}
        />
      </div>
      <StepComponent
        site={site}
        onNext={onAdvance}
        wizardType={wizardType}
      />
      <StepNavigator
        onBack={onPrevious}
        nextLabel={getNextLabel()}
        onNext={onAdvance}
      />
    </>
  );
};

export default VariableStep;
