const getVariableSchema = ({
  sortedSiteVars,
  units,
  variableSources,
  DEFAULT_AGGREGATION_OPTIONS,
}) => ({
  maxItems: 10,
  type: 'array',
  title: 'Signals',
  items: {
    type: 'object',
    required: ['id', 'aggregate'],
    properties: {
      id: {
        type: 'string',
        title: 'Signal',
        default: sortedSiteVars?.[0]?.id,
        placeholder: 'Select signal',
        noOptionsMessage: 'No signals available',
        anyOf: sortedSiteVars?.length
          ? sortedSiteVars.map((v) => ({
              const: v.id,
              title: `${v.stateset ? v.stateset.name : ''} [ ${
                variableSources.find((s) => s.id === v.source_id).name || '-'
              } ] [ ${v.itemDesignation || '-'} ] ${v.name}`,
            }))
          : [],
      },
      label: {
        type: 'string',
        title: 'Label',
      },
      unit: {
        type: 'string',
        title: 'Unit',
        default: units[0].id,
        oneOf: units.map((unit) => ({
          const: unit.id,
          title: `${unit.category}: ${unit.name}`,
        })),
      },
      decimals: {
        type: 'string',
        title: 'Decimals',
        default: 'auto',
        enum: ['auto', '0', '1', '2', '3', '4', '5'],
      },
    },
    dependencies: {
      // https://github.com/rjsf-team/react-jsonschema-form/issues/1703
      id: {
        oneOf: variableSources.map((source) => {
          const { variables = [null], aggregationOptions, aggregationOptionNames } = source;

          const fillOptions = [
            { const: 'null', title: 'Show gaps (linear interpolation)' },
            { const: 'null-monotone', title: 'Show gaps (polynomial interpolation)' },
            { const: 'connect', title: 'Connect data points (linear interpolation)' },
            { const: 'connect-monotone', title: 'Connect data points (polynomial interpolation)' },
          ];
          if (source.type === 'dec') {
            fillOptions.push(
              {
                const: 'previous',
                title: 'Fill missing data points using previous value (linear interpolation)',
              },
              {
                const: 'previous-monotone',
                title: 'Fill missing data points using previous value (polynomial interpolation)',
              }
            );
          }

          let aggOptions = DEFAULT_AGGREGATION_OPTIONS.map(({ value, label }) => ({
            const: value,
            title: label,
          }));

          if (aggregationOptions && aggregationOptionNames) {
            aggOptions = aggregationOptions.map((agg, i) => ({
              const: agg,
              title: aggregationOptionNames[i],
            }));
          } else if (source.options?.aggregationOptions) {
            aggOptions = aggregationOptions.map((agg) => ({
              const: agg,
              title: agg.charAt(0).toUpperCase() + agg.slice(1),
            }));
          }

          const sourceOptions = {
            properties: {
              id: { enum: variables },
              aggregate: {
                type: 'string',
                title: 'Aggregate',
                default: 'max',
                oneOf: aggOptions,
              },
              fill: {
                title: 'Fill',
                description: 'How to fill any gaps in the series data',
                type: 'string',
                default: 'null',
                oneOf: fillOptions,
              },
            },
          };

          if (source.type === 'dummy') {
            sourceOptions.properties.quality = {
              title: 'Quality',
              description: 'Set data quality',
              type: 'string',
              default: '1',
              oneOf: [
                { const: '1', title: 'Valid' },
                { const: '2', title: 'Invalid' },
                { const: 'random', title: 'Random' },
              ],
            };
          }

          return sourceOptions;
        }),
      },
    },
  },
});

const updateVisualizationSchema = ({ visualization, siteVariables, schema, colors }) => {
  const baseSchema = schema(visualization.type);
  const varsWithDefinedAgg = (visualization.variables || [])
    .filter((v) => v.aggregate)
    .map((v) => {
      const { name: varName, itemDesignation } = siteVariables.find((sv) => sv.id === v.id);
      return {
        ...v,
        seriesLabel: `[ ${itemDesignation || '-'} ] ${v.label || varName} (${v.aggregate})`,
      };
    });

  if (visualization.type === 'graph' && varsWithDefinedAgg.length) {
    baseSchema.properties.series = {
      type: 'array',
      title: 'Series',
      items: {
        type: 'object',
        properties: {
          axis: {
            type: 'string',
            title: 'Y-axis position',
            default: 'left',
            enum: ['left', 'right'],
          },
          id: {
            type: 'string',
            title: 'Signal id',
            default: `${varsWithDefinedAgg[0].id}.${varsWithDefinedAgg[0].aggregate}`,
            oneOf: varsWithDefinedAgg.map((variable) => ({
              const: `${variable.id}.${variable.aggregate}`,
              title: variable.seriesLabel,
            })),
          },
          color: {
            type: 'string',
            title: 'Color',
            default: colors[0].value,
            oneOf: colors.map((color) => ({
              const: color.value,
              title: color.label,
            })),
          },
        },
      },
    };
  }

  if (visualization.type === 'duval' && varsWithDefinedAgg.length) {
    const duvalSignalIdSchema = {
      type: 'string',
      default: `${varsWithDefinedAgg[0].id}.${varsWithDefinedAgg[0].aggregate}`,
      oneOf: varsWithDefinedAgg.map((v) => ({
        const: `${v.id}.${v.aggregate}`,
        title: v.seriesLabel,
      })),
    };
    baseSchema.properties.plots = {
      maxItems: 3,
      type: 'array',
      title: 'Plots',
      items: {
        type: 'object',
        title: 'Configure plot',
        properties: {
          label: {
            type: 'string',
            title: 'Label',
          },
          color: {
            type: 'string',
            title: 'Color',
            default: colors[0].value,
            oneOf: colors.map((color) => ({
              const: color.value,
              title: color.label,
            })),
          },
          rollingAverageColor: {
            type: 'string',
            title: 'Rolling average color',
            default: colors[1].value,
            oneOf: colors.map((color) => ({
              const: color.value,
              title: color.label,
            })),
          },
          leftId: {
            title: 'Left axis signal',
            ...duvalSignalIdSchema,
          },
          rightId: {
            title: 'Right axis signal',
            ...duvalSignalIdSchema,
          },
          bottomId: {
            title: 'Bottom axis signal',
            ...duvalSignalIdSchema,
          },
        },
      },
    };
  }

  if (visualization.type === 'star' && varsWithDefinedAgg.length) {
    const starSignalIdSchema = {
      type: 'string',
      default: `${varsWithDefinedAgg[0].id}.${varsWithDefinedAgg[0].aggregate}`,
      oneOf: varsWithDefinedAgg.map((v) => ({
        const: `${v.id}.${v.aggregate}`,
        title: v.seriesLabel,
      })),
    };
    baseSchema.properties.plot = {
      type: 'object',
      title: 'Assign signals to plot vectors',
      properties: {
        label: {
          type: 'string',
          title: 'Label',
        },
        L1: {
          title: 'Vector 1 signal id',
          ...starSignalIdSchema,
        },
        L2: {
          title: 'Vector 2 signal id',
          ...starSignalIdSchema,
        },
        L3: {
          title: 'Vector 3 signal id',
          ...starSignalIdSchema,
        },
        color: {
          type: 'string',
          title: 'Color',
          default: colors[0].value,
          oneOf: colors.map((color) => ({
            const: color.value,
            title: color.label,
          })),
        },
      },
    };
  }

  return baseSchema;
};

export { getVariableSchema, updateVisualizationSchema };
