import { matchPath } from 'react-router-dom';
import { isFunction, removeTrailingSlash } from '../../../utils';
/*
 * @param {String} [url] a url to match against routeTrails.
 * @param {Object} [context] a object holding context to enable title creation
 * @param {RouteTrail[]} [routeTrails] define breadcrumbs based on route paths.
 *
 * @return {breadCrumb} Holding information to render a clickable item or link.
 */
function createBreadCrumb(url, context, routeTrails) {
  const breadCrumb = routeTrails
    .map((trailDef) => {
      const match = matchPath(url, {
        path: trailDef.route,
        exact: true,
        strict: false,
      });

      return {
        ...trailDef,
        match,
        title:
          match && isFunction(trailDef.title) ? trailDef.title(context, match) : trailDef.title,
      };
    })
    .find((c) => c.match);
  return breadCrumb;
}

function routeTrail({ context, routeTrails, url }) {
  let currentUrl = '';

  return (
    url
      .split('/')
      .map((urlPart) => {
        if (urlPart) {
          currentUrl = `${currentUrl}/${urlPart}`;
        }
        return currentUrl;
      })
      .map((curUrl) => createBreadCrumb(curUrl, context, routeTrails))
      .filter((bc) => bc) || []
  );
}

function getNewUrl({ activeTree, url }) {
  let newUrl = removeTrailingSlash(url).replace(/\/pages\/\d*.*/gm, '');
  if (activeTree?.page >= 0) {
    newUrl = `${newUrl}/pages/${activeTree.page}`;
  }
  if (activeTree?.section >= 0) {
    newUrl = `${newUrl}/sections/${activeTree.section}`;
  }
  if (activeTree?.column >= 0) {
    newUrl = `${newUrl}/columns/${activeTree.column}`;
  }
  if (activeTree?.panel >= 0) {
    newUrl = `${newUrl}/panels/${activeTree.panel}`;
  }
  return newUrl;
}

export { createBreadCrumb, routeTrail, getNewUrl };
