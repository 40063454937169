import React from 'react';

import Heading from '../../../../../../Heading';

const Option = ({ option, importType, onSelect }) => (
  <div
    className="import-option"
    onClick={() => onSelect(option.name)}
  >
    <div className="option-select-outer">
      <div className={`option-select-inner ${importType !== option.name ? 'hidden' : ''}`} />
    </div>
    <p>{option.text}</p>
  </div>
);

const ChoiceStep = ({ importType, setChoice }) => (
  <>
    <div className="wizard-step-main__top choice-step">
      <Heading
        contentLeft={
          <div className="title">How would you like to construct the component tree?"</div>
        }
      />
    </div>
    <div className="wizard-step-main__body">
      <Option
        option={{ name: 'import', text: 'Import components from an Excel' }}
        importType={importType}
        onSelect={setChoice}
      />
      <Option
        option={{ name: 'manual', text: 'Manually add components' }}
        importType={importType}
        onSelect={setChoice}
      />
    </div>
  </>
);

export default ChoiceStep;
