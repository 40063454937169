import React, { useEffect, useState, useRef } from 'react';

const TextPreview = ({ file, fullScreenRef }) => {
  const isMounted = useRef(true);
  const [textContent, setTextContent] = useState(null);

  useEffect(() => {
    file.text().then((text) => {
      if (isMounted.current) {
        setTextContent(text);
      }
    });

    return () => {
      isMounted.current = false;
    };
  }, [file]);

  return (
    <div
      className="text-preview-content custom-scrollbar"
      ref={fullScreenRef}
      tabIndex="1"
    >
      <pre>{textContent}</pre>
    </div>
  );
};

export default TextPreview;
