.loading-layer {
  position: relative;
}
.loading-layer .loader-component {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvTG9hZGluZ0xheWVyIiwic291cmNlcyI6WyJMb2FkaW5nTGF5ZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5sb2FkaW5nLWxheWVyIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICAubG9hZGVyLWNvbXBvbmVudCB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRvcDogMDtcbiAgICByaWdodDogMDtcbiAgICBib3R0b206IDA7XG4gICAgbGVmdDogMDtcbiAgfVxufSJdfQ== */