import React, { useCallback } from 'react';

import SimpleModal from '../../../../../SimpleModal';
import JSONEditor from '../../../../../JSONEditor';

const AddInvitationModal = ({
  formData,
  onCloseModal = () => {},
  onSubmit = () => {},
  onChange = () => {},
  schema,
  uiSchema,
  invitations,
  submitDisabled,
}) => {
  const handleSubmit = ({ formData: userData }) => {
    onSubmit(userData);
  };

  const onFormChange = ({ formData: updatedFormData }) => {
    onChange({ ...updatedFormData });
  };

  const customValidate = useCallback(
    (userInput, errors) => {
      invitations.forEach((invitation) => {
        if (invitation.email.toLowerCase() === userInput.email.toString().toLowerCase()) {
          errors.email.addError(
            `An invitation for ${invitation.email.toLowerCase()} already exists.`
          );
        }
      });
      return errors;
    },

    [invitations]
  );

  return (
    <SimpleModal
      onClose={onCloseModal}
      allowOverlayScroll
      className="add-user-modal-component"
      title="Invite users"
    >
      <div className="add-user-modal">
        <JSONEditor
          submitDisabled={submitDisabled}
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          saveButtonText={'Save'}
          onFormChange={onFormChange}
          customValidate={customValidate}
          initialEditMode
          showEditButton={false}
          cancelCallback={onCloseModal}
          onFormSubmit={handleSubmit}
          editorOnly
        />
      </div>
    </SimpleModal>
  );
};

export default AddInvitationModal;
