import thresholdSchema from './thresholdSchema';

export const schema = {
  type: 'object',
  properties: {
    label: {
      type: 'string',
      title: 'Display widget name',
    },
    ...thresholdSchema,
  },
};

export const uiSchema = {};
