import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Can } from '@iq/react-components';
import { useHistory, useParams } from 'react-router-dom';

import {
  requestTasks,
  updateTask,
  deleteTask,
  requestJobs,
  getTask,
  getTaskJobs,
} from '../../bundles/tasks';
import { useFilesSession, requestFiles, getFiles } from '../../bundles/files';
import { getUploads } from '../../bundles/uploads';
import { getActiveSite } from '../../bundles/sites';
import { getStaticComponents, requestComponents } from '../../bundles/components';
import { requestTypes, getTypes } from '../../bundles/types';
import { requestTags, getTags } from '../../bundles/tags';

import Delete from '../Delete';
import Heading from '../Heading';
import JobListItem from './components/JobListItem';
import JSONEditor from '../JSONEditor';
import { getTaskSchema, taskUiSchema } from './schemas/taskSchema';

const ManageTaskView = () => {
  const history = useHistory();
  const params = useParams();
  const { algorithmId, taskId } = params;
  const dispatch = useDispatch();

  const site = useSelector(getActiveSite);
  const task = useSelector((state) => getTask(state, taskId));
  const jobs = useSelector((state) => getTaskJobs(state, taskId));
  const uploads = useSelector(getUploads);
  const components = useSelector(getStaticComponents);
  const tags = useSelector(getTags);
  const types = useSelector(getTypes);

  const taskSchema = getTaskSchema({
    components,
    tags,
    types,
  });

  const sessionId = useFilesSession();
  const { files } = useSelector((state) => getFiles(state, sessionId));

  useEffect(() => {
    if (site.id) {
      dispatch(requestTypes(site.id));
      dispatch(requestComponents(site.id));
      dispatch(requestTags(site.id));
      dispatch(requestTasks({ site: site.id }));
      dispatch(requestJobs(undefined));
    }
  }, [site.id]);

  useEffect(() => {
    if (site.id && site.org) {
      dispatch(
        requestFiles(sessionId, {
          org: site.org,
          site: site.id,
          contextType: 'job',
          fileType: 'pdf',
          sortBy: 'filename',
          order: 'asc',
        })
      );
    }
  }, [site]);

  useEffect(() => {
    let pollingDelay;
    if (uploads.length) {
      pollingDelay = setTimeout(() => dispatch(requestTasks({ site: site.id })), 3000);
    }
    return () => clearTimeout(pollingDelay);
  }, [uploads]);

  const onDelete = useCallback(
    (id) => {
      dispatch(deleteTask(id));
      history.push(`/sites/${site.id}/admin/algorithms`);
    },
    [site.id]
  );

  const onUpdate = useCallback(
    (id, data) => {
      const { config, ...taskData } = data;

      const taskToUpdate = {
        ...taskData,
        config: JSON.parse(config),
      };

      if (!taskToUpdate.attributes) {
        delete taskToUpdate.attributes;
      }

      dispatch(updateTask(id, taskToUpdate));
    },
    [site.id]
  );

  if (!task) return null;

  return (
    <>
      <Heading
        contentLeft={<div className="title">{`Task: ${task.name}`}</div>}
        contentRight={
          <Button
            onClick={() => history.push(`/sites/${site.id}/admin/algorithms/${algorithmId}/tasks`)}
            activity="secondary"
          >
            Close
          </Button>
        }
      />

      <JSONEditor
        title="Properties"
        formData={{
          ...task,
          config: JSON.stringify(task.config, null, 4),
        }}
        uiSchema={taskUiSchema}
        schema={taskSchema}
        onFormSubmit={({ formData }) => onUpdate(task.id, { ...task, ...formData })}
        saveButtonText={'Update task'}
        ignoreKeys={[
          'id',
          'site',
          'org',
          'triggerType',
          'triggerConditions',
          'outputLabels',
          'algorithm_id',
          'config',
        ]}
      />

      <Can
        permission="algorithms/Delete"
        scope={{ org: site.org, site: site.id }}
      >
        <div className="task-column-content">
          <Delete
            onDelete={() => onDelete(task.id)}
            title="Delete task"
          />
        </div>
      </Can>

      <Heading contentLeft={<div className="title">Jobs</div>} />

      {jobs.map((job) => (
        <JobListItem
          job={job}
          key={job.id}
          file={files && files.find((f) => f.context.identifier === job.id)}
        />
      ))}
    </>
  );
};

export default ManageTaskView;
