.layout-item.styled-item {
  margin: auto;
  margin-bottom: 0.5rem;
}
.layout-item.styled-item > .styled-item__content > .styled-item__header .updated {
  opacity: 0.7;
  font-family: monospace;
  font-size: 0.9rem;
}
.layout-item.styled-item > .styled-item__content > .styled-item__header .avt-react-components--icon {
  transform: rotate(0);
  transition: transform 0.1s linear;
}
.layout-item.styled-item.open > .styled-item__content > .styled-item__header .avt-react-components--icon {
  transform: rotate(0.25turn);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9MYXlvdXRWaWV3L2NvbXBvbmVudHMvTGF5b3V0SXRlbSIsInNvdXJjZXMiOlsiTGF5b3V0SXRlbS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTs7QUFHRTtFQUNFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBTUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5sYXlvdXQtaXRlbS5zdHlsZWQtaXRlbSB7XG4gIG1hcmdpbjogYXV0bztcbiAgbWFyZ2luLWJvdHRvbTogMC41cmVtO1xuXG4gID4gLnN0eWxlZC1pdGVtX19jb250ZW50ID4gLnN0eWxlZC1pdGVtX19oZWFkZXIge1xuICAgIC51cGRhdGVkIHtcbiAgICAgIG9wYWNpdHk6IDAuNztcbiAgICAgIGZvbnQtZmFtaWx5OiBtb25vc3BhY2U7XG4gICAgICBmb250LXNpemU6IDAuOXJlbTtcbiAgICB9XG5cbiAgICAuYXZ0LXJlYWN0LWNvbXBvbmVudHMtLWljb24ge1xuICAgICAgdHJhbnNmb3JtOiByb3RhdGUoMCk7XG4gICAgICB0cmFuc2l0aW9uOiB0cmFuc2Zvcm0gMC4xcyBsaW5lYXI7XG4gICAgfVxuICB9XG5cbiAgJi5vcGVuIHtcbiAgICA+IC5zdHlsZWQtaXRlbV9fY29udGVudCA+IC5zdHlsZWQtaXRlbV9faGVhZGVyIHtcbiAgICAgIC5hdnQtcmVhY3QtY29tcG9uZW50cy0taWNvbiB7XG4gICAgICAgIHRyYW5zZm9ybTogcm90YXRlKDAuMjV0dXJuKTtcbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */