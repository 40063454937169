.column-component {
  min-height: 100%;
  overflow-y: visible;
  max-width: 100%;
}
.column-component .column-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.column-component .column-title:last-child {
  margin-bottom: 0;
}
.column-component::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.column-component::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.column-component .column-content {
  height: 100%;
}
.column-component .column-content .panel-component:last-child.files-component-type .drop-wrapper {
  min-height: 26rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvQ29sdW1uIiwic291cmNlcyI6WyJDb2x1bW4uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBR0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBRUE7RUFDRTs7QUFJSjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHSTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbHVtbi1jb21wb25lbnQge1xuICBtaW4taGVpZ2h0OiAxMDAlO1xuICAvLyBoZWlnaHQ6IDA7XG4gIC8vIG92ZXJmbG93LXk6IGhpZGRlbjsgY2hhbmdlZCB0byBhY2NvbW1vZGF0ZSBwb3BvdXQgc2VsZWN0cyBleHRlbmRpbmcgYmV5b25kIGxhc3QgcGFuZWxcbiAgb3ZlcmZsb3cteTogdmlzaWJsZTtcbiAgbWF4LXdpZHRoOiAxMDAlO1xuXG4gIC5jb2x1bW4tdGl0bGUge1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG4gICAgbWFyZ2luLWJvdHRvbTogMC41cmVtO1xuXG4gICAgJjpsYXN0LWNoaWxkIHtcbiAgICAgIG1hcmdpbi1ib3R0b206IDA7XG4gICAgfVxuICB9XG5cbiAgJjo6LXdlYmtpdC1zY3JvbGxiYXIge1xuICAgIC13ZWJraXQtYXBwZWFyYW5jZTogbm9uZTtcbiAgICB3aWR0aDogN3B4O1xuICB9XG5cbiAgJjo6LXdlYmtpdC1zY3JvbGxiYXItdGh1bWIge1xuICAgIGJvcmRlci1yYWRpdXM6IDRweDtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiByZ2JhKDAsIDAsIDAsIDAuNSk7XG4gICAgLXdlYmtpdC1ib3gtc2hhZG93OiAwIDAgMXB4IHJnYmEoMjU1LCAyNTUsIDI1NSwgMC41KTtcbiAgfVxuXG4gIC5jb2x1bW4tY29udGVudCB7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIC5wYW5lbC1jb21wb25lbnQ6bGFzdC1jaGlsZCB7XG4gICAgICAmLmZpbGVzLWNvbXBvbmVudC10eXBlIHtcbiAgICAgICAgLmRyb3Atd3JhcHBlciB7XG4gICAgICAgICAgbWluLWhlaWdodDogMjZyZW07XG4gICAgICAgIH1cbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */