import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { Button, Icon } from '@iq/react-components';
import classnames from 'classnames';

import SimpleModal from '../../SimpleModal';
import {
  WizardTypeStep,
  MemberStep,
  ComponentStep,
  ModelStep,
  SourceStep,
  VariableStep,
  // SuccessStep,
  StepIndicator,
} from './components';
import { requestSources, getSources } from '../../../bundles/sources';
import { updateSite as setCreationState } from '../../../bundles/sites';
import { requestModels, getModelIdsWithProcessingVersions } from '../../../bundles/models';
import InstructionalModal from '../../InstructionalModal';

const WIZARD_SELECT_STEP = {
  component: WizardTypeStep,
  name: 'select',
  title: 'Choose a configuration plan to start',
};
const BASIC_STEPS = [
  WIZARD_SELECT_STEP,
  {
    component: MemberStep,
    title: 'Add site members',
    name: 'member',
    icon: 'he-user',
  },
  {
    component: ComponentStep,
    title: 'Set up component tree',
    name: 'component',
    icon: 'he-schema',
  },
  {
    component: ModelStep,
    title: 'Upload 3D & 2D models',
    name: 'model',
    icon: 'he-cube',
  },
  {
    component: ComponentStep,
    title: 'Set up component tree',
    name: 'component',
    icon: 'abb-network',
  },
];
const ADVANCED_STEPS = [
  ...BASIC_STEPS,
  {
    component: SourceStep,
    title: 'Add data sources',
    name: 'source',
    icon: 'he-storage',
  },
  {
    component: VariableStep,
    title: 'Visualize signals',
    name: 'variable',
    icon: 'he-line-chart-alt',
  },
];

/*
 *  const SUCCESS_STEP = {
 *    component: SuccessStep,
 *    name: 'success',
 *    title: 'Choose a configuration plan to start',
 *  };
 */

const WIZARDS = {
  basic: BASIC_STEPS,
  advanced: ADVANCED_STEPS,
};

const InitComponent = () => {
  const [showInstruction, setShowInstruction] = useState(false);
  const fullScreenRef = useRef(null);

  return (
    <div className="wizard-info-init">
      <p>
        As a site manager, you want to create essential content like the component tree, content
        panels, upload CAD models, etc. for your target audience.
      </p>
      <div className="cta-image-wrapper">
        <div className="cta-image" />
      </div>
      {/* <Button
        design="text"
        icon={<Icon icon="he-popup" />}
        onClick={() => setShowInstruction(true)}>
        Site manager training tutorial
      </Button> */}
      {showInstruction && (
        <InstructionalModal
          fullScreenRef={fullScreenRef}
          onClose={() => setShowInstruction(false)}
        />
      )}
    </div>
  );
};

const Wizard = ({ site, onClose }) => {
  const dispatch = useDispatch();
  const sources = useSelector(getSources);
  const processingIds = useSelector(getModelIdsWithProcessingVersions);

  const [step, setStep] = useState(0);
  const [wizardType, setWizardType] = useState(null);

  const [wizardSteps, setWizardSteps] = useState([]);
  const { component: StepComponent } = wizardSteps.length ? wizardSteps[step] : WIZARD_SELECT_STEP;

  const completedSteps = useMemo(() => {
    if (site.creationState) {
      return (site.creationState.find(({ name }) => name === wizardType) || {}).steps || [];
    }
    return [];
  }, [site, wizardType]);
  const completedStepNames = completedSteps.map(({ name }) => name);

  useEffect(() => {
    dispatch(requestSources({ siteId: site.id, withVariables: false })); // pre-fetch for source step
    dispatch(requestModels(site.id)); // pre-fetch for model step, processing check
  }, []);

  const restart = () => {
    setWizardType(null);
    setWizardSteps([]);
    setStep(0);
  };
  const prevStep = () => {
    if (step === 1) restart();
    setStep((currentStep) => Math.max(currentStep - 1, 0));
  };
  const nextStep = () => {
    const currentStepName = wizardSteps[step].name;
    const alreadyComplete = completedStepNames.includes(currentStepName);
    if (step !== 0 && !alreadyComplete) {
      const unchangedState = (site.creationState || []).filter(({ name }) => name !== wizardType);
      dispatch(
        setCreationState(site.id, {
          creationState: [
            ...unchangedState,
            { name: wizardType, steps: [...completedSteps, { name: currentStepName }] },
          ],
        })
      );
    }
    if (step === wizardSteps.length - 1) {
      onClose();
    }
    setStep((currentStep) => Math.min(currentStep + 1, wizardSteps.length - 1));
  };

  const onWizardType = (selectedWizType) => {
    setWizardType(selectedWizType);
    setStep(1);
    setWizardSteps(WIZARDS[selectedWizType]);
  };

  const header = {
    // basic: <div className="step-header">Basic</div>, // Revert when re-adding advanced wizard option
    basic: <div className="step-header">Back</div>,
    advanced: <div className="step-header">Advanced setup</div>,
    default: (
      <div className="cta-header">
        Set up your <strong>IdentiQ</strong> now.
      </div>
    ),
  }[wizardType || 'default'];
  const headerClass = classnames({
    '--advanced': wizardType === 'advanced',
    '--basic': wizardType === 'basic',
  });

  const checkDisabled = (wizStep) => {
    if (wizStep === 4) {
      return !sources.length;
    }
    return false;
  };

  const stepIndicators = useMemo(
    () =>
      wizardSteps.slice(1).map((wizStep, i) => {
        const key = `step-${i}`;
        return (
          <StepIndicator
            key={key}
            title={wizStep.title}
            icon={wizStep.icon}
            active={step === i + 1}
            checked={completedStepNames.includes(wizStep.name)}
            wizardType={wizardType}
            onSelect={() => setStep(i + 1)}
            disabled={checkDisabled(i)}
            loading={wizStep.name === 'model' && processingIds.length}
          />
        );
      }),
    [wizardSteps, step, completedStepNames, processingIds]
  );

  return (
    <SimpleModal
      className="setup-wizard-wrapper"
      size="l"
      onClose={onClose}
      overlayCanClose={false}
      noHeader="noHeader"
      noBodyPadding
    >
      <Icon
        size="xs"
        icon="he-close"
        onClick={onClose}
      />
      <div className={`setup-wizard wizard-info wizard-info${headerClass}`}>
        <div className={`wizard-info__header${headerClass}`}>
          {step !== 0 ? (
            <Icon
              size="m"
              icon="he-backwards"
              onClick={restart}
            />
          ) : null}
          {header}
        </div>
        {step === 0 ? (
          <InitComponent />
        ) : (
          <ul className="step-indicator-container">{stepIndicators}</ul>
        )}
      </div>
      <div className={`setup-wizard-component setup-wizard-component${headerClass}`}>
        <StepComponent
          site={site}
          step={step}
          wizardType={wizardType}
          onWizardType={onWizardType}
          onBack={prevStep}
          onNext={nextStep}
        />
      </div>
    </SimpleModal>
  );
};

export default Wizard;
