.edit-page-component .properties-title,
.edit-page-component .sections-title {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 1rem 0;
}
.edit-page-component .delete-page {
  padding: 1rem;
  text-align: center;
}

.dark-theme .edit-page-component .json-editor-component .prop-table .row,
.dark-theme .edit-page-component .json-editor-component .key,
.dark-theme .edit-page-component .json-editor-component .value {
  border: 1px solid rgb(25.5, 25.5, 25.5);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUFkbWluVmlldy9MYXlvdXRWaWV3L2NvbXBvbmVudHMvRWRpdFBhZ2UiLCJzb3VyY2VzIjpbIkVkaXRQYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0U7QUFBQTtFQUVFO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7OztBQU9FO0FBQUE7QUFBQTtFQUdFIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnQGlxL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2NzcycgYXMgY29sb3JzO1xuXG4uZWRpdC1wYWdlLWNvbXBvbmVudCB7XG4gIC5wcm9wZXJ0aWVzLXRpdGxlLFxuICAuc2VjdGlvbnMtdGl0bGUge1xuICAgIGZvbnQtc2l6ZTogMS4xcmVtO1xuICAgIGZvbnQtd2VpZ2h0OiA1MDA7XG4gICAgbWFyZ2luOiAxcmVtIDA7XG4gIH1cblxuICAuZGVsZXRlLXBhZ2Uge1xuICAgIHBhZGRpbmc6IDFyZW07XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICB9XG59XG5cbi5kYXJrLXRoZW1lIHtcbiAgLmVkaXQtcGFnZS1jb21wb25lbnQge1xuICAgIC5qc29uLWVkaXRvci1jb21wb25lbnQge1xuICAgICAgLnByb3AtdGFibGUgLnJvdyxcbiAgICAgIC5rZXksXG4gICAgICAudmFsdWUge1xuICAgICAgICBib3JkZXI6IDFweCBzb2xpZCBjb2xvcnMuJEdyZXk5MDtcbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */