import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { scaleTime, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Grid } from '@visx/grid';
import { localPoint } from '@visx/event';
import {
  useTooltip,
  useTooltipInPortal,
  defaultStyles as defaultTooltipStyle,
} from '@visx/tooltip';

import {
  usePanelDateDomain,
  useSelectedDateDomain,
  useActiveTimelineData,
  useOnSelectedDateDomainChange,
  useStyling,
  useZScale,
  useUserEventTypes,
  useEventTypeFilter,
} from './EventTimelineState';
import { UserEventTooltip } from './EventTooltips';
import services from '../../../services';
import { getTimezone } from '../../../bundles/application';
import { displayNotification, checkIsOnline } from '../../../bundles/notifications';
import getNotification from '../../../bundles/notification-defaults';
import { useClientSize } from '../../../utils';
import { getDateTickFormatter } from '../../../datetimeUtils';
import {
  USER_EVENTS,
  EVENT_DEFAULT_MARGIN,
  EVENT_TYPE_USER,
  EVENT_TYPE_ALL,
  STATELESS_EVENTS,
} from '../../../constants';
import EventLegends from './EventLegends';
import { formatYTick, useEventPicker } from './utils';
import { getLimitedUsers } from '../../../bundles/ad';

function renderUserEventTooltip(event, timezone, comments = [], showStatus = false) {
  return {
    eventId: event.id,
    tooltip: (
      <UserEventTooltip
        event={event}
        comments={comments}
        showStatus={showStatus}
        timezone={timezone}
      />
    ),
  };
}

function getTaskSortedEventTypes(userEventTypes, allEventTypes) {
  const taskTypes = userEventTypes
    .filter((eventType) => allEventTypes.find(({ id }) => eventType === id)?.task)
    .sort((a, b) => a - b);
  const nonTaskTypes = userEventTypes
    .filter((eventType) => !taskTypes.includes(eventType))
    .sort((a, b) => a - b);
  return [...taskTypes, ...nonTaskTypes];
}

const UserTimeline = ({ margin = EVENT_DEFAULT_MARGIN, plotSize = 4, fullWidth, onOpenEvent }) => {
  const timezone = useSelector(getTimezone);
  const users = useSelector(getLimitedUsers);
  const dispatch = useDispatch();
  const plotSize2 = plotSize * 2;

  const zScale = useZScale();

  const [{ width }, clientRef] = useClientSize();

  const { tickStroke, background, indicatorStroke, xTickLabelProps, yTickLabelProps } =
    useStyling();

  const selectedDateDomain = useSelectedDateDomain();
  const panelDateDomain = usePanelDateDomain();
  const [eventTypeFilter] = useEventTypeFilter();
  const onZoomChange = useOnSelectedDateDomainChange();
  let periodInMs =
    selectedDateDomain?.length === 2 ? selectedDateDomain[1] - selectedDateDomain[0] : 0;
  if (!periodInMs) {
    periodInMs = panelDateDomain?.length === 2 ? panelDateDomain[1] - panelDateDomain[0] : 0;
  }
  const hitMaxZoom = periodInMs <= 2;

  const {
    response: {
      userEvents = [],
      userEventTypes = [],
      iotBuckets = [],
      dateDomain = selectedDateDomain?.length ? selectedDateDomain : panelDateDomain,
    } = {},
  } = useActiveTimelineData();
  const allEventTypes = useUserEventTypes();

  const userEventsRef = useRef(userEvents);
  userEventsRef.current = userEvents;

  const [activeUserEvent, setActiveUserEvent] = useState(null);
  const [eventComments, setEventComments] = useState(null);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    updateTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });
  const onOpenUserEvent = useCallback(
    (e) => {
      const { index } = e.target.dataset;
      if (onOpenEvent && index) {
        const event = userEventsRef.current[index];
        onOpenEvent({
          event,
          id: event.id,
          type: 'user',
        });
      }
    },
    [onOpenEvent]
  );
  const openTooltip = async (event) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    const { index } = event.target.dataset;
    const userEvent = userEventsRef.current[index];
    if (userEvent) {
      setActiveUserEvent(() => userEvent);
      userEvent.assigneeName = userEvent.assignedTo
        ? users.filter((x) => x.id === userEvent.assignedTo)[0]?.name
        : '';
    }
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: renderUserEventTooltip(userEvent, timezone),
    });
  };

  useEffect(() => {
    if (activeUserEvent) {
      (async () => {
        const { values: comments = [] } = await services.collaboration
          .getComments({ parentId: activeUserEvent.id })
          .then(
            (res) => res,
            (e) => {
              console.error('Unable to fetch comments: ', e);
              dispatch(checkIsOnline());
              dispatch(displayNotification(getNotification('getComments', 'error')()));
              return {};
            }
          );
        setEventComments(() => ({ eventId: activeUserEvent.id, comments }));
      })();
    }
  }, [activeUserEvent]);

  useEffect(() => {
    if (
      eventComments?.comments?.length &&
      activeUserEvent &&
      eventComments.eventId === activeUserEvent.id &&
      eventComments.eventId === tooltipData.eventId
    ) {
      updateTooltip({
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData: renderUserEventTooltip(activeUserEvent, timezone, eventComments.comments),
      });
    }
  }, [eventComments, activeUserEvent]);

  const closeUserEventTooltip = () => {
    setActiveUserEvent(() => null);
    setEventComments(() => null);
    hideTooltip();
  };

  const userEventHeight = 25;
  const paddingBottom = userEventHeight;
  const iotEventHeight = userEventHeight * 4;
  let height = userEventHeight * userEventTypes.length + margin.top + margin.bottom;

  if (userEventTypes.length === 0) {
    height += 100;
  }

  const xSize = width - margin.right - margin.left;
  const ySize = height - margin.top - margin.bottom;
  const xScale = useMemo(
    () =>
      scaleTime({
        domain: [dateDomain[0], dateDomain[1]],
        range: [margin.left, width - margin.right],
        clamp: true,
      }),
    [dateDomain, width]
  );
  const sortedEventTypes = getTaskSortedEventTypes(userEventTypes, allEventTypes);
  const yScale = useMemo(() => {
    const domain = sortedEventTypes.length > 0 ? [...sortedEventTypes] : ['default'];
    let range = sortedEventTypes.map((_, i) => ySize + margin.top - userEventHeight * (i + 1));
    if (sortedEventTypes.length === 0) {
      range = [margin.top + iotEventHeight / 2];
    }
    return scaleOrdinal({ domain, range });
  }, [
    sortedEventTypes,
    ySize,
    userEventHeight,
    height,
    paddingBottom,
    iotEventHeight,
    iotBuckets.length,
  ]);

  const dragState = useRef({
    isDragging: false,
    startX: 0,
  });
  const zoomRef = useRef();

  const onDragStart = useCallback((e) => {
    dragState.current.isDragging = true;
    const { x } = localPoint(e);
    dragState.current.startX = x;
  }, []);
  const onDrag = useCallback(
    (e) => {
      if (dragState.current.isDragging) {
        const { x: x2 } = localPoint(e);
        const x = dragState.current.startX;
        const min = Math.max(xScale.range()[0], Math.min(x, x2));
        const max = Math.min(xScale.range()[1], Math.max(x, x2));
        if (zoomRef.current) {
          const el = zoomRef.current;
          el.setAttribute('x', min);
          el.setAttribute('width', Math.max(0, max - min));
        }
      }
    },
    [xScale]
  );
  const onDragEnd = useCallback(
    (e) => {
      if (dragState.current.isDragging) {
        const { x: endX } = localPoint(e);
        dragState.current.isDragging = false;
        if (endX !== dragState.current.startX && !hitMaxZoom) {
          const cx1 = Math.min(endX, dragState.current.startX);
          const cx2 = Math.max(endX, dragState.current.startX);
          onZoomChange([xScale.invert(cx1).getTime(), xScale.invert(cx2).getTime(), null]);
        }
        if (zoomRef.current) {
          const el = zoomRef.current;
          el.setAttribute('x', 0);
          el.setAttribute('width', 0);
        }
      }
    },
    [xSize, onZoomChange, xScale]
  );

  const [eventPicker] = useEventPicker();

  const userShapes = useMemo(
    () => (
      <>
        {userEvents.map((event, idx) => {
          const size = plotSize * 4;
          const halfSize = size / 2;
          const svg = USER_EVENTS.filter((x) => x.id === event.state);
          const isStateLessEvent = STATELESS_EVENTS.includes(event.type);

          if (event.from === event.to || !event.to) {
            return (
              <rect
                className="timeline-chart-event"
                key={event.id}
                x={xScale(new Date(event.from).getTime()) - halfSize}
                y={yScale(event.type) - halfSize}
                rx={halfSize}
                ry={halfSize}
                width={size}
                height={size}
                fill={
                  // eslint-disable-next-line no-nested-ternary
                  isStateLessEvent ? '#FFFFFF' : !isStateLessEvent ? svg[0]?.color : '#A9A2AB'
                }
                stroke={svg[0]?.border || '#666666'}
                strokeWidth="2"
                onMouseOver={openTooltip}
                onMouseOut={closeUserEventTooltip}
                onClick={onOpenUserEvent}
                data-index={idx}
              />
            );
          }
          const startDate = new Date(event.from).getTime();
          const endDate = new Date(event.to).getTime();
          const startDomain = dateDomain[0];
          const endDomain = dateDomain[1];
          const croppedStart = startDate <= startDomain;
          const croppedEnd = endDate >= endDomain;

          const startPlot = croppedStart ? xScale(startDomain) : xScale(startDate);
          const endPlot = croppedEnd ? xScale(endDomain) : xScale(endDate);
          return (
            <rect
              className="timeline-chart-event"
              key={event.id}
              x={startPlot - halfSize}
              y={yScale(event.type) - halfSize}
              rx={halfSize}
              ry={halfSize}
              width={size + Math.max(0, endPlot - startPlot)}
              height={size}
              fill={svg[0]?.color || '#A9A2AB'}
              stroke={svg[0]?.border || '#666666'}
              strokeWidth="2"
              onMouseOver={openTooltip}
              onMouseOut={closeUserEventTooltip}
              onClick={onOpenUserEvent}
              data-index={idx}
            />
          );
        })}
      </>
    ),
    [
      closeUserEventTooltip,
      showTooltip,
      onOpenUserEvent,
      openTooltip,
      xScale,
      yScale,
      zScale,
      userEvents,
      plotSize,
      plotSize2,
    ]
  );

  const presentIndicator = useMemo(() => {
    const now = Date.now();

    if (dateDomain[1] - now <= 0) {
      return null;
    }

    const x = xScale(now) || 0;
    return (
      <line
        x1={x}
        y1={0}
        x2={x}
        y2={height - margin.bottom}
        stroke={indicatorStroke}
        strokeOpacity={0.5}
      />
    );
  }, [xScale, height, dateDomain]);

  const eventStatus = (props) => {
    const { state } = props.event;
    const eventState = USER_EVENTS.find((e) => e.id === state);
    return eventState?.border;
  };
  return (
    <>
      {[EVENT_TYPE_USER, EVENT_TYPE_ALL].includes(eventTypeFilter) && (
        <>
          <EventLegends
            fullWidth={fullWidth}
            type={'user'}
          />
          <div
            ref={clientRef}
            className="event-timeline-chart-area"
          >
            <svg
              width={width}
              height={height}
              ref={containerRef}
              onTouchMove={onDrag}
              onTouchEnd={onDragEnd}
              onMouseUp={onDragEnd}
              onMouseLeave={onDragEnd}
              onMouseMove={onDrag}
            >
              {presentIndicator}
              <rect
                ref={zoomRef}
                fillOpacity={0}
                stroke="currentColor"
                strokeDasharray="1,2"
                width={0}
                height={height - margin.top - margin.bottom - 10}
                y={margin.top - 5}
              />
              <rect
                fillOpacity={0}
                x={margin.left}
                stroke={tickStroke}
                width={Math.max(0, width - margin.left - margin.right - 2)}
                height={height - margin.bottom}
                onTouchStart={onDragStart}
                onMouseDown={onDragStart}
              />
              <Grid
                xScale={xScale}
                yScale={yScale}
                width={Math.max(0, width - margin.right - margin.left)}
                height={height}
                left={margin.left}
                columnLineStyle={{ display: 'none' }}
                stroke={tickStroke}
              />
              {userShapes}
              <rect
                // covers any overflow from chart
                x={0}
                y={0}
                width={margin.left}
                height={height}
                fill={background}
              />
              <AxisLeft
                scale={yScale}
                left={margin.left}
                hideAxisLine
                stroke={tickStroke}
                tickStroke="transparent"
                tickLabelProps={yTickLabelProps}
                tickFormat={(val) => formatYTick(val, allEventTypes)}
              />
              <AxisBottom
                scale={xScale}
                top={height - margin.bottom}
                hideAxisLine
                stroke={tickStroke}
                tickStroke={tickStroke}
                tickLabelProps={xTickLabelProps}
                numTicks={Math.max(Math.floor(xSize / 80), 1)}
                tickFormat={getDateTickFormatter(periodInMs, timezone, {
                  scale: xScale,
                  width: width - margin.right,
                })}
              />
              <rect
                // covers removed end tick line
                x={width - margin.right - 20}
                y={height - margin.bottom + 1}
                width={30}
                height={8}
                fill={background}
              />
            </svg>
            {tooltipOpen && (
              <TooltipInPortal
                // set this to random so it correctly updates with parent bounds
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft}
                style={{
                  ...defaultTooltipStyle,
                  padding: 0,
                  borderRadius: '0.1rem',
                  backgroundColor: 'transparent',
                  border: `1px solid ${eventStatus(tooltipData.tooltip.props)}`,
                  zIndex: 10000,
                }}
              >
                {tooltipData.tooltip}
              </TooltipInPortal>
            )}
            {eventPicker}
          </div>
        </>
      )}
    </>
  );
};

export default UserTimeline;
