export const fileComponentsValidator = ({ file, identifier }) => {
  const errors = {};
  let hasError = false;
  if (!file.components || !file.components.length) {
    hasError = true;
    errors[identifier] = {
      ...errors[identifier],
      components: 'You must select at least one component',
    };
  }
  return { hasError, errors };
};

export const fileNameValidator = ({ file, identifier, filesToValidate }) => {
  const errors = {};
  let hasError = false;
  const duplicateFiles = filesToValidate
    .filter(
      (item, index) => filesToValidate.findIndex((f) => f.file.name === item.file.name) !== index
    )
    .flatMap((f) => f.file.name);

  if (duplicateFiles.includes(file.file.name)) {
    hasError = true;
    errors[identifier] = {
      ...errors[identifier],
      name: 'Duplicate file name was specified',
    };
  }
  return { hasError, errors };
};

export const fileTypeValidator = ({ file, identifier }) => {
  const errors = {};
  let hasError = false;
  if (!file.type) {
    hasError = true;
    errors[identifier] = {
      ...errors[identifier],
      type: 'You must select type',
    };
  }
  return { hasError, errors };
};

export const fileTagsValidator = ({ file, identifier, limitTags, availableTags }) => {
  const errors = {};
  let hasError = false;
  if (!file || !identifier || !limitTags || !availableTags) return { hasError, errors };

  const hasIncludedTags = limitTags.allIncludes;
  const hasTagsSelected = file.tags && file.tags.length > 0;

  let hasAnyIncludedTagSelected = false;
  if (hasIncludedTags) {
    hasAnyIncludedTagSelected =
      hasTagsSelected &&
      file.tags.map((tag) => limitTags.allIncludes.map((t) => t.id).includes(tag));
  }

  if (limitTags.allIncludes && (!hasTagsSelected || !hasAnyIncludedTagSelected)) {
    const incTagsByName = availableTags
      .filter((tag) => limitTags.allIncludes.map((t) => t.id).includes(tag.id))
      .map((tag) => tag.name)
      .join(', ');
    hasError = true;
    errors[identifier] = {
      ...errors[identifier],
      tags: `You must select one of the following tags: ${incTagsByName} `,
    };
  }

  if (!hasIncludedTags && (!file.tags || !file.tags.length)) {
    hasError = true;
    errors[identifier] = {
      ...errors[identifier],
      tags: 'You must select at least one tag',
    };
  }

  if (
    limitTags.excludesTogether &&
    limitTags.excludesTogether.every((tag) => (file.tags || []).includes(tag))
  ) {
    const excTagsByName = availableTags
      .filter((tag) => limitTags.excludesTogether.includes(tag.id))
      .map((tag) => tag.name)
      .join(', ');
    hasError = true;
    errors[identifier] = {
      ...errors[file.id],
      tags:
        'Files in this context can not contain all of the following tags ' +
        `simultaneously; ${excTagsByName}.`,
    };
  }
  return { hasError, errors };
};

export const validateFileOnPropertyChange = ({
  key,
  file,
  identifier,
  limitTags,
  availableTags,
}) => {
  let errorObject = file.errors || undefined;

  if (key === 'type') {
    if (errorObject && errorObject.type) {
      delete errorObject.type;
    }
    const { errors } = fileTypeValidator({
      file,
      identifier,
    });
    errorObject = { ...errorObject, ...errors[identifier] };
  }

  if (key === 'tags') {
    if (errorObject && errorObject.tags) {
      delete errorObject.tags;
    }
    const { errors } = fileTagsValidator({
      file,
      identifier,
      limitTags,
      availableTags,
    });
    errorObject = { ...errorObject, ...errors[identifier] };
  }

  if (key === 'components') {
    if (errorObject && errorObject.components) {
      delete errorObject.components;
    }
    const { errors } = fileComponentsValidator({
      file,
      identifier,
    });
    errorObject = { ...errorObject, ...errors[identifier] };
  }

  const hasError = errorObject && Object.keys(errorObject).length > 0;
  return { file, errors: errorObject, hasError };
};

const fileUpsertValidation = ({
  file,
  identifier,
  limitTags,
  availableTags,
  filesToValidate,
  isEditing = false,
}) => {
  let hasError = false;
  let objectOfErrors = {};

  const name =
    !isEditing &&
    fileNameValidator({
      file,
      identifier,
      filesToValidate,
    });

  const component = fileComponentsValidator({
    file,
    identifier,
  });

  const type = fileTypeValidator({
    file,
    identifier,
  });

  const tags = fileTagsValidator({
    file,
    identifier,
    limitTags,
    availableTags,
  });

  hasError = name?.hasError || component.hasError || type.hasError || tags.hasError;
  objectOfErrors = { ...name?.errors, ...component.errors, ...type.errors, ...tags.errors };

  return { hasError, objectOfErrors };
};

export default fileUpsertValidation;
