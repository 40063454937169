import React from 'react';
import GridItem from './GridItem';

const FileGrid = ({
  files,
  selectedFiles,
  getDropdownOptions,
  format,
  isFullScreen,
  onPreview,
  onSelectFile,
  isShiftPressed,
  disableSelect,
  maxNameLength,
}) => {
  const items = files.map((item) => (
    <GridItem
      item={item}
      getDropdownOptions={getDropdownOptions}
      selectedFiles={selectedFiles}
      key={item.id}
      onPreview={onPreview}
      onSelectFile={onSelectFile}
      isShiftPressed={isShiftPressed}
      disableSelect={disableSelect}
      truncateLength={maxNameLength}
    />
  ));

  return (
    <div className="file-grid-component">
      <div className="grid">
        <div className={`grid-content ${isFullScreen ? 'widest' : format}`}>{items}</div>
      </div>
    </div>
  );
};

export default FileGrid;
