import { all } from 'redux-saga/effects';

import { sagas as ad } from './bundles/ad';
import { sagas as algorithms } from './bundles/algorithms';
import { sagas as application } from './bundles/application';
import { sagas as auth } from './bundles/auth';
import { sagas as bookmarks } from './bundles/bookmarks';
import { sagas as components } from './bundles/components';
import { sagas as events } from './bundles/events';
import { sagas as files } from './bundles/files';
import { sagas as geometryMappings } from './bundles/geometry-mappings';
import { sagas as integrations } from './bundles/integrations';
import { sagas as layouts } from './bundles/layouts';
import { sagas as models } from './bundles/models';
import { sagas as notifications } from './bundles/notifications';
import { sagas as sites } from './bundles/sites';
import { sagas as sources } from './bundles/sources';
import { sagas as statesets } from './bundles/statesets';
import { sagas as systemMessages } from './bundles/systemMessages';
import { sagas as tags } from './bundles/tags';
import { sagas as tasks } from './bundles/tasks';
import { sagas as types } from './bundles/types';
import { sagas as uploads } from './bundles/uploads';
import { sagas as variables } from './bundles/variables';
import { sagas as visualizations } from './bundles/visualizations';
import { sagas as tfrTemplates } from './bundles/tfr-templates';

function* watchAll() {
  yield all([
    ...ad,
    ...algorithms,
    ...application,
    ...auth,
    ...bookmarks,
    ...components,
    ...events,
    ...files,
    ...geometryMappings,
    ...integrations,
    ...layouts,
    ...models,
    ...notifications,
    ...sites,
    ...sources,
    ...statesets,
    ...systemMessages,
    ...tags,
    ...tasks,
    ...types,
    ...uploads,
    ...variables,
    ...visualizations,
    ...tfrTemplates,
  ]);
}

export default watchAll;
