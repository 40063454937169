const getTaskSchema = ({ tags, components, types }) => {
  const typeOptions = [
    {
      const: null,
      title: 'None',
    },
    ...types.map((type) => ({
      const: type,
      title: type,
    })),
  ];

  const componentOptions = components.map((comp) => ({
    const: comp.id,
    title: `${comp.name} (${comp.itemDesignation})`,
  }));

  const tagOptions = tags.map((tag) => ({
    const: tag.id,
    title: tag.name,
  }));

  return {
    type: 'object',
    required: ['config', 'name', 'triggerType'],

    description: '',

    properties: {
      config: {
        type: 'string',
        title: 'Configuration',
        description: 'Enter site configuration',
        default: '',
        rows: 5,
        placeholder: 'JSON config...',
      },

      name: {
        type: 'string',
        title: 'Name',
      },

      triggerType: {
        type: 'string',
        enum: ['manual', 'event'],
        title: 'Trigger type',
        default: 'event',
      },

      triggerConditions: {
        type: 'object',
        title: 'Trigger Conditions',
        description: 'Select on what files the algorithm should be triggered.',
        properties: {
          type: {
            title: 'Type',
            type: ['string', 'null'],
            default: null,
            uniqueItems: true,
            isClearable: true,
            anyOf: typeOptions,
          },
          components: {
            title: 'Components',
            type: 'array',
            default: [],
            uniqueItems: true,
            isMulti: true,
            isClearable: true,
            items: {
              type: 'string',
              anyOf: componentOptions,
            },
          },
          tags: {
            title: 'Tags',
            type: 'array',
            default: [],
            uniqueItems: true,
            isMulti: true,
            isClearable: true,
            items: {
              type: 'string',
              anyOf: tagOptions,
            },
          },
        },
      },

      outputLabels: {
        type: 'object',
        title: 'Output Labels',
        description: 'Select type, components and tags for the output report file.',

        properties: {
          type: {
            title: 'Type',
            type: ['string', 'null'],
            isClearable: true,
            anyOf: typeOptions,
          },

          tags: {
            title: 'Tags',
            type: 'array',
            default: [],
            uniqueItems: true,
            isMulti: true,
            isClearable: true,
            items: {
              type: 'string',
              anyOf: tagOptions,
            },
          },

          components: {
            title: 'Components',
            type: 'array',
            default: [],
            uniqueItems: true,
            isMulti: true,
            isClearable: true,
            items: {
              type: 'string',
              enum: componentOptions,
            },
          },
        },
      },
    },
  };
};

const taskUiSchema = {
  config: {
    'ui:classNames': 'resize-none',
    'ui:widget': 'textarea',
  },

  triggerType: {
    'ui:widget': 'hidden',
  },
};

export { getTaskSchema, taskUiSchema };
