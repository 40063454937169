import React from 'react';

import SourceView from '../../../SourceView';
import StepNavigator from '../StepNavigator';

const SourceStep = ({ onNext, onBack, wizardType, site }) => (
  <>
    <div className="wizard-step-top">
      <div className="wizard-step-top__header">
        <h1>Add data sources</h1>
      </div>
    </div>
    <div className="wizard-step-main__body--framed custom-thin-scrollbar">
      <SourceView
        wizardType={wizardType}
        site={site}
      />
    </div>
    <StepNavigator
      onBack={onBack}
      onNext={onNext}
    />
  </>
);

export default SourceStep;
