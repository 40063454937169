import React from 'react';

const StyledItem = React.forwardRef((props, ref) => {
  const {
    itemClass,
    active = false,
    itemPre = null, // status indicator, etc
    headerLabel, // visible label
    headerContent, // visible content
    headerActions, // visible action buttons
    bodyActions, // collapsibe content actions
    bodyActionsPosition = 'right',
    children, // collapsible content
    onClick = () => null,
  } = props;

  return (
    <div
      ref={ref}
      className={`styled-item ${itemClass || ''} ${active ? 'open' : 'closed'}`}
    >
      {itemPre}
      <div className="styled-item__content">
        <div
          className="styled-item__header"
          onClick={onClick}
        >
          {headerLabel ? <div className="styled-item__header__label">{headerLabel}</div> : null}
          <div className="styled-item__header__info">{headerContent}</div>
          <div className="styled-item__header__actions">{headerActions}</div>
        </div>

        <div className="styled-item__body">
          {children}
          {bodyActions ? (
            <div className={`button-row ${bodyActionsPosition}`}>{bodyActions}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default StyledItem;
