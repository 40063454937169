/* eslint-disable default-param-last */

import { call, takeLatest, put, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { displayNotification, checkOnline } from './notifications';
import getNotification from './notification-defaults';
import { CLEAR_SITE_DATA } from './application';

import {
  getAlgorithms as getAlgorithmsApi,
  createAlgorithm as createAlgorithmApi,
  deleteAlgorithm as deleteAlgorithmApi,
  updateAlgorithm as updateAlgorithmApi,
} from '../services';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const REQUEST_ALGORITHMS = 'dt/algorithms/REQUEST_ALGORITHMS';
export const RECEIVED_ALGORITHMS = 'dt/algorithms/RECEIVED_ALGORITHMS';
export const CREATE_ALGORITHM = 'dt/algorithms/CREATE_ALGORITHM';
export const DELETE_ALGORITHM = 'dt/algorithms/DELETE_ALGORITHM';
export const UPDATE_ALGORITHM = 'dt/algorithms/UPDATE_ALGORITHM';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestAlgorithms = (query = {}) => ({
  type: REQUEST_ALGORITHMS,
  query,
});

export const receiveAlgorithms = (algorithms) => ({
  type: RECEIVED_ALGORITHMS,
  algorithms,
});

export const createAlgorithm = (data) => ({
  type: CREATE_ALGORITHM,
  data,
});

export const deleteAlgorithm = (algorithmId) => ({
  type: DELETE_ALGORITHM,
  algorithmId,
});

export const updateAlgorithm = (algorithmId, data) => ({
  type: UPDATE_ALGORITHM,
  algorithmId,
  data,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  dataLoaded: false,
  algorithms: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVED_ALGORITHMS: {
      return {
        ...state,
        dataLoaded: true,
        algorithms: action.algorithms || [],
      };
    }
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        dataLoaded: false,
        algorithms: [],
      };
    }
    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getAlgorithms = (state) => state.algorithms.algorithms;

export const algorithmsLoaded = (state) => state.algorithms.dataLoaded;

export const getSiteAlgorithms = createSelector(
  getAlgorithms,
  (_, siteId) => siteId,
  (algos, siteId) => algos.filter((a) => a.site === siteId)
);

export const getAlgorithm = createSelector(
  getAlgorithms,
  (_, algoId) => algoId,
  (algos, algoId) => algos.find((alg) => alg.id === algoId)
);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

export function* doRequestAlgorithms(action) {
  const { query } = action;
  try {
    const { values: algorithms } = yield call(getAlgorithmsApi, query);
    yield put(receiveAlgorithms(algorithms));
  } catch (e) {
    console.error('Unable to fetch algorithms: ', e);
    yield put(receiveAlgorithms([]));
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getAlgorithms', 'error')()));
  }
}

function* doCreateAlgorithm(action) {
  const { data } = action;

  try {
    yield call(createAlgorithmApi, data);
    yield call(doRequestAlgorithms, { query: data.site });
    yield put(displayNotification(getNotification('createAlgorithm', 'success')(data.name)));
  } catch (e) {
    console.error('Unable to create algorithm: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createAlgorithm', 'error')()));
  }
}

function* doUpdateAlgorithm(action) {
  const { algorithmId, data } = action;

  try {
    yield call(updateAlgorithmApi, algorithmId, data);
    yield call(doRequestAlgorithms, { query: data.site });
    yield put(displayNotification(getNotification('updateAlgorithm', 'success')(data.name)));
  } catch (e) {
    console.error('Unable to update algorithm: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('updateAlgorithm', 'erorr')()));
  }
}

function* doDeleteAlgorithm(action) {
  const { algorithmId } = action;
  const algorithm = yield select((state) =>
    state.algorithms.algorithms.find((a) => a.id === algorithmId)
  );

  try {
    yield call(deleteAlgorithmApi, algorithmId);
    yield call(doRequestAlgorithms, { site: algorithm.site });
    yield put(displayNotification(getNotification('deleteAlgorithm', 'success')()));
  } catch (e) {
    console.error('Unable to delete algorithm: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteAlgorithm', 'error')()));
  }
}

export const sagas = [
  takeLatest(REQUEST_ALGORITHMS, doRequestAlgorithms),
  takeLatest(CREATE_ALGORITHM, doCreateAlgorithm),
  takeLatest(DELETE_ALGORITHM, doDeleteAlgorithm),
  takeLatest(UPDATE_ALGORITHM, doUpdateAlgorithm),
];
