import React, { useMemo } from 'react';
import { Icon, Spinner, Button } from '@iq/react-components';

import { useSubscription } from '../../../../bundles/visualizations';
import { format } from '../../../../units';

const MetricVisualization = ({ visualization, panelId, isPreview = false }) => {
  const { id, configuration = {} } = visualization;
  const { latestValues: values, initialLoaded, loading } = useSubscription(id, panelId);

  const [value, quality] = useMemo(() => {
    if (initialLoaded && values) {
      const [firstVar] = values;
      const [, lastValue, lastValueQuality] = firstVar?.value || [];
      if (lastValue || lastValue === 0) {
        const currValue = Number.isNaN(lastValue) ? null : lastValue;
        return [currValue, lastValueQuality];
      }
    }
    return [null, 0];
  }, [initialLoaded, values]);

  const { value: formattedValue, unit } = format(
    configuration.unit,
    value ?? configuration.defaultEmptyValue,
    configuration.decimals
  );

  // only invalidate if quality 2 (invalid) or 3 (questionable)
  const isDataInvalid = quality > 1;

  return (
    <div
      className={`metric-visualization-component ${isPreview ? 'preview' : 'normal'} ${
        isDataInvalid ? 'invalid-data' : ''
      }`}
    >
      <div className="wrapper">
        <div className="label">
          {configuration.label}
          {isDataInvalid ? (
            <Button
              tooltip="Invalid signal value"
              design="text"
            >
              <Icon
                icon="he-help"
                size="s"
                color="#FF7300"
              />
            </Button>
          ) : null}
        </div>
        <div className="value-wrapper">
          <div className="value">{formattedValue}</div>
          {(value || value === 0) && (
            <div className="unit">{configuration.unitLabel ? configuration.unitLabel : unit} </div>
          )}
        </div>
        {loading ? <Spinner /> : <Icon icon={configuration.icon} />}
      </div>
    </div>
  );
};

export default MetricVisualization;
