/* eslint-disable default-param-last */
import { call, put, takeLatest } from 'redux-saga/effects';

import { getSystemMessages as getSystemMessagesApi } from '../services';
import { checkOnline } from './notifications';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

export const REQUEST_SYSTEM_MESSAGES = 'dt/systemMessages/REQUEST_SYSTEM_MESSAGES';
export const RECEIVE_SYSTEM_MESSAGES = 'dt/systemMessages/RECEIVE_SYSTEM_MESSAGES';

export const DISMISS_SYSTEM_MESSAGE = 'dt/systemMessages/DISMISS_SYSTEM_MESSAGE';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */

export const requestSystemMessages = () => ({
  type: REQUEST_SYSTEM_MESSAGES,
});

export const dismissSystemMessage = (id) => ({
  type: DISMISS_SYSTEM_MESSAGE,
  systemMessageId: id,
});

export const receiveSystemMessages = (systemMessages) => ({
  type: RECEIVE_SYSTEM_MESSAGES,
  systemMessages,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  systemMessages: {},
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SYSTEM_MESSAGES: {
      return {
        ...state,
        systemMessages: action.systemMessages.reduce((acc, message) => {
          acc[message.id] = {
            ...message,
            ...state.systemMessages[message.id],
          };
          return acc;
        }, {}),
      };
    }
    case DISMISS_SYSTEM_MESSAGE: {
      const { systemMessageId } = action;
      return {
        ...state,
        systemMessages: {
          ...state.systemMessages,
          [systemMessageId]: {
            ...state.systemMessages[systemMessageId],
            dismissed: true,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getSystemMessages = (state) =>
  Object.values(state.systemMessages.systemMessages).filter((message) => !message.dismissed);

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

export function* doRequestSystemMessages() {
  try {
    const { values: systemMessages } = yield call(getSystemMessagesApi);
    yield put(receiveSystemMessages(systemMessages));
  } catch (err) {
    yield console.error(err);
    yield call(checkOnline);
  }
}

export const sagas = [takeLatest(REQUEST_SYSTEM_MESSAGES, doRequestSystemMessages)];
