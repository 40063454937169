const getSiteSchema = (templateOptions) => {
  const schema = {
    type: 'object',
    required: ['name', 'customer', 'location'],
    properties: {
      name: {
        type: 'string',
        title: 'Name',
      },
      customer: {
        type: 'string',
        title: 'Customer',
      },
      isTemplate: {
        type: 'boolean',
        default: false,
        title: 'Set as template site',
        description: 'Should this site be used as a template for other sites?',
      },
      location: {
        type: 'object',
        title: 'Location',
        required: ['lat', 'lng', 'city', 'country'],
        properties: {
          city: {
            type: 'string',
            title: 'City',
          },
          country: {
            type: 'string',
            title: 'Country',
          },
          lat: {
            type: 'number',
            title: 'Latitude',
            minimum: -90,
            maximum: 90,
          },
          lng: {
            type: 'number',
            title: 'Longitude',
            minimum: -180,
            maximum: 180,
          },
        },
      },
      support: {
        type: 'object',
        title: 'Site support info',
        properties: {
          name: {
            type: 'string',
            title: 'Name of support contact',
          },
          email: {
            type: 'string',
            inputType: 'email',
            title: 'Support contact email',
          },
          phone: {
            type: 'string',
            title: 'Support phone number',
          },
        },
      },
    },
  };

  if (templateOptions) {
    schema.properties.template = {
      type: 'string',
      title: 'Template',
      default: 'default',
      anyOf: [
        {
          const: 'default',
          title: 'Default template',
        },
        ...templateOptions.map((template) => ({
          const: template.key,
          title: template.siteName,
        })),
      ],
    };
  }

  return schema;
};

const siteUISchema = { 'ui:options': { orderable: false } };

export { getSiteSchema, siteUISchema };
