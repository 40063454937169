import React from 'react';
import SelectPollingDateRange from '../../SelectPollingDateRange/SelectPollingDateRange';
import './DateTimeRangePickerField.scss';

const DateTimeRangePickerField = ({ schema: { title, panelId, required, pickerId } }) => {
  return (
    <div className={`date-time-picker`}>
      <label>{`${title}${required ? '*' : ''}`}</label>
      <SelectPollingDateRange
        panelId={panelId}
        pickerId={pickerId}
        enableGlobalDates={false}
      />
    </div>
  );
};

export default DateTimeRangePickerField;
