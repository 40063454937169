const HealthPanelSchema = {
  title: {
    type: 'string',
    default: 'Status',
  },
  options: {
    type: 'object',
    properties: {
      showComponentName: {
        type: 'boolean',
        title: 'Show component name',
        default: true,
      },
      showStatus: {
        type: 'boolean',
        title: 'Show status',
        default: true,
      },
      showSuggestions: {
        type: 'boolean',
        title: 'Show suggestions',
        default: true,
      },
      showRiskIfNoActiveComponent: {
        type: 'boolean',
        title: 'Show Risk if no active component',
        default: true,
      },
    },
  },
};

export default HealthPanelSchema;
