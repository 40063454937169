import React from 'react';
import EditPage from '../components/EditPage';
import LayoutItem from '../components/LayoutItem';
import PageList from '../components/PageList';

export default function LayoutContent({
  activeTree,
  layout,
  pages,
  setActiveTreeIndexes,
  updateColumn,
  updatePage,
  updatePages,
  updateSection,
}) {
  return activeTree.page >= 0 && pages.length ? (
    <EditPage
      page={pages[activeTree.page]}
      activeTree={activeTree}
      setActiveTreeIndexes={setActiveTreeIndexes}
      updatePages={updatePages}
      updatePage={updatePage}
      updateSection={updateSection}
      updateColumn={updateColumn}
    />
  ) : (
    <LayoutItem layout={layout}>
      <PageList
        key="page-list"
        updatePages={updatePages}
        pages={pages}
        selectPage={(pageIndex) => {
          setActiveTreeIndexes({
            ...activeTree,
            page: pageIndex,
          });
        }}
      />
    </LayoutItem>
  );
}
