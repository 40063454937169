import React, { useState, useCallback, useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@iq/react-components';

import './ExportEventModal.scss';
import JSONEditor from '../../../JSONEditor';
import SimpleModal from '../../../SimpleModal';
import ExportProgress from '../../../ExportProgress';
import { getUiSchema, getEventSeriesExportSchema } from './exportEventSchema';
import {
  getIsExporting,
  fetchExportEvents,
  stopFetchExportEvents,
} from '../../../../bundles/events';
import { getLimitedUsers } from '../../../../bundles/ad';
import { getTags } from '../../../../bundles/tags';
import { getComponentHashmap } from '../../../../bundles/components';
import { utcToSite, datetimeString } from '../../../../datetimeUtils';
import { displayNotification } from '../../../../bundles/notifications';
import getNotification from '../../../../bundles/notification-defaults';
import {
  useDateSubscription,
  getPollingDateRange,
  setPollingDateRange,
  getTimezone,
} from '../../../../bundles/application';
import {
  useScopedComponentIds,
  useScopedIotComponentIds,
  useScopedToAllComps,
  useFilterParams,
  useEventTypeFilter,
} from '../EventTimelineState';
import { getEventBuckets } from '../../../../services';
import { EXPORT_EVENT_PANEL, EXPORT_EVENT_LIMIT } from '../../../../constants';
import { exportEventsToFile } from '../../../../utils';
import PieChartExport from '../ExportEventStats/ExportEventStats';

const ExportEventModal = ({
  onClose,
  panelId,
  site,
  panelEventType,
  availableIotEventTypes,
  availableUserEventTypes,
  allUserEventTypes,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [validating, setValidating] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [totalEvents, setTotalEvents] = useState(0);
  const [eventRequests, setEventRequests] = useState(0);
  const [eventsData, setEventsData] = useState({});
  const eventPanelDateRange = useSelector((state) => getPollingDateRange(state, panelId));
  const inputDateRange = useDateSubscription(EXPORT_EVENT_PANEL);
  const exporting = useSelector(getIsExporting);
  const allUsers = useSelector(getLimitedUsers);
  const allTags = useSelector(getTags);
  const componentMaps = useSelector(getComponentHashmap);
  const scopedComponentIds = useScopedComponentIds();
  const scopedIotComponentIds = useScopedIotComponentIds();
  const scopedToAllComps = useScopedToAllComps();
  const [filterParams] = useFilterParams();
  const [eventTypeFilter] = useEventTypeFilter();
  const [eventsStats, setEventsStats] = useState([]);
  const [eventsStatsRetCount, setEventsStatsRetCount] = useState(0);

  useEffect(() => {
    dispatch(setPollingDateRange({ ...eventPanelDateRange }, EXPORT_EVENT_PANEL));
  }, [eventPanelDateRange]);

  useEffect(() => {
    setFormData((fd) => ({ ...fd, from: inputDateRange.startDate, to: inputDateRange.endDate }));
  }, [inputDateRange]);

  const schema = useMemo(
    () =>
      getEventSeriesExportSchema(
        panelId,
        allUsers,
        allTags,
        panelEventType,
        availableIotEventTypes,
        availableUserEventTypes,
        filterParams,
        eventTypeFilter,
        EXPORT_EVENT_PANEL
      ),
    [
      allUsers,
      allTags,
      availableIotEventTypes,
      panelEventType,
      availableUserEventTypes,
      filterParams,
      eventTypeFilter,
    ]
  );

  const tooManyEventsMsg = `Event export limit of ${EXPORT_EVENT_LIMIT} events exceeded. Please adjust filters to reduce the number of
        events.`;

  const noEventsMsg = 'There are no events to export.';

  const getTagByName = (tagId) => allTags.find((tag) => tag.id === tagId)?.name || 'N/A';

  const getMappedUserEvents = (events) =>
    events.map((event) => {
      return {
        ...event,
        components: event.components.map((c) => componentMaps[c]?.itemDesignation || 'N/A'),
        tags: event.tags.map((t) => getTagByName(t)),
        createdBy: allUsers.find((user) => user.id === event.createdBy)?.name || 'N/A',
        assignedTo: allUsers.find((user) => user.id === event.assignedTo)?.name || 'N/A',
        type: allUserEventTypes.find((e) => e.id === event.type)?.name || event.type,
      };
    });

  const onExportDone = () => {
    dispatch(displayNotification(getNotification('exportEvents', 'success')('Events')));
    onClose();
  };

  const handleProgressCompleted = (userEvents) => {
    exportEventsToFile({
      siteName: site.name,
      events: formData.eventType === 'user' ? getMappedUserEvents(userEvents) : eventsData.events,
      startDate: formData.to,
      endDate: formData.from,
      exportType: formData.exportType,
      eventType: formData.eventType,
      onExportCompleted: onExportDone,
    });
  };

  const fetchEventsCallback = (eventType, events) => {
    const eventsLength = events.length;
    if (eventsLength === 0) {
      setValidationError(noEventsMsg);
    } else if (!events) {
      onClose();
    } else if (eventType === 'user') {
      handleProgressCompleted(events);
    } else {
      setEventsData({ events, eventsLength });
    }
  };

  const timezone = useSelector(getTimezone);
  const format = (timestamp, withTz = false) =>
    timestamp ? datetimeString(utcToSite(timestamp, timezone), timezone, false, withTz) : '-';

  const onFormSubmit = useCallback(async () => {
    setValidating(true);
    if (formData.exportContent === 'events') {
      const query = {
        ...formData,
        site: site.id,
        order: 'asc',
      };

      if (formData.eventType === 'iot') {
        query.interval = formData.to - formData.from;
        query.types =
          formData.iotTypes.length > 0
            ? formData.iotTypes
            : availableIotEventTypes.map((types) => types.id);
        query.active = formData.active;
        query.components = scopedIotComponentIds;

        try {
          const { values } = await getEventBuckets(query);
          if (values.length > 0) {
            const [{ numEvents }] = values;
            if (numEvents > EXPORT_EVENT_LIMIT) {
              setValidationError(tooManyEventsMsg);
            } else if (numEvents === 0) {
              setValidationError(noEventsMsg);
            } else {
              setTotalEvents(numEvents);
              setShowProgressModal(true);
              dispatch(
                fetchExportEvents({
                  eventType: formData.eventType,
                  query,
                  perRequestCallback: (totalRequests) => setEventRequests(totalRequests),
                  exportCallback: (events) => fetchEventsCallback(formData.eventType, events),
                })
              );
            }
          } else {
            setValidationError(noEventsMsg);
          }
        } catch (error) {
          dispatch(displayNotification(getNotification('exportEvents', 'error')()));
          onClose();
        }
      }
      if (formData.eventType === 'user') {
        query.type =
          formData?.userTypes?.length > 0
            ? formData.userTypes
            : availableUserEventTypes.map((type) => type.id);

        query.components =
          scopedComponentIds.length === 0 || scopedToAllComps ? undefined : scopedComponentIds;

        if (formData?.status && formData.status !== 'all') {
          query.state = [formData.status];
        }
        if (formData?.activity && formData.activity !== 'all') {
          query.planned = formData.status === 'planned';
        }

        dispatch(
          fetchExportEvents({
            eventType: formData.eventType,
            query,
            exportCallback: (events) => fetchEventsCallback(formData.eventType, events),
          })
        );
      }
    } else {
      const query = {
        site: site.id,
        order: 'asc',
        statistics: true,
        withRecurring: true,
        from: formData.from,
        to: formData.to,
      };
      query.type =
        formData?.taskTypes?.length > 0
          ? formData.taskTypes
          : availableUserEventTypes.filter((x) => x.task).map((type) => type.id);
      dispatch(
        fetchExportEvents({
          eventType: 'user',
          query,
          exportCallback: (events) => {
            if (!events || events.length === 0) {
              setValidationError(noEventsMsg);
            } else {
              setShowProgressModal(true);
              setEventsStats(events);
            }
          },
        })
      );
    }
    setValidating(false);
  }, [scopedComponentIds, scopedIotComponentIds, scopedToAllComps, formData]);

  const onHandleClose = useCallback(() => onClose(), []);

  const onHandleChange = useCallback(({ formData: updatedFormData }) => {
    setValidationError('');
    setFormData((fd) => ({ ...fd, ...updatedFormData }));
  }, []);

  const onCancelExport = () => {
    dispatch(stopFetchExportEvents());
    setShowProgressModal(false);
  };

  return (
    <>
      {showProgressModal && (
        <SimpleModal
          onClose={onClose}
          title="Export data"
        >
          <div className="export-progress-modal">
            <ExportProgress
              exportType={formData.exportContent === 'events' ? 'event' : 'signal'}
              useStaticTimer={300}
              retrievedDataPoints={
                formData.exportContent === 'events' ? eventsData.eventsLength : eventsStatsRetCount
              }
              increment={formData.exportContent === 'events' ? eventRequests : 0}
              expectedDataPoints={formData.exportContent === 'events' ? totalEvents : 10000}
              onExportCompleted={handleProgressCompleted}
            />
            <Button
              className="cancel"
              activity="secondary"
              onClick={onCancelExport}
            >
              Cancel
            </Button>
          </div>
        </SimpleModal>
      )}
      {!showProgressModal && (
        <SimpleModal
          onClose={onClose}
          overlayCanClose={true}
          title={'Export events'}
        >
          <div className="export-events-modal">
            <JSONEditor
              formData={formData}
              schema={schema}
              uiSchema={getUiSchema()}
              editorOnly
              cancelCallback={onHandleClose}
              onFormChange={onHandleChange}
              onFormSubmit={onFormSubmit}
              saveButtonText={'Export'}
              initialEditMode={true}
              showEditButton={false}
              submitDisabled={validating || exporting}
              customErrorMessage={validationError}
            />
          </div>
        </SimpleModal>
      )}

      {eventsStats?.length > 0 && (
        <PieChartExport
          site={site.name}
          eventsStats={eventsStats}
          onClose={() => {
            dispatch(displayNotification(getNotification('exportEvents', 'success')('Statistics')));
            onClose();
          }}
          setEventsStatsRetCount={setEventsStatsRetCount}
          dateDisplay={`${format(formData.from)} - ${format(formData.to)}`}
          allUserEventTypes={allUserEventTypes}
        ></PieChartExport>
      )}
    </>
  );
};

ExportEventModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  panelId: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  eventTypes: PropTypes.array.isRequired,
  availableIotEventTypes: PropTypes.array.isRequired,
  availableUserEventTypes: PropTypes.array.isRequired,
  allUserEventTypes: PropTypes.array.isRequired,
};

export default ExportEventModal;
