.video-preview-content {
  height: calc(100% - 5.2rem);
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 8rem);
  padding: 0 0 1rem 1rem;
}
.video-preview-content video {
  max-width: 100%;
  height: 100%;
}
.video-preview-content.no-padding {
  padding: 0;
}
.video-preview-content.media-loading video::-webkit-media-controls {
  visibility: visible;
}
.video-preview-content.media-playing video::-webkit-media-controls {
  visibility: hidden;
}
.video-preview-content.media-playing video::-webkit-media-controls-enclosure {
  visibility: visible;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvRmlsZVByZXZpZXdNb2RhbC90eXBlcy9WaWRlb1ByZXZpZXciLCJzb3VyY2VzIjpbIlZpZGVvUHJldmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi52aWRlby1wcmV2aWV3LWNvbnRlbnQge1xuICBoZWlnaHQ6IGNhbGMoMTAwJSAtIDUuMnJlbSk7IC8vIGhlYWRlciAoYnV0dG9ucyArIHBhZGRpbmcpICsgcGFkLWJ0bVxuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgbWF4LWhlaWdodDogY2FsYygxMDB2aCAtIDhyZW0pO1xuICBwYWRkaW5nOiAwIDAgMXJlbSAxcmVtO1xuXG4gIHZpZGVvIHtcbiAgICBtYXgtd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICB9XG5cbiAgJi5uby1wYWRkaW5nIHtcbiAgICBwYWRkaW5nOiAwO1xuICB9XG5cbiAgJi5tZWRpYS1sb2FkaW5nIHZpZGVvOjotd2Via2l0LW1lZGlhLWNvbnRyb2xzIHtcbiAgICB2aXNpYmlsaXR5OiB2aXNpYmxlO1xuICB9XG5cbiAgJi5tZWRpYS1wbGF5aW5nIHZpZGVvOjotd2Via2l0LW1lZGlhLWNvbnRyb2xzIHtcbiAgICB2aXNpYmlsaXR5OiBoaWRkZW47XG4gIH1cblxuICAmLm1lZGlhLXBsYXlpbmcgdmlkZW86Oi13ZWJraXQtbWVkaWEtY29udHJvbHMtZW5jbG9zdXJlIHtcbiAgICB2aXNpYmlsaXR5OiB2aXNpYmxlO1xuICB9XG59XG4iXX0= */