import React from 'react';

const getFormat = (columns, index) => {
  if (columns === 1) {
    return 'widest';
  }

  if (columns === 2 && index === 0) {
    return 'wide';
  }

  return 'narrow';
};

const Section = ({ children, className, title, align = false, displayTitle }) => {
  const columns = React.Children.toArray(children).length;

  return (
    <div
      className={`section-component ${className} with-${columns}-columns align-${
        align ? 'height' : 'none'
      }`}
    >
      {typeof title !== 'undefined' && displayTitle && <div className="section-title">{title}</div>}
      <div className="section-content">
        {React.Children.map(children, (child, i) => {
          if (child === null) {
            return null;
          }

          const format = getFormat(columns, i);
          return React.cloneElement(child, { format });
        })}
      </div>
    </div>
  );
};

export default Section;
