/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ImagePreview = ({ file, fullScreenRef }) => {
  const imageElement = useRef(null);
  const isMounted = useRef(true);
  const [svgContent, setSvgContent] = useState(null);
  const [isSvg, setIsSvg] = useState(file.name.slice(-4).toLowerCase() === '.svg');
  const [isFullScreen, setIsFullscreen] = useState(false);

  const onChangeFullScreen = () => {
    setIsFullscreen(!isFullScreen);
  };

  useEffect(() => {
    if (fullScreenRef.current) {
      fullScreenRef.current.onfullscreenchange = onChangeFullScreen;
    }
  }, [fullScreenRef.current]);

  useEffect(() => {
    if (file.name.slice(-4).toLowerCase() === '.svg') {
      setIsSvg(true);
      file.text().then((content) => {
        if (isMounted.current) {
          setSvgContent(
            encodeURIComponent(content)
              .replace(/%20/g, ' ')
              .replace(/%3D/g, '=')
              .replace(/%22/g, "'")
          );
        }
      });
    } else {
      setIsSvg(false);
    }

    return () => {
      isMounted.current = false;
    };
  }, [file]);

  const renderImagePreview = () => {
    if (isSvg && svgContent) {
      return <img src={`data:image/svg+xml,${svgContent}`} />;
    }
    if (isSvg) return null;
    return (
      <TransformWrapper
        enablePadding={false}
        enablePanPadding={false}
        wheel={{ step: isFullScreen ? 100 : 4.5 }}
      >
        <TransformComponent>
          <div className="image-pan-wrapper">
            <img
              src={URL.createObjectURL(file)}
              ref={imageElement}
            />
          </div>
        </TransformComponent>
      </TransformWrapper>
    );
  };

  const classes = classnames('image-preview-content');

  return (
    <div
      className={classes}
      ref={fullScreenRef}
    >
      {renderImagePreview()}
    </div>
  );
};

export default ImagePreview;
