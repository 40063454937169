import React from 'react';

import TypeLabel from '../TypeLabel';

const Heading = ({ contentLeft, contentRight, typeLabel, large = false, onClick }) => (
  <div
    className={`heading size-${large ? 'large' : 'small'}`}
    onClick={onClick}
  >
    <div>
      {typeof typeLabel !== 'undefined' && <TypeLabel type={typeLabel} />}
      {contentLeft}
    </div>
    <div>{contentRight}</div>
  </div>
);

export default Heading;
