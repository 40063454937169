import { units } from '../../../../units';
import thresholdSchema from './thresholdSchema';

export const schema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      title: 'Title',
    },
    mode: {
      type: 'string',
      title: 'Mode',
      enum: ['line', 'area', 'step', 'bar'],
      default: 'line',
    },
    legend: {
      type: 'object',
      title: 'Legend',
      properties: {
        show: { type: 'boolean', default: false },
      },
    },
    ...thresholdSchema,
    axes: {
      type: 'object',
      title: 'Axes',
      properties: {
        yLeft: {
          type: 'object',
          title: 'Y-axis (left)',
          properties: {
            label: {
              type: 'string',
              title: 'Label',
            },
            show: { type: 'boolean', default: true },
            unit: {
              type: 'string',
              title: 'Unit',
              default: units[0].id,
              anyOf: units.map((unit) => ({
                const: unit.id,
                title: `${unit.category}: ${unit.name}`,
              })),
            },
            decimals: {
              type: 'string',
              title: 'Decimals',
              default: 'auto',
              enum: ['auto', '0', '1', '2', '3', '4', '5'],
            },
            minValue: {
              type: 'number',
              title: 'Min Value',
            },
            maxValue: {
              type: 'number',
              title: 'Max Value',
            },
          },
        },
        yRight: {
          type: 'object',
          title: 'Y-axis (right)',
          properties: {
            label: {
              type: 'string',
              title: 'Label',
            },
            show: { type: 'boolean', default: false },
            unit: {
              type: 'string',
              title: 'Unit',
              default: units[0].id,
              anyOf: units.map((unit) => ({
                const: unit.id,
                title: `${unit.category}: ${unit.name}`,
              })),
            },
            decimals: {
              default: 'auto',
              title: 'Decimals',
              type: 'string',
              enum: ['auto', '0', '1', '2', '3', '4', '5'],
            },
          },
        },
        x: {
          type: 'object',
          title: 'X-axis',
          properties: {
            show: { type: 'boolean', default: true },
            scale: {
              type: 'string',
              title: 'Scale',
              enum: ['time', 'linear'],
              default: 'time',
            },
          },
        },
      },
    },
  },
};

export const uiSchema = {
  thresholds: {
    areaItems: {
      'ui:options': {
        orderable: false,
      },
    },
    linearItems: {
      'ui:options': {
        orderable: false,
      },
    },
  },
};
